// const parseAnnualLeaveDays = (day, value) => {
// 	if (Array.isArray(day)) {
// 		console.log(day);
// 		return day.map((d) => ({
// 			day: parseInt(d.date, 10),
// 			type: '연차',
// 			value: d.content,
// 		}));
// 	} else if (typeof day === 'string') {
// 		let days = [];

// 		// 📌 "15~17", "15일~17", "15~17일" 같은 연속된 날짜 처리
// 		const rangeMatch = day.match(/(\d+)\D*~\D*(\d+)/);
// 		if (rangeMatch) {
// 			const start = parseInt(rangeMatch[1], 10);
// 			const end = parseInt(rangeMatch[2], 10);

// 			console.log(`📌 범위 파싱: ${start} ~ ${end}`); // 디버깅용 로그

// 			for (let i = start; i <= end; i++) {
// 				days.push({ day: i, type: '연차', value: 1 });
// 			}
// 			return days;
// 		}

// 		// 📌 "9일 , 10일", "9, 10일", "9 ,10" 같은 개별 날짜 처리
// 		const singleDays = day.match(/\d+/g);
// 		if (singleDays) {
// 			return singleDays.map((d) => ({
// 				day: parseInt(d, 10),
// 				type: '연차',
// 				value: 1,
// 			}));
// 		}
// 	}
// 	return [];
// };

// const allAnnualLeavesData = paydocus
// 	.filter((a) => a.usedAnnualLeaves) // ✅ usedAnnualLeaves가 있는 경우만 처리
// 	.flatMap((a) => {
// 		const year = parseInt(a.info.year, 10);
// 		const month = parseInt(a.info.month, 10);
// 		const allAnnualLeavesData = a.usedAnnualLeaveDates || '';

// 		return parseAnnualLeaveDays(allAnnualLeavesData, 1).map((d) => ({
// 			year,
// 			month,
// 			day: d.day,
// 			type: d.type,
// 			value: d.value,
// 		}));
// 	})
// 	.filter((a) => a.day);

// console.log(allAnnualLeavesData);

import { koreanHolidays } from './util';

export const parseLeaveDays = (day, type, value) => {
	const unit =
		type === '연차' || type === '주휴공제' || type === '결근' ? '일' : '시간';

	if (Array.isArray(day)) {
		return day.map((d) => ({
			day: parseInt(d.date, 10),
			type: d.type || type, // type이 지정되지 않으면 기본값 사용
			value: `${Number(parseFloat(d.content)) || value}${unit}`,
		}));
	} else if (typeof day === 'string') {
		let days = [];

		// 📌 "15~17", "15일~17", "15~17일" 같은 연속된 날짜 처리
		const rangeMatch = day.match(/(\d+)\D*~\D*(\d+)/);
		if (rangeMatch) {
			const start = parseInt(rangeMatch[1], 10);
			const end = parseInt(rangeMatch[2], 10);
			const dayCount = end - start + 1; // 날짜 범위 내의 총 날짜 수 계산
			const distributedValue = value / dayCount; // value를 날짜 수로 나눈 값

			for (let i = start; i <= end; i++) {
				days.push({ day: i, type, value: `${distributedValue}${unit}` });
			}
			return days;
		}

		// 📌 "9일 , 10일", "9, 10일", "9 ,10" 같은 개별 날짜 처리
		const singleDays = day.match(/\d+/g);
		if (singleDays) {
			const dayCount = singleDays.length; // 개별 날짜 수 계산
			const distributedValue = value / dayCount; // value를 날짜 수로 나눈 값

			return singleDays.map((d) => ({
				day: parseInt(d, 10),
				type,
				value: `${distributedValue}${unit}`,
			}));
		}
	}
	return [];
};

const getNestedValue = (obj, path, value = undefined) => {
	return path
		.split('.') // "absentMinusWage.absentDates" → ['absentMinusWage', 'absentDates']
		.reduce(
			(acc, key) => (acc && acc[key] !== undefined ? acc[key] : value),
			obj
		);
};

export const extractLeaveData = (paydocus, typeKey, dateKey, typeName) => {
	return paydocus
		.filter((a) => getNestedValue(a, typeKey)) // ✅ 중첩된 객체에서도 안전하게 값 가져오기
		.flatMap((a) => {
			const year = parseInt(a.info.year, 10);
			const month = parseInt(a.info.month, 10);
			const leaveDates = getNestedValue(a, dateKey, '');
			const value = parseInt(getNestedValue(a, typeKey, 1), 10) || 1;
			const workerName = a.workerInfo.worker || '';

			return parseLeaveDays(leaveDates, typeName, value).map((d) => ({
				year,
				month,
				day: d.day,
				type: d.type,
				value: d.value,
				workerName: workerName,
			}));
		})
		.filter((a) => a.day);
};

export const validateEntry = (year, month, day) => {
	const monthIndex = month - 1;
	if (monthIndex < 0 || monthIndex >= 12) {
		console.error(`Invalid month: ${month}`);
		return false;
	}

	const maxDay = new Date(year, month, 0).getDate();
	if (day < 1 || day > maxDay) {
		console.error(`Invalid day: ${day} for month ${month}`);
		return false;
	}
	return true;
};

export const formattedTimeUnixTimestampToHHMMSS = (
	timestamp,
	checkInTime = null
) => {
	if (!timestamp || isNaN(timestamp)) return '';

	const date = new Date(timestamp);
	// getHours() → 로컬시간의 시(HH)
	const localHours = date.getHours().toString().padStart(2, '0');
	const localMinutes = date.getMinutes().toString().padStart(2, '0');
	const localSeconds = date.getSeconds().toString().padStart(2, '0');

	if (!checkInTime || isNaN(checkInTime)) {
		return `${localHours}:${localMinutes}:${localSeconds}`;
	}

	const checkInDate = new Date(checkInTime);
	// 같은 로컬일인지 비교
	const isNextDay = date.getDate() !== checkInDate.getDate();

	return isNextDay
		? `익일 ${localHours}:${localMinutes}:${localSeconds}`
		: `${localHours}:${localMinutes}:${localSeconds}`;
};

export const getWeekNumber = (date) => {
	const tempDate = new Date(date);
	tempDate.setHours(0, 0, 0, 0);

	// ✅ 해당 연도의 첫 번째 일요일 찾기
	let firstSunday = new Date(tempDate.getFullYear(), 0, 1);
	while (firstSunday.getDay() !== 0) {
		firstSunday.setDate(firstSunday.getDate() - 1);
	}

	// ✅ 현재 날짜의 가장 가까운 일요일 찾기
	const dayOfWeek = tempDate.getDay(); // 0(일) ~ 6(토)
	const sundayOffset = -dayOfWeek; // 일요일(0) 기준으로 조정
	tempDate.setDate(tempDate.getDate() + sundayOffset);

	// ✅ 주 번호 계산 (밀리초 차이 -> 주 단위 변환)
	const weekNumber = Math.floor((tempDate - firstSunday) / (7 * 86400000)) + 1;

	return weekNumber;
};

export const calculateWorkDuration = (
	checkIn,
	checkOut,
	breaks,
	outingTimes,
	earlyLeave
) => {
	if (!checkIn || !checkOut) return '근무 미기록';

	// 총 근무 시간 (퇴근 - 출근)
	let workDuration = checkOut - checkIn;

	// 휴게 시간 차감
	breaks?.forEach((breakItem) => {
		if (breakItem.startTime && breakItem.endTime) {
			workDuration -= breakItem.endTime - breakItem.startTime;
		}
	});

	// 외출 시간 차감
	outingTimes?.forEach((outing) => {
		if (outing.startTime && outing.endTime) {
			workDuration -= outing.endTime - outing.startTime;
		}
	});

	// 조퇴 시간 차감
	if (earlyLeave && earlyLeave.startTime && earlyLeave.endTime) {
		workDuration -= earlyLeave.endTime - earlyLeave.startTime;
	}

	// 밀리초를 시간과 분 단위로 변환
	const hours = Math.floor(workDuration / (1000 * 60 * 60));
	const minutes = Math.floor((workDuration % (1000 * 60 * 60)) / (1000 * 60));

	return `${hours}시간 ${minutes}분`;
};

/**
 * breakTimeContents (string) 예:
 *   "12:00~13:00, 15:00~16:00, 18:30~19:00"
 * 또는 형식이 안 맞으면 skip해야 함.
 *
 * @param {string} breakTimeContents - 쉼표(,)로 구분된 "HH:MM~HH:MM" 문자열
 * @param {Date} baseDate - 해당 날짜 0시 기준 Date 객체 (당일)
 * @returns {Array<{ startTime: number, endTime: number }>}
 */
export function parseBreakTimes(breakTimeContents, baseDate) {
	if (!breakTimeContents) return [];
	const intervals = breakTimeContents.split(',').map((s) => s.trim());
	const breakIntervals = [];

	intervals.forEach((range) => {
		if (!range.includes('~')) {
			// "12:00~13:00" 형태가 아니면 skip
			return;
		}
		const [startStr, endStr] = range.split('~').map((t) => t.trim());

		// 정규표현식으로 "HH:MM" 형태인지 확인
		if (!/^\d{1,2}:\d{2}$/.test(startStr) || !/^\d{1,2}:\d{2}$/.test(endStr)) {
			// 형식 안맞으면 skip
			return;
		}

		const [startH, startM] = startStr.split(':').map(Number);
		const [endH, endM] = endStr.split(':').map(Number);

		// 시분 유효성 체크
		if (
			isNaN(startH) ||
			isNaN(startM) ||
			isNaN(endH) ||
			isNaN(endM) ||
			startH < 0 ||
			startH > 23 ||
			startM < 0 ||
			startM > 59 ||
			endH < 0 ||
			endH > 23 ||
			endM < 0 ||
			endM > 59
		) {
			return;
		}

		const startDate = new Date(baseDate);
		startDate.setHours(startH, startM, 0, 0);

		const endDate = new Date(baseDate);
		endDate.setHours(endH, endM, 0, 0);

		// 종료시간이 시작시간보다 빠르면 skip
		if (endDate <= startDate) {
			return;
		}

		breakIntervals.push({
			startTime: startDate.getTime(),
			endTime: endDate.getTime(),
		});
	});

	return breakIntervals;
}

// 두 구간이 겹치는 시간을(ms) 계산해서 반환
export function getOverlap([start1, end1], [start2, end2]) {
	const overlapStart = Math.max(start1, start2);
	const overlapEnd = Math.min(end1, end2);
	return overlapEnd > overlapStart ? overlapEnd - overlapStart : 0;
}

// 특정 구간 [start, end]에서 breakIntervals가 차지하는 겹치는 부분을 빼줌
// 즉, 구간 내 휴게 시간을 제외한 실제 근무(혹은 지각/조퇴) 시간을 구함
export function subtractBreakOverlap(start, end, breakIntervals) {
	let total = end - start;
	if (total <= 0) return 0;

	breakIntervals.forEach(({ startTime, endTime }) => {
		// 구간이 서로 겹치는 부분만큼 빼준다
		const overlap = getOverlap([start, end], [startTime, endTime]);
		total -= overlap;
	});

	return Math.max(0, total);
}

// 포맷 함수
export function formatTime(millis) {
	if (millis <= 0) return '0시간 0분';
	const minutes = Math.floor(millis / (1000 * 60));
	const hours = Math.floor(minutes / 60);
	const remainMin = minutes % 60;
	return `${hours}시간 ${remainMin}분`;
}

export function calculateLateAndEarlyLeave(weekData, workingDays) {
	let totalLateTime = 0; // 지각 누적(ms)
	let totalEarlyLeaveTime = 0; // 조기퇴근 누적(ms)

	weekData.forEach((entry) => {
		const entryDate = new Date(entry.checkIn);
		const dayOfWeek = entryDate.getDay();
		const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
		const dayName = daysOfWeek[dayOfWeek];

		const workingDay = workingDays?.find((wd) => wd.day === dayName);
		if (!workingDay) return; // 해당 요일 근무 세팅 없으면 스킵

		// 예정 출근
		const scheduledCheckIn = new Date(entryDate);
		if (workingDay.workingStartTime) {
			const [sH, sM] = workingDay.workingStartTime.split(':');
			scheduledCheckIn.setHours(sH, sM, 0, 0);
		}

		// 예정 퇴근
		const scheduledCheckOut = new Date(entryDate);
		if (workingDay.workingEndTime) {
			const [eH, eM] = workingDay.workingEndTime.split(':');
			scheduledCheckOut.setHours(eH, eM, 0, 0);
			// 익일 여부
			if (workingDay.workingEndTimeNextDayOrNot === '익일') {
				scheduledCheckOut.setDate(scheduledCheckOut.getDate() + 1);
			}
		}

		// 휴게 구간(형식 맞을 때만)
		const baseDay = new Date(entryDate);
		baseDay.setHours(0, 0, 0, 0);
		const breakIntervals = parseBreakTimes(
			workingDay.breakTimeContents,
			baseDay
		);

		// ───────────── 지각 계산 ─────────────
		if (entry.checkIn > scheduledCheckIn.getTime()) {
			// 실제 지각 구간: [scheduledCheckIn, entry.checkIn]
			const actualLate = subtractBreakOverlap(
				scheduledCheckIn.getTime(),
				entry.checkIn,
				breakIntervals
			);
			totalLateTime += actualLate;
		}

		// ───────────── 조기퇴근 계산 ─────────────
		if (
			entry?.earlyLeave?.startTime &&
			entry?.earlyLeave?.startTime < scheduledCheckOut.getTime()
		) {
			// 실제 조기퇴근 구간: [entry?.earlyLeave?.startTime, scheduledCheckOut]
			const actualEarly = subtractBreakOverlap(
				entry?.earlyLeave?.startTime,
				scheduledCheckOut.getTime(),
				breakIntervals
			);
			totalEarlyLeaveTime += actualEarly;
		}
	});

	return {
		totalLateTime: formatTime(totalLateTime),
		totalEarlyLeaveTime: formatTime(totalEarlyLeaveTime),
	};
}

// "0시간 0분" 또는 "00시간 00분"은 표시하지 않도록 검사하는 함수
export function isNotZeroTime(timeStr) {
	if (!timeStr) return false; // null, undefined, 빈 문자열 등 방어
	const trimmed = timeStr.trim();
	return trimmed !== '0시간 0분' && trimmed !== '00시간 00분';
}

export const parseTimeToHours = (timeStr) => {
	const matches = timeStr.match(/(\d+)시간\s(\d+)분/);
	if (!matches) return 0;
	const hours = parseInt(matches[1], 10);
	const minutes = parseInt(matches[2], 10);
	return hours + minutes / 60;
};

const calculateUnifiedWorkDurations = (
	monthData,
	workingDays,
	previousMonthLastWeekData = [],
	paidOffDay = '일',
	koreanHolidays = []
) => {
	// 이전 달 마지막 주 데이터와 현재 달 데이터를 병합
	const allDays = [...previousMonthLastWeekData, ...monthData];

	// 요일 문자열을 숫자로 매핑
	const dayMap = {
		일: 0,
		월: 1,
		화: 2,
		수: 3,
		목: 4,
		금: 5,
		토: 6,
	};

	// 날짜 데이터를 paidOffDay(예: "일")를 기준으로 주 단위로 그룹화
	const groupDaysIntoWeeks = (days, paidOffDay = '일') => {
		const pivotDay = dayMap[paidOffDay] ?? 0;
		const sortedDays = [...days].sort(
			(a, b) => parseInt(a.id) - parseInt(b.id)
		);
		const weeks = [];
		let currentWeek = [];

		sortedDays.forEach((day) => {
			const date = new Date(parseInt(day.id));
			// pivotDay에 도달하면 새로운 주를 시작 (단, currentWeek에 데이터가 있을 경우)
			if (date.getDay() === pivotDay && currentWeek.length > 0) {
				weeks.push(currentWeek);
				currentWeek = [];
			}
			currentWeek.push(day);
		});
		if (currentWeek.length > 0) {
			weeks.push(currentWeek);
		}
		return weeks;
	};

	const weeks = groupDaysIntoWeeks(allDays, paidOffDay);

	// 하루 근무시간 계산 (체크인~체크아웃에서 휴게, 외출, 조퇴 시간 차감)
	const calculateDailyWorkDuration = ({
		checkIn,
		checkOut,
		breaks,
		outingTimes,
		earlyLeave,
	}) => {
		if (!checkIn || !checkOut) return 0;
		let workDuration = checkOut - checkIn;
		// 휴게 시간 차감
		breaks?.forEach(({ startTime, endTime }) => {
			if (startTime && endTime) workDuration -= endTime - startTime;
		});
		// 외출 시간 차감
		outingTimes?.forEach(({ startTime, endTime }) => {
			if (startTime && endTime) workDuration -= endTime - startTime;
		});
		// 조퇴 시간 차감
		if (earlyLeave?.startTime && earlyLeave?.endTime) {
			workDuration -= earlyLeave.endTime - earlyLeave.startTime;
		}
		return Math.max(0, workDuration);
	};

	// 단일 날짜의 야간 근무시간 계산 (22:00 ~ 익일 06:00)
	const calculateNightWorkForDay = ({
		checkIn,
		checkOut,
		breaks,
		outingTimes,
		earlyLeave,
	}) => {
		if (!checkIn || !checkOut) return 0;
		const checkInDate = new Date(checkIn);
		const checkOutDate = new Date(checkOut);
		const nightStart = new Date(checkInDate);
		nightStart.setHours(22, 0, 0, 0); // 22:00
		const nightEnd = new Date(checkInDate);
		nightEnd.setDate(nightEnd.getDate() + 1);
		nightEnd.setHours(6, 0, 0, 0); // 익일 06:00

		let nightWorkDuration = 0;
		if (checkOutDate > nightStart && checkInDate < nightEnd) {
			// 근무시간 중 야간 구간의 시작과 끝 결정
			const start = checkInDate > nightStart ? checkInDate : nightStart;
			const end = checkOutDate < nightEnd ? checkOutDate : nightEnd;
			let excludedTime = 0;
			// 야간 중 휴게 시간 차감
			breaks?.forEach(({ startTime, endTime }) => {
				if (startTime && endTime) {
					const breakStart = new Date(startTime);
					const breakEnd = new Date(endTime);
					if (start < breakEnd && end > breakStart) {
						const overlapStart = breakStart > start ? breakStart : start;
						const overlapEnd = breakEnd < end ? breakEnd : end;
						excludedTime += overlapEnd - overlapStart;
					}
				}
			});
			// 야간 중 외출 시간 차감
			outingTimes?.forEach(({ startTime, endTime }) => {
				if (startTime && endTime) {
					const outingStart = new Date(startTime);
					const outingEnd = new Date(endTime);
					if (start < outingEnd && end > outingStart) {
						const overlapStart = outingStart > start ? outingStart : start;
						const overlapEnd = outingEnd < end ? outingEnd : end;
						excludedTime += overlapEnd - overlapStart;
					}
				}
			});
			// 야간 중 조퇴 시간 차감
			if (earlyLeave?.startTime && earlyLeave?.endTime) {
				const earlyLeaveStart = new Date(earlyLeave.startTime);
				const earlyLeaveEnd = new Date(earlyLeave.endTime);
				if (start < earlyLeaveEnd && end > earlyLeaveStart) {
					const overlapStart =
						earlyLeaveStart > start ? earlyLeaveStart : start;
					const overlapEnd = earlyLeaveEnd < end ? earlyLeaveEnd : end;
					excludedTime += overlapEnd - overlapStart;
				}
			}
			nightWorkDuration = Math.max(0, end - start - excludedTime);
		}
		return nightWorkDuration;
	};

	// 밀리초를 "X시간 Y분" 형식으로 포맷팅
	const formatTime = (millis) => {
		const hours = Math.floor(millis / (1000 * 60 * 60));
		const minutes = Math.floor((millis % (1000 * 60 * 60)) / (1000 * 60));
		return `${hours}시간 ${minutes}분`;
	};

	// 주간 소정근로 최대 시간 (예: 40시간)
	const maxWeeklyRegularTime = 40 * 60 * 60 * 1000;
	const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

	// 누적값 초기화
	let totalWorkTime = 0; // 실제 근무한 전체 시간
	let totalRegularWorkTime = 0; // 비휴일 소정 근로 시간 (주간 한도 적용)
	let totalOvertime = 0; // 비휴일 연장 근로 시간
	let totalHolidayWorkTime = 0; // 휴일(주휴일/공휴일) 근무 시간
	let totalNightWorkTime = 0; // 야간 근무 시간

	// 주 단위로 처리
	weeks.forEach((week) => {
		let weeklyRegularAccumulated = 0; // 해당 주의 소정 근로 누적 (비휴일)
		week.forEach((day) => {
			// 하루 실제 근무시간 계산
			const workDuration = calculateDailyWorkDuration(day);
			totalWorkTime += workDuration;
			// 하루 야간 근무시간 계산
			const nightDuration = calculateNightWorkForDay(day);
			totalNightWorkTime += nightDuration;

			// 날짜 및 요일 확인
			const date = new Date(parseInt(day.id));
			const dayOfWeek = daysOfWeek[date.getDay()];
			const formattedDate = date.toISOString().split('T')[0];
			// 해당 날짜가 휴일인지 (주휴일 또는 공휴일) 판단
			const isHoliday =
				dayOfWeek === paidOffDay || koreanHolidays?.includes(formattedDate);

			if (isHoliday) {
				// 휴일은 전부 휴일근무로 처리 (소정/연장 근로 구분 없이)
				totalHolidayWorkTime += workDuration;
			} else {
				// 비휴일의 경우, 소정 근로시간(일별 예정시간과 주간 잔여 소정 시간 중 최소값)과 초과분(연장근로) 산출
				const workingDay = workingDays.find((wd) => wd.day === dayOfWeek);
				const dailyScheduled = workingDay
					? workingDay.dayLawBaseTime * 60 * 60 * 1000
					: 0;
				const remainingWeeklyRegular = Math.max(
					0,
					maxWeeklyRegularTime - weeklyRegularAccumulated
				);
				const regularForDay = Math.min(
					workDuration,
					dailyScheduled,
					remainingWeeklyRegular
				);
				const overtimeForDay = workDuration - regularForDay;

				totalRegularWorkTime += regularForDay;
				totalOvertime += overtimeForDay;
				weeklyRegularAccumulated += regularForDay;
			}
		});
	});

	return {
		// 실제 근무한 전체 시간 (중복없이)
		totalWorkTime: formatTime(totalWorkTime),
		// 비휴일의 소정 근로 및 연장 근로
		regularWorkTime: formatTime(totalRegularWorkTime),
		overtime: formatTime(totalOvertime),
		// 휴일에 근무한 시간 (주휴일/공휴일)
		holidayWorkTime: formatTime(totalHolidayWorkTime),
		// 야간 근무 시간 (전체 근무시간 중 22:00~06:00)
		nightWorkTime: formatTime(totalNightWorkTime),
	};
};

// 캘린더 생성 함수
export const generateCalendar = ({
	selectedYear,
	selectedMonth,
	renderDayEntries,
	isMobile,
	groupedData = {},
	workingDays = [],
	paidOffDay,
	useWorkingDays,
	isCompany,
}) => {
	// 현재 달의 첫 날과 마지막 날 계산
	const currentMonthFirstDate = new Date(selectedYear, selectedMonth - 1, 1);
	const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
	const firstDay = currentMonthFirstDate.getDay(); // 첫 날의 요일 (0: 일요일)
	const weeks = [];
	let week = [];
	let weekData = [];
	let dayCount = 1;
	let weekStartDay = null; // 이번 주의 첫 실제 날짜(숫자)
	let monthData = []; // 월별 데이터 초기화

	// 이전 달의 마지막 주 데이터 생성
	const previousMonthYear =
		selectedMonth === 1 ? selectedYear - 1 : selectedYear;
	const previousMonth = selectedMonth === 1 ? 12 : selectedMonth - 1;
	const previousMonthLastDate = new Date(previousMonthYear, previousMonth, 0);
	const previousMonthLastDay = previousMonthLastDate.getDate();

	// 이전 달의 마지막 주에 속하는 날짜들 추출 (예: 12월 29, 30, 31)
	const previousMonthLastWeekDays = [];
	for (let i = firstDay - 1; i >= 0; i--) {
		const day = previousMonthLastDay - i;
		const date = new Date(previousMonthYear, previousMonth - 1, day);
		previousMonthLastWeekDays.push(date.getTime().toString()); // 타임스탬프 문자열로 저장
	}

	// 이전 달의 마지막 주 데이터 필터링
	const previousMonthLastWeekData = previousMonthLastWeekDays
		.map((day) => groupedData[day] || [])
		.flat();

	// 달력의 첫 주 앞쪽 빈 칸 추가 (예: 1일이 수요일이면, 일~화요일 빈 칸)
	for (let i = 0; i < firstDay; i++) {
		week.push(<td key={`empty-${i}`} style={{ minHeight: '150px' }}></td>);
	}

	while (dayCount <= daysInMonth) {
		const currentDate = new Date(selectedYear, selectedMonth - 1, dayCount);
		const isHoliday = koreanHolidays[selectedYear]?.includes(
			currentDate.toISOString().split('T')[0]
		);
		const textColor = isHoliday
			? 'red'
			: currentDate.getDay() === 0
			? 'red'
			: currentDate.getDay() === 6
			? 'blue'
			: 'inherit';

		// 기록된 날짜 중 첫번째 실제 날짜(빈 칸 제외)
		if (weekStartDay === null) {
			weekStartDay = dayCount;
		}

		// 해당 날짜의 데이터 (없으면 빈 배열)
		const dailyData = groupedData[dayCount] || [];
		weekData.push(...dailyData);
		monthData.push(...dailyData); // 월별 데이터 추가

		const content = (
			<div className={`calendar-cell ${isMobile ? 'mobile' : ''}`}>
				<div className='calendar-day' style={{ color: textColor }}>
					{dayCount}
				</div>
				{renderDayEntries(dayCount.toString())}
			</div>
		);

		week.push(
			<td key={dayCount} style={{ verticalAlign: 'top' }}>
				{content}
			</td>
		);

		dayCount++;

		// 주의 마지막 날이거나 달의 마지막 날에 도달하면 주를 마무리합니다.
		if (week.length === 7 || dayCount > daysInMonth) {
			// 남은 칸이 있다면 빈 칸으로 채웁니다.
			while (week.length < 7) {
				week.push(
					<td
						key={`empty-end-${week.length}`}
						style={{ minHeight: '150px' }}></td>
				);
			}

			// 주 번호는 이번 주의 첫 실제 날짜 기준으로 계산 (getWeekNumber 함수 사용)
			const weekStartDate = new Date(
				selectedYear,
				selectedMonth - 1,
				weekStartDay
			);

			const currentWeekNumber = getWeekNumber(weekStartDate);
			const weekLawBaseTimes = calculateUnifiedWorkDurations(
				weekData,
				workingDays,
				previousMonthLastWeekData,
				(paidOffDay = '일'),
				koreanHolidays[selectedYear] || []
			).regularWorkTime;
			const weekOvertime = calculateUnifiedWorkDurations(
				weekData,
				workingDays,
				previousMonthLastWeekData,
				(paidOffDay = '일'),
				koreanHolidays[selectedYear] || []
			).overtime;
			const weekTotalWorkTime = calculateUnifiedWorkDurations(
				weekData,
				workingDays,
				previousMonthLastWeekData,
				(paidOffDay = '일'),
				koreanHolidays[selectedYear] || []
			).totalWorkTime;

			const nightWorkTime = calculateUnifiedWorkDurations(
				weekData,
				workingDays,
				previousMonthLastWeekData,
				(paidOffDay = '일'),
				koreanHolidays[selectedYear] || []
			).nightWorkTime;

			// 휴일 근무 시간 계산
			const weekholidayWorkingHour = calculateUnifiedWorkDurations(
				weekData,
				workingDays,
				previousMonthLastWeekData,
				(paidOffDay = '일'),
				koreanHolidays[selectedYear] || []
			).holidayWorkTime;

			const { totalLateTime, totalEarlyLeaveTime } = calculateLateAndEarlyLeave(
				weekData,
				workingDays
			);

			// 주간 총 근무시간을 계산하여 첫 셀에 추가 (전체 주 데이터인 weekData 사용)
			week.unshift(
				<td key={`week-${currentWeekNumber}`} className='week-number-cell'>
					<strong>{currentWeekNumber} 번째 주</strong>
					{!useWorkingDays && !isCompany && (
						<>
							{weekData && weekData.length > 0 && (
								<div>
									{/* 소정근로 계 */}
									{isNotZeroTime(weekLawBaseTimes) && (
										<div className='week-work-time'>
											<strong>소정근로 계: </strong>
											{weekLawBaseTimes}
										</div>
									)}
									{/* 연장근로 계 */}
									{isNotZeroTime(weekOvertime) && (
										<div className='week-work-time'>
											<strong>연장근로 계: </strong>
											{weekOvertime}
										</div>
									)}

									{/* 야간근로 계 */}
									{isNotZeroTime(nightWorkTime) && (
										<div className='week-work-time'>
											<strong>야간근로 계: </strong>
											{nightWorkTime}
										</div>
									)}
									{/* 휴일근로 계 */}
									{isNotZeroTime(weekholidayWorkingHour) && (
										<div className='week-work-time'>
											<strong>휴일근로 계: </strong>
											{weekholidayWorkingHour}
										</div>
									)}
									{/* 지각 계 */}
									{isNotZeroTime(totalLateTime) && (
										<div className='week-work-time' style={{ color: 'red' }}>
											<strong>지각 계: </strong>
											{totalLateTime}
										</div>
									)}
									{/* 조기퇴근시간 계 */}
									{isNotZeroTime(totalEarlyLeaveTime) && (
										<div className='week-work-time' style={{ color: 'purple' }}>
											<strong>조기퇴근시간 계: </strong>
											{totalEarlyLeaveTime}
										</div>
									)}
									<hr />

									{/* 실근로 계 */}
									{isNotZeroTime(weekTotalWorkTime) && (
										<div className='week-work-time'>
											<strong>실근로 계: </strong>
											{weekTotalWorkTime}
										</div>
									)}
									{isNotZeroTime(weekTotalWorkTime) &&
									parseTimeToHours(weekTotalWorkTime) > 52 ? (
										<div className='week-work-time'>
											<span style={{ color: 'red' }}>
												<strong>주 52시간 위반 </strong>
											</span>
										</div>
									) : (
										<div className='week-work-time'>
											<span style={{ color: 'blue' }}>
												<strong>주 52시간제 준수 </strong>
											</span>{' '}
										</div>
									)}
								</div>
							)}
						</>
					)}
				</td>
			);

			weeks.push(<tr key={dayCount}>{week}</tr>);

			// 다음 주를 위해 초기화
			week = [];
			weekData = [];
			weekStartDay = null;
		}
	}

	// 월별 데이터 처리
	const monthLawBaseTimes = calculateUnifiedWorkDurations(
		monthData,
		workingDays,
		previousMonthLastWeekData,
		(paidOffDay = '일'),
		koreanHolidays[selectedYear] || []
	).regularWorkTime;
	const monthOvertime = calculateUnifiedWorkDurations(
		monthData,
		workingDays,
		previousMonthLastWeekData,
		(paidOffDay = '일'),
		koreanHolidays[selectedYear] || []
	).overtime;
	const monthTotalWorkTime = calculateUnifiedWorkDurations(
		monthData,
		workingDays,
		previousMonthLastWeekData,
		(paidOffDay = '일'),
		koreanHolidays[selectedYear] || []
	).totalWorkTime;
	const monthNightWorkTime = calculateUnifiedWorkDurations(
		monthData,
		workingDays,
		previousMonthLastWeekData,
		(paidOffDay = '일'),
		koreanHolidays[selectedYear] || []
	).nightWorkTime;
	const monthHolidayWorkingHour = calculateUnifiedWorkDurations(
		monthData,
		workingDays,
		previousMonthLastWeekData,
		(paidOffDay = '일'),
		koreanHolidays[selectedYear] || []
	).holidayWorkTime;
	const { totalLateTime, totalEarlyLeaveTime } = calculateLateAndEarlyLeave(
		monthData,
		workingDays
	);

	return {
		weeks: weeks,
		monthData: {
			lawBaseTimes: monthLawBaseTimes,
			overtime: monthOvertime,
			totalWorkTime: monthTotalWorkTime,
			nightWorkTime: monthNightWorkTime,
			holidayWorkingHour: monthHolidayWorkingHour,
			totalLateTime,
			totalEarlyLeaveTime,
		},
		previousMonthLastWeekData,
	};
};

export const convertToTimestamp = (timeStr) => {
	if (!timeStr) return null; // 값이 없을 경우 null 반환

	const [hour, minute] = timeStr.split(':').map(Number);
	const now = new Date();
	return new Date(
		now.getFullYear(),
		now.getMonth(),
		now.getDate(),
		hour,
		minute,
		0
	).getTime();
};

export const getTodayWorkingDay = (workingDays, daysOfWeek) => {
	const today = new Date();
	const dayOfWeek = daysOfWeek[today.getDay() - 1]; // 월요일부터 시작하도록 조정

	const todayWorkingDay =
		workingDays.find((day) => day.day === dayOfWeek) || {};

	return {
		contractWorkingStartTime:
			convertToTimestamp(todayWorkingDay.workingStartTime) ||
			convertToTimestamp('09:00'),
		contractWorkingEndTime:
			convertToTimestamp(todayWorkingDay.workingEndTime) ||
			convertToTimestamp('18:00'),
		contractBreakTime:
			convertBreakTimes(todayWorkingDay.breakTimeContents) || [],
	};
};

export const convertBreakTimes = (breakTimeContents, baseDate = new Date()) => {
	if (!breakTimeContents) return [];

	const parseTime = (timeStr) => {
		const [h, m] = timeStr?.split(':').map(Number);
		const date = new Date(baseDate);
		date.setHours(h, m, 0, 0);
		return date;
	};

	const ranges = breakTimeContents
		?.split(', ')
		.filter((range) => range && range.includes('~'))
		.map((range) => {
			const parts = range.split('~');
			if (parts.length !== 2) {
				throw new Error(`잘못된 휴게시간 형식입니다: ${range}`);
			}
			const [startStr, endStr] = parts;
			if (!startStr || !endStr) {
				throw new Error(`휴게시간 범위에 값이 부족합니다: ${range}`);
			}

			let startTime = parseTime(startStr);
			let endTime = parseTime(endStr);

			// 종료 시간이 시작 시간보다 이전인 경우 (ex. 23:00~02:00)
			if (endTime < startTime) {
				endTime = new Date(endTime.getTime() + 24 * 60 * 60 * 1000); // 다음 날로 변경
			}

			const intervals = [];
			let currentStart = startTime;

			while (currentStart < endTime) {
				const nextDay = new Date(currentStart);
				nextDay.setDate(nextDay.getDate() + 1);
				nextDay.setHours(6, 0, 0, 0); // 다음 날 06:00

				const currentEnd = new Date(Math.min(endTime, nextDay.getTime()));

				// 야간 시간대 판단 (22:00 ~ 06:00)
				const isNightTime =
					currentStart.getHours() >= 22 || currentEnd.getHours() < 6;

				intervals.push({
					startTime: new Date(currentStart),
					endTime: new Date(currentEnd),
					isNightTime,
				});

				currentStart = currentEnd;
			}

			return intervals;
		});

	return ranges.flat();
};

export const removeBreakTime = (index, setEditData) => {
	setEditData((prev) => ({
		...prev,
		breaks: prev.breaks.filter((_, i) => i !== index),
	}));
};
