import React from 'react';
import { Header, Segment, Statistic, Table } from 'semantic-ui-react';
import {
	convertBreakTimes,
	generateCalendar,
} from '../../../../../app/common/util/dateUtil';
import { useMediaQuery } from 'react-responsive';
import '../../../../../styles/Calendar.css';

export default function RealtimeCalendar({
	selectedYear,
	selectedMonth,
	renderDayEntries,
	groupedData,
	workingDays,
	paidOffDay,
	useWorkingDays,
}) {
	const isMobile = useMediaQuery({ maxWidth: 768 });

	const calendarData = generateCalendar({
		selectedYear,
		selectedMonth,
		renderDayEntries,
		isMobile,
		groupedData,
		workingDays,
		paidOffDay,
		useWorkingDays,
	});

	const { monthData } = calendarData ?? {};

	return (
		<div className={`calendar-container ${isMobile ? 'mobile' : 'desktop'}`}>
			{!useWorkingDays && monthData && (
				<Segment
					raised
					className='monthly-summary-segment'
					style={{ maxWidth: '100%' }}>
					<Header
						as='h3'
						dividing
						style={{ marginBottom: '10px' }}
						color='blue'>
						{selectedYear}년 {selectedMonth}월 월간 근로 시간 요약
					</Header>
					<Statistic.Group widths='7' size='mini'>
						{monthData.totalWorkTime && (
							<Statistic>
								<Statistic.Label>전체 근로 시간</Statistic.Label>
								<Statistic.Value>{monthData.totalWorkTime}</Statistic.Value>
							</Statistic>
						)}

						{monthData.lawBaseTimes && (
							<Statistic>
								<Statistic.Label>소정 근로 시간</Statistic.Label>
								<Statistic.Value>{monthData.lawBaseTimes}</Statistic.Value>
							</Statistic>
						)}

						{monthData.overtime && (
							<Statistic>
								<Statistic.Label>연장 근로 시간</Statistic.Label>
								<Statistic.Value>{monthData.overtime}</Statistic.Value>
							</Statistic>
						)}

						{monthData.holidayWorkingHour && (
							<Statistic>
								<Statistic.Label>휴일 근로 시간</Statistic.Label>
								<Statistic.Value>
									{monthData.holidayWorkingHour}
								</Statistic.Value>
							</Statistic>
						)}

						{monthData.nightWork && (
							<Statistic>
								<Statistic.Label>야간 근로 시간</Statistic.Label>
								<Statistic.Value>{monthData.nightWork}</Statistic.Value>
							</Statistic>
						)}

						{monthData.totalLateTime && (
							<Statistic>
								<Statistic.Label>월간 지각 시간</Statistic.Label>
								<Statistic.Value>{monthData.totalLateTime}</Statistic.Value>
							</Statistic>
						)}

						{monthData.totalEarlyLeaveTime && (
							<Statistic>
								<Statistic.Label>월간 조기퇴근 시간</Statistic.Label>
								<Statistic.Value>
									{monthData.totalEarlyLeaveTime}
								</Statistic.Value>
							</Statistic>
						)}
					</Statistic.Group>
				</Segment>
			)}
			{!isMobile ? (
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								style={{ minWidth: '100px', textAlign: 'center' }}>
								주(연도 기준)
							</Table.HeaderCell>
							{['일', '월', '화', '수', '목', '금', '토'].map((d, i) => {
								let headerText = d;
								let headerStyle = { minWidth: '100px', textAlign: 'center' };

								if (d === paidOffDay) {
									headerText += '<br />(주휴일)';
									headerStyle = { ...headerStyle, color: 'red' };
								}
								return (
									<Table.HeaderCell key={i} style={headerStyle}>
										<span dangerouslySetInnerHTML={{ __html: headerText }} />
									</Table.HeaderCell>
								);
							})}
						</Table.Row>
						{/* 두번째 행: 출근 시간 */}
						<Table.Row>
							<Table.Cell style={{ minWidth: '100px', textAlign: 'center' }}>
								시업 및 종업
							</Table.Cell>
							{['일', '월', '화', '수', '목', '금', '토'].map((d, i) => {
								const wd = workingDays?.find((wd) => wd.day === d);
								return (
									<Table.Cell
										key={i}
										style={{ minWidth: '100px', textAlign: 'center' }}>
										{wd ? (
											<>
												{wd.workingStartTime}~
												{wd.workingEndTimeNextDayOrNot === '익일' ? (
													<span style={{ color: 'blue' }}>
														익일 {wd.workingEndTime}
													</span>
												) : (
													wd.workingEndTime
												)}
											</>
										) : (
											''
										)}
									</Table.Cell>
								);
							})}
						</Table.Row>

						{/* 네번째 행: 휴게 시간 */}
						<Table.Row>
							<Table.Cell style={{ minWidth: '100px', textAlign: 'center' }}>
								휴게
							</Table.Cell>
							{['일', '월', '화', '수', '목', '금', '토'].map((d, i) => {
								const wd = workingDays?.find((wd) => wd.day === d);
								// 기준 날짜 생성 (해당 요일의 날짜 설정)
								const today = new Date();
								const dayOffset = i - today.getDay(); // 현재 요일과 비교하여 보정
								const baseDay = new Date(today);
								baseDay.setDate(today.getDate() + dayOffset);
								baseDay.setHours(0, 0, 0, 0); // 해당 요일의 00:00:00으로 설정

								const breakTimes = wd
									? convertBreakTimes(wd.breakTimeContents, baseDay)
									: [];

								return (
									<Table.Cell
										key={i}
										style={{ minWidth: '100px', textAlign: 'center' }}>
										{breakTimes.map((time, idx) => (
											<div
												key={idx}
												style={{
													color: time.isNightTime ? 'blue' : 'inherit',
												}}>
												{new Date(time.startTime).toLocaleTimeString([], {
													hour: '2-digit',
													minute: '2-digit',
												})}{' '}
												~
												{new Date(time.endTime).toLocaleTimeString([], {
													hour: '2-digit',
													minute: '2-digit',
												})}
											</div>
										))}
									</Table.Cell>
								);
							})}
						</Table.Row>
					</Table.Header>
					<Table.Body>{calendarData.weeks}</Table.Body>
				</Table>
			) : (
				<div className='calendar-mobile'>{calendarData.weeks}</div>
			)}
		</div>
	);
}
