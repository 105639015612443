import { format } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { getUserProfile } from '../../../app/firestore/firestoreService';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { listenToSelectedUserProfile } from '../../../features/profiles/profileActions';
import RuleOfEmployeeContents from './RuleOfEmployeeContents';
import { Link } from 'react-router-dom';

export default function RuleOfEmployee({ match }) {
	const dispatch = useDispatch();
	const { selectedUserProfile, currentUserProfile } = useSelector(
		(state) => state.profile
	);
	const { currentUser } = useSelector((state) => state.auth);
	const { loading, error } = useSelector((state) => state.async);
	const { clientInfo, filter } = useSelector((state) => state.companyReducer);

	let profile;

	const id = clientInfo?.clientUid
		? clientInfo?.clientUid
		: clientInfo?.id
		? clientInfo?.id
		: match.params.id;

	useFirestoreDoc({
		query: () => getUserProfile(id),
		data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
		deps: [dispatch, id],
		shouldExecute: id !== currentUser.uid,
	});

	if (id === currentUser.uid) {
		profile = currentUserProfile;
	} else {
		profile = selectedUserProfile;
	}

	const adminStartDate =
		currentUserProfile.authLevel > 100 &&
		(currentUserProfile?.serviceStartDate.seconds
			? new Date(currentUserProfile.serviceStartDate.seconds * 1000)
					.toISOString()
					.split('T')[0]
			: new Date(currentUserProfile?.serviceStartDate)
					.toISOString()
					.split('T')[0]);

	const serviceStartDate = clientInfo?.serviceStartDate
		? clientInfo.serviceStartDate.seconds
			? format(
					new Date(clientInfo.serviceStartDate.seconds * 1000),
					'yyyy-MM-dd'
			  )
			: format(new Date(clientInfo.serviceStartDate), 'yyyy-MM-dd')
		: adminStartDate
		? adminStartDate
		: format(new Date(), 'yyyy-MM-dd');

	const roeExcuteDueDate = clientInfo?.roeExcuteDueDate
		? clientInfo.roeExcuteDueDate.seconds
			? format(
					new Date(clientInfo.roeExcuteDueDate.seconds * 1000),
					'yyyy-MM-dd'
			  )
			: format(new Date(clientInfo.roeExcuteDueDate), 'yyyy-MM-dd')
		: format(new Date(), 'yyyy-MM-dd');

	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;

	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	const childCompany =
		companyName?.includes('어린이집') || companyName?.includes('유치원')
			? true
			: false;

	const privateSchool = clientInfo?.privateSchool
		? clientInfo.privateSchool
		: currentUserProfile?.privateSchool;

	const isWorker = currentUserProfile?.isWorker ? true : false;

	if ((loading && !profile) || (!profile && !error))
		return <LoadingComponent content='Loading 취업규칙...' />;

	return (
		<Grid>
			<Grid.Column width={16}>
				{isWorker || authLevel > 25 ? (
					<RuleOfEmployeeContents
						currentUserProfile={currentUserProfile}
						profile={profile}
						filter={filter}
						serviceStartDate={serviceStartDate}
						roeExcuteDueDate={roeExcuteDueDate}
						clientInfo={clientInfo}
						childCompany={childCompany}
						isWorker={isWorker}
						privateSchool={privateSchool}
					/>
				) : (
					<>
						<h2>
							노무법인 20년 취업규칙 컨설팅 노하우가 포함되었고, 현행법이
							실시간으로 반영되어 현장에서 바로 사용할 수 있는{' '}
							<span style={{ color: 'teal' }}>
								근로기준법 제93조의 취업규칙
							</span>
							은 DELUXE와 PREMIUM에서 제공되는 서비스입니다.
						</h2>
						<Button as={Link} to={`/pricePolicyPaper`} color='facebook'>
							서비스 종류 보러가기.
						</Button>
						<Button as={Link} to={`/clientReqs4In`} color='blue'>
							Main화면으로 가기
						</Button>
					</>
				)}
			</Grid.Column>
		</Grid>
	);
}
