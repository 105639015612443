import React, { useEffect, useState } from 'react';
import MyNumberInput from '../../../../../app/common/form/MyNumberInput';
import MyTextArea from '../../../../../app/common/form/MyTextArea';
import MyTextInput from '../../../../../app/common/form/MyTextInput';

export default function CrwfEtcLaborContractTimeInfo({ values }) {
	const [alertShown, setAlertShown] = useState(false);

	useEffect(() => {
		// 필수 입력란이 모두 채워졌는지 확인
		const allRequiredFieldsFilled =
			values.etc?.paidOffdays &&
			values.etc?.paidHolidayLBTime &&
			values.etc?.weekLBTime &&
			values.etc?.workTimeComment;
		if (allRequiredFieldsFilled && !alertShown) {
			alert(
				'2. 근로시간정보 필수 입력란을 모두 입력하셨습니다. "3.임금정보" 단계로 진행하셔도 됩니다.'
			);
			setAlertShown(true); // 다시 뜨지 않도록 상태 업데이트
		}
	}, [
		values.etc?.paidOffdays,
		values.etc?.paidHolidayLBTime,
		values.etc?.weekLBTime,
		values.etc?.workTimeComment,
		alertShown,
	]);

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					alignContent: 'flex-end',
					width: '100%',
					marginBottom: '30px',
					marginTop: '30px',
					fontSize: '17px',
				}}>
				<span style={{ cursor: 'pointer' }}>
					<span style={{ color: 'red' }}>* </span>
					<span style={{ color: 'blue' }}>
						{' '}
						Step 2. 교대제 등 주휴일, 포괄역산시간 등 입력
					</span>
				</span>
			</div>
			<>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'flex-start',
						alignItems: 'center',
						alignContent: 'flex-end',
					}}>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyTextInput
							label={
								<span style={{ color: 'red' }}>* (1) 법정 유급 주휴일</span>
							}
							placeholder='매주 00요일 또는 매주 첫번째 비번일'
							name='etc.paidOffdays'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label={
								<span style={{ color: 'red' }}>
									* (2) 1일 소정근로시간(주휴수당 근거 수당)
								</span>
							}
							placeholder='1일 8시간을 초과할 수 없음.'
							name='etc.paidHolidayLBTime'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label={
								<span style={{ color: 'red' }}>* (3) 1주 소정근로시간</span>
							}
							placeholder='1주 40시간을 초과할 수 없음.'
							name='etc.weekLBTime'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(4) 월간 약정연장시간(환산전)'
							placeholder='실제 연장 근로한 시간으로 1.5배 등 환산하지 않은 환산전 시간입력요망'
							name='etc.monthOWT'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(5) 월간 약정야간시간(22시~06시)(환산전)'
							placeholder='실제 야간 근로한 시간으로 0.5배 등 환산하지 않은 환산전 시간입력요망'
							name='etc.monthNWT'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(6) 월간 약정휴일기본시간(8시간이내)(환산전)'
							placeholder='실제 휴일 기본근로한 시간으로 1.5배 등 환산하지 않은 환산전 시간입력요망'
							name='etc.monthHoliBasicTime'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(7) 월간 약정휴일연장시간(8시간초과)(환산전)'
							placeholder='실제 휴일 연장근로한 시간으로 2배 등 환산하지 않은 환산전 시간입력요망'
							name='etc.monthOWTHoli'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(8) 월간 약정휴일야간시간(22시~06시)(환산전)'
							placeholder='실제 휴일 야간근로한 시간으로 0.5배 등 환산하지 않은 환산전 시간입력요망'
							name='etc.monthNWTHoli'
							autoComplete='off'
						/>
					</div>
					<div
						className='margin'
						style={{ width: '99.5%', marginRight: '3px' }}>
						<MyTextArea
							multiple
							label={
								<span style={{ color: 'red' }}>
									* (9) 시업 및 종업, 휴게시간 등 실근무시간 설명(근로계약서
									제5조(근무일, 시업/종업, 휴게) 제1항에 명시될 내용)
								</span>
							}
							placeholder='(2교대 예시) 24시간 2교대(근무일/비번일)로 근무하되 근무일 근무시간은 09:00 ~ 익일 09:00, 휴게시간은 12:00~13:00, 18:00~19:00, 24:00~01:00로 한다. 
                            (3교대 예시) 3교대(일근/야근/비번일)로 근무하되 첫번째 근무일 근무시간은 09:00 ~ 18:00, 휴게시간은 12:00~13:00, 두번째 근무일 근무시간은 18:00~익일 09:00, 휴게시간은 18:00~19:00, 24:00~01:00로 한다.'
							name='etc.workTimeComment'
							rows='3'
						/>
					</div>
				</div>
				<br />
			</>
		</>
	);
}
