import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Select, Tab } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import {
	clearPaydocu,
	fetchPaydocusYearComFormFirestoreService4In,
} from '../../../../companyManage/companyActions';
import {
	extractLeaveData,
	validateEntry,
} from '../../../../../app/common/util/dateUtil';

import RealtimeCalendarDisplayCompany from './RealtimeCalendarDisplayCompany';
import ClientReqDashboard4InCalendarMonthCompany from './ClientReqDashboard4InCalendarMonthCompany';
// import ClientReqDashboard4InCalendarYearCompany from './ClientReqDashboard4InCalendarYearCompany';

export default function ClientReqDashboard4InCalendarCompany({
	clientInfo,
	filter,
	companyId,
}) {
	const dispatch = useDispatch();
	const { paydocus, workerUserCompanyData } = useSelector(
		(state) => state.companyReducer
	);
	const today = useMemo(() => new Date(), []);
	const [selectedYear, setSelectedYear] = useState(
		today.getFullYear().toString()
	);
	const [selectedMonth, setSelectedMonth] = useState(today.getMonth() + 1);
	const [attendanceData, setAttendanceData] = useState([]);
	const [categories, setCategories] = useState([]);
	const [activeView, setActiveView] = useState('year');
	const [activeCategory, setActiveCategory] = useState('전체');

	// 조퇴 상태: 이미 조퇴 시작했는지 여부

	// 요일 배열 정의
	const daysOfWeek = ['월', '화', '수', '목', '금', '토', '일'];
	// 요일 순서는 필요에 따라 정의하세요.

	const combineWorkDaysDataByDay = (workerUserCompanyData) => {
		// 데이터가 없으면 빈 배열을 반환하여 에러 방지
		if (!workerUserCompanyData || workerUserCompanyData.length === 0) {
			return [];
		}

		const combinedData = [];
		const realWorkTime = workerUserCompanyData[0].realWorkTime || {};

		for (let i = 1; i <= 6; i++) {
			const workDays = workerUserCompanyData[0][`selectWorkDays${i}`];
			const workDaysInfo = workerUserCompanyData[0][`selectWorkDays${i}Info`];
			// realWorkTime에서 days1LBTime ~ days6LBTime 가져오기
			const lbTime = realWorkTime[`days${i}LBTime`];

			if (workDays && workDaysInfo) {
				workDays.forEach((day) => {
					combinedData.push({
						day, // 요일
						workingEndTimeNextDayOrNot: workDaysInfo.workingEndTimeNextDayOrNot,
						dayBreakTimes: workDaysInfo.dayBreakTimes,
						breakTimeContents: workDaysInfo.breakTimeContents,
						workingStartTime: workDaysInfo.workingStartTime,
						skipWeek: workDaysInfo.skipWeek,
						workingEndTime: workDaysInfo.workingEndTime,
						nightBreakTimes: workDaysInfo.nightBreakTimes,
						dayLawBaseTime: lbTime,
					});
				});
			}
		}

		// 요일 순서대로 정렬 (daysOfWeek 배열 순서 기준)
		combinedData.sort(
			(a, b) => daysOfWeek.indexOf(a.day) - daysOfWeek.indexOf(b.day)
		);

		return combinedData;
	};

	const workingDays = combineWorkDaysDataByDay(workerUserCompanyData);

	const paidOffDay =
		!workerUserCompanyData || workerUserCompanyData.length === 0
			? '일'
			: workerUserCompanyData[0].paidOffdays;

	// 	if (earlyLeaveStatus) return; // 이미 조퇴가 시작된 경우 무시

	// 	setEarlyLeaveStatus(true);
	// 	const earlyLeaveStartTimestamp = Date.now();

	// 	try {
	// 		const attendanceRef = ref(
	// 			realdb,
	// 			`attendance/${companyId}/${selectedYear}/${selectedMonth}/${workerId}/${attendanceId}`
	// 		);

	// 		// 조퇴 시작 기록
	// 		await update(attendanceRef, {
	// 			earlyLeave: { startTime: earlyLeaveStartTimestamp },
	// 		});

	// 		// 현재 요일 가져오기
	// 		const today = new Date();
	// 		const dayOfWeek = daysOfWeek[today.getDay() - 1]; // 월요일부터 시작하도록 조정

	// 		// 해당 요일의 퇴근 시간 가져오기
	// 		const todayWorkingDay = workingDays.find((day) => day.day === dayOfWeek);
	// 		const regularCheckOutTimeStr = todayWorkingDay?.workingEndTime || '18:00'; // 기본 퇴근 시간

	// 		// HH:MM 형식을 파싱하여 정규 퇴근 시간 Unix Timestamp 계산
	// 		const [hour, minute] = regularCheckOutTimeStr.split(':').map(Number);
	// 		const now = new Date();
	// 		const checkoutTime = new Date(
	// 			now.getFullYear(),
	// 			now.getMonth(),
	// 			now.getDate(),
	// 			hour,
	// 			minute,
	// 			0
	// 		).getTime();

	// 		// 즉시 조퇴 종료 및 퇴근 시간 기록 (정규 퇴근시간 사용)
	// 		await update(attendanceRef, {
	// 			earlyLeave: {
	// 				startTime: earlyLeaveStartTimestamp,
	// 				endTime: checkoutTime,
	// 			},
	// 			checkOutTime: checkoutTime,
	// 		});

	// 		// 상태 업데이트 (퇴근 처리)
	// 		setAttendanceId(null);
	// 		setCheckInStatus(false);
	// 	} catch (error) {
	// 		console.error('Error recording early leave:', error);
	// 	}
	// };

	// 옵션 설정
	const yearOptions = useMemo(
		() =>
			Array.from({ length: 5 }, (_, i) => ({
				key: `${today.getFullYear() - i}`,
				value: `${today.getFullYear() - i}`,
				text: `${today.getFullYear() - i}년`,
			})),
		[today]
	);

	const monthOptions = useMemo(
		() =>
			Array.from({ length: 12 }, (_, i) => ({
				key: i + 1,
				value: i + 1,
				text: `${i + 1}월`,
			})),
		[]
	);

	useEffect(() => {
		let isActive = true;
		const fetchData = async () => {
			try {
				// 기존 데이터 초기화
				dispatch(clearPaydocu());
				// 새 데이터 가져오기
				await dispatch(
					fetchPaydocusYearComFormFirestoreService4In(
						filter,
						selectedYear,
						clientInfo // 변경된 clientInfo 반영
					)
				);
			} finally {
				if (!isActive) {
					dispatch(clearPaydocu());
				}
			}
		};

		if (clientInfo) {
			// clientInfo 유효성 검사 추가
			fetchData();
		}

		return () => {
			isActive = false;
		};
	}, [dispatch, filter, selectedYear, clientInfo]); // clientInfo 의존성 추가

	const allLeaveData = useMemo(() => {
		if (!paydocus || !clientInfo) return []; // clientInfo 유효성 검사
		return [
			...extractLeaveData(
				paydocus,
				'usedAnnualLeaves',
				'usedAnnualLeaveDates',
				'연차'
			),
			...extractLeaveData(
				paydocus,
				'absentMinusWage.absentDays',
				'absentMinusWage.absentDates',
				'결근'
			),
			...extractLeaveData(
				paydocus,
				'absentMinusWage.absentTimes',
				'absentMinusWage.absentTimeDates',
				'시간공제'
			),
			...extractLeaveData(
				paydocus,
				'absentMinusWage.absentPaidHoliDays',
				'absentMinusWage.absentPaidHoliDates',
				'주휴공제'
			),
		];
	}, [paydocus, clientInfo]); // clientInfo 의존성 추가
	// 데이터 가져오기

	const fetchDynamicData = useCallback(
		async (year) => {
			const allData = allLeaveData;
			const result = allData.filter((entry) => entry.year === Number(year));
			return result;
		},
		[allLeaveData]
	);

	// 데이터 초기화 (에러 핸들링 강화)
	const initializeData = useCallback(
		async (year) => {
			try {
				const dynamicData = await fetchDynamicData(year);
				const getDaysInMonth = (y, m) => new Date(y, m + 1, 0).getDate();

				const data = Array.from({ length: 12 }, (_, month) => ({
					month: `${year}년 ${month + 1}월`,
					days: Array.from({ length: getDaysInMonth(year, month) }, () => []),
					aggregate: {},
				}));

				if (dynamicData.length > 0) {
					const uniqueCategories = [
						...new Set(dynamicData.map((item) => item.type)),
					];
					setCategories((prev) => [...new Set([...prev, ...uniqueCategories])]);

					dynamicData.forEach(({ month, day, type, value, workerName }) => {
						if (!validateEntry(year, month, day)) return;

						const monthIndex = month - 1;
						data[monthIndex].days[day - 1].push(
							`${type} ${value}  ${workerName}`
						);
					});
				} else {
					setCategories(['연차']);
				}

				return data;
			} catch (error) {
				console.error('Data initialization failed:', error);
				return [];
			}
		},
		[fetchDynamicData]
	);

	// 데이터 집계 계산 (메모이제이션 적용)
	// 수정된 calculateAggregates 함수
	const calculateAggregates = useCallback((data) => {
		const totalAggregate = Array.from({ length: 32 }, () => ({}));
		let grandTotal = {};

		data?.forEach((month) => {
			let monthAggregate = {};

			month.days?.forEach((entries, dayIndex) => {
				const dayNumber = dayIndex + 1;
				entries?.forEach((entry) => {
					const [type, value] = entry.split(' ');
					const numericValue = parseFloat(value);

					// 월별 집계
					monthAggregate[type] = (monthAggregate[type] || 0) + numericValue;

					// 일자별 전체 집계
					if (dayNumber <= 31) {
						totalAggregate[dayNumber][type] =
							(totalAggregate[dayNumber][type] || 0) + numericValue;
					}

					// 전체 종합
					grandTotal[type] = (grandTotal[type] || 0) + numericValue;
				});
			});

			month.aggregate = monthAggregate;
		});

		// 합계 데이터 형식 일관성 유지
		const summaryDays = totalAggregate.map((dayAggregate) =>
			Object.entries(dayAggregate).map(
				([type, value, workerName]) => `${type} ${value} ${workerName}`
			)
		);

		return [
			...data,
			{
				month: '합계',
				days: summaryDays, // 일관된 배열 구조 사용
				aggregate: grandTotal,
			},
		];
	}, []);
	// 데이터 필터링 (성능 최적화)
	const filteredData = useMemo(
		() =>
			attendanceData.filter(
				(item) => activeCategory === '전체' || item.aggregate[activeCategory]
			),
		[attendanceData, activeCategory]
	);
	// 데이터 로드 효과
	useEffect(() => {
		const fetchData = async () => {
			const rawData = await initializeData(selectedYear);
			const processedData = calculateAggregates(rawData);
			setAttendanceData(processedData);
		};
		fetchData();
	}, [selectedYear, initializeData, calculateAggregates]);

	// 카테고리 유효성 검사
	useEffect(() => {
		if (!categories.includes(activeCategory)) {
			setActiveCategory('전체');
		}
	}, [categories, activeCategory]);

	// function isCurrentYearMonth(selectedYear, selectedMonth) {
	// 	const today = new Date();
	// 	const currentYear = today.getFullYear();
	// 	// getMonth()는 0~11 범위를 반환하므로 +1 해줌
	// 	const currentMonth = today.getMonth() + 1;
	// 	return (
	// 		selectedYear === String(currentYear) && selectedMonth === currentMonth
	// 	);
	// }

	// // 사용 예시:
	// const isToday = isCurrentYearMonth(selectedYear, selectedMonth);
	// const laborContractTimeUse = false;

	return (
		<>
			<div style={{ display: 'flex', gap: '10px', marginBottom: '15px' }}>
				<Select
					options={yearOptions}
					value={selectedYear}
					onChange={(e, { value }) => setSelectedYear(value)}
					style={{ width: '120px', fontSize: '14px' }}
				/>
				<Select
					options={monthOptions}
					value={selectedMonth}
					onChange={(e, { value }) => setSelectedMonth(value)}
					style={{ width: '100px', fontSize: '14px' }}
				/>
			</div>
			{/* <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
				{isToday && (
					<>
						{!breakStatus && (
							<>
								{!outingStatus && ( // ✅ 오늘 날짜인 경우에만 버튼 표시
									<Button
										onClick={handleCheckInOut}
										style={{
											backgroundColor: checkInStatus ? '#007bff' : '#28a745',
											color: 'white',
											fontSize: '15px',
											padding: '10px 15px',
										}}>
										{checkInStatus ? '퇴근' : '출근'}
									</Button>
								)}
							</>
						)}
					</>
				)}
				{checkInStatus && (
					<>
						{!outingStatus && (
							<Button
								onClick={handleBreak}
								style={{
									backgroundColor: breakStatus ? '#dc3545' : '#ffc107',
									color: breakStatus ? 'white' : 'black',
									fontSize: '15px',
									padding: '10px 15px',
								}}>
								{breakStatus ? '휴게 종료' : '휴게 시작'}
							</Button>
						)}
						{!breakStatus && (
							<Button
								onClick={handleOuting}
								style={{
									backgroundColor: outingStatus ? '#17a2b8' : '#6c757d',
									color: 'white',
									fontSize: '15px',
									padding: '10px 15px',
								}}>
								{outingStatus ? '외출 종료' : '외출 시작'}
							</Button>
						)}
					</>
				)}
				{checkInStatus && (
					<>
						{!outingStatus && (
							<>
								{!breakStatus && (
									<Button
										onClick={handleEarlyLeave}
										style={{
											backgroundColor: '#dc3545',
											color: 'white',
											fontSize: '15px',
											padding: '10px 15px',
										}}>
										조퇴 시작
									</Button>
								)}
							</>
						)}
					</>
				)}
				{laborContractTimeUse && !checkInStatus && (
					<Checkbox
						toggle
						label='근로계약서상 근무시간 사용'
						checked={useWorkingDays}
						onChange={() => setUseWorkingDays(!useWorkingDays)}
					/>
				)}
			</div> */}
			<Tab
				menu={{ secondary: true, pointing: true }}
				activeIndex={activeView === 'year' ? 0 : activeView === 'month' ? 1 : 2} // ✅ "월간 출퇴근" 탭도 고려
				onTabChange={
					(e, { activeIndex }) =>
						setActiveView(
							activeIndex === 0
								? 'year'
								: activeIndex === 1
								? 'month'
								: 'attendance'
						) // ✅ "월간 출퇴근" 추가
				}
				panes={[
					{
						menuItem: '월간 출퇴근',
						render: () => (
							<RealtimeCalendarDisplayCompany
								selectedYear={selectedYear} // ✅ 필수 추가
								selectedMonth={selectedMonth}
								companyId={companyId} // companyId prop 전달
								workingDays={workingDays}
								paidOffDay={paidOffDay}
							/>
						),
					},
					{
						menuItem: '월간 근태',
						render: () => (
							<ClientReqDashboard4InCalendarMonthCompany
								selectedYear={selectedYear} // ✅ 필수 추가
								selectedMonth={selectedMonth}
								attendanceData={filteredData}
								categories={categories}
								activeCategory={activeCategory}
								onCategoryChange={setActiveCategory}
								companyId={companyId} // companyId prop 전달
								workingDays={workingDays}
								paidOffDay={paidOffDay}
							/>
						),
					},
					// {
					// 	menuItem: '년간 근태',
					// 	render: () => (
					// 		<ClientReqDashboard4InCalendarYearCompany
					// 			selectedYear={selectedYear} // ✅ 필수 추가
					// 			attendanceData={filteredData} // ✅ prop 이름 변경 (filteredData -> attendanceData)
					// 			categories={categories}
					// 			activeCategory={activeCategory}
					// 			onCategoryChange={setActiveCategory}
					// 			companyId={companyId} // companyId prop 전달
					// 		/>
					// 	),
					// },
				]}
			/>
		</>
	);
}
