import { auth, db } from '../config/firebase';
// import { auth, db, realdb } from '../config/firebase';
// import { ref, set } from "firebase/database";  // Firebase Realtime Database의 함수를 가져옵니다.
import {
	doc,
	getDoc,
	query,
	collection,
	orderBy,
	limit,
	where,
	getDocs,
	arrayUnion,
	addDoc,
	updateDoc,
	deleteDoc,
	serverTimestamp,
	Timestamp,
	onSnapshot,
	arrayRemove,
} from 'firebase/firestore';
// import { decrypto, encrypto } from './firestoreService';
import { encrypto } from './firestoreService';

import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid'; // UUID 생성기 라이브러리
import {
	calRegionTaxF,
	calTaxF,
	cMH0Time,
	cMHBTime,
	cMHOTime,
	cMNWTime,
	cMOWTime,
	covid19SupportFundPersonF,
	days1LBTimeF,
	days2LBTime,
	days3LBTime,
	days4LBTime,
	days5LBTime,
	days6LBTime,
	daysNWT,
	daysOWT,
	daysWT,
	getAgeFromSocialNumber,
	getAgeFromSocialNumberEnteredDate,
	getGenderFromSocialNumber,
	healthCare,
	healthCareTaxWageSumF,
	healthCareText,
	hourOrdiWage,
	hourOrdiWageMonth,
	isThreeMonthsOrMore,
	lowestTimeWageOf2024,
	lowestTimeWageOf2025,
	mBW,
	mHBWWage,
	mHNWWage,
	mHOWWage,
	mLBT,
	mNWWage,
	monthTotalPaidConversedTime,
	mOrdiWage,
	mOWWage,
	netAge15To18F,
	noJobInsurance,
	noJobTaxWageSumF,
	noJobText,
	numStringToNumber,
	oldCare,
	oldCareTaxWageSumF,
	oldCareText,
	paidHolidayLBTimeF,
	payedAuthLevel,
	pension,
	pensionTaxWageSumF,
	pensionText,
	replacedWorkerFundPersonF,
	restLawViolationJudge,
	roundUp,
	roundUp2,
	sMONHWage,
	sumDaysOWTF,
	sumOneWeekLBDWTF,
	sumOneWeekNWTF,
	sumOneWeekWTF,
	sumOneWeekWTForOverTimeF,
	taxFreeCarWageLimitF,
	taxFreeChildWageLimitF,
	taxFreeMealWageLimitF,
	taxFreeStudyWageLimitF,
	teacherPension,
	teacherPensionTaxWageSumF,
	weekLBTimeF,
	weekToMonthCal,
	workingTimeDayNightAmount,
	workingTimeDayOverAmount,
	workingTimeDayTotalAmount,
	youthSupportFundPersonF,
} from '../common/util/util';
import moment from 'moment';

// snapshot.id를 가져오고  날짜를 자바스크립트형식으로 변경하는 함수
export function dataFromSnapshotIdDate(docSnapshot) {
	const data = docSnapshot.data();
	for (const prop in data) {
		if (data?.hasOwnProperty(prop)) {
			if (data[prop] instanceof Timestamp) {
				data[prop] = data[prop].toDate();
			}
		}
	}
	return {
		...data,
		id: docSnapshot.id,
	};
}
// 실시간 리스닝 검토
export function getClientReqs4InFromFirestore(observer) {
	return onSnapshot(collection(db, 'workers'), observer);
}

export async function dataFromSnapshotIdDateTable(docRef) {
	const docSnapshot = await getDoc(docRef);
	if (!docSnapshot.exists()) return undefined;
	const data = docSnapshot.data();
	for (const prop in data) {
		if (data?.hasOwnProperty(prop)) {
			if (data[prop] instanceof Timestamp) {
				data[prop] = data[prop].toDate().toString();
			}
		}
	}
	return {
		...data,
		id: docSnapshot.id,
	};
}

// 직원 유저 검색
export async function fetchWorkerUsersFromFirestore(companyId) {
	const workerUsersRef = collection(db, 'workerUsers');
	const q = query(workerUsersRef, where('companyId', '==', companyId));
	const querySnapshot = await getDocs(q);

	return querySnapshot;
}

export async function fetchClientReqs4InFromFirestore(filter) {
	const user = auth.currentUser;
	let clientReqsRef4In;
	switch (filter) {
		case 'isHosting': // 회원사
			clientReqsRef4In = query(
				collection(db, 'workers'),
				where('hostUid', '==', user?.uid),
				orderBy('worker.name', 'asc')
			);
			break;
		default:
			clientReqsRef4In = query(
				collection(db, 'workers'),
				where('companyName', '==', filter),
				orderBy('worker.name', 'asc')
			);
			break;
	}

	const snapshot = await getDocs(clientReqsRef4In);
	return snapshot;
}

//임금명세서 filteredList
export async function fetchAllPaydocusFromFirestore(
	filter,
	paydocuWorkerUid,
	yearMonth
) {
	const user = auth.currentUser;
	let payDocuQuery;

	switch (filter) {
		case 'isHosting': // 회원사
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('companyInfo.companyId', '==', user?.uid),
				orderBy('info.yearMonth', 'desc')
				// orderBy('workerInfo.workerDivisions', 'asc')
			);
			break;
		case 'isWorker': // 근로자
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('workerInfo.workerId', '==', paydocuWorkerUid),
				where('info.yearMonth', '==', yearMonth), // 현재 달과 이전 달 모두 가져옴
				orderBy('info.yearMonth', 'desc')
			);
			break;
		case 'isDayWorker': // 근로자
			const previousMonth = moment(yearMonth, 'YYYYMM')
				.subtract(1, 'months')
				.format('YYYYMM');

			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('workerInfo.workerId', '==', paydocuWorkerUid),
				where('info.yearMonth', 'in', [yearMonth, previousMonth]), // 현재 달과 이전 달 모두 가져옴
				orderBy('info.yearMonth', 'desc')
			);
			break;
		case filter: //
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('companyInfo.companyName', '==', filter),
				orderBy('info.yearMonth', 'desc'),
				limit(36)
				// orderBy('workerInfo.workerDivisions', 'asc')
			);
			break;
		default: // 전체 노무사를 볼수 있는
			payDocuQuery = query(
				collection(db, 'payDocu'),
				orderBy('info.yearMonth', 'desc'),
				limit(36)
				// orderBy('workerInfo.workerDivisions', 'asc')
			);
			break;
	}

	const querySnapshot = await getDocs(payDocuQuery);
	return querySnapshot;
}

export async function fetchLastMonthPaydocusFromFirestore(
	paydocuWorkerUid,
	yearMonth
) {
	let payDocuQuery;

	const previousMonth = moment(yearMonth, 'YYYYMM')
		.subtract(1, 'months')
		.format('YYYYMM');

	payDocuQuery = query(
		collection(db, 'payDocu'),
		where('workerInfo.workerId', '==', paydocuWorkerUid),
		where('info.yearMonth', '==', previousMonth), // 현재 달과 이전 달 모두 가져옴
		orderBy('info.yearMonth', 'desc')
	);

	const querySnapshot = await getDocs(payDocuQuery);
	return querySnapshot;
}

//임금명세서 filteredList
export async function fetchPaydocusFromFirestore(
	filter,
	yearMonth,
	paydocuWorkerUid,
	clientInfo
) {
	const user = auth.currentUser;
	const year = yearMonth.substr(0, 4);
	let payDocuQuery;
	const userUid = clientInfo?.id ? clientInfo?.id : user?.uid;

	switch (filter) {
		case 'isHosting': // 회원사
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('companyInfo.companyId', '==', user?.uid),
				where('info.yearMonth', '==', yearMonth),
				orderBy('workerInfo.worker', 'asc')
			);
			break;
		//11월21일(DB색인연동)
		case 'isWorker': //
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('workerInfo.workerId', '==', paydocuWorkerUid),
				orderBy('info.yearMonth', 'asc'),
				limit(36)
			);
			break;
		case 'isAgent': //
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('companyInfo.companyId', '==', userUid),
				where('info.year', '==', year),
				orderBy('workerInfo.worker', 'asc')
			);
			break;
		case filter: //
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('companyInfo.companyId', '==', userUid),
				where('info.yearMonth', '==', yearMonth),
				orderBy('workerInfo.worker', 'asc')
			);
			break;
		default: // 직원꺼
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('workerInfo.workerId', '==', paydocuWorkerUid)
			);
			break;
	}

	try {
		const querySnapshot = await getDocs(payDocuQuery);
		return querySnapshot;
	} catch (error) {
		console.error('Error fetching paydocus:', error);
		throw error;
	}
}

// 임금명세서(직원별) filteredList
export async function fetchPaydocusWorkerFromFirestore(
	workerName,
	paydocuWorkerCode,
	companyId
) {
	let payDocuQuery;

	if (paydocuWorkerCode) {
		payDocuQuery = query(
			collection(db, 'payDocu'),
			where('companyInfo.companyId', '==', companyId),
			where('workerInfo.workerId', '==', paydocuWorkerCode),
			orderBy('info.yearMonth', 'desc'),
			limit(36)
		);
	} else {
		payDocuQuery = query(
			collection(db, 'payDocu'),
			where('companyInfo.companyId', '==', companyId),
			where('workerInfo.worker', '==', workerName),
			orderBy('info.yearMonth', 'desc'),
			limit(36)
		);
	}

	const querySnapshot = await getDocs(payDocuQuery);
	return querySnapshot;
}

//4대보험 입사자 신고용
export function listenToClientReq4InFromFirestore(clientReq4InId) {
	return doc(db, 'workers', clientReq4InId);
}

// 급여대장 wageTables
export function fetchWageTablesFromFirestore() {
	const user = auth.currentUser;
	return query(
		collection(db, 'wageTables'),
		where('xhostUid', '==', user?.uid)
	);
}

//급여대장
export function listenToWageTableInFromFirestore(wageTableId) {
	return doc(db, 'wageTables', wageTableId);
}

//회사직원 등록
export async function addWorkerUsersToFirestore(profile) {
	const user = auth?.currentUser;
	const workerUserId = profile?.id ? profile?.id : user?.uid;

	return await addDoc(collection(db, 'workerUsers'), {
		...profile,
		workerUserId: workerUserId,
		createdAt: serverTimestamp(),
	});
}

//user에 회사직원 등록
export async function updateWorkerUsersToFirestore(profile) {
	const user = auth.currentUser;
	const userRef = doc(db, 'users', profile?.companyId);
	const docSnapshot = await getDoc(userRef);
	if (!docSnapshot.exists()) throw new Error('Company does not exist');

	const docData = docSnapshot?.data();
	const numOfWorkers = docData?.numOfWorkers ?? 5;
	const finBaseDate = docData?.finBaseDate ?? null;
	const annualLeaves = docData?.annualLeaves ?? '입사일기준';
	const companyAddress = docData?.companyAddress ?? null;
	const companyBizItem = docData?.bizItem ?? null;
	const companyRegion = docData?.companyRegion ?? null;

	const workerRef = doc(db, 'workers', profile?.paydocuWorkerUid);
	const workerDocSnapshot = await getDoc(workerRef);
	if (!workerDocSnapshot.exists()) throw new Error('Worker does not exist');

	const workerData = workerDocSnapshot?.data();
	const daylyTimePay = workerData?.daylyPay?.timeWage
		? numStringToNumber(workerData?.daylyPay?.timeWage)
		: 0;
	const isDayWorker =
		workerData?.dayWorker?.daylyPay || daylyTimePay ? true : false;
	const isTimeWorker = daylyTimePay ? true : false;
	const isBizWorker = workerData?.isBizWorker ? true : false;
	const workerEnteredDate = workerData?.workerEnteredDate
		? workerData?.workerEnteredDate
		: '';
	const workerUserId = profile?.id ? profile?.id : user.uid;
	const workerName = workerData?.worker?.name ? workerData?.worker?.name : '';

	await updateDoc(doc(db, 'users', workerUserId), {
		...profile,
		workerUserId: workerUserId || '',
		// workerName : encryptoProfile?.workerName,
		workerEnteredDate: workerEnteredDate || '',
		companyId: profile?.companyId || '',
		numOfWorkers: numOfWorkers || '',
		finBaseDate: finBaseDate || '',
		annualLeaves: annualLeaves || '',
		companyAddress: companyAddress || '',
		companyBizItem: companyBizItem || '',
		companyRegion: companyRegion || '',
		isDayWorker: isDayWorker || '',
		isTimeWorker: isTimeWorker || '',
		isBizWorker: isBizWorker || '',
		isWorker: true,
		workerName: workerName || '',

		paydocuWorkerUids: arrayUnion({
			paydocuWorkerUid: profile?.paydocuWorkerUid || '',
			workerInputCompanyName: profile?.workerInputCompanyName || '',
			agentName: profile?.agentName || '',
			agentUid: profile?.agentUid || '',
			createdAt: new Date(),
			numOfWorkers: numOfWorkers || '',
			finBaseDate: finBaseDate || '',
			annualLeaves: annualLeaves || '',
			companyAddress: companyAddress || '',
			companyBizItem: companyBizItem || '',
			companyId: profile?.companyId || '',
			isDayWorker: isDayWorker || '',
			isTimeWorker: isTimeWorker || '',
			isBizWorker: isBizWorker || '',
		}),
		createdAt: serverTimestamp(),
	});
}

export async function deleteAnnualLeavesToFirestore(workerId, yearMonth) {
	const workerRef = doc(db, 'workers', workerId);
	const workerDocSnapshot = await getDoc(workerRef);
	const workerData = workerDocSnapshot.data();

	if (workerData?.usedAnnualLeaves) {
		const updatedAnnualLeaves = workerData?.usedAnnualLeaves.filter(
			(usedAnnualLeave) => usedAnnualLeave.yearMonth !== yearMonth
		);
		await updateDoc(workerRef, { usedAnnualLeaves: updatedAnnualLeaves });
	}
}

export async function addAbsentDaysToFirestore(workerId, values) {
	const workerRef = doc(db, 'workers', workerId);

	const absentDay = parseFloat(values.absentDays);
	const yearMonth = values.thisYear + values.thisMonth;
	const absentDates = values.absentDates;
	const absentTimes = values.absentTimes;
	const absentTimeDates = values.absentTimeDates;

	return await updateDoc(workerRef, {
		absentDays: arrayUnion({
			absentDay: absentDay,
			yearMonth: yearMonth,
			absentDates: absentDates,
			absentTimes: absentTimes,
			absentTimeDates: absentTimeDates,
		}),
	});
}

export async function addMaternityLeaveToWorkerFirestore(workerId, values) {
	const workerRef = doc(db, 'workers', workerId);

	const maternityLeaveStartDate = values.maternityLeaveStartDate;
	const maternityLeaveEndDate = values.maternityLeaveEndDate;
	const maternityLeaveType = values.maternityLeaveType;

	return await updateDoc(workerRef, {
		maternityLeaveStartDate: values.maternityLeaveStartDate,
		maternityLeaveEndDate: values.maternityLeaveEndDate,
		maternityLeaveType: values.maternityLeaveType,

		maternityLeaves: arrayUnion({
			id: uuidv4(), // 고유 ID 생성
			maternityLeaveStartDate: maternityLeaveStartDate,
			maternityLeaveEndDate: maternityLeaveEndDate,
			maternityLeaveType: maternityLeaveType,
			addDate: new Date(),
		}),
	});
}

export async function addChildLeaveOfAbsenceToWorkerFirestore(
	workerId,
	values
) {
	const workerRef = doc(db, 'workers', workerId);

	const childLeaveOfAbsenceStartDate = values.childLeaveOfAbsenceStartDate;
	const childLeaveOfAbsenceEndDate = values.childLeaveOfAbsenceEndDate;
	const childLeaveOfAbsenceType = values.childLeaveOfAbsenceType;

	return await updateDoc(workerRef, {
		childLeaveOfAbsenceStartDate: values.childLeaveOfAbsenceStartDate,
		childLeaveOfAbsenceEndDate: values.childLeaveOfAbsenceEndDate,
		childLeaveOfAbsenceType: values.childLeaveOfAbsenceType,

		childLeaveOfAbsences: arrayUnion({
			id: uuidv4(), // 고유 ID 생성
			childLeaveOfAbsenceStartDate: childLeaveOfAbsenceStartDate,
			childLeaveOfAbsenceEndDate: childLeaveOfAbsenceEndDate,
			childLeaveOfAbsenceType: childLeaveOfAbsenceType,
			addDate: new Date(),
		}),
	});
}

export async function addChildShortTimeToWorkerFirestore(workerId, values) {
	const workerRef = doc(db, 'workers', workerId);

	const childShortTimeStartDate = values.childShortTimeStartDate;
	const childShortTimeEndDate = values.childShortTimeEndDate;
	const childShortTimeType = values.childShortTimeType;

	return await updateDoc(workerRef, {
		childShortTimeStartDate: values.childShortTimeStartDate,
		childShortTimeEndDate: values.childShortTimeEndDate,
		childShortTimeType: values.childShortTimeType,

		childShortTimes: arrayUnion({
			id: uuidv4(), // 고유 ID 생성
			childShortTimeStartDate: childShortTimeStartDate,
			childShortTimeEndDate: childShortTimeEndDate,
			childShortTimeType: childShortTimeType,
			addDate: new Date(),
		}),
	});
}

export async function addLeaveOfAbsenceToWorkerFirestore(workerId, values) {
	const workerRef = doc(db, 'workers', workerId);

	const leaveOfAbsenceStartDate = values.leaveOfAbsenceStartDate;
	const leaveOfAbsenceEndDate = values.leaveOfAbsenceEndDate;
	const leaveOfAbsenceType = values.leaveOfAbsenceType;

	return await updateDoc(workerRef, {
		leaveOfAbsenceStartDate: values.leaveOfAbsenceStartDate,
		leaveOfAbsenceEndDate: values.leaveOfAbsenceEndDate,
		leaveOfAbsenceType: values.leaveOfAbsenceType,

		leaveOfAbsences: arrayUnion({
			id: uuidv4(), // 고유 ID 생성
			leaveOfAbsenceStartDate: leaveOfAbsenceStartDate,
			leaveOfAbsenceEndDate: leaveOfAbsenceEndDate,
			leaveOfAbsenceType: leaveOfAbsenceType,
			addDate: new Date(),
		}),
	});
}

export async function updateMaternityLeaveInWorkerFirestore(
	workerId,
	leaveId,
	updatedValues
) {
	const workerRef = doc(db, 'workers', workerId);
	const workerSnap = await getDoc(workerRef);

	if (workerSnap.exists()) {
		const workerData = workerSnap.data();
		const maternityLeaves = workerData.maternityLeaves || [];

		const updatedLeaves = maternityLeaves.map((leave) => {
			if (leave.id === leaveId) {
				return {
					...leave,
					...updatedValues,
					addDate: new Date(),
				};
			}
			return leave;
		});

		await updateDoc(workerRef, { maternityLeaves: updatedLeaves });
	} else {
		console.log('No such document!');
	}
}

export async function updateChildLeaveOfAbsenceInWorkerFirestore(
	workerId,
	leaveId,
	updatedValues
) {
	const workerRef = doc(db, 'workers', workerId);
	const workerSnap = await getDoc(workerRef);

	if (workerSnap.exists()) {
		const workerData = workerSnap.data();
		const childLeaveOfAbsences = workerData.childLeaveOfAbsences || [];

		const updatedLeaves = childLeaveOfAbsences.map((leave) => {
			if (leave.id === leaveId) {
				return {
					...leave,
					...updatedValues,
					addDate: new Date(),
				};
			}
			return leave;
		});

		await updateDoc(workerRef, { childLeaveOfAbsences: updatedLeaves });
	} else {
		console.log('No such document!');
	}
}

export async function updateChildShortTimeInWorkerFirestore(
	workerId,
	leaveId,
	updatedValues
) {
	const workerRef = doc(db, 'workers', workerId);
	const workerSnap = await getDoc(workerRef);

	if (workerSnap.exists()) {
		const workerData = workerSnap.data();
		const childShortTimes = workerData.childShortTimes || [];

		const updatedLeaves = childShortTimes.map((leave) => {
			if (leave.id === leaveId) {
				return {
					...leave,
					...updatedValues,
					addDate: new Date(),
				};
			}
			return leave;
		});

		await updateDoc(workerRef, { childShortTimes: updatedLeaves });
	} else {
		console.log('No such document!');
	}
}

export async function updateLeaveOfAbsenceInWorkerFirestore(
	workerId,
	leaveId,
	updatedValues
) {
	const workerRef = doc(db, 'workers', workerId);
	const workerSnap = await getDoc(workerRef);

	if (workerSnap.exists()) {
		const workerData = workerSnap.data();
		const leaveOfAbsences = workerData.leaveOfAbsences || [];

		const updatedLeaves = leaveOfAbsences.map((leave) => {
			if (leave.id === leaveId) {
				return {
					...leave,
					...updatedValues,
					addDate: new Date(),
				};
			}
			return leave;
		});

		await updateDoc(workerRef, { leaveOfAbsences: updatedLeaves });
	} else {
		console.log('No such document!');
	}
}

export async function deleteMaternityLeaveFromWorkerFirestore(
	workerId,
	leaveId
) {
	const workerRef = doc(db, 'workers', workerId);
	const workerSnap = await getDoc(workerRef);

	if (workerSnap.exists()) {
		const workerData = workerSnap.data();
		const maternityLeaves = workerData.maternityLeaves || [];

		const leaveToDelete = maternityLeaves.find((leave) => leave.id === leaveId);

		if (leaveToDelete) {
			await updateDoc(workerRef, {
				maternityLeaves: arrayRemove(leaveToDelete),
			});

			// Get the updated document to check the current state of maternityLeaves
			const updatedWorkerSnap = await getDoc(workerRef);
			const updatedWorkerData = updatedWorkerSnap.data();
			const updatedMaternityLeaves = updatedWorkerData.maternityLeaves || [];

			// If the array is empty after deletion, set values to empty strings
			if (updatedMaternityLeaves.length === 0) {
				await updateDoc(workerRef, {
					maternityLeaveStartDate: '',
					maternityLeaveEndDate: '',
					maternityLeaveType: '',
				});
			}
		} else {
			console.log('해당 ID의 출산휴가를 찾을 수 없습니다!');
		}
	} else {
		console.log('해당 문서를 찾을 수 없습니다!');
	}
}

export async function deleteChildLeaveOfAbsenceFromWorkerFirestore(
	workerId,
	leaveId
) {
	const workerRef = doc(db, 'workers', workerId);
	const workerSnap = await getDoc(workerRef);

	if (workerSnap.exists()) {
		const workerData = workerSnap.data();
		const childLeaveOfAbsences = workerData.childLeaveOfAbsences || [];

		const leaveToDelete = childLeaveOfAbsences.find(
			(leave) => leave.id === leaveId
		);

		if (leaveToDelete) {
			await updateDoc(workerRef, {
				childLeaveOfAbsences: arrayRemove(leaveToDelete),
			});

			// Get the updated document to check the current state of maternityLeaves
			const updatedWorkerSnap = await getDoc(workerRef);
			const updatedWorkerData = updatedWorkerSnap.data();
			const updatedMaternityLeaves = updatedWorkerData.maternityLeaves || [];

			// If the array is empty after deletion, set values to empty strings
			if (updatedMaternityLeaves.length === 0) {
				await updateDoc(workerRef, {
					childLeaveOfAbsenceStartDate: '',
					childLeaveOfAbsenceEndDate: '',
					childLeaveOfAbsenceType: '',
				});
			}
		} else {
			console.log('해당 ID의 육아휴직을 찾을 수 없습니다!');
		}
	} else {
		console.log('해당 문서를 찾을 수 없습니다!');
	}
}

export async function deleteChildShortTimeFromWorkerFirestore(
	workerId,
	leaveId
) {
	const workerRef = doc(db, 'workers', workerId);
	const workerSnap = await getDoc(workerRef);

	if (workerSnap.exists()) {
		const workerData = workerSnap.data();
		const childShortTimes = workerData.childShortTimes || [];

		const leaveToDelete = childShortTimes.find((leave) => leave.id === leaveId);

		if (leaveToDelete) {
			await updateDoc(workerRef, {
				childShortTimes: arrayRemove(leaveToDelete),
			});

			// Get the updated document to check the current state of maternityLeaves
			const updatedWorkerSnap = await getDoc(workerRef);
			const updatedWorkerData = updatedWorkerSnap.data();
			const updatedMaternityLeaves = updatedWorkerData.maternityLeaves || [];

			// If the array is empty after deletion, set values to empty strings
			if (updatedMaternityLeaves.length === 0) {
				await updateDoc(workerRef, {
					childShortTimeStartDate: '',
					childShortTimeEndDate: '',
					childShortTimeType: '',
				});
			}
		} else {
			console.log('해당 ID의 육아기 근로시간 단축을 찾을 수 없습니다!');
		}
	} else {
		console.log('해당 문서를 찾을 수 없습니다!');
	}
}

export async function deleteLeaveOfAbsenceFromWorkerFirestore(
	workerId,
	leaveId
) {
	const workerRef = doc(db, 'workers', workerId);
	const workerSnap = await getDoc(workerRef);

	if (workerSnap.exists()) {
		const workerData = workerSnap.data();
		const leaveOfAbsences = workerData.leaveOfAbsences || [];

		const leaveToDelete = leaveOfAbsences.find((leave) => leave.id === leaveId);

		if (leaveToDelete) {
			await updateDoc(workerRef, {
				leaveOfAbsences: arrayRemove(leaveToDelete),
			});

			// Get the updated document to check the current state of maternityLeaves
			const updatedWorkerSnap = await getDoc(workerRef);
			const updatedWorkerData = updatedWorkerSnap.data();
			const updatedMaternityLeaves = updatedWorkerData.maternityLeaves || [];

			// If the array is empty after deletion, set values to empty strings
			if (updatedMaternityLeaves.length === 0) {
				await updateDoc(workerRef, {
					leaveOfAbsenceStartDate: '',
					leaveOfAbsenceEndDate: '',
					leaveOfAbsenceType: '',
				});
			}
		} else {
			console.log('해당 ID의 개인 휴직을 찾을 수 없습니다!');
		}
	} else {
		console.log('해당 문서를 찾을 수 없습니다!');
	}
}

export async function addTotalWagesArrayToWorkerFirestore(workerId, values) {
	const workerRef = doc(db, 'workers', workerId);

	const totalMonthWageAmount = values.totalMonthWageAmount;
	const wageContractStartDate = values.wageContractStartDate;
	const wageContarctEndDate = values.wageContarctEndDate;
	const workerDivisions = values.workerDivisions;
	const workerTitles = values.workerTitles;

	return await updateDoc(workerRef, {
		totalWages: arrayUnion({
			totalMonthWageAmount: totalMonthWageAmount,
			wageContractStartDate: wageContractStartDate,
			wageContarctEndDate: wageContarctEndDate,
			workerDivisions: workerDivisions,
			workerTitles: workerTitles,
			addDate: new Date(),
		}),
	});
}

export async function deleteAbsentDaysToFirestore(workerId, yearMonth) {
	const workerRef = doc(db, 'workers', workerId);
	const workerSnap = await getDoc(workerRef);

	if (workerSnap.data().absentDays) {
		const filteredAbsentDays = workerSnap
			.data()
			.absentDays.filter((absentDay) => absentDay.yearMonth !== yearMonth);
		return await updateDoc(workerRef, { absentDays: filteredAbsentDays });
	} else {
		return null;
	}
}

//회사직원 불러오기
export function getWorkerUserProfile(observer) {
	const collectionRef = collection(db, 'users');
	return onSnapshot(collectionRef, observer);
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export async function addRetireFundClient4InToFirestore(workerId, values) {
	const workerRef = doc(db, 'workers', workerId);
	return await updateDoc(workerRef, {
		retireWage: {
			...values,
			exceptAverPeriodAndWageMemo: values.exceptAverPeriodAndWageMemo
				? values.exceptAverPeriodAndWageMemo
				: '',
			updatedDate: serverTimestamp(),
		},
	});
}

//worker 등록
export async function addClientReq4InToFirestore(
	clientReq4In,
	agentUid,
	clientInfo,
	isBizIncomWorker
) {
	const user = auth.currentUser;
	const userRef = doc(db, 'users', user.uid);
	const docSnapshot = await getDoc(userRef);
	const data = docSnapshot.data();
	const numOfWorkers = clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: data?.numOfWorkers;
	const finBaseDate = clientInfo?.finBaseDate
		? clientInfo?.finBaseDate
		: data?.finBaseDate;
	const annualLeaves = clientInfo?.annualLeaves
		? clientInfo?.annualLeaves
		: data?.annualLeaves;
	const companyBizItem = clientInfo?.bizItem
		? clientInfo?.bizItem
		: data?.bizItem;
	const overFiveStartDay = clientInfo?.overFiveStartDay
		? clientInfo?.overFiveStartDay
		: data?.overFiveStartDay;
	// 11월21일
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: data?.companyName;
	const companyBizNumber = clientInfo?.companyBizNumber
		? clientInfo?.companyBizNumber
		: data?.companyBizNumber;

	const ceoName = clientInfo?.ceoName ? clientInfo?.ceoName : data?.ceoName;
	const companyAddress = clientInfo?.companyAddress
		? clientInfo?.companyAddress
		: data?.companyAddress;
	const hostUid = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: user?.uid;

	const privateSchool = clientInfo?.privateSchool
		? clientInfo?.privateSchool
		: data?.privateSchool;

	const agentName = clientInfo?.agentName
		? clientInfo?.agentName
		: data?.agentName;
	const agentType = clientInfo?.agentType
		? clientInfo?.agentType
		: data?.agentType;
	const agentWorkerName = clientInfo?.agentWorkerName
		? clientInfo?.agentWorkerName
		: data?.agentWorkerName;
	const managerCode = clientInfo?.managerCode
		? clientInfo?.managerCode
		: data?.managerCode;
	const insureManageType = clientInfo?.insureManageType
		? clientInfo?.insureManageType
		: data?.insureManageType;

	const normalWorkerWeekWorkDays = clientInfo?.normalWorkerWeekWorkDays
		? clientInfo?.normalWorkerWeekWorkDays
		: data?.normalWorkerWeekWorkDays;

	const encryptoClientReq4In = {};
	for (const prop in clientReq4In) {
		encryptoClientReq4In[prop] =
			prop === 'workerSocialNumberFront' ||
			prop === 'workerSocialNumberBack' ||
			prop === 'familySocialNumber' ||
			prop === 'healthCarePeopleComments'
				? encrypto(clientReq4In[prop], String(process.env.CRYPTO_KEY))
				: clientReq4In[prop];
	}

	// 성별... 나이
	const gender = getGenderFromSocialNumber(clientReq4In.workerSocialNumberBack);
	const enterDate = clientReq4In.workerEnteredDate;
	const netAge = getAgeFromSocialNumber(clientReq4In.workerSocialNumberFront);
	// const netAge15To18 = clientReq4In?.netAge15To18 ? clientReq4In?.netAge15To18 : ''
	const netAgeEntered = getAgeFromSocialNumberEnteredDate(
		clientReq4In?.workerSocialNumberFront,
		enterDate
	);

	// const netAge15To18 = clientReq4In?.netAge15To18 ? clientReq4In?.netAge15To18 : ''

	const netAge15To18 = netAge15To18F(netAge);
	const workerEnteredDate = enterDate
		? format(new Date(enterDate), 'yyyy-MM-dd')
		: '';

	const contractEndDate = clientReq4In?.contractEndDate
		? format(clientReq4In?.contractEndDate, 'yyyy-MM-dd')
		: '정년';
	const over3MonthsWorker = isThreeMonthsOrMore(enterDate, contractEndDate);

	//////////////////////////////////////// 감단직//////////////////////////////////////////////////

	//(입력된) 첫번째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL1 =
		clientReq4In?.selectWorkDaysETL1Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL1Info?.workingStartTime;
	const dayTypeETL1 =
		clientReq4In?.selectWorkDaysETL1Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL1Info?.workingEndTimeNextDayOrNot;
	const endWTETL1 =
		clientReq4In?.selectWorkDaysETL1Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL1Info?.workingEndTime;
	const dayRTETL1 =
		clientReq4In?.selectWorkDaysETL1Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL1Info?.dayBreakTimes;
	const nigthRTETL1 =
		clientReq4In?.selectWorkDaysETL1Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL1Info?.nightBreakTimes;

	//(입력된) 둘째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL2 =
		clientReq4In?.selectWorkDaysETL2Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL2Info?.workingStartTime;
	const dayTypeETL2 =
		clientReq4In?.selectWorkDaysETL2Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL2Info?.workingEndTimeNextDayOrNot;
	const endWTETL2 =
		clientReq4In?.selectWorkDaysETL2Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL2Info?.workingEndTime;
	const dayRTETL2 =
		clientReq4In?.selectWorkDaysETL2Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL2Info?.dayBreakTimes;
	const nigthRTETL2 =
		clientReq4In?.selectWorkDaysETL2Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL2Info?.nightBreakTimes;

	//(입력된) 세째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL3 =
		clientReq4In?.selectWorkDaysETL3Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL3Info?.workingStartTime;
	const dayTypeETL3 =
		clientReq4In?.selectWorkDaysETL3Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL3Info?.workingEndTimeNextDayOrNot;
	const endWTETL3 =
		clientReq4In?.selectWorkDaysETL3Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL3Info?.workingEndTime;
	const dayRTETL3 =
		clientReq4In?.selectWorkDaysETL3Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL3Info?.dayBreakTimes;
	const nigthRTETL3 =
		clientReq4In?.selectWorkDaysETL3Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL3Info?.nightBreakTimes;

	//(입력된) 네째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL4 =
		clientReq4In?.selectWorkDaysETL4Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL4Info?.workingStartTime;
	const dayTypeETL4 =
		clientReq4In?.selectWorkDaysETL4Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL4Info?.workingEndTimeNextDayOrNot;
	const endWTETL4 =
		clientReq4In?.selectWorkDaysETL4Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL4Info?.workingEndTime;
	const dayRTETL4 =
		clientReq4In?.selectWorkDaysETL4Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL4Info?.dayBreakTimes;
	const nigthRTETL4 =
		clientReq4In?.selectWorkDaysETL4Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL4Info?.nightBreakTimes;

	//(입력된) 다섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL5 =
		clientReq4In?.selectWorkDaysETL5Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL5Info?.workingStartTime;
	const dayTypeETL5 =
		clientReq4In?.selectWorkDaysETL5Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL5Info?.workingEndTimeNextDayOrNot;
	const endWTETL5 =
		clientReq4In?.selectWorkDaysETL5Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL5Info?.workingEndTime;
	const dayRTETL5 =
		clientReq4In?.selectWorkDaysETL5Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL5Info?.dayBreakTimes;
	const nigthRTETL5 =
		clientReq4In?.selectWorkDaysETL5Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL5Info?.nightBreakTimes;

	//(입력된) 여섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL6 =
		clientReq4In?.selectWorkDaysETL6Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL6Info?.workingStartTime;
	const dayTypeETL6 =
		clientReq4In?.selectWorkDaysETL6Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL6Info?.workingEndTimeNextDayOrNot;
	const endWTETL6 =
		clientReq4In?.selectWorkDaysETL6Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL6Info?.workingEndTime;
	const dayRTETL6 =
		clientReq4In?.selectWorkDaysETL6Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL6Info?.dayBreakTimes;
	const nigthRTETL6 =
		clientReq4In?.selectWorkDaysETL6Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL6Info?.nightBreakTimes;

	//(입력된) 근무요일들
	const daysETL1 = clientReq4In?.selectWorkDaysETL1;
	const daysETL2 = clientReq4In?.selectWorkDaysETL2;
	const daysETL3 = clientReq4In?.selectWorkDaysETL3;
	const daysETL4 = clientReq4In?.selectWorkDaysETL4;
	const daysETL5 = clientReq4In?.selectWorkDaysETL5;
	const daysETL6 = clientReq4In?.selectWorkDaysETL6;

	//(입력된) 근무일 갯수
	const daysETL1Length =
		daysETL1?.length === undefined ? 0 : Number(daysETL1?.length);
	const daysETL2Length =
		daysETL2?.length === undefined ? 0 : Number(daysETL2?.length);
	const daysETL3Length =
		daysETL3?.length === undefined ? 0 : Number(daysETL3?.length);
	const daysETL4Length =
		daysETL4?.length === undefined ? 0 : Number(daysETL4?.length);
	const daysETL5Length =
		daysETL5?.length === undefined ? 0 : Number(daysETL5?.length);
	const daysETL6Length =
		daysETL6?.length === undefined ? 0 : Number(daysETL6?.length);

	//(계산된) 첫번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL1 = daysWT(
		endWTETL1,
		dayTypeETL1,
		startWTETL1,
		dayRTETL1,
		nigthRTETL1
	);
	//두번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL2 = daysWT(
		endWTETL2,
		dayTypeETL2,
		startWTETL2,
		dayRTETL2,
		nigthRTETL2
	);
	//세번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL3 = daysWT(
		endWTETL3,
		dayTypeETL3,
		startWTETL3,
		dayRTETL3,
		nigthRTETL3
	);
	//네번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL4 = daysWT(
		endWTETL4,
		dayTypeETL4,
		startWTETL4,
		dayRTETL4,
		nigthRTETL4
	);
	//다섯번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL5 = daysWT(
		endWTETL5,
		dayTypeETL5,
		startWTETL5,
		dayRTETL5,
		nigthRTETL5
	);
	//여섯번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL6 = daysWT(
		endWTETL6,
		dayTypeETL6,
		startWTETL6,
		dayRTETL6,
		nigthRTETL6
	);

	const expectYearly7thWorkDayCounts =
		clientReq4In?.expectYearly7thWorkDayCounts
			? +clientReq4In?.expectYearly7thWorkDayCounts
			: '';
	//(입력된) 여섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL7 =
		clientReq4In?.expectYearly7thDaysETLInfo?.workingStartTime === undefined
			? 0
			: clientReq4In?.expectYearly7thDaysETLInfo?.workingStartTime;
	const dayTypeETL7 =
		clientReq4In?.expectYearly7thDaysETLInfo?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.expectYearly7thDaysETLInfo?.workingEndTimeNextDayOrNot;
	const endWTETL7 =
		clientReq4In?.expectYearly7thDaysETLInfo?.workingEndTime === undefined
			? 0
			: clientReq4In?.expectYearly7thDaysETLInfo?.workingEndTime;
	const dayRTETL7 =
		clientReq4In?.expectYearly7thDaysETLInfo?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.expectYearly7thDaysETLInfo?.dayBreakTimes;
	const nigthRTETL7 =
		clientReq4In?.expectYearly7thDaysETLInfo?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.expectYearly7thDaysETLInfo?.nightBreakTimes;

	const daysWTETL7 =
		(daysWT(endWTETL7, dayTypeETL7, startWTETL7, dayRTETL7, nigthRTETL7) *
			expectYearly7thWorkDayCounts) /
		12 /
		4.345;

	const expectWeeklyOverETRWorks =
		clientReq4In?.expectWeeklyOverETRWorks === undefined
			? 0
			: +clientReq4In?.expectWeeklyOverETRWorks;
	const expectWeeklyNightETRWorks =
		clientReq4In?.expectWeeklyNightETRWorks === undefined
			? 0
			: +clientReq4In?.expectWeeklyNightETRWorks;

	// 격일제 근로자
	const shiftWorkWorker =
		clientReq4In?.selectWorkDaysETL1Info?.shiftWork === '격일제 해당'
			? true
			: false;

	// 1주일 전체 1일 전체근로시간의 합계(휴게제외)-기본급(일반 근로자)
	const nomalWorkerSumOneWeekLBDWTETL =
		daysWTETL1 * daysETL1Length +
		daysWTETL2 * daysETL2Length +
		daysWTETL3 * daysETL3Length +
		daysWTETL4 * daysETL4Length +
		daysWTETL5 * daysETL5Length +
		daysWTETL6 * daysETL6Length +
		daysWTETL7 +
		expectWeeklyOverETRWorks;

	const sumOneWeekLBDWTETL = shiftWorkWorker
		? nomalWorkerSumOneWeekLBDWTETL / 2
		: nomalWorkerSumOneWeekLBDWTETL;

	const fixedMonthRealWorkingHoursETL = shiftWorkWorker
		? (daysWTETL1 * 365) / 2 / 12
		: ((sumOneWeekLBDWTETL / 7) * 365) / 12;

	//////////////////////////////////////////////////야간근로시간////////////////////ㅡ
	//첫번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL1 = daysNWT(
		daysETL1,
		startWTETL1,
		endWTETL1,
		dayTypeETL1,
		nigthRTETL1
	);
	//두번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL2 = daysNWT(
		daysETL2,
		startWTETL2,
		endWTETL2,
		dayTypeETL2,
		nigthRTETL2
	);
	//세번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL3 = daysNWT(
		daysETL3,
		startWTETL3,
		endWTETL3,
		dayTypeETL3,
		nigthRTETL3
	);
	//네번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL4 = daysNWT(
		daysETL4,
		startWTETL4,
		endWTETL4,
		dayTypeETL4,
		nigthRTETL4
	);
	//다섯번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL5 = daysNWT(
		daysETL5,
		startWTETL5,
		endWTETL5,
		dayTypeETL5,
		nigthRTETL5
	);
	//여섯번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL6 = daysNWT(
		daysETL6,
		startWTETL6,
		endWTETL6,
		dayTypeETL6,
		nigthRTETL6
	);

	const daysNWTETL7 =
		(daysNWT(1, startWTETL7, endWTETL7, dayTypeETL7, nigthRTETL7) *
			expectYearly7thWorkDayCounts) /
		12 /
		4.345;

	const nomalWorkerSumOneWeekNWTETL =
		daysNWTETL1 * daysETL1Length +
		daysNWTETL2 * daysETL2Length +
		daysNWTETL3 * daysETL3Length +
		daysNWTETL4 * daysETL4Length +
		daysNWTETL5 * daysETL5Length +
		daysNWTETL6 * daysETL6Length +
		daysNWTETL7 +
		expectWeeklyNightETRWorks;

	//1주 총 야간근로시간
	const sumOneWeekNWTETL = shiftWorkWorker
		? nomalWorkerSumOneWeekNWTETL / 2
		: nomalWorkerSumOneWeekNWTETL;

	const fixedMonthRealNightWorkingHoursETL = shiftWorkWorker
		? (daysNWTETL1 * 365) / 2 / 12
		: ((sumOneWeekNWTETL / 7) * 365) / 12;
	const isExceptTimeLawWorker = fixedMonthRealWorkingHoursETL ? true : false;
	const annualLeavesIncludeMonthWageTime =
		clientReq4In?.annualLeavesIncludeOrNot === '년차 15일분 임금에 포함'
			? (((sumOneWeekLBDWTETL - daysWTETL7) / 6) * 15) / 12
			: 0;

	//////////////////////////////////////// 위가 감단직//////////////////////////////////////////////////

	//(입력된) 첫번째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek1 =
		clientReq4In?.selectWorkDays1Info?.skipWeek === undefined
			? ''
			: clientReq4In?.selectWorkDays1Info?.skipWeek;
	const startWT1 =
		clientReq4In?.selectWorkDays1Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDays1Info?.workingStartTime;
	const dayType1 =
		clientReq4In?.selectWorkDays1Info?.workingEndTimeNextDayOrNot === undefined
			? 0
			: clientReq4In?.selectWorkDays1Info?.workingEndTimeNextDayOrNot;
	const endWT1 =
		clientReq4In?.selectWorkDays1Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDays1Info?.workingEndTime;
	const dayRT1 =
		clientReq4In?.selectWorkDays1Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays1Info?.dayBreakTimes;
	const nigthRT1 =
		clientReq4In?.selectWorkDays1Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays1Info?.nightBreakTimes;

	//(입력된) 둘째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek2 =
		clientReq4In?.selectWorkDays2Info?.skipWeek === undefined
			? ''
			: clientReq4In?.selectWorkDays2Info?.skipWeek;
	const startWT2 =
		clientReq4In?.selectWorkDays2Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDays2Info?.workingStartTime;
	const dayType2 =
		clientReq4In?.selectWorkDays2Info?.workingEndTimeNextDayOrNot === undefined
			? 0
			: clientReq4In?.selectWorkDays2Info?.workingEndTimeNextDayOrNot;
	const endWT2 =
		clientReq4In?.selectWorkDays2Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDays2Info?.workingEndTime;
	const dayRT2 =
		clientReq4In?.selectWorkDays2Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays2Info?.dayBreakTimes;
	const nigthRT2 =
		clientReq4In?.selectWorkDays2Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays2Info?.nightBreakTimes;

	//(입력된) 세째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek3 =
		clientReq4In?.selectWorkDays3Info?.skipWeek === undefined
			? ''
			: clientReq4In?.selectWorkDays3Info?.skipWeek;
	const startWT3 =
		clientReq4In?.selectWorkDays3Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDays3Info?.workingStartTime;
	const dayType3 =
		clientReq4In?.selectWorkDays3Info?.workingEndTimeNextDayOrNot === undefined
			? 0
			: clientReq4In?.selectWorkDays3Info?.workingEndTimeNextDayOrNot;
	const endWT3 =
		clientReq4In?.selectWorkDays3Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDays3Info?.workingEndTime;
	const dayRT3 =
		clientReq4In?.selectWorkDays3Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays3Info?.dayBreakTimes;
	const nigthRT3 =
		clientReq4In?.selectWorkDays3Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays3Info?.nightBreakTimes;

	//(입력된) 네째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek4 =
		clientReq4In?.selectWorkDays4Info?.skipWeek === undefined
			? ''
			: clientReq4In?.selectWorkDays4Info?.skipWeek;
	const startWT4 =
		clientReq4In?.selectWorkDays4Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDays4Info?.workingStartTime;
	const dayType4 =
		clientReq4In?.selectWorkDays4Info?.workingEndTimeNextDayOrNot === undefined
			? 0
			: clientReq4In?.selectWorkDays4Info?.workingEndTimeNextDayOrNot;
	const endWT4 =
		clientReq4In?.selectWorkDays4Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDays4Info?.workingEndTime;
	const dayRT4 =
		clientReq4In?.selectWorkDays4Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays4Info?.dayBreakTimes;
	const nigthRT4 =
		clientReq4In?.selectWorkDays4Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays4Info?.nightBreakTimes;

	//(입력된) 다섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek5 =
		clientReq4In?.selectWorkDays5Info?.skipWeek === undefined
			? ''
			: clientReq4In?.selectWorkDays5Info?.skipWeek;
	const startWT5 =
		clientReq4In?.selectWorkDays5Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDays5Info?.workingStartTime;
	const dayType5 =
		clientReq4In?.selectWorkDays5Info?.workingEndTimeNextDayOrNot === undefined
			? 0
			: clientReq4In?.selectWorkDays5Info?.workingEndTimeNextDayOrNot;
	const endWT5 =
		clientReq4In?.selectWorkDays5Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDays5Info?.workingEndTime;
	const dayRT5 =
		clientReq4In?.selectWorkDays5Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays5Info?.dayBreakTimes;
	const nigthRT5 =
		clientReq4In?.selectWorkDays5Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays5Info?.nightBreakTimes;

	//(입력된) 여섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek6 =
		clientReq4In?.selectWorkDays6Info?.skipWeek === undefined
			? ''
			: clientReq4In?.selectWorkDays6Info?.skipWeek;
	const startWT6 =
		clientReq4In?.selectWorkDays6Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDays6Info?.workingStartTime;
	const dayType6 =
		clientReq4In?.selectWorkDays6Info?.workingEndTimeNextDayOrNot === undefined
			? 0
			: clientReq4In?.selectWorkDays6Info?.workingEndTimeNextDayOrNot;
	const endWT6 =
		clientReq4In?.selectWorkDays6Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDays6Info?.workingEndTime;
	const dayRT6 =
		clientReq4In?.selectWorkDays6Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays6Info?.dayBreakTimes;
	const nigthRT6 =
		clientReq4In?.selectWorkDays6Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays6Info?.nightBreakTimes;

	//(입력된) 근무요일들
	const days1 = clientReq4In?.selectWorkDays1;
	const days2 = clientReq4In?.selectWorkDays2;
	const days3 = clientReq4In?.selectWorkDays3;
	const days4 = clientReq4In?.selectWorkDays4;
	const days5 = clientReq4In?.selectWorkDays5;
	const days6 = clientReq4In?.selectWorkDays6;

	//(입력된) 근무일 갯수
	const days1Length = days1?.length === undefined ? 0 : Number(days1?.length);
	const days2Length = days2?.length === undefined ? 0 : Number(days2?.length);
	const days3Length = days3?.length === undefined ? 0 : Number(days3?.length);
	const days4Length = days4?.length === undefined ? 0 : Number(days4?.length);
	const days5Length = days5?.length === undefined ? 0 : Number(days5?.length);
	const days6Length = days6?.length === undefined ? 0 : Number(days6?.length);

	//(계산된) 첫번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT1 = daysWT(endWT1, dayType1, startWT1, dayRT1, nigthRT1);
	//두번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT2 = daysWT(endWT2, dayType2, startWT2, dayRT2, nigthRT2);
	//세번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT3 = daysWT(endWT3, dayType3, startWT3, dayRT3, nigthRT3);
	//네번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT4 = daysWT(endWT4, dayType4, startWT4, dayRT4, nigthRT4);
	//다섯번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT5 = daysWT(endWT5, dayType5, startWT5, dayRT5, nigthRT5);
	//여섯번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT6 = daysWT(endWT6, dayType6, startWT6, dayRT6, nigthRT6);

	// 1주일 전체 1일 소정근로시간의 합계(휴게제외)
	const sumOneWeekLBDWT = sumOneWeekLBDWTF(
		numOfWorkers,
		daysWT1,
		daysWT2,
		daysWT3,
		daysWT4,
		daysWT5,
		daysWT6,
		days1Length,
		days2Length,
		days3Length,
		days4Length,
		days4Length,
		days4Length,
		skipWeek1,
		skipWeek2,
		skipWeek3,
		skipWeek4,
		skipWeek5,
		skipWeek6
	);

	const etcWeekLBTime = clientReq4In?.etc?.weekLBTime
		? clientReq4In?.etc?.weekLBTime
		: '';
	const etcPaidHolidayLBTime = clientReq4In?.etc?.paidHolidayLBTime
		? clientReq4In?.etc?.paidHolidayLBTime
		: '';

	// 1주 소정근로시간(기타직은 입력값)
	const weekLBTime = weekLBTimeF(
		etcWeekLBTime,
		isExceptTimeLawWorker,
		sumOneWeekLBDWTETL,
		sumOneWeekLBDWT,
		numOfWorkers,
		netAge15To18
	);

	//(입력된) 통상근로자 1주 소정근로일수
	const normalWorkerWeekWDs = normalWorkerWeekWorkDays
		? normalWorkerWeekWorkDays
		: 5;
	// 1일 소정근로시간(주휴수당근거수당)(기타직은 입력값)
	const paidHolidayLBTime = paidHolidayLBTimeF(
		etcPaidHolidayLBTime,
		weekLBTime,
		normalWorkerWeekWDs,
		numOfWorkers,
		netAge15To18
	);
	// 1달 통상임금산정기준시간수(속칭 1달 소정근로시간)
	const monthLBTime = isExceptTimeLawWorker
		? fixedMonthRealWorkingHoursETL
		: mLBT(weekLBTime, paidHolidayLBTime);

	//(입력된) 휴일 근무의 시업, 종업일, 종업시간, 휴게(분)
	const startWTHoli =
		clientReq4In?.expectYearlyHolidayWorksInfo?.workingStartTime;
	const dayTypeHoli =
		clientReq4In?.expectYearlyHolidayWorksInfo?.workingEndTimeNextDayOrNot;
	const endWTHoli = clientReq4In?.expectYearlyHolidayWorksInfo?.workingEndTime;
	const dayRTHoli =
		clientReq4In?.expectYearlyHolidayWorksInfo?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.expectYearlyHolidayWorksInfo?.dayBreakTimes;
	const nigthRTHoli =
		clientReq4In?.expectYearlyHolidayWorksInfo?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.expectYearlyHolidayWorksInfo?.nightBreakTimes;

	//입력된 1주간 포괄 연장, 야간
	const expectWeeklyOverWorks =
		clientReq4In?.expectWeeklyOverWorks === undefined
			? 0
			: +clientReq4In?.expectWeeklyOverWorks;
	const expectWeeklyNightWorks =
		clientReq4In?.expectWeeklyNightWorks === undefined
			? 0
			: +clientReq4In?.expectWeeklyNightWorks;

	//(입력된) 월급총액
	const cTotalMonthWageAmount = clientReq4In?.totalMonthWageAmount
		? numStringToNumber(clientReq4In?.totalMonthWageAmount)
		: 0;

	//(입력된) 통상직책수당
	const cOWTitle = clientReq4In?.ordinaryWage?.title
		? numStringToNumber(clientReq4In?.ordinaryWage?.title)
		: 0;
	//(입력된) 통상직무수당
	const cOWJob = clientReq4In?.ordinaryWage?.job
		? numStringToNumber(clientReq4In?.ordinaryWage?.job)
		: 0;
	//(입력된) 통상자격수당
	const cOWLicense = clientReq4In?.ordinaryWage?.license
		? numStringToNumber(clientReq4In?.ordinaryWage?.license)
		: 0;
	//(입력된) 통상월상여금
	const cOWMonthlyBonus = clientReq4In?.ordinaryWage?.monthlyBonus
		? numStringToNumber(clientReq4In?.ordinaryWage?.monthlyBonus)
		: 0;

	const cOWLongTerm = clientReq4In?.ordinaryWage?.longTerm
		? numStringToNumber(clientReq4In?.ordinaryWage?.longTerm)
		: 0;
	//(입력된) 통상기타수당계
	const cOWEtc = clientReq4In?.ordinaryWage?.etc
		? numStringToNumber(clientReq4In?.ordinaryWage?.etc)
		: 0;

	//(입력된) 통상상여금
	const cOWYearlyBonus = clientReq4In?.ordinaryWage?.yearlyBonus
		? numStringToNumber(clientReq4In?.ordinaryWage?.yearlyBonus)
		: 0;

	//어린이집
	const cOWChildManageStudy = clientReq4In?.ordinaryWage?.childManageStudy
		? numStringToNumber(clientReq4In?.ordinaryWage?.childManageStudy)
		: 0;
	const cOWChildStudy = clientReq4In?.ordinaryWage?.childStudy
		? numStringToNumber(clientReq4In?.ordinaryWage?.childStudy)
		: 0;
	const cOWChildCare = clientReq4In?.ordinaryWage?.childCare
		? numStringToNumber(clientReq4In?.ordinaryWage?.childCare)
		: 0;
	const cOWChildActivity = clientReq4In?.ordinaryWage?.childActivity
		? numStringToNumber(clientReq4In?.ordinaryWage?.childActivity)
		: 0;

	const cOWChildManageStudyComment = cOWChildManageStudy
		? '관리 및 연구 수당'
		: '';
	const cOWChildStudyComment = cOWChildStudy
		? '직원에게 지급하는 연구수당'
		: '';
	const cOWChildCareComment = cOWChildCare
		? '직원에게 지급하는 취약보육수당'
		: '';
	const cOWChildActivityComment = cOWChildActivity
		? '직원에게 지급하는 활동보조수당'
		: '';

	// 11월24일-공통
	//(입력된) 출근일당 지급하는 일당 통상임금1
	const cOWDay1 = clientReq4In?.ordinaryWage?.day1
		? numStringToNumber(clientReq4In?.ordinaryWage.day1)
		: 0;
	//(입력된) 출근일당 지급하는 일당 통상임금2
	const cOWDay2 = clientReq4In?.ordinaryWage?.day2
		? numStringToNumber(clientReq4In?.ordinaryWage.day2)
		: 0;
	//(입력된) 출근일당 지급하는 일당 통상임금3
	const cOWDay3 = clientReq4In?.ordinaryWage?.day3
		? numStringToNumber(clientReq4In?.ordinaryWage.day3)
		: 0;
	//--------------

	//(입력된) 통상비과세식대
	const cTFMealWage = clientReq4In?.taxFreeWages?.mealWage
		? numStringToNumber(clientReq4In?.taxFreeWages?.mealWage)
		: 0;
	//(입력된) 통상비과세차량유지비
	const cTFCarWage = clientReq4In?.taxFreeWages?.carWage
		? numStringToNumber(clientReq4In?.taxFreeWages?.carWage)
		: 0;
	//(입력된) 통상비과세육아수당
	const cTFChildWage = clientReq4In?.taxFreeWages?.childWage
		? numStringToNumber(clientReq4In?.taxFreeWages?.childWage)
		: 0;
	//(입력된) 통상비과세연구수당
	const cTFStudyWage = clientReq4In?.taxFreeWages?.studyWage
		? numStringToNumber(clientReq4In?.taxFreeWages?.studyWage)
		: 0;
	//(입력된) 통상비과세기타 계
	const cTFEtc = clientReq4In?.taxFreeWages?.etc
		? numStringToNumber(clientReq4In?.taxFreeWages?.etc)
		: 0;

	//(입력된) 평균임금수당 금액
	const cAWage1 = clientReq4In?.averageWage?.irregularAWage1
		? numStringToNumber(clientReq4In?.averageWage.irregularAWage1)
		: 0;

	const cAWage2 = clientReq4In?.averageWage?.irregularAWage2
		? numStringToNumber(clientReq4In?.averageWage.irregularAWage2)
		: 0;

	const cAWage3 = clientReq4In?.averageWage?.irregularAWage3
		? numStringToNumber(clientReq4In?.averageWage.irregularAWage3)
		: 0;

	const cAWage4 = clientReq4In?.averageWage?.irregularAWage4
		? numStringToNumber(clientReq4In?.averageWage.irregularAWage4)
		: 0;

	//(입력된) 평균임금 비과세 금액
	const cAWageTF1 = clientReq4In?.averageWageTF?.irregularAWage1
		? numStringToNumber(clientReq4In?.averageWageTF.irregularAWage1)
		: 0;

	const cAWageTF2 = clientReq4In?.averageWageTF?.irregularAWage2
		? numStringToNumber(clientReq4In?.averageWageTF.irregularAWage2)
		: 0;

	const cAWageTF3 = clientReq4In?.averageWageTF?.irregularAWage3
		? numStringToNumber(clientReq4In?.averageWageTF.irregularAWage3)
		: 0;

	const cAWageTF4 = clientReq4In?.averageWageTF?.irregularAWage4
		? numStringToNumber(clientReq4In?.averageWageTF.irregularAWage4)
		: 0;

	// const lastYearNetWage =clientReq4In?.lastYearNetWage? commaStringToNumber(clientReq4In?.lastYearNetWage) : 0;

	//////////////////////////////////////////////////////휴일근로//////////////////////////
	//(입력된) 년간 휴일근무 일수
	const numOfYearlyHoliWD =
		clientReq4In?.expectYearlyHolidayWorks === undefined
			? 0
			: clientReq4In?.expectYearlyHolidayWorks;
	// 1달 휴일근로 일수(/12개월)
	const monthHoliWD = numOfYearlyHoliWD ? numOfYearlyHoliWD / 12 : 0;
	//휴일근로 1일 실근로시간(휴게제외)
	const daysWTHoli = daysWT(
		endWTHoli,
		dayTypeHoli,
		startWTHoli,
		dayRTHoli,
		nigthRTHoli
	);
	//휴일근로 1일 실 야간 근로시간(휴게제외)
	const daysNWTHoli = daysNWT(
		daysWTHoli,
		startWTHoli,
		endWTHoli,
		dayTypeHoli,
		nigthRTHoli
	);
	//1일 8시간 이내 기본 휴일근로시간
	const daysHoliBasicTime = daysWTHoli >= 8 ? 8 : daysWTHoli;
	//1일 휴일연장시간
	const daysNOWTHoli = daysWTHoli - daysHoliBasicTime;
	// 월간 휴일 기본시간(기타직은 입력값)
	const monthHoliBasicTime = clientReq4In?.etc?.monthHoliBasicTime
		? clientReq4In?.etc?.monthHoliBasicTime
		: daysHoliBasicTime * monthHoliWD;
	// 월간 휴일 연장시간(기타직은 입력값)
	const monthOWTHoli = clientReq4In?.etc?.monthOWTHoli
		? clientReq4In?.etc?.monthOWTHoli
		: daysNOWTHoli * monthHoliWD;
	// 월간 휴일 야간시간(기타직은 입력값)
	const monthNWTHoli = clientReq4In?.etc?.monthNWTHoli
		? clientReq4In?.etc?.monthNWTHoli
		: daysNWTHoli * monthHoliWD;
	//주간 휴일근로시간(연장시간인지는 좀 검토 필요)
	const weekWTHoli = (numOfYearlyHoliWD * daysWTHoli) / (365 / 7);

	// 근무일수 합계
	const oneWeekworkdaysCheck =
		days1Length +
		days2Length +
		days3Length +
		days4Length +
		days5Length +
		days6Length;
	const oneWeekworkdaysCheckETLWorker =
		daysETL1Length +
		daysETL2Length +
		daysETL3Length +
		daysETL4Length +
		daysETL5Length +
		daysETL6Length;

	/////////////////////////////////////////////////연장근로/////////////////ㅡ

	//첫번째 근로시간이 같은 날들의 1일 소정근로시간
	const days1LBTime = days1LBTimeF(daysWT1, netAge15To18);

	//첫번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT1 = workingTimeDayOverAmount(daysWT1, days1LBTime);
	//두번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT2 = daysOWT(
		days2,
		daysWT2,
		days2LBTime(days1LBTime, days1Length, daysWT2, netAge15To18)
	);
	//세번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT3 = daysOWT(
		days3,
		daysWT3,
		days3LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			netAge15To18
		)
	);
	//네번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT4 = daysOWT(
		days4,
		daysWT4,
		days4LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			days3Length,
			daysWT4,
			netAge15To18
		)
	);
	//다섯번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT5 = daysOWT(
		days5,
		daysWT5,
		days5LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			days3Length,
			daysWT4,
			days4Length,
			daysWT5,
			netAge15To18
		)
	);
	//여섯번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT6 = daysOWT(
		days6,
		daysWT6,
		days6LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			days3Length,
			daysWT4,
			days4Length,
			daysWT5,
			days5Length,
			daysWT6,
			netAge15To18
		)
	);

	//1주 총 1일 8시간 기준 연장근로시간 합계
	const sumDaysOWT = sumDaysOWTF(
		daysOWT1,
		daysOWT2,
		daysOWT3,
		daysOWT4,
		daysOWT5,
		daysOWT6,
		days1Length,
		days2Length,
		days3Length,
		days4Length,
		days5Length,
		days6Length,
		skipWeek1,
		skipWeek2,
		skipWeek3,
		skipWeek4,
		skipWeek5,
		skipWeek6
	);

	// 1주일 전체 실근로시간(휴게제외)-52시간제 판단 시간
	const sumOneWeekWT = sumOneWeekWTF(
		daysWT1,
		daysWT2,
		daysWT3,
		daysWT4,
		daysWT5,
		daysWT6,
		days1Length,
		days2Length,
		days3Length,
		days4Length,
		days5Length,
		days6Length,
		skipWeek1,
		skipWeek2,
		skipWeek3,
		skipWeek4,
		skipWeek5,
		skipWeek6,
		expectWeeklyOverWorks,
		weekWTHoli
	);

	// 1주일 전체 시간외근로(휴일제외)
	const sumOneWeekWTForOverTime = sumOneWeekWTForOverTimeF(
		daysWT1,
		daysWT2,
		daysWT3,
		daysWT4,
		daysWT5,
		daysWT6,
		days1Length,
		days2Length,
		days3Length,
		days4Length,
		days5Length,
		days6Length,
		skipWeek1,
		skipWeek2,
		skipWeek3,
		skipWeek4,
		skipWeek5,
		skipWeek6,
		expectWeeklyOverWorks
	);

	//1주 40시간 기준 연장근로시간
	const weekBaseOWT = sumOneWeekWTForOverTime - weekLBTime;

	// 최종 1주 연장근로시간
	const finalWeekOWT = Math.max(sumDaysOWT, weekBaseOWT);

	//////////////////////////////////////////////////야간근로시간////////////////////ㅡ
	//첫번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT1 = daysNWT(days1, startWT1, endWT1, dayType1, nigthRT1);
	//두번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT2 = daysNWT(days2, startWT2, endWT2, dayType2, nigthRT2);
	//세번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT3 = daysNWT(days3, startWT3, endWT3, dayType3, nigthRT3);
	//네번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT4 = daysNWT(days4, startWT4, endWT4, dayType4, nigthRT4);
	//다섯번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT5 = daysNWT(days5, startWT5, endWT5, dayType5, nigthRT5);
	//여섯번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT6 = daysNWT(days6, startWT6, endWT6, dayType6, nigthRT6);

	//1주 총 야간근로시간
	const sumOneWeekNWT = sumOneWeekNWTF(
		daysNWT1,
		daysNWT2,
		daysNWT3,
		daysNWT4,
		daysNWT5,
		daysNWT6,
		days1Length,
		days2Length,
		days3Length,
		days4Length,
		days5Length,
		days6Length,
		skipWeek1,
		skipWeek2,
		skipWeek3,
		skipWeek4,
		skipWeek5,
		skipWeek6,
		expectWeeklyNightWorks
	);

	//////////////// 월급구성항목 계산 시간(환산작업)//////////////ㅡ

	// 월간  연장근로시간(기타직은 입력값)
	const monthOWT = clientReq4In?.etc?.monthOWT
		? clientReq4In?.etc?.monthOWT
		: weekToMonthCal(finalWeekOWT);
	// 월간 환산연장근로시간
	const converseMonthOWT = cMOWTime(monthOWT, numOfWorkers);

	// 월간 야간시간(기타직은 입력값)
	const monthNWT = clientReq4In?.etc?.monthNWT
		? clientReq4In?.etc?.monthNWT
		: isExceptTimeLawWorker
		? weekToMonthCal(sumOneWeekNWTETL)
		: weekToMonthCal(sumOneWeekNWT);
	// 월간 환산야간시간
	const converseMonthNWT = cMNWTime(monthNWT, numOfWorkers);

	// 월간 환산 휴일 기본시간
	const converseMonthHoliBasicTime = cMHBTime(monthHoliBasicTime, numOfWorkers);
	// 월간 환산 휴일 연장시간
	const conversemonthOWTHoli = cMHOTime(monthOWTHoli, numOfWorkers);
	// 월간 환산 휴일 야간시간
	const converseMonthNWTHoli = cMH0Time(monthNWTHoli, numOfWorkers);

	// 5인이상 && 농민 총 유급시간 , 5인미만은 가산금이 없어서 총 근무시간(기본급 시간)
	const mTPCTime =
		numOfWorkers >= 5
			? isExceptTimeLawWorker
				? fixedMonthRealNightWorkingHoursETL * 0.5 +
				  fixedMonthRealWorkingHoursETL +
				  annualLeavesIncludeMonthWageTime
				: monthTotalPaidConversedTime(
						converseMonthNWT,
						converseMonthOWT,
						converseMonthHoliBasicTime,
						conversemonthOWTHoli,
						converseMonthNWTHoli,
						monthLBTime
				  )
			: monthLBTime +
			  monthHoliBasicTime +
			  monthOWTHoli +
			  expectWeeklyOverWorks * 4.345;

	//////////////////월급 구성항목 및 월통상임금/시간급통상임금

	//(계산된) 통상임금 일반 수당 합계
	const sumOfOWages =
		cOWTitle +
		cOWJob +
		cOWLicense +
		cOWMonthlyBonus +
		cOWLongTerm +
		cOWEtc +
		cOWChildManageStudy +
		cOWChildStudy +
		cOWChildCare +
		cOWChildActivity;

	// 비과세 수당 합계(통상임금 + 평균임금)
	const sumOfTFWages =
		cTFMealWage +
		cTFCarWage +
		cTFChildWage +
		cTFStudyWage +
		cTFEtc +
		cAWageTF1 +
		cAWageTF2 +
		cAWageTF3 +
		cAWageTF4;

	// 비과세 수당 합계(최저임금 산입비율 판단용으로 평균임금 비과세 수당은 이미 뺐으므로 제외한다.)
	const sumOfTFWagesForBLLowWage =
		cTFMealWage + cTFCarWage + cTFChildWage + cTFStudyWage + cTFEtc;

	// 평균임금 수당 합계
	const sumOfAWages =
		cAWage1 +
		cAWage2 +
		cAWage3 +
		cAWage4 +
		cAWageTF1 +
		cAWageTF2 +
		cAWageTF3 +
		cAWageTF4;

	// 평균임금 비과세 중복 조정용 평균비과세 수당 합계(기본급에 영향을 줌)
	const sumOfAWagesTFMinusAmount =
		cAWageTF1 + cAWageTF2 + cAWageTF3 + cAWageTF4;

	// 월 통상임금 대상 임금(유급시간 계산임금)(입력된 전체월급 - 평균임금수당) 즉, 법정가산임금은 포함된 금액
	const tWMinusTAW = cTotalMonthWageAmount - sumOfAWages;

	//11월24일-공통
	//(계산된) 일당 통상임금 합계
	const sumOfCOWDay = cOWDay1 + cOWDay2 + cOWDay3;
	//일당 통상임금(최저임금도 같음) / (1일소정근로시간 + 1일 주휴시간)
	const cOWDayHourWage =
		sumOfCOWDay / (paidHolidayLBTime + paidHolidayLBTime / normalWorkerWeekWDs);

	// 통상 시급(금액)
	const hourOrdinaryWage = hourOrdiWage(tWMinusTAW, mTPCTime, cOWYearlyBonus);
	const hourOrdinaryWageMonth = hourOrdiWageMonth(tWMinusTAW, mTPCTime);

	//------------

	//  비과세 한도액
	const year = parseInt(format(new Date(), 'yyyy-MM-dd').split('-')[0]);
	const month = parseInt(format(new Date(), 'yyyy-MM-dd').split('-')[1]);

	const cTFMealWageMax =
		cTFMealWage >= taxFreeMealWageLimitF(year)
			? taxFreeMealWageLimitF(year)
			: cTFMealWage;
	const cTFCarWageMax =
		cTFCarWage >= taxFreeCarWageLimitF(year)
			? taxFreeCarWageLimitF(year)
			: cTFCarWage;
	const cTFChildWageMax =
		cTFChildWage >= taxFreeChildWageLimitF(year)
			? taxFreeChildWageLimitF(year)
			: cTFChildWage;
	const cTFStudyWageMax =
		cTFStudyWage >= taxFreeStudyWageLimitF(year)
			? taxFreeStudyWageLimitF(year)
			: cTFStudyWage;

	// 월 과세임금(비과세 한도만 뺌.)
	const tempTaxWageSum =
		cTotalMonthWageAmount -
		(cTFMealWageMax +
			cTFCarWageMax +
			cTFChildWageMax +
			cTFStudyWageMax +
			cTFEtc +
			cAWageTF1 +
			cAWageTF2 +
			cAWageTF3 +
			cAWageTF4);
	const taxWageSum = tempTaxWageSum <= 0 ? 0 : tempTaxWageSum;

	// 월 연장근로수당(금액)
	const mOWage = mOWWage(hourOrdinaryWage, converseMonthOWT, numOfWorkers);
	//월 야간근로수당(금액)
	const mNWage = mNWWage(hourOrdinaryWage, converseMonthNWT, numOfWorkers);
	//월 휴일기본근로수당(금액)
	const mHBWage = mHBWWage(
		hourOrdinaryWage,
		converseMonthHoliBasicTime,
		numOfWorkers
	);
	//월 휴일연장근로수당(금액)
	const mHOWage = mHOWWage(
		hourOrdinaryWage,
		conversemonthOWTHoli,
		numOfWorkers
	);
	//월 휴일야간근로수당(금액)
	const mHNWage = mHNWWage(
		hourOrdinaryWage,
		converseMonthNWTHoli,
		numOfWorkers
	);

	const annualLeavesIncludeMonthWage = roundUp(
		annualLeavesIncludeMonthWageTime * hourOrdinaryWage
	);

	//월 법정수당 합계(금액)
	const sumOfMonthONHWages = sMONHWage(
		mOWage,
		mNWage,
		mHBWage,
		mHOWage,
		mHNWage,
		annualLeavesIncludeMonthWage
	);

	// 월 통상임금((평균임금제외) -법정가산수당))
	const monthOrdinaryWage = mOrdiWage(
		cTotalMonthWageAmount,
		sumOfAWages,
		sumOfMonthONHWages
	);
	// 월 기본급(5인이상이면 월 통상임금 - 비과세(통상) - 통상수당  )
	const monthBasicWage = mBW(
		numOfWorkers,
		cTotalMonthWageAmount,
		sumOfAWages,
		sumOfMonthONHWages,
		sumOfTFWages,
		sumOfOWages,
		sumOfAWagesTFMinusAmount
	);

	////////////////////////////////////////////////////////
	// 최저임금 판단부분

	// 법정연도별 최저임금
	const lBLowestHourWage =
		year === 2025
			? lowestTimeWageOf2025
			: year === 2024
			? lowestTimeWageOf2024
			: year === 2023
			? 9620
			: year === 2022
			? 9160
			: year === 2021
			? 8720
			: year === 2020
			? 8590
			: year === 2019
			? 8350
			: 0;
	const lBLowestMonthWage = lBLowestHourWage * 209;
	// 법정복리후생비 공제 비율 및 금액
	const lBWelfareFeeDeductRate =
		year === 2023
			? 0.01
			: year === 2022
			? 0.02
			: year === 2021
			? 0.03
			: year === 2020
			? 0.05
			: year === 2019
			? 0.07
			: 0;
	const lBWelfareFeeDeductWage = lBLowestMonthWage * lBWelfareFeeDeductRate;
	// 법정월정기상여금 공제 비율 및 금액
	const lBMonthBonusDeductRate =
		year === 2023
			? 0.05
			: year === 2022
			? 0.1
			: year === 2021
			? 0.15
			: year === 2020
			? 0.2
			: year === 2019
			? 0.25
			: 0;
	const lBMonthBonusDeductWage = lBLowestMonthWage * lBMonthBonusDeductRate;

	// 계산된 최저임금 복리후생비 공제액
	const calWelfareWage =
		sumOfTFWagesForBLLowWage > lBWelfareFeeDeductWage
			? sumOfTFWagesForBLLowWage - lBWelfareFeeDeductWage
			: 0;
	// 계산된 최저임금 월상여금 공제액
	const calMonthBonus =
		cOWMonthlyBonus > lBMonthBonusDeductWage
			? cOWMonthlyBonus - lBMonthBonusDeductWage
			: 0;

	// 계산된 최저임금 대상금액(전체월급 - 평균임금수당 - 공제액)
	const calTotalMonthLowestWage =
		numOfWorkers < 5
			? cTotalMonthWageAmount -
			  sumOfTFWagesForBLLowWage -
			  cOWMonthlyBonus -
			  sumOfAWages +
			  calWelfareWage +
			  calMonthBonus
			: monthOrdinaryWage -
			  sumOfTFWagesForBLLowWage -
			  cOWMonthlyBonus +
			  calWelfareWage +
			  calMonthBonus;

	// 계산된 회사 최저 시급
	const calHourLowestWage =
		numOfWorkers < 5
			? calTotalMonthLowestWage / mTPCTime +
			  (cOWDayHourWage ? cOWDayHourWage : 0)
			: calTotalMonthLowestWage / monthLBTime +
			  (cOWDayHourWage ? cOWDayHourWage : 0); // ------------------

	// 최저임금법 위반 판단
	const judgeLowestWageResult = clientReq4In.daylyPay
		? null
		: calHourLowestWage >= lBLowestHourWage
		? true
		: false;

	// // 해당년도 해당 업체 근무시간에 따른 최저임금(월급)
	// const calLBMonthLowestWage = lBLowestHourWage * mTPCTime + sumOfAWages;
	// const insufficientWageResult = clientReq4In.daylyPay ? null : (calLBMonthLowestWage-cTotalMonthWageAmount > 0 ? calLBMonthLowestWage - cTotalMonthWageAmount : 0);

	// 해당년도 해당 업체 근무시간에 따른 최저임금(월급)
	const calLawBaseWageMinusCompanyBaseWage =
		judgeLowestWageResult === false
			? ((lBLowestHourWage * monthLBTime - calHourLowestWage * monthLBTime) *
					mTPCTime) /
			  monthLBTime
			: 0;
	// const calLBMonthLowestWage = lBLowestHourWage * mTPCTime + sumOfAWages;
	const insufficientWageResult = clientReq4In.daylyPay
		? null
		: calLawBaseWageMinusCompanyBaseWage > 0
		? roundUp(calLawBaseWageMinusCompanyBaseWage)
		: 0;

	///////////////////////////////////////////////////////////////////////////////

	// 주52시간제 준수여부
	const judge52 = clientReq4In.daylyPay
		? null
		: sumOneWeekWT <= 52 || numOfWorkers < 5
		? true
		: false;

	//근로소득세, 지방소득세, 연금, 건보, 요양, 고용

	const taxPayType = clientReq4In?.taxPayType;
	const calTaxRate =
		taxPayType === '120%' ? 1.2 : taxPayType === '80%' ? 0.8 : 1;
	const pensionPayType = clientReq4In?.pensionPayType;
	const healthCarePayType = clientReq4In?.healthCarePayType;
	const oldCarePayType = clientReq4In?.oldCarePayType;
	const noJobPayType = clientReq4In?.noJobPayType;
	const teacherPensionPayType = clientReq4In?.teacherPensionPayType;

	const numOfTaxFamily = clientReq4In.numOfTaxFamily
		? +clientReq4In.numOfTaxFamily
		: 0;
	const numOfTaxFamily7To20 = clientReq4In.numOfTaxFamily7
		? +clientReq4In.numOfTaxFamily7To20
		: 0;

	const pensionBaseWage = clientReq4In?.pensionBaseWage
		? numStringToNumber(clientReq4In?.pensionBaseWage)
		: null;
	const healthCareBaseWage = clientReq4In?.healthCareBaseWage
		? numStringToNumber(clientReq4In?.healthCareBaseWage)
		: null;
	const oldCareBaseWage = clientReq4In?.oldCareBaseWage
		? numStringToNumber(clientReq4In?.oldCareBaseWage)
		: null;
	const noJobBaseWage = clientReq4In?.noJobBaseWage
		? numStringToNumber(clientReq4In?.noJobBaseWage)
		: null;

	const teacherPensionBaseWage = clientReq4In?.teacherPensionBaseWage
		? numStringToNumber(clientReq4In?.teacherPensionBaseWage)
		: null;

	const visaType = clientReq4In?.foreigner?.visaType
		? clientReq4In?.foreigner?.visaType
		: '';
	const selfNoJobPay = clientReq4In?.foreigner?.selfNoJobPay
		? clientReq4In?.foreigner?.selfNoJobPay
		: '';

	const endless65EmploymentInsurance =
		clientReq4In?.endless65EmploymentInsurance
			? clientReq4In?.endless65EmploymentInsurance
			: '';

	const pensionLimitWage2023 = 2200000;
	const isInterInWorker = false;
	const pensionTaxWageSum = pensionTaxWageSumF(
		pensionLimitWage2023,
		pensionPayType,
		taxWageSum,
		pensionBaseWage,
		netAge,
		weekLBTime,
		isInterInWorker,
		visaType,
		workerEnteredDate
	);

	const teacherPensionTaxWageSum = teacherPensionTaxWageSumF(
		teacherPensionPayType,
		taxWageSum,
		teacherPensionBaseWage,
		privateSchool
	);

	const healthCareTaxWageSum = healthCareTaxWageSumF(
		healthCarePayType,
		taxWageSum,
		healthCareBaseWage,
		weekLBTime
	);

	const oldCareTaxWageSum = oldCareTaxWageSumF(
		oldCarePayType,
		taxWageSum,
		oldCareBaseWage,
		weekLBTime
	);

	const noJobTaxWageSum = noJobTaxWageSumF(
		noJobPayType,
		taxWageSum,
		noJobBaseWage,
		netAgeEntered,
		weekLBTime,
		over3MonthsWorker,
		visaType,
		selfNoJobPay,
		isInterInWorker,
		endless65EmploymentInsurance
	);

	const calTax = calTaxF(
		taxWageSum,
		numOfTaxFamily,
		numOfTaxFamily7To20,
		calTaxRate,
		''
	);
	const calRegionTax = calRegionTaxF(calTax);
	const calPension =
		pensionTaxWageSum === 0 ? 0 : pension(pensionTaxWageSum, year, month);

	const calTeacherPension =
		teacherPensionTaxWageSum === 0
			? 0
			: teacherPension(teacherPensionTaxWageSum, year, privateSchool);

	const calHealthCare =
		healthCareTaxWageSum === 0
			? 0
			: healthCare(healthCareTaxWageSum, year, healthCarePayType);
	const calOldCare =
		oldCareTaxWageSum === 0
			? 0
			: oldCare(oldCareTaxWageSum, year, healthCarePayType);
	const calNoJobInsurance =
		noJobTaxWageSum === 0 ? 0 : noJobInsurance(noJobTaxWageSum, year, month);

	const healthCareTextOption =
		healthCarePayType === '건강보험료 공제안함'
			? null
			: healthCareText(
					healthCareBaseWage,
					healthCarePayType,
					year,
					calHealthCare
			  );
	const oldCareTextOption =
		oldCarePayType === '요양보험료 공제안함'
			? null
			: oldCareText(oldCareBaseWage, oldCarePayType, year, calHealthCare);
	const pensionTextOption =
		pensionPayType === '국민연금료 공제안함'
			? null
			: pensionText(pensionBaseWage, pensionPayType, calPension, visaType);

	const teacherPensionTextOption =
		teacherPensionPayType === '사학연금료 공제안함'
			? null
			: pensionText(
					teacherPensionBaseWage,
					teacherPensionPayType,
					calTeacherPension
			  );

	const noJobTextOption =
		noJobPayType === '고용보험료 공제안함'
			? null
			: noJobText(
					noJobBaseWage,
					noJobPayType,
					year,
					calNoJobInsurance,
					visaType,
					selfNoJobPay
			  );

	//공제계
	const dedectTaxEtc =
		calTax +
		calRegionTax +
		calPension +
		calHealthCare +
		calOldCare +
		calNoJobInsurance +
		calTeacherPension;

	//실수령액(실제)
	const deductWage = cTotalMonthWageAmount - dedectTaxEtc;

	///////////////////////////////////일용직//////////////////////////////////////ㅡ
	// 일용직 일당
	const daylyPay = clientReq4In?.daylyPay?.wage
		? numStringToNumber(clientReq4In?.daylyPay?.wage)
		: 0;
	const daylyTimePay = clientReq4In?.daylyPay?.timeWage
		? numStringToNumber(clientReq4In?.daylyPay?.timeWage)
		: 0;
	const daylyPaidOffDays = clientReq4In?.daylyPay?.paidOffDays || '';

	const isTimeWorker = daylyTimePay ? true : false;
	const isDayWorker =
		clientReq4In?.daylyPay?.wage || clientReq4In?.daylyPay?.timeWage
			? true
			: false;
	const isEtcWorker = clientReq4In?.etc?.weekLBTime ? true : false;
	const isBizWorker = isBizIncomWorker;
	const isRegularWorekr =
		!isDayWorker &&
		!isEtcWorker &&
		!isBizWorker &&
		!isExceptTimeLawWorker &&
		!isTimeWorker
			? true
			: false;

	// (입력된) 일용직 1일 근로시간 휴게 등
	const workStartTime = clientReq4In?.daylyPay?.workingStartTime
		? clientReq4In?.daylyPay?.workingStartTime
		: null;
	const workEndTime = clientReq4In?.daylyPay?.workingEndTime
		? clientReq4In?.daylyPay?.workingEndTime
		: null;
	const dayType = clientReq4In?.daylyPay?.workingEndTimeNextDayOrNot
		? clientReq4In?.daylyPay?.workingEndTimeNextDayOrNot
		: null;
	const dayRest =
		clientReq4In?.daylyPay?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.daylyPay?.dayBreakTimes;
	const nightRest =
		clientReq4In?.daylyPay?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.daylyPay?.nightBreakTimes;

	// 1일 실근로시간
	const workingTimeDayTotal = daylyPay
		? workingTimeDayTotalAmount(
				workEndTime,
				dayType,
				workStartTime,
				dayRest,
				nightRest
		  )
		: null;
	const workingTimeDayNight = daylyPay
		? numOfWorkers < 5
			? 0
			: workingTimeDayNightAmount(
					workStartTime,
					workEndTime,
					dayType,
					nightRest
			  )
		: null;
	const workingTimeDayOver = daylyPay
		? numOfWorkers < 5
			? 0
			: workingTimeDayTotal > 8
			? workingTimeDayTotal - 8
			: 0
		: null;
	const workingTimeDayBasic = daylyPay
		? numOfWorkers < 5
			? 0
			: workingTimeDayTotal >= 8
			? 8
			: workingTimeDayTotal
		: null;
	const dayWorkerPaidHolidayLBTime =
		clientReq4In?.week14OverBLTime === '주휴수당 포함'
			? workingTimeDayTotal >= 8
				? 8 * 0.2
				: workingTimeDayTotal * 0.2
			: 0;

	const dayTotalPaidConversedTime = daylyPay
		? numOfWorkers < 5
			? workingTimeDayTotal + dayWorkerPaidHolidayLBTime
			: workingTimeDayBasic +
			  workingTimeDayNight * 0.5 +
			  workingTimeDayOver * 1.5 +
			  dayWorkerPaidHolidayLBTime
		: null;
	const realTimeWage = daylyPay ? daylyPay / dayTotalPaidConversedTime : null;
	const insufficientTimeWage = daylyPay
		? lBLowestHourWage - realTimeWage > 0
			? lBLowestHourWage - realTimeWage
			: 0
		: null;
	const insufficientDayWage = daylyPay
		? insufficientTimeWage === 0
			? 0
			: insufficientTimeWage * dayTotalPaidConversedTime
		: null;

	/////////////// 지원금

	const exCompanyOutDate = clientReq4In?.exCompanyOutDate;
	const worknetRegisterDate = clientReq4In?.worknetRegisterDate;
	const newOrNot = clientReq4In?.newOrNot;

	//육아휴직부여 지원금
	const childLeaveOfAbsenceStartDateM = moment(
		clientReq4In.childLeaveOfAbsenceStartDate
	);
	const childLeaveOfAbsenceEndDateM = moment(
		clientReq4In.childLeaveOfAbsenceEndDate
	).add(1, 'days');

	const childSupportFund =
		childLeaveOfAbsenceEndDateM.diff(childLeaveOfAbsenceStartDateM, 'months') >=
		1
			? true
			: false;
	const childSupportFundPerson =
		childSupportFund && judgeLowestWageResult
			? '육아휴직(단축) 등 사업주 지원금 대상'
			: false;

	//청년채용특별지원금
	const workerYouth = clientReq4In.workerYouthType;
	const youthSupportFundPerson = youthSupportFundPersonF(
		judgeLowestWageResult,
		enterDate,
		contractEndDate,
		netAge,
		weekLBTime,
		workerYouth
	);

	//코로나 특별 지원금---없음.
	const covid19SupportFundPerson = covid19SupportFundPersonF(
		judgeLowestWageResult,
		enterDate,
		contractEndDate,
		newOrNot,
		exCompanyOutDate,
		worknetRegisterDate,
		weekLBTime,
		cTotalMonthWageAmount
	);

	//대체인력 지원금
	const altWorker = clientReq4In?.altWorker;
	const originalWorkerLeaveStartDate =
		clientReq4In?.originalWorkerLeaveStartDate;
	const originalWorkerLeaveEndDate = clientReq4In?.originalWorkerLeaveEndDate;
	const replacedWorkerFundPerson = replacedWorkerFundPersonF(
		judgeLowestWageResult,
		enterDate,
		contractEndDate,
		altWorker,
		originalWorkerLeaveStartDate,
		originalWorkerLeaveEndDate
	);

	const monthTotalT =
		weekLBTime * 4.345 + monthOWT + monthHoliBasicTime + monthOWTHoli;
	const monthPaidHolidayLBTime = paidHolidayLBTime * 4.345;

	///////////////////////////////////////////////////////////////////////////////

	// 휴게시간 준수여부
	const restViolation1 = restLawViolationJudge(
		endWT1,
		dayType1,
		startWT1,
		dayRT1,
		nigthRT1
	);
	const restViolation2 = startWT2
		? restLawViolationJudge(endWT2, dayType2, startWT2, dayRT2, nigthRT2)
		: true;
	const restViolation3 = startWT3
		? restLawViolationJudge(endWT3, dayType3, startWT3, dayRT3, nigthRT3)
		: true;
	const restViolation4 = startWT4
		? restLawViolationJudge(endWT4, dayType4, startWT4, dayRT4, nigthRT4)
		: true;
	const restViolation5 = startWT5
		? restLawViolationJudge(endWT5, dayType5, startWT5, dayRT5, nigthRT5)
		: true;
	const restViolation6 = startWT6
		? restLawViolationJudge(endWT6, dayType6, startWT6, dayRT6, nigthRT6)
		: true;

	const restTotalViolation =
		restViolation1 &&
		restViolation2 &&
		restViolation3 &&
		restViolation4 &&
		restViolation5 &&
		restViolation6;

	const restViolationDay = workStartTime
		? restLawViolationJudge(
				workEndTime,
				dayType,
				workStartTime,
				dayRest,
				nightRest
		  )
		: true;
	// const totalWagesLength = clientReq4In?.totalWages ? clientReq4In?.totalWages.length : 0

	const workerRegistryType = clientReq4In?.workerRegistryType;

	const workersCollectionRef = collection(db, 'workers');
	const docRef = await addDoc(workersCollectionRef, {
		...encryptoClientReq4In,
		absentDays: [],
		usedAnnualLeaves: [],
		maternityLeaves: [],
		childLeaveOfAbsences: [],
		childShortTimes: [],
		leaveOfAbsences: [],
		totalWages: [],
		workerEnteredDateArray: [],
		contractEndDateArray: [],
		afterRetireAgainContractEndDateArray: [],
		againContractStartDateArray: [],
		afterRetireAgainContractStartDateArray: [],

		date: serverTimestamp(),
		addDate: serverTimestamp(),

		id: null,
		confirmedTimeOfLaborContract: '',
		confirmedTimeOfWageContract: '',
		upDatedDate: '',
		upDatedDateArray: '',

		againContractStartDate: '',

		retiredDate: '',
		eiOutType: '',
		eiOutDetailType: '',
		eiOutReason: '',
		retiredPaperType: '',
		firedPaperType: '',
		judgeIlligalFire: '',
		noticeFire: '',
		noticeFireException: '',

		noJobPayYesType: '',
		noJobPayNoType: '',
		pensionOutType: '',
		healthOutType: '',
		healthOut: {
			taxWageThisYear: '',
			workedMonthThisYear: '',
			taxWageLastYear: '',
			workedMonthLastYear: '',
		},

		insureInReport: '',
		insureInReportTime: '',
		insureInResponse: '',
		insureInResponseDate: '',
		isInsureInRequest: '',
		isInsureInRequestDate: '',
		isInsureInResponse: '',
		isInsureInResponseTime: '',

		insureOutReport: '',
		insureOutReportTime: '',
		insureOutResponse: '',
		insureOutResponseDate: '',
		isInsureOutRequestDate: '',
		isInsureOutResponseDate: '',
		isInsureOutRequest: '',
		isInsureOutResponse: '',
		isInsureInResponseDate: '',

		retireInfo: {
			companyName: '',
			healthOutType: '',
			isInsureOutRequest: '',
			isRetired: '',
			lastYearTaxWageOver7Paydocu: '',
			lastYearTaxWagePaydocu: '',
			lastYearTaxWageUnder7Paydocu: '',
			noPayReason: '',
			noPayDetailReason: '',
			pensionOutType: '',
			registerDate: '',
			retiredDate: '',
			thisYearTaxWagePaydocu: '',
			workerId: '',
			workerName: '',
		},
		retireInfoSign: '',

		//산전후휴가, 휴직 등
		maternityLeaveStartDate: '',
		maternityLeaveEndDate: '',
		maternityLeaveType: '',
		childLeaveOfAbsenceStartDate: '',
		childLeaveOfAbsenceEndDate: '',
		childLeaveOfAbsenceType: '',

		childShortTimeStartDate: '',
		childShortTimeEndDate: '',
		childShortTimeType: '',

		leaveOfAbsenceStartDate: '',
		leaveOfAbsenceEndDate: '',
		leaveOfAbsenceType: '',

		liquidation: {
			compensationVacationPay: '',
			severancePay: '',
			annualLeavesPay: '',
			yearEndRefundPay: '',
		},
		comment4InsureOut: '',
		childSupportFundPerson: childSupportFundPerson,

		healthCareRecal: {
			lastYearHealthCareRecalPaid: '',
			lastYearOldCareRecalPaid: '',
			paidHealthFeeTotalLastYear: '',
			paidHealthFeeTotalThisYear: '',
			paidOldFeeTotalLastYear: '',
			paidOldFeeTotalThisYear: '',
			retiredDate: '',
			taxWageLastYear: '',
			taxWageThisYear: '',
			updatedDate: '',
			workedMonthLastYear: '',
			workedMonthThisYear: '',
			workerEnteredDate: '',
			workerName: '',
		},

		isTimeWorker: isTimeWorker || '',
		isEtcWorker: isEtcWorker || '',
		isDayWorker: isDayWorker || '',
		isRegularWorekr: isRegularWorekr || '',
		isBizWorker: isBizWorker || '',
		isExceptTimeLawWorker: isExceptTimeLawWorker || '',
		workerRegistryType: workerRegistryType || null,

		sumOneWeekLBDWTETL: sumOneWeekLBDWTETL,
		fixedMonthRealWorkingHoursETL: fixedMonthRealWorkingHoursETL,
		sumOneWeekNWTETL: sumOneWeekNWTETL,
		fixedMonthRealNightWorkingHoursETL: fixedMonthRealNightWorkingHoursETL,
		expectYearly7thWorkDayCounts: expectYearly7thWorkDayCounts,
		expectWeeklyNightETRWorks: expectWeeklyNightETRWorks,
		expectWeeklyOverETRWorks: expectWeeklyOverETRWorks,

		annualLeavesIncludeOrNot: clientReq4In?.annualLeavesIncludeOrNot || '',
		annualLeavesIncludeMonthWageTime: annualLeavesIncludeMonthWageTime,
		annualLeavesIncludeMonthWage: annualLeavesIncludeMonthWage,
		overFiveStartDay: overFiveStartDay || '',
		// 대리인 입력시 고려
		hostUid: hostUid || user.uid,
		agentName: encryptoClientReq4In?.agentName || agentName || '',
		agentType: encryptoClientReq4In?.agentType || agentType || '',
		agentWorkerName:
			encryptoClientReq4In?.agentWorkerName || agentWorkerName || '',
		managerCode: encryptoClientReq4In?.managerCode || managerCode || '',
		insureManageType:
			encryptoClientReq4In?.insureManageType || insureManageType || '',
		agentUid: agentUid || '',

		// 1 인적사항 및 월급
		totalMonthWageAmount: cTotalMonthWageAmount, //월급 총액
		deductWage: deductWage, // 실수령액

		//2. 근로시간(52시간 판단용 실근무시간)
		monthTotalT: roundUp2(monthTotalT), //1달 총 실근무시간(주휴,야간제외)
		monthOWT: roundUp2(monthOWT), //월간 연장시간(환산 전)
		monthNWT: roundUp2(monthNWT), //월 야간시간(환산 전)
		monthHoliBasicTime: roundUp2(monthHoliBasicTime), //월 휴일8시간(이하) 기본(환산 전)
		monthNOWTHoli: roundUp2(monthOWTHoli), //월 휴일연장(환산 전)
		monthOWTHoli: roundUp2(monthOWTHoli), //월 휴일연장(환산 전)

		monthNWTHoli: roundUp2(monthNWTHoli), //월 휴일야간(환산전)
		monthPaidHolidayLBTime: roundUp2(monthPaidHolidayLBTime), // 1달 주휴시간

		//3. 유급근무시간(월급계산용)
		monthLBTime: roundUp2(monthLBTime), //1달 통상임금 산정기준 시간수roundUp2(속칭 월 소정근로시간)
		converseMonthOWT: roundUp2(converseMonthOWT), //월간 연장 환산시간
		converseMonthNWT: roundUp2(converseMonthNWT), //월간 야간 환산시간
		converseMonthHoliBasicTime: roundUp2(converseMonthHoliBasicTime), //월간 휴일기본 환산시간
		conversemonthOWTHoli: roundUp2(conversemonthOWTHoli), //월간 휴일연장 환산시간
		converseMonthNWTHoli: roundUp2(converseMonthNWTHoli), //월간 휴일야간 환산시간
		mTPCTime: roundUp2(mTPCTime), //1달 총 유급시간(5인미만은 기본급 시간)

		// 4. 기본급 및 법정수당
		monthBasicWage: monthBasicWage, //월 기본급
		mOWage: mOWage, //월 연장근로 수당
		mNWage: mNWage, //월 야간근로 수당
		mHBWage: mHBWage, //월 휴일근로 기본 수당
		mHOWage: mHOWage, //월 휴일 연장근로수당,
		mHNWage: mHNWage, //월 휴일 야간근로 수당
		sumOfMonthONHWages: sumOfMonthONHWages, //월 법정수당 합계

		//5. 통상임금 수당
		cOWTitle: cOWTitle, //직책수당
		cOWJob: cOWJob, //직무수당
		cOWLicense: cOWLicense, //자격수당
		cOWLongTerm: cOWLongTerm, //장기근속수당
		cOWMonthlyBonus: cOWMonthlyBonus, //월상여금
		cOWEtc: cOWEtc, //기타 통상임금 소계
		cOWYearlyBonus: cOWYearlyBonus, //연간상여금

		//어린이집
		cOWChildManageStudyComment: cOWChildManageStudyComment, //육아수당, 연구수당
		cOWChildStudyComment: cOWChildStudyComment, //육아수당, 연구수당
		cOWChildCareComment: cOWChildCareComment, //육아수당, 연구수당
		cOWChildActivityComment: cOWChildActivityComment, //육아수당, 연구수당

		// 11월24일-공통
		//5-1. 일당 통상임금
		cOWDay1: cOWDay1,
		cOWDay2: cOWDay2,
		cOWDay3: cOWDay3,
		sumOfCOWDay: sumOfCOWDay, //일당 소계
		cOWDayHourWage: cOWDayHourWage, //일당 통상임금(일당소계/1일소정근로시간)
		// ------

		cTFMealWage: cTFMealWage, //식대
		cTFCarWage: cTFCarWage, //차량유지비
		cTFChildWage: cTFChildWage, //육아수당
		cTFStudyWage: cTFStudyWage, //연구수당
		cTFEtc: cTFEtc, //기타 통상임금 소계

		//6. 평균임금 수당
		cAWage1: cAWage1,
		cAWage2: cAWage2,
		cAWage3: cAWage3,
		cAWage4: cAWage4,
		cAWageTF1: cAWageTF1,
		cAWageTF2: cAWageTF2,
		cAWageTF3: cAWageTF3,
		cAWageTF4: cAWageTF4,

		//7. 공제액
		calTax: calTax === undefined ? 0 : calTax, //근로소득세
		calRegionTax: calRegionTax, // 지방소득세
		calPension: calPension, // 국민연금
		calHealthCare: calHealthCare, // 건강보험
		calOldCare: calOldCare, //노령연금
		calNoJobInsurance: calNoJobInsurance, //고용보험
		calTeacherPension: calTeacherPension, //사학연금
		dedectTaxEtc: dedectTaxEtc, //기타 공제금

		healthCareText: healthCareTextOption,
		oldCareText: oldCareTextOption,
		pensionText: pensionTextOption,
		teacherPensionText: teacherPensionTextOption, //사학연금
		noJobText: noJobTextOption,

		hourOrdinaryWage: hourOrdinaryWage, //통상시급
		hourOrdinaryWageMonth: hourOrdinaryWageMonth, //통상시급(월급)
		calHourLowestWage: calHourLowestWage, //최저임금 시급
		numOfWorkers: numOfWorkers, //전체 직원수

		gender: gender,
		netAge: netAge,
		netAgeEntered: netAgeEntered,
		netAge15To18: netAge15To18,
		youthSupportFundPerson: youthSupportFundPerson || '',
		covid19SupportFundPerson: covid19SupportFundPerson,
		replacedWorkerFundPerson: replacedWorkerFundPerson,

		companyName: companyName || '',
		companyBizNumber: companyBizNumber || '',
		companyAddress: companyAddress || '',
		ceoName: ceoName || '',
		companyBizItem: companyBizItem, // 종목

		hostedBy: user.displayName, // companyName 절대 아님
		hostPhotoURL: user.photoURL || null,
		annualLeaves: annualLeaves || null,
		finBaseDate: finBaseDate || null,
		normalWorkerWeekWDs: normalWorkerWeekWDs, //통상근로자 소정근무일수
		// lastYearNetWage: lastYearNetWage,
		dayWorker: isDayWorker
			? {
					daylyPay: daylyPay, // 일당
					timeWage: daylyTimePay || '', //입력된 시급
					paidOffDays: daylyPaidOffDays || '',
					workingTimeDayTotal: workingTimeDayTotal, //1일 전체 근로시간
					workingTimeDayNight: workingTimeDayNight, //1일 순 야간
					workingTimeDayOver: workingTimeDayOver, //1일 순 연장
					workingTimeDayBasic: workingTimeDayBasic, // 1일 기본시간
					dayWorkerPaidHolidayLBTime: dayWorkerPaidHolidayLBTime, //주휴수당
					dayTotalPaidConversedTime: dayTotalPaidConversedTime, //1일 환산시간
					realTimeWage: realTimeWage, //시급
					insufficientDayWage: insufficientDayWage, //최저임금 부족 일당
					restViolationDay: restViolationDay, //휴게시간 판단
					workerRegistryType: workerRegistryType || null,
			  }
			: null,

		lawBase: !isDayWorker
			? {
					judge52: judge52, //52시간 판단
					judgeLowestWage: judgeLowestWageResult, //최저임금판단
					restViolation1: restViolation1, //휴게시간 판단
					restViolation2: restViolation2, //휴게시간 판단
					restViolation3: restViolation3, //휴게시간 판단
					restViolation4: restViolation4, //휴게시간 판단
					restViolation5: restViolation5, //휴게시간 판단
					restViolation6: restViolation6, //휴게시간 판단
					restTotalViolation: restTotalViolation, // 전체 휴게시간 판단 종합
					insufficientWage: insufficientWageResult, //최저임금 부족액
					paidHolidayLBTime: paidHolidayLBTime, //1일 소정근로시간(주휴근거)
					weekLBTime: weekLBTime, // 1주 소정근로시간
					monthLBTime: monthLBTime, //1달 통상임금 산정기준 시간수(속칭 월 소정근로시간)
					hourOrdinaryWage: hourOrdinaryWage, //통상시급
					hourOrdinaryWageMonth: hourOrdinaryWageMonth, //통상시급(월급)
					calTotalMonthLowestWage: calTotalMonthLowestWage, //최저임금 월급
					calHourLowestWage: calHourLowestWage, //최저임금 시급
			  }
			: null,
		realWorkTime: !isDayWorker
			? {
					sumOneWeekWT: sumOneWeekWT, //1주 전체 실근로시간(환산전)
					daysWT1: daysWT1, //첫번째 근로시간이 같은 요일의 1일 실근로시간
					daysWT2: daysWT2, //두번째 근로시간이 같은 요일의 1일 실근로시간
					daysWT3: daysWT3, //세번째 근로시간이 같은 요일의 1일 실근로시간
					daysWT4: daysWT4, //네번째 근로시간이 같은 요일의 1일 실근로시간
					daysWT5: daysWT5, //다섯번째 근로시간이 같은 요일의 1일 실근로시간
					daysWT6: daysWT6, //여섯번째 근로시간이 같은 요일의 1일 실근로시간
					days1Length: days1Length, //입력된 첫번째날 근무일 갯수
					days2Length: days2Length, //입력된 두번째날 근무일 갯수
					days3Length: days3Length, //입력된 세번째날 근무일 갯수
					days4Length: days4Length, //입력된 네번째날 근무일 갯수
					days5Length: days5Length, //입력된 다섯번째날 근무일 갯수
					days6Length: days6Length, //입력된 여섯번째날 근무일 갯수

					daysWTETL1: daysWTETL1, //첫번째 근로시간이 같은 요일의 1일 실근로시간
					daysWTETL2: daysWTETL2, //두번째 근로시간이 같은 요일의 1일 실근로시간
					daysWTETL3: daysWTETL3, //세번째 근로시간이 같은 요일의 1일 실근로시간
					daysWTETL4: daysWTETL4, //네번째 근로시간이 같은 요일의 1일 실근로시간
					daysWTETL5: daysWTETL5, //다섯번째 근로시간이 같은 요일의 1일 실근로시간
					daysWTETL6: daysWTETL6, //여섯번째 근로시간이 같은 요일의 1일 실근로시간
					daysWTETL7: daysWTETL7, //여섯번째 근로시간이 같은 요일의 1일 실근로시간

					daysETL1Length: daysETL1Length, //입력된 첫번째날 근무일 갯수
					daysETL2Length: daysETL2Length, //입력된 두번째날 근무일 갯수
					daysETL3Length: daysETL3Length, //입력된 세번째날 근무일 갯수
					daysETL4Length: daysETL4Length, //입력된 네번째날 근무일 갯수
					daysETL5Length: daysETL5Length, //입력된 다섯번째날 근무일 갯수
					daysETL6Length: daysETL6Length, //입력된 여섯번째날 근무일 갯수

					expectWeeklyOverETRWorks: expectWeeklyOverETRWorks,
					expectWeeklyNightETRWorks: expectWeeklyNightETRWorks,
					sumOneWeekLBDWTETL: sumOneWeekLBDWTETL,
					fixedMonthRealWorkingHoursETL: fixedMonthRealWorkingHoursETL,
					sumOneWeekNWTETL: sumOneWeekNWTETL,
					fixedMonthRealNightWorkingHoursETL:
						fixedMonthRealNightWorkingHoursETL,

					days1LBTime: days1LBTime, //첫번째 근로시간이 같은 요일 소정근로시간(일용직용)
					days2LBTime: daysWT2
						? days2LBTime(days1LBTime, days1Length, daysWT2, netAge15To18)
						: '', //두번째 근로시간이 같은 요일 소정근로시간
					days3LBTime: daysWT3
						? days3LBTime(
								days1LBTime,
								days1Length,
								daysWT2,
								days2Length,
								daysWT3,
								netAge15To18
						  )
						: '', //세번째 근로시간이 같은 요일 소정근로시간
					days4LBTime: daysWT4
						? days4LBTime(
								days1LBTime,
								days1Length,
								daysWT2,
								days2Length,
								daysWT3,
								days3Length,
								daysWT4,
								netAge15To18
						  )
						: '', //네번째 근로시간이 같은 요일 소정근로시간
					days5LBTime: daysWT5
						? days5LBTime(
								days1LBTime,
								days1Length,
								daysWT2,
								days2Length,
								daysWT3,
								days3Length,
								daysWT4,
								days4Length,
								daysWT5,
								netAge15To18
						  )
						: '', //다섯번째 근로시간이 같은 요일 소정근로시간
					days6LBTime: daysWT6
						? days6LBTime(
								days1LBTime,
								days1Length,
								daysWT2,
								days2Length,
								daysWT3,
								days3Length,
								daysWT4,
								days4Length,
								daysWT5,
								days5Length,
								daysWT6,
								netAge15To18
						  )
						: '', //여섯번째 근로시간이 같은 요일 소정근로시간

					oneWeekworkdaysCheck: oneWeekworkdaysCheck, //1주 근무일수 합계
					oneWeekworkdaysCheckETLWorker: oneWeekworkdaysCheckETLWorker,

					sumDaysOWT: sumDaysOWT, //1일 8시간 초과 연장 1주 합계(환산 전)
					expectWeeklyOverWorks: expectWeeklyOverWorks, //포괄 불특정 1주 연장
					weekBaseOWT: weekBaseOWT, //1주 40시간 기준 1주 연장시간(환산 전)
					finalWeekOWT: finalWeekOWT, //1주 최총 연장시간(max(8.VS.40))(환산 전)
					monthOWT: monthOWT, //월간 연장시간(환산 전)
					expectWeeklyNightWorks: expectWeeklyNightWorks, //1주 포괄 불특정 야간
					sumOneWeekNWT: sumOneWeekNWT, //1주 야간시간(환산 전)
					monthNWT: monthNWT, //월 야간시간(환산 전)
					monthHoliBasicTime: monthHoliBasicTime, //월 휴일8시간(이하) 기본(환산 전)
					monthOWTHoli: monthOWTHoli, //월 휴일연장(환산 전)
					monthNOWTHoli: monthOWTHoli, //월 휴일연장(환산 전)(monthOWTHoli 같은 놈)
					monthNWTHoli: monthNWTHoli, //월 휴일야간(환산전)
					monthHoliWD: monthHoliWD, //월간 휴일근로 일수
					numOfYearlyHoliWD: numOfYearlyHoliWD, //년간휴일근로일수
					daysWTHoli: daysWTHoli, // 1일 휴일 총 근무시간
					weekWTHoli: weekWTHoli, // 1주 평균 휴일 실근무시간
					daysHoliBasicTime: daysHoliBasicTime, //1일 휴일근로기본시간
					daysNOWTHoli: daysNOWTHoli, //1일 휴일연장시간
					daysNWTHoli: daysNWTHoli, //1일 휴일근로 야간시간
			  }
			: null,
		calWorkTime: !isDayWorker
			? {
					converseMonthOWT: converseMonthOWT, //월간 연장 환산시간
					converseMonthNWT: converseMonthNWT, //월간 야간 환산시간
					converseMonthHoliBasicTime: converseMonthHoliBasicTime, //월간 휴일기본 환산시간
					conversemonthOWTHoli: conversemonthOWTHoli, //월간 휴일연장 환산시간
					converseMonthNWTHoli: converseMonthNWTHoli, //월간 휴일야간 환산시간
					mTPCTime: mTPCTime, //1달 총 유급시간(5인미만은 기본급 시간)
			  }
			: null,
		calWorkWage: !isDayWorker
			? {
					monthBasicWage: monthBasicWage, //월 기본급
					mOWage: mOWage, //월 연장근로 수당
					mNWage: mNWage, //월 야간근로 수당
					mHBWage: mHBWage, //월 휴일근로 기본 수당
					mHOWage: mHOWage, //월 휴일 연장근로수당,
					mHNWage: mHNWage, //월 휴일 야간근로 수당
					sumOfMonthONHWages: sumOfMonthONHWages, //월 법정수당 합계
					sumOfAWages: sumOfAWages, //입력된 월 평균임금 합계(평균 일반 + 비과세.)-법정수당제외
					tWMinusTAW: tWMinusTAW, // 월 유급시간 계산임금(입력된 전체월급 - 평균임금수당)즉, 법정가산임금은 포함된 금액
					sumOfTFWages: sumOfTFWages, //월 비과세 수당합계(통상+평균 모두)
					taxWageSum: taxWageSum, //월 과세임금
					monthOrdinaryWage: monthOrdinaryWage, //월 통상임금(월 유급시간 계산입금-법정수당합계)
			  }
			: null,
		calTaxAnd4In: !isDayWorker
			? {
					calTax: calTax === undefined ? 0 : calTax,
					calRegionTax: calRegionTax,
					calPension: calPension,
					calHealthCare: calHealthCare,
					calOldCare: calOldCare,
					calNoJobInsurance: calNoJobInsurance,
					calTeacherPension: calTeacherPension, //사학연금
					dedectTaxEtc: dedectTaxEtc,
					deductWage: deductWage,
					// overTimeTaxFree: clientReq4In?.lastYearNetWage ? overTimeTaxFree() : null,
			  }
			: null,
	});

	// 문서 생성 후 Firestore 문서 ID를 업데이트
	await updateDoc(docRef, {
		id: docRef.id,
	});
	// 추가된 문서의 ID를 얻음
	const addedDocumentId = docRef.id;

	// 함수가 문서 ID를 반환하도록 할 수도 있음
	return addedDocumentId;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function updateClientReq4InInFirestore(
	clientReq4In,
	agentUid,
	clientInfo,
	id
) {
	const user = auth.currentUser;
	const userRef = doc(db, 'users', user.uid);
	const docSnap = await getDoc(userRef);
	const companyData = docSnap.data();
	// 11월21일

	const numOfWorkers = clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: companyData?.numOfWorkers;
	const finBaseDate = clientInfo?.finBaseDate
		? clientInfo?.finBaseDate
		: companyData?.finBaseDate;
	const annualLeaves = clientInfo?.annualLeaves
		? clientInfo?.annualLeaves
		: companyData?.annualLeaves;
	const companyBizItem = clientInfo?.bizItem
		? clientInfo?.bizItem
		: companyData?.bizItem;
	const overFiveStartDay = clientInfo?.overFiveStartDay
		? clientInfo?.overFiveStartDay
		: companyData?.overFiveStartDay;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: companyData?.companyName;
	const companyBizNumber = clientInfo?.companyBizNumber
		? clientInfo?.companyBizNumber
		: companyData?.companyBizNumber;

	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: companyData?.ceoName;
	const companyAddress = clientInfo?.companyAddress
		? clientInfo?.companyAddress
		: companyData?.companyAddress;
	// const hostUid = clientInfo?.id ? clientInfo?.id :
	//   clientInfo?.clientUid ? clientInfo?.clientUid :
	//   user?.uid;

	const privateSchool = clientInfo?.privateSchool
		? clientInfo?.privateSchool
		: companyData?.privateSchool;

	const agentName = clientInfo?.agentName
		? clientInfo?.agentName
		: companyData?.agentName;
	const agentType = clientInfo?.agentType
		? clientInfo?.agentType
		: companyData?.agentType;
	const agentWorkerName = clientInfo?.agentWorkerName
		? clientInfo?.agentWorkerName
		: companyData?.agentWorkerName;
	const managerCode = clientInfo?.managerCode
		? clientInfo?.managerCode
		: companyData?.managerCode;
	const insureManageType = clientInfo?.insureManageType
		? clientInfo?.insureManageType
		: companyData?.insureManageType;
	const normalWorkerWeekWorkDays = clientInfo?.normalWorkerWeekWorkDays
		? clientInfo?.normalWorkerWeekWorkDays
		: companyData?.normalWorkerWeekWorkDays;

	// 성별... 나이
	const gender = getGenderFromSocialNumber(clientReq4In.workerSocialNumberBack);
	const netAge = getAgeFromSocialNumber(clientReq4In.workerSocialNumberFront);
	// const netAge15To18 = clientReq4In?.netAge15To18 ? clientReq4In?.netAge15To18 : ''
	const netAge15To18 = netAge15To18F(netAge);

	const encryptoClientReq4In = {};
	for (const prop in clientReq4In) {
		encryptoClientReq4In[prop] =
			prop === 'workerSocialNumberFront' ||
			prop === 'workerSocialNumberBack' ||
			prop === 'familySocialNumber' ||
			prop === 'healthCarePeopleComments'
				? encrypto(clientReq4In[prop], String(process.env.CRYPTO_KEY))
				: clientReq4In[prop];
	}
	const enterDate = clientReq4In.workerEnteredDate;
	const workerEnteredDate = enterDate
		? format(new Date(enterDate), 'yyyy-MM-dd')
		: '';

	const contractEndDate = clientReq4In?.contractEndDate
		? format(clientReq4In?.contractEndDate, 'yyyy-MM-dd')
		: '정년';

	const over3MonthsWorker = isThreeMonthsOrMore(enterDate, contractEndDate);
	const netAgeEntered = getAgeFromSocialNumberEnteredDate(
		clientReq4In?.workerSocialNumberFront,
		enterDate
	);

	//(입력된) 첫번째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL1 =
		clientReq4In?.selectWorkDaysETL1Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL1Info?.workingStartTime;
	const dayTypeETL1 =
		clientReq4In?.selectWorkDaysETL1Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL1Info?.workingEndTimeNextDayOrNot;
	const endWTETL1 =
		clientReq4In?.selectWorkDaysETL1Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL1Info?.workingEndTime;
	const dayRTETL1 =
		clientReq4In?.selectWorkDaysETL1Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL1Info?.dayBreakTimes;
	const nigthRTETL1 =
		clientReq4In?.selectWorkDaysETL1Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL1Info?.nightBreakTimes;

	//(입력된) 둘째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL2 =
		clientReq4In?.selectWorkDaysETL2Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL2Info?.workingStartTime;
	const dayTypeETL2 =
		clientReq4In?.selectWorkDaysETL2Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL2Info?.workingEndTimeNextDayOrNot;
	const endWTETL2 =
		clientReq4In?.selectWorkDaysETL2Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL2Info?.workingEndTime;
	const dayRTETL2 =
		clientReq4In?.selectWorkDaysETL2Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL2Info?.dayBreakTimes;
	const nigthRTETL2 =
		clientReq4In?.selectWorkDaysETL2Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL2Info?.nightBreakTimes;

	//(입력된) 세째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL3 =
		clientReq4In?.selectWorkDaysETL3Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL3Info?.workingStartTime;
	const dayTypeETL3 =
		clientReq4In?.selectWorkDaysETL3Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL3Info?.workingEndTimeNextDayOrNot;
	const endWTETL3 =
		clientReq4In?.selectWorkDaysETL3Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL3Info?.workingEndTime;
	const dayRTETL3 =
		clientReq4In?.selectWorkDaysETL3Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL3Info?.dayBreakTimes;
	const nigthRTETL3 =
		clientReq4In?.selectWorkDaysETL3Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL3Info?.nightBreakTimes;

	//(입력된) 네째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL4 =
		clientReq4In?.selectWorkDaysETL4Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL4Info?.workingStartTime;
	const dayTypeETL4 =
		clientReq4In?.selectWorkDaysETL4Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL4Info?.workingEndTimeNextDayOrNot;
	const endWTETL4 =
		clientReq4In?.selectWorkDaysETL4Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL4Info?.workingEndTime;
	const dayRTETL4 =
		clientReq4In?.selectWorkDaysETL4Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL4Info?.dayBreakTimes;
	const nigthRTETL4 =
		clientReq4In?.selectWorkDaysETL4Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL4Info?.nightBreakTimes;

	//(입력된) 다섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL5 =
		clientReq4In?.selectWorkDaysETL5Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL5Info?.workingStartTime;
	const dayTypeETL5 =
		clientReq4In?.selectWorkDaysETL5Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL5Info?.workingEndTimeNextDayOrNot;
	const endWTETL5 =
		clientReq4In?.selectWorkDaysETL5Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL5Info?.workingEndTime;
	const dayRTETL5 =
		clientReq4In?.selectWorkDaysETL5Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL5Info?.dayBreakTimes;
	const nigthRTETL5 =
		clientReq4In?.selectWorkDaysETL5Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL5Info?.nightBreakTimes;

	//(입력된) 여섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL6 =
		clientReq4In?.selectWorkDaysETL6Info?.workingStartTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL6Info?.workingStartTime;
	const dayTypeETL6 =
		clientReq4In?.selectWorkDaysETL6Info?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.selectWorkDaysETL6Info?.workingEndTimeNextDayOrNot;
	const endWTETL6 =
		clientReq4In?.selectWorkDaysETL6Info?.workingEndTime === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL6Info?.workingEndTime;
	const dayRTETL6 =
		clientReq4In?.selectWorkDaysETL6Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL6Info?.dayBreakTimes;
	const nigthRTETL6 =
		clientReq4In?.selectWorkDaysETL6Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDaysETL6Info?.nightBreakTimes;

	//(입력된) 근무요일들
	const daysETL1 = clientReq4In?.selectWorkDaysETL1;
	const daysETL2 = clientReq4In?.selectWorkDaysETL2;
	const daysETL3 = clientReq4In?.selectWorkDaysETL3;
	const daysETL4 = clientReq4In?.selectWorkDaysETL4;
	const daysETL5 = clientReq4In?.selectWorkDaysETL5;
	const daysETL6 = clientReq4In?.selectWorkDaysETL6;

	//(입력된) 근무일 갯수
	const daysETL1Length =
		daysETL1?.length === undefined ? 0 : Number(daysETL1?.length);
	const daysETL2Length =
		daysETL2?.length === undefined ? 0 : Number(daysETL2?.length);
	const daysETL3Length =
		daysETL3?.length === undefined ? 0 : Number(daysETL3?.length);
	const daysETL4Length =
		daysETL4?.length === undefined ? 0 : Number(daysETL4?.length);
	const daysETL5Length =
		daysETL5?.length === undefined ? 0 : Number(daysETL5?.length);
	const daysETL6Length =
		daysETL6?.length === undefined ? 0 : Number(daysETL6?.length);

	//(계산된) 첫번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL1 = daysWT(
		endWTETL1,
		dayTypeETL1,
		startWTETL1,
		dayRTETL1,
		nigthRTETL1
	);
	//두번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL2 = daysWT(
		endWTETL2,
		dayTypeETL2,
		startWTETL2,
		dayRTETL2,
		nigthRTETL2
	);
	//세번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL3 = daysWT(
		endWTETL3,
		dayTypeETL3,
		startWTETL3,
		dayRTETL3,
		nigthRTETL3
	);
	//네번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL4 = daysWT(
		endWTETL4,
		dayTypeETL4,
		startWTETL4,
		dayRTETL4,
		nigthRTETL4
	);
	//다섯번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL5 = daysWT(
		endWTETL5,
		dayTypeETL5,
		startWTETL5,
		dayRTETL5,
		nigthRTETL5
	);
	//여섯번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWTETL6 = daysWT(
		endWTETL6,
		dayTypeETL6,
		startWTETL6,
		dayRTETL6,
		nigthRTETL6
	);

	const expectYearly7thWorkDayCounts =
		clientReq4In?.expectYearly7thWorkDayCounts
			? +clientReq4In?.expectYearly7thWorkDayCounts
			: '';
	//(입력된) 여섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL7 =
		clientReq4In?.expectYearly7thDaysETLInfo?.workingStartTime === undefined
			? 0
			: clientReq4In?.expectYearly7thDaysETLInfo?.workingStartTime;
	const dayTypeETL7 =
		clientReq4In?.expectYearly7thDaysETLInfo?.workingEndTimeNextDayOrNot ===
		undefined
			? 0
			: clientReq4In?.expectYearly7thDaysETLInfo?.workingEndTimeNextDayOrNot;
	const endWTETL7 =
		clientReq4In?.expectYearly7thDaysETLInfo?.workingEndTime === undefined
			? 0
			: clientReq4In?.expectYearly7thDaysETLInfo?.workingEndTime;
	const dayRTETL7 =
		clientReq4In?.expectYearly7thDaysETLInfo?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.expectYearly7thDaysETLInfo?.dayBreakTimes;
	const nigthRTETL7 =
		clientReq4In?.expectYearly7thDaysETLInfo?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.expectYearly7thDaysETLInfo?.nightBreakTimes;

	const daysWTETL7 =
		(daysWT(endWTETL7, dayTypeETL7, startWTETL7, dayRTETL7, nigthRTETL7) *
			expectYearly7thWorkDayCounts) /
		12 /
		4.345;

	const expectWeeklyOverETRWorks =
		clientReq4In?.expectWeeklyOverETRWorks === undefined
			? 0
			: +clientReq4In?.expectWeeklyOverETRWorks;
	const expectWeeklyNightETRWorks =
		clientReq4In?.expectWeeklyNightETRWorks === undefined
			? 0
			: +clientReq4In?.expectWeeklyNightETRWorks;

	// 격일제 근로자
	const shiftWorkWorker =
		clientReq4In?.selectWorkDaysETL1Info?.shiftWork === '격일제 해당'
			? true
			: false;

	// 1주일 전체 1일 전체근로시간의 합계(휴게제외)-기본급(일반 근로자)
	const nomalWorkerSumOneWeekLBDWTETL =
		daysWTETL1 * daysETL1Length +
		daysWTETL2 * daysETL2Length +
		daysWTETL3 * daysETL3Length +
		daysWTETL4 * daysETL4Length +
		daysWTETL5 * daysETL5Length +
		daysWTETL6 * daysETL6Length +
		daysWTETL7 +
		expectWeeklyOverETRWorks;

	const sumOneWeekLBDWTETL = shiftWorkWorker
		? nomalWorkerSumOneWeekLBDWTETL / 2
		: nomalWorkerSumOneWeekLBDWTETL;

	const fixedMonthRealWorkingHoursETL = ((sumOneWeekLBDWTETL / 7) * 365) / 12;

	//////////////////////////////////////////////////야간근로시간////////////////////ㅡ
	//첫번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL1 = daysNWT(
		daysETL1,
		startWTETL1,
		endWTETL1,
		dayTypeETL1,
		nigthRTETL1
	);
	//두번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL2 = daysNWT(
		daysETL2,
		startWTETL2,
		endWTETL2,
		dayTypeETL2,
		nigthRTETL2
	);
	//세번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL3 = daysNWT(
		daysETL3,
		startWTETL3,
		endWTETL3,
		dayTypeETL3,
		nigthRTETL3
	);
	//네번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL4 = daysNWT(
		daysETL4,
		startWTETL4,
		endWTETL4,
		dayTypeETL4,
		nigthRTETL4
	);
	//다섯번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL5 = daysNWT(
		daysETL5,
		startWTETL5,
		endWTETL5,
		dayTypeETL5,
		nigthRTETL5
	);
	//여섯번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWTETL6 = daysNWT(
		daysETL6,
		startWTETL6,
		endWTETL6,
		dayTypeETL6,
		nigthRTETL6
	);

	const daysNWTETL7 =
		(daysNWT(1, startWTETL7, endWTETL7, dayTypeETL7, nigthRTETL7) *
			expectYearly7thWorkDayCounts) /
		12 /
		4.345;

	const nomalWorkerSumOneWeekNWTETL =
		daysNWTETL1 * daysETL1Length +
		daysNWTETL2 * daysETL2Length +
		daysNWTETL3 * daysETL3Length +
		daysNWTETL4 * daysETL4Length +
		daysNWTETL5 * daysETL5Length +
		daysNWTETL6 * daysETL6Length +
		daysNWTETL7 +
		expectWeeklyNightETRWorks;

	//1주 총 야간근로시간
	const sumOneWeekNWTETL = shiftWorkWorker
		? nomalWorkerSumOneWeekNWTETL / 2
		: nomalWorkerSumOneWeekNWTETL;

	const fixedMonthRealNightWorkingHoursETL =
		((sumOneWeekNWTETL / 7) * 365) / 12;

	const isExceptTimeLawWorker = fixedMonthRealWorkingHoursETL ? true : false;

	const annualLeavesIncludeMonthWageTime =
		clientReq4In?.annualLeavesIncludeOrNot === '년차 15일분 임금에 포함'
			? (((sumOneWeekLBDWTETL - daysWTETL7) / 6) * 15) / 12
			: 0;

	//(입력된) 첫번째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek1 = clientReq4In?.selectWorkDays1Info?.skipWeek;
	const startWT1 = clientReq4In?.selectWorkDays1Info?.workingStartTime;
	const dayType1 =
		clientReq4In?.selectWorkDays1Info?.workingEndTimeNextDayOrNot;
	const endWT1 = clientReq4In?.selectWorkDays1Info?.workingEndTime;
	const dayRT1 =
		clientReq4In?.selectWorkDays1Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays1Info?.dayBreakTimes;
	const nigthRT1 =
		clientReq4In?.selectWorkDays1Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays1Info?.nightBreakTimes;

	//(입력된) 둘째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek2 = clientReq4In?.selectWorkDays2Info?.skipWeek;
	const startWT2 = clientReq4In?.selectWorkDays2Info?.workingStartTime;
	const dayType2 =
		clientReq4In?.selectWorkDays2Info?.workingEndTimeNextDayOrNot;
	const endWT2 = clientReq4In?.selectWorkDays2Info?.workingEndTime;
	const dayRT2 =
		clientReq4In?.selectWorkDays2Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays2Info?.dayBreakTimes;
	const nigthRT2 =
		clientReq4In?.selectWorkDays2Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays2Info?.nightBreakTimes;

	//(입력된) 세째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek3 = clientReq4In?.selectWorkDays3Info?.skipWeek;
	const startWT3 = clientReq4In?.selectWorkDays3Info?.workingStartTime;
	const dayType3 =
		clientReq4In?.selectWorkDays3Info?.workingEndTimeNextDayOrNot;
	const endWT3 = clientReq4In?.selectWorkDays3Info?.workingEndTime;
	const dayRT3 =
		clientReq4In?.selectWorkDays3Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays3Info?.dayBreakTimes;
	const nigthRT3 =
		clientReq4In?.selectWorkDays3Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays3Info?.nightBreakTimes;

	//(입력된) 네째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek4 = clientReq4In?.selectWorkDays4Info?.skipWeek;
	const startWT4 = clientReq4In?.selectWorkDays4Info?.workingStartTime;
	const dayType4 =
		clientReq4In?.selectWorkDays4Info?.workingEndTimeNextDayOrNot;
	const endWT4 = clientReq4In?.selectWorkDays4Info?.workingEndTime;
	const dayRT4 =
		clientReq4In?.selectWorkDays4Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays4Info?.dayBreakTimes;
	const nigthRT4 =
		clientReq4In?.selectWorkDays4Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays4Info?.nightBreakTimes;

	//(입력된) 다섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek5 = clientReq4In?.selectWorkDays5Info?.skipWeek;
	const startWT5 = clientReq4In?.selectWorkDays5Info?.workingStartTime;
	const dayType5 =
		clientReq4In?.selectWorkDays5Info?.workingEndTimeNextDayOrNot;
	const endWT5 = clientReq4In?.selectWorkDays5Info?.workingEndTime;
	const dayRT5 =
		clientReq4In?.selectWorkDays5Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays5Info?.dayBreakTimes;
	const nigthRT5 =
		clientReq4In?.selectWorkDays5Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays5Info?.nightBreakTimes;

	//(입력된) 여섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const skipWeek6 = clientReq4In?.selectWorkDays6Info?.skipWeek;
	const startWT6 = clientReq4In?.selectWorkDays6Info?.workingStartTime;
	const dayType6 =
		clientReq4In?.selectWorkDays6Info?.workingEndTimeNextDayOrNot;
	const endWT6 = clientReq4In?.selectWorkDays6Info?.workingEndTime;
	const dayRT6 =
		clientReq4In?.selectWorkDays6Info?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays6Info?.dayBreakTimes;
	const nigthRT6 =
		clientReq4In?.selectWorkDays6Info?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.selectWorkDays6Info?.nightBreakTimes;

	//(입력된) 근무요일들
	const days1 = clientReq4In?.selectWorkDays1;
	const days2 = clientReq4In?.selectWorkDays2;
	const days3 = clientReq4In?.selectWorkDays3;
	const days4 = clientReq4In?.selectWorkDays4;
	const days5 = clientReq4In?.selectWorkDays5;
	const days6 = clientReq4In?.selectWorkDays6;

	//(입력된) 근무일 갯수
	const days1Length = days1?.length === undefined ? 0 : Number(days1?.length);
	const days2Length = days2?.length === undefined ? 0 : Number(days2?.length);
	const days3Length = days3?.length === undefined ? 0 : Number(days3?.length);
	const days4Length = days4?.length === undefined ? 0 : Number(days4?.length);
	const days5Length = days5?.length === undefined ? 0 : Number(days5?.length);
	const days6Length = days6?.length === undefined ? 0 : Number(days6?.length);

	//(계산된) 첫번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT1 = daysWT(endWT1, dayType1, startWT1, dayRT1, nigthRT1);
	//두번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT2 = daysWT(endWT2, dayType2, startWT2, dayRT2, nigthRT2);
	//세번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT3 = daysWT(endWT3, dayType3, startWT3, dayRT3, nigthRT3);
	//네번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT4 = daysWT(endWT4, dayType4, startWT4, dayRT4, nigthRT4);
	//다섯번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT5 = daysWT(endWT5, dayType5, startWT5, dayRT5, nigthRT5);
	//여섯번째 근로시간이 같은 날들의 1일 실근로시간(휴게제외)
	const daysWT6 = daysWT(endWT6, dayType6, startWT6, dayRT6, nigthRT6);

	//첫번째 근로시간이 같은 날들의 1일 소정근로시간
	const days1LBTime = days1LBTimeF(daysWT1, netAge15To18);

	// 1주일 전체 1일 소정근로시간의 합계(휴게제외)
	const sumOneWeekLBDWT = sumOneWeekLBDWTF(
		numOfWorkers,
		daysWT1,
		daysWT2,
		daysWT3,
		daysWT4,
		daysWT5,
		daysWT6,
		days1Length,
		days2Length,
		days3Length,
		days4Length,
		days4Length,
		days4Length,
		skipWeek1,
		skipWeek2,
		skipWeek3,
		skipWeek4,
		skipWeek5,
		skipWeek6
	);

	const isEtcWorker = clientReq4In?.etc?.weekLBTime ? true : false;

	//(입력된) 휴일 근무의 시업, 종업일, 종업시간, 휴게(분)
	const startWTHoli =
		clientReq4In?.expectYearlyHolidayWorksInfo?.workingStartTime;
	const dayTypeHoli =
		clientReq4In?.expectYearlyHolidayWorksInfo?.workingEndTimeNextDayOrNot;
	const endWTHoli = clientReq4In?.expectYearlyHolidayWorksInfo?.workingEndTime;
	const dayRTHoli =
		clientReq4In?.expectYearlyHolidayWorksInfo?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.expectYearlyHolidayWorksInfo?.dayBreakTimes;
	const nigthRTHoli =
		clientReq4In?.expectYearlyHolidayWorksInfo?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.expectYearlyHolidayWorksInfo?.nightBreakTimes;

	//입력된 1주간 포괄 연장, 야간
	const expectWeeklyOverWorks =
		clientReq4In?.expectWeeklyOverWorks === undefined
			? 0
			: +clientReq4In?.expectWeeklyOverWorks;
	const expectWeeklyNightWorks =
		clientReq4In?.expectWeeklyNightWorks === undefined
			? 0
			: +clientReq4In?.expectWeeklyNightWorks;

	//(입력된) 월급총액
	const cTotalMonthWageAmount = clientReq4In?.totalMonthWageAmount
		? numStringToNumber(clientReq4In?.totalMonthWageAmount)
		: 0;

	// 일용직 일당
	const daylyPay = clientReq4In?.daylyPay?.wage
		? numStringToNumber(clientReq4In?.daylyPay?.wage)
		: 0;
	const daylyTimePay = clientReq4In?.daylyPay?.timeWage
		? numStringToNumber(clientReq4In?.daylyPay?.timeWage)
		: 0;
	const daylyPaidOffDays = clientReq4In?.daylyPay?.paidOffDays || '';

	const isDayWorker =
		clientReq4In?.daylyPay?.wage || clientReq4In?.daylyPay?.timeWage
			? true
			: false;
	const isTimeWorker = daylyTimePay ? true : false;

	//(입력된) 통상직책수당
	const cOWTitle = clientReq4In?.ordinaryWage?.title
		? numStringToNumber(clientReq4In?.ordinaryWage?.title)
		: 0;
	//(입력된) 통상직무수당
	const cOWJob = clientReq4In?.ordinaryWage?.job
		? numStringToNumber(clientReq4In?.ordinaryWage?.job)
		: 0;
	//(입력된) 통상자격수당
	const cOWLicense = clientReq4In?.ordinaryWage?.license
		? numStringToNumber(clientReq4In?.ordinaryWage?.license)
		: 0;
	//(입력된) 통상월상여금
	const cOWMonthlyBonus = clientReq4In?.ordinaryWage?.monthlyBonus
		? numStringToNumber(clientReq4In?.ordinaryWage?.monthlyBonus)
		: 0;
	//(입력된) 통상기타수당계
	const cOWLongTerm = clientReq4In?.ordinaryWage?.longTerm
		? numStringToNumber(clientReq4In?.ordinaryWage?.longTerm)
		: 0;
	//(입력된) 통상기타수당계
	const cOWEtc = clientReq4In?.ordinaryWage?.etc
		? numStringToNumber(clientReq4In?.ordinaryWage?.etc)
		: 0;

	const cOWYearlyBonus = clientReq4In?.ordinaryWage?.yearlyBonus
		? numStringToNumber(clientReq4In?.ordinaryWage?.yearlyBonus)
		: 0;

	//어린이집
	const cOWChildManageStudy = clientReq4In?.ordinaryWage?.childManageStudy
		? numStringToNumber(clientReq4In?.ordinaryWage?.childManageStudy)
		: 0;
	const cOWChildStudy = clientReq4In?.ordinaryWage?.childStudy
		? numStringToNumber(clientReq4In?.ordinaryWage?.childStudy)
		: 0;
	const cOWChildCare = clientReq4In?.ordinaryWage?.childCare
		? numStringToNumber(clientReq4In?.ordinaryWage?.childCare)
		: 0;
	const cOWChildActivity = clientReq4In?.ordinaryWage?.childActivity
		? numStringToNumber(clientReq4In?.ordinaryWage?.childActivity)
		: 0;

	const cOWChildManageStudyComment = cOWChildManageStudy
		? '관리 및 연구 수당'
		: '';
	const cOWChildStudyComment = cOWChildStudy
		? '직원에게 지급하는 연구수당'
		: '';
	const cOWChildCareComment = cOWChildCare
		? '직원에게 지급하는 취약보육수당'
		: '';
	const cOWChildActivityComment = cOWChildActivity
		? '직원에게 지급하는 활동보조수당'
		: '';

	// 11월24일-공통
	//(입력된) 출근일당 지급하는 일당 통상임금1
	const cOWDay1 = clientReq4In?.ordinaryWage?.day1
		? numStringToNumber(clientReq4In?.ordinaryWage.day1)
		: 0;
	//(입력된) 출근일당 지급하는 일당 통상임금2
	const cOWDay2 = clientReq4In?.ordinaryWage?.day2
		? numStringToNumber(clientReq4In?.ordinaryWage.day2)
		: 0;
	//(입력된) 출근일당 지급하는 일당 통상임금3
	const cOWDay3 = clientReq4In?.ordinaryWage?.day3
		? numStringToNumber(clientReq4In?.ordinaryWage.day3)
		: 0;
	//--------------
	//(입력된) 통상비과세식대
	const cTFMealWage = clientReq4In?.taxFreeWages?.mealWage
		? numStringToNumber(clientReq4In?.taxFreeWages?.mealWage)
		: 0;
	//(입력된) 통상비과세차량유지비
	const cTFCarWage = clientReq4In?.taxFreeWages?.carWage
		? numStringToNumber(clientReq4In?.taxFreeWages?.carWage)
		: 0;
	//(입력된) 통상비과세육아수당
	const cTFChildWage = clientReq4In?.taxFreeWages?.childWage
		? numStringToNumber(clientReq4In?.taxFreeWages?.childWage)
		: 0;
	//(입력된) 통상비과세연구수당
	const cTFStudyWage = clientReq4In?.taxFreeWages?.studyWage
		? numStringToNumber(clientReq4In?.taxFreeWages?.studyWage)
		: 0;
	//(입력된) 통상비과세기타 계
	const cTFEtc = clientReq4In?.taxFreeWages?.etc
		? numStringToNumber(clientReq4In?.taxFreeWages?.etc)
		: 0;

	//(입력된) 평균임금수당 금액
	const cAWage1 = clientReq4In?.averageWage?.irregularAWage1
		? numStringToNumber(clientReq4In?.averageWage.irregularAWage1)
		: 0;

	const cAWage2 = clientReq4In?.averageWage?.irregularAWage2
		? numStringToNumber(clientReq4In?.averageWage.irregularAWage2)
		: 0;

	const cAWage3 = clientReq4In?.averageWage?.irregularAWage3
		? numStringToNumber(clientReq4In?.averageWage.irregularAWage3)
		: 0;

	const cAWage4 = clientReq4In?.averageWage?.irregularAWage4
		? numStringToNumber(clientReq4In?.averageWage.irregularAWage4)
		: 0;

	//(입력된) 평균임금 비과세 금액
	const cAWageTF1 = clientReq4In?.averageWageTF?.irregularAWage1
		? numStringToNumber(clientReq4In?.averageWageTF.irregularAWage1)
		: 0;

	const cAWageTF2 = clientReq4In?.averageWageTF?.irregularAWage2
		? numStringToNumber(clientReq4In?.averageWageTF.irregularAWage2)
		: 0;

	const cAWageTF3 = clientReq4In?.averageWageTF?.irregularAWage3
		? numStringToNumber(clientReq4In?.averageWageTF.irregularAWage3)
		: 0;

	const cAWageTF4 = clientReq4In?.averageWageTF?.irregularAWage4
		? numStringToNumber(clientReq4In?.averageWageTF.irregularAWage4)
		: 0;

	// const lastYearNetWage =clientReq4In?.lastYearNetWage? commaStringToNumber(clientReq4In?.lastYearNetWage) : 0;

	const etcWeekLBTime = clientReq4In?.etc?.weekLBTime
		? clientReq4In?.etc?.weekLBTime
		: '';
	const etcPaidHolidayLBTime = clientReq4In?.etc?.paidHolidayLBTime
		? clientReq4In?.etc?.paidHolidayLBTime
		: '';

	////////////////////////////////////////////////////근로시간////////////////////////////ㅡ
	// 1주 소정근로시간(기타직은 입력값)
	const weekLBTime = weekLBTimeF(
		etcWeekLBTime,
		isExceptTimeLawWorker,
		sumOneWeekLBDWTETL,
		sumOneWeekLBDWT,
		numOfWorkers,
		netAge15To18
	);
	//(입력된) 통상근로자 1주 소정근로일수
	const normalWorkerWeekWDs = normalWorkerWeekWorkDays
		? normalWorkerWeekWorkDays
		: 5;
	// 1일 소정근로시간(주휴수당근거수당)(기타직은 입력값)
	const paidHolidayLBTime = paidHolidayLBTimeF(
		etcPaidHolidayLBTime,
		weekLBTime,
		normalWorkerWeekWDs,
		numOfWorkers,
		netAge15To18
	);
	// 1달 통상임금산정기준시간수(속칭 1달 소정근로시간)
	const monthLBTime = isExceptTimeLawWorker
		? fixedMonthRealWorkingHoursETL
		: mLBT(weekLBTime, paidHolidayLBTime);

	///////////////////////////////////////////////근무일수
	// 근무일수 합계
	const oneWeekworkdaysCheck =
		days1Length +
		days2Length +
		days3Length +
		days4Length +
		days5Length +
		days6Length;
	const oneWeekworkdaysCheckETLWorker =
		daysETL1Length +
		daysETL2Length +
		daysETL3Length +
		daysETL4Length +
		daysETL5Length +
		daysETL6Length;

	//////////////////////////////////////////////////////휴일근로//////////////////////////

	//(입력된) 년간 휴일근무 일수
	const numOfYearlyHoliWD =
		clientReq4In?.expectYearlyHolidayWorks === undefined
			? 0
			: clientReq4In?.expectYearlyHolidayWorks;
	// 1달 휴일근로 일수(/12개월)
	const monthHoliWD = numOfYearlyHoliWD ? numOfYearlyHoliWD / 12 : 0;
	//휴일근로 1일 실근로시간(휴게제외)
	const daysWTHoli = daysWT(
		endWTHoli,
		dayTypeHoli,
		startWTHoli,
		dayRTHoli,
		nigthRTHoli
	);
	//휴일근로 1일 실 야간 근로시간(휴게제외)
	const daysNWTHoli = daysNWT(
		daysWTHoli,
		startWTHoli,
		endWTHoli,
		dayTypeHoli,
		nigthRTHoli
	);
	//1일 8시간 이내 기본 휴일근로시간
	const daysHoliBasicTime = daysWTHoli >= 8 ? 8 : daysWTHoli;
	//1일 휴일연장시간
	const daysNOWTHoli = daysWTHoli - daysHoliBasicTime;
	// 월간 휴일 기본시간
	const monthHoliBasicTime = clientReq4In?.etc?.monthHoliBasicTime
		? clientReq4In?.etc?.monthHoliBasicTime
		: daysHoliBasicTime * monthHoliWD;
	// 월간 휴일 연장시간(기타직은 입력값)
	const monthOWTHoli = clientReq4In?.etc?.monthOWTHoli
		? clientReq4In?.etc?.monthOWTHoli
		: daysNOWTHoli * monthHoliWD;
	// 월간 휴일 야간시간(기타직은 입력값)
	const monthNWTHoli = clientReq4In?.etc?.monthNWTHoli
		? clientReq4In?.etc?.monthNWTHoli
		: daysNWTHoli * monthHoliWD;
	//주간 휴일근로시간(연장시간인지는 좀 검토 필요)
	const weekWTHoli = (numOfYearlyHoliWD * daysWTHoli) / (365 / 7);

	/////////////////////////////////////////////////연장근로/////////////////ㅡ

	//첫번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT1 = workingTimeDayOverAmount(daysWT1, days1LBTime);
	//두번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT2 = daysOWT(
		days2,
		daysWT2,
		days2LBTime(days1LBTime, days1Length, daysWT2, netAge15To18)
	);
	//세번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT3 = daysOWT(
		days3,
		daysWT3,
		days3LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			netAge15To18
		)
	);
	//네번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT4 = daysOWT(
		days4,
		daysWT4,
		days4LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			days3Length,
			daysWT4,
			netAge15To18
		)
	);
	//다섯번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT5 = daysOWT(
		days5,
		daysWT5,
		days5LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			days3Length,
			daysWT4,
			days4Length,
			daysWT5,
			netAge15To18
		)
	);
	//여섯번째 근로시간이 같은 날들의 1일 실연장근로시간(휴게제외)
	const daysOWT6 = daysOWT(
		days6,
		daysWT6,
		days6LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			days3Length,
			daysWT4,
			days4Length,
			daysWT5,
			days5Length,
			daysWT6,
			netAge15To18
		)
	);

	//1주 총 1일 8시간 기준 연장근로시간 합계
	const sumDaysOWT = sumDaysOWTF(
		daysOWT1,
		daysOWT2,
		daysOWT3,
		daysOWT4,
		daysOWT5,
		daysOWT6,
		days1Length,
		days2Length,
		days3Length,
		days4Length,
		days5Length,
		days6Length,
		skipWeek1,
		skipWeek2,
		skipWeek3,
		skipWeek4,
		skipWeek5,
		skipWeek6
	);

	// 1주일 전체 실근로시간(휴게제외)-52시간제 판단 시간
	const sumOneWeekWT = sumOneWeekWTF(
		daysWT1,
		daysWT2,
		daysWT3,
		daysWT4,
		daysWT5,
		daysWT6,
		days1Length,
		days2Length,
		days3Length,
		days4Length,
		days5Length,
		days6Length,
		skipWeek1,
		skipWeek2,
		skipWeek3,
		skipWeek4,
		skipWeek5,
		skipWeek6,
		expectWeeklyOverWorks,
		weekWTHoli
	);

	// 1주일 전체 시간외근로(휴일제외)
	const sumOneWeekWTForOverTime = sumOneWeekWTForOverTimeF(
		daysWT1,
		daysWT2,
		daysWT3,
		daysWT4,
		daysWT5,
		daysWT6,
		days1Length,
		days2Length,
		days3Length,
		days4Length,
		days5Length,
		days6Length,
		skipWeek1,
		skipWeek2,
		skipWeek3,
		skipWeek4,
		skipWeek5,
		skipWeek6,
		expectWeeklyOverWorks
	);

	//1주 40시간 기준 연장근로시간
	const weekBaseOWT = sumOneWeekWTForOverTime - weekLBTime;

	// 최종 1주 연장근로시간
	const finalWeekOWT = Math.max(sumDaysOWT, weekBaseOWT);

	//////////////////////////////////////////////////야간근로시간////////////////////ㅡ
	//첫번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT1 = daysNWT(days1, startWT1, endWT1, dayType1, nigthRT1);
	//두번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT2 = daysNWT(days2, startWT2, endWT2, dayType2, nigthRT2);
	//세번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT3 = daysNWT(days3, startWT3, endWT3, dayType3, nigthRT3);
	//네번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT4 = daysNWT(days4, startWT4, endWT4, dayType4, nigthRT4);
	//다섯번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT5 = daysNWT(days5, startWT5, endWT5, dayType5, nigthRT5);
	//여섯번째 근로시간이 같은 날들의 1일 실 야간 근로시간(휴게제외)
	const daysNWT6 = daysNWT(days6, startWT6, endWT6, dayType6, nigthRT6);

	//1주 총 야간근로시간
	const sumOneWeekNWT = sumOneWeekNWTF(
		daysNWT1,
		daysNWT2,
		daysNWT3,
		daysNWT4,
		daysNWT5,
		daysNWT6,
		days1Length,
		days2Length,
		days3Length,
		days4Length,
		days5Length,
		days6Length,
		skipWeek1,
		skipWeek2,
		skipWeek3,
		skipWeek4,
		skipWeek5,
		skipWeek6,
		expectWeeklyNightWorks
	);

	//////////////////////////////////////////////////// 월급구성항목 계산 시간(환산작업)//////////////ㅡ

	// 월간  연장근로시간
	const monthOWT = clientReq4In?.etc?.monthOWT
		? clientReq4In?.etc?.monthOWT
		: weekToMonthCal(finalWeekOWT);
	// 월간 환산연장근로시간
	const converseMonthOWT = cMOWTime(monthOWT, numOfWorkers);

	// 월간 야간시간(기타직은 입력값)
	const monthNWT = clientReq4In?.etc?.monthNWT
		? clientReq4In?.etc?.monthNWT
		: isExceptTimeLawWorker
		? weekToMonthCal(sumOneWeekNWTETL)
		: weekToMonthCal(sumOneWeekNWT);
	// 월간 환산야간시간
	const converseMonthNWT = cMNWTime(monthNWT, numOfWorkers);

	// 월간 환산 휴일 기본시간
	const converseMonthHoliBasicTime = cMHBTime(monthHoliBasicTime, numOfWorkers);
	// 월간 환산 휴일 연장시간
	const conversemonthOWTHoli = cMHOTime(monthOWTHoli, numOfWorkers);
	// 월간 환산 휴일 야간시간
	const converseMonthNWTHoli = cMH0Time(monthNWTHoli, numOfWorkers);

	// 5인이상 && 농민 총 유급시간 , 5인미만은 가산금이 없어서 총 근무시간(기본급 시간)
	const mTPCTime =
		numOfWorkers >= 5
			? isExceptTimeLawWorker
				? fixedMonthRealNightWorkingHoursETL * 0.5 +
				  fixedMonthRealWorkingHoursETL +
				  annualLeavesIncludeMonthWageTime
				: monthTotalPaidConversedTime(
						converseMonthNWT,
						converseMonthOWT,
						converseMonthHoliBasicTime,
						conversemonthOWTHoli,
						converseMonthNWTHoli,
						monthLBTime
				  )
			: monthLBTime +
			  monthHoliBasicTime +
			  monthOWTHoli +
			  expectWeeklyOverWorks * 4.345;

	///////////////////////////////////////////////////월급 구성항목 및 월통상임금/시간급통상임금

	//(계산된) 통상임금 일반 수당 합계
	const sumOfOWages =
		cOWTitle +
		cOWJob +
		cOWLicense +
		cOWLongTerm +
		cOWMonthlyBonus +
		cOWEtc +
		cOWChildManageStudy +
		cOWChildStudy +
		cOWChildCare +
		cOWChildActivity;

	//11월24일-공통
	//(계산된) 일당 통상임금 합계
	const sumOfCOWDay = cOWDay1 + cOWDay2 + cOWDay3;
	//일당 통상임금 / 1일소정근로시간
	const cOWDayHourWage =
		sumOfCOWDay / (paidHolidayLBTime + paidHolidayLBTime / normalWorkerWeekWDs);
	//---------------

	// 비과세 수당 합계(통상임금 + 평균임금)
	const sumOfTFWages =
		cTFMealWage +
		cTFCarWage +
		cTFChildWage +
		cTFStudyWage +
		cTFEtc +
		cAWageTF1 +
		cAWageTF2 +
		cAWageTF3 +
		cAWageTF4;

	// 비과세 수당 합계(최저임금 산입비율 판단용으로 평균임금 비과세 수당은 이미 뺐으므로 제외한다.)
	const sumOfTFWagesForBLLowWage =
		cTFMealWage + cTFCarWage + cTFChildWage + cTFStudyWage + cTFEtc;

	// 평균임금 수당 합계
	const sumOfAWages =
		cAWage1 +
		cAWage2 +
		cAWage3 +
		cAWage4 +
		cAWageTF1 +
		cAWageTF2 +
		cAWageTF3 +
		cAWageTF4;

	// 평균임금 비과세 중복 조정용 평균비과세 수당 합계(기본급에 영향을 줌)
	const sumOfAWagesTFMinusAmount =
		cAWageTF1 + cAWageTF2 + cAWageTF3 + cAWageTF4;

	// 월 통상임금 대상 임금(유급시간 계산임금)(입력된 전체월급 - 평균임금수당) 즉, 법정가산임금은 포함된 금액
	const tWMinusTAW = cTotalMonthWageAmount - sumOfAWages;

	//  비과세 한도액
	const year = parseInt(format(new Date(), 'yyyy-MM-dd').split('-')[0]);
	const month = parseInt(format(new Date(), 'yyyy-MM-dd').split('-')[1]);

	const cTFMealWageMax =
		cTFMealWage >= taxFreeMealWageLimitF(year)
			? taxFreeMealWageLimitF(year)
			: cTFMealWage;
	const cTFCarWageMax =
		cTFCarWage >= taxFreeCarWageLimitF(year)
			? taxFreeCarWageLimitF(year)
			: cTFCarWage;
	const cTFChildWageMax =
		cTFChildWage >= taxFreeChildWageLimitF(year)
			? taxFreeChildWageLimitF(year)
			: cTFChildWage;
	const cTFStudyWageMax =
		cTFStudyWage >= taxFreeStudyWageLimitF(year)
			? taxFreeStudyWageLimitF(year)
			: cTFStudyWage;

	// 월 과세임금(비과세 한도만 뺌.)
	const tempTaxWageSum =
		cTotalMonthWageAmount -
		(cTFMealWageMax +
			cTFCarWageMax +
			cTFChildWageMax +
			cTFStudyWageMax +
			cTFEtc +
			cAWageTF1 +
			cAWageTF2 +
			cAWageTF3 +
			cAWageTF4);
	const taxWageSum = tempTaxWageSum <= 0 ? 0 : tempTaxWageSum;

	// 통상 시급(금액)
	const hourOrdinaryWage = hourOrdiWage(tWMinusTAW, mTPCTime, cOWYearlyBonus);
	const hourOrdinaryWageMonth = hourOrdiWageMonth(tWMinusTAW, mTPCTime);
	//------------

	// 월 연장근로수당(금액)
	const mOWage = mOWWage(hourOrdinaryWage, converseMonthOWT, numOfWorkers);
	//월 야간근로수당(금액)
	const mNWage = mNWWage(hourOrdinaryWage, converseMonthNWT, numOfWorkers);
	//월 휴일기본근로수당(금액)
	const mHBWage = mHBWWage(
		hourOrdinaryWage,
		converseMonthHoliBasicTime,
		numOfWorkers
	);
	//월 휴일연장근로수당(금액)
	const mHOWage = mHOWWage(
		hourOrdinaryWage,
		conversemonthOWTHoli,
		numOfWorkers
	);
	//월 휴일야간근로수당(금액)
	const mHNWage = mHNWWage(
		hourOrdinaryWage,
		converseMonthNWTHoli,
		numOfWorkers
	);

	const annualLeavesIncludeMonthWage = roundUp(
		annualLeavesIncludeMonthWageTime * hourOrdinaryWage
	);

	//월 법정수당 합계(금액)
	const sumOfMonthONHWages = sMONHWage(
		mOWage,
		mNWage,
		mHBWage,
		mHOWage,
		mHNWage,
		annualLeavesIncludeMonthWage
	);

	// 월 통상임금((평균임금제외) -법정가산수당))
	const monthOrdinaryWage = mOrdiWage(
		cTotalMonthWageAmount,
		sumOfAWages,
		sumOfMonthONHWages
	);
	// 월 기본급(5인이상이면 월 통상임금 - 비과세(통상) - 통상수당  )
	const monthBasicWage = mBW(
		numOfWorkers,
		cTotalMonthWageAmount,
		sumOfAWages,
		sumOfMonthONHWages,
		sumOfTFWages,
		sumOfOWages,
		sumOfAWagesTFMinusAmount
	);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// 최저임금 판단부분

	// 법정연도별 최저임금
	const lBLowestHourWage =
		year === 2025
			? lowestTimeWageOf2025
			: year === 2024
			? lowestTimeWageOf2024
			: year === 2023
			? 9620
			: year === 2022
			? 9160
			: year === 2021
			? 8720
			: year === 2020
			? 8590
			: year === 2019
			? 8350
			: 0;
	const lBLowestMonthWage = lBLowestHourWage * 209;
	// 법정복리후생비 공제 비율 및 금액
	const lBWelfareFeeDeductRate =
		year === 2023
			? 0.01
			: year === 2022
			? 0.02
			: year === 2021
			? 0.03
			: year === 2020
			? 0.05
			: year === 2019
			? 0.07
			: 0;
	const lBWelfareFeeDeductWage = lBLowestMonthWage * lBWelfareFeeDeductRate;
	// 법정월정기상여금 공제 비율 및 금액
	const lBMonthBonusDeductRate =
		year === 2023
			? 0.05
			: year === 2022
			? 0.1
			: year === 2021
			? 0.15
			: year === 2020
			? 0.2
			: year === 2019
			? 0.25
			: 0;
	const lBMonthBonusDeductWage = lBLowestMonthWage * lBMonthBonusDeductRate;

	// 계산된 최저임금 복리후생비 공제액
	const calWelfareWage =
		sumOfTFWagesForBLLowWage > lBWelfareFeeDeductWage
			? sumOfTFWagesForBLLowWage - lBWelfareFeeDeductWage
			: 0;
	// 계산된 최저임금 월상여금 공제액
	const calMonthBonus =
		cOWMonthlyBonus > lBMonthBonusDeductWage
			? cOWMonthlyBonus - lBMonthBonusDeductWage
			: 0;

	// 계산된 최저임금 대상금액(전체월급 - 평균임금수당 - 공제액)
	const calTotalMonthLowestWage =
		numOfWorkers < 5
			? cTotalMonthWageAmount -
			  sumOfTFWagesForBLLowWage -
			  cOWMonthlyBonus -
			  sumOfAWages +
			  calWelfareWage +
			  calMonthBonus
			: monthOrdinaryWage -
			  sumOfTFWagesForBLLowWage -
			  cOWMonthlyBonus +
			  calWelfareWage +
			  calMonthBonus;

	// 계산된 회사 최저 시급
	const calHourLowestWage =
		numOfWorkers < 5
			? calTotalMonthLowestWage / mTPCTime +
			  (cOWDayHourWage ? cOWDayHourWage : 0)
			: calTotalMonthLowestWage / monthLBTime +
			  (cOWDayHourWage ? cOWDayHourWage : 0); // ------------------

	// 최저임금법 위반 판단
	const judgeLowestWageResult = clientReq4In.daylyPay
		? null
		: calHourLowestWage >= lBLowestHourWage
		? true
		: false;

	// // 해당년도 해당 업체 근무시간에 따른 최저임금(월급)
	// const calLBMonthLowestWage = lBLowestHourWage * mTPCTime + sumOfAWages;
	// const insufficientWageResult = clientReq4In.daylyPay ? null : (calLBMonthLowestWage-cTotalMonthWageAmount > 0 ? calLBMonthLowestWage - cTotalMonthWageAmount : 0);

	// 해당년도 해당 업체 근무시간에 따른 최저임금(월급)
	const calLawBaseWageMinusCompanyBaseWage =
		judgeLowestWageResult === false
			? ((lBLowestHourWage * monthLBTime - calHourLowestWage * monthLBTime) *
					mTPCTime) /
			  monthLBTime
			: 0;
	// const calLBMonthLowestWage = lBLowestHourWage * mTPCTime + sumOfAWages;
	const insufficientWageResult = clientReq4In.daylyPay
		? null
		: calLawBaseWageMinusCompanyBaseWage > 0
		? roundUp(calLawBaseWageMinusCompanyBaseWage)
		: 0;

	// 주52시간제 준수여부
	const judge52 = clientReq4In.daylyPay
		? null
		: sumOneWeekWT <= 52 || numOfWorkers < 5
		? true
		: false;

	//근로소득세, 지방소득세, 연금, 건보, 요양, 고용
	const taxPayType = clientReq4In?.taxPayType;
	const calTaxRate =
		taxPayType === '120%' ? 1.2 : taxPayType === '80%' ? 0.8 : 1;
	const pensionPayType = clientReq4In?.pensionPayType;
	const healthCarePayType = clientReq4In?.healthCarePayType;
	const oldCarePayType = clientReq4In?.oldCarePayType;
	const noJobPayType = clientReq4In?.noJobPayType;
	const teacherPensionPayType = clientReq4In?.teacherPensionPayType;

	const pensionBaseWage = clientReq4In?.pensionBaseWage
		? numStringToNumber(clientReq4In?.pensionBaseWage)
		: null;
	const healthCareBaseWage = clientReq4In?.healthCareBaseWage
		? numStringToNumber(clientReq4In?.healthCareBaseWage)
		: null;
	const oldCareBaseWage = clientReq4In?.oldCareBaseWage
		? numStringToNumber(clientReq4In?.oldCareBaseWage)
		: null;
	const noJobBaseWage = clientReq4In?.noJobBaseWage
		? numStringToNumber(clientReq4In?.noJobBaseWage)
		: null;

	const teacherPensionBaseWage = clientReq4In?.teacherPensionBaseWage
		? numStringToNumber(clientReq4In?.teacherPensionBaseWage)
		: null;
	const numOfTaxFamily = clientReq4In.numOfTaxFamily
		? +clientReq4In.numOfTaxFamily
		: 0;
	const numOfTaxFamily7To20 = clientReq4In.numOfTaxFamily7To20
		? +clientReq4In.numOfTaxFamily7To20
		: 0;

	const visaType = clientReq4In?.foreigner?.visaType
		? clientReq4In?.foreigner?.visaType
		: '';
	const selfNoJobPay = clientReq4In?.foreigner?.selfNoJobPay
		? clientReq4In?.foreigner?.selfNoJobPay
		: '';

	const endless65EmploymentInsurance =
		clientReq4In?.endless65EmploymentInsurance
			? clientReq4In?.endless65EmploymentInsurance
			: '';

	const pensionLimitWage2023 = 2200000;
	const interInWorker = false;
	const pensionTaxWageSum = pensionTaxWageSumF(
		pensionLimitWage2023,
		pensionPayType,
		taxWageSum,
		pensionBaseWage,
		netAge,
		weekLBTime,
		interInWorker,
		visaType,
		workerEnteredDate
	);

	const teacherPensionTaxWageSum = teacherPensionTaxWageSumF(
		teacherPensionPayType,
		taxWageSum,
		teacherPensionBaseWage,
		privateSchool
	);

	const healthCareTaxWageSum = healthCareTaxWageSumF(
		healthCarePayType,
		taxWageSum,
		healthCareBaseWage,
		weekLBTime,
		''
	);
	const oldCareTaxWageSum = oldCareTaxWageSumF(
		oldCarePayType,
		taxWageSum,
		oldCareBaseWage,
		weekLBTime,
		''
	);

	const isInterInWorker = false;

	const noJobTaxWageSum = noJobTaxWageSumF(
		noJobPayType,
		taxWageSum,
		noJobBaseWage,
		netAgeEntered,
		weekLBTime,
		over3MonthsWorker,
		visaType,
		selfNoJobPay,
		isInterInWorker,
		endless65EmploymentInsurance
	);

	const calTax = calTaxF(
		taxWageSum,
		numOfTaxFamily,
		numOfTaxFamily7To20,
		calTaxRate,
		''
	);
	const calRegionTax = calRegionTaxF(calTax);

	const calPension =
		pensionTaxWageSum === 0 ? 0 : pension(pensionTaxWageSum, year, month);

	const calTeacherPension =
		teacherPensionTaxWageSum === 0
			? 0
			: teacherPension(teacherPensionTaxWageSum, year, privateSchool);

	const calHealthCare =
		healthCareTaxWageSum === 0
			? 0
			: healthCare(healthCareTaxWageSum, year, healthCarePayType);
	const calOldCare =
		oldCareTaxWageSum === 0
			? 0
			: oldCare(oldCareTaxWageSum, year, healthCarePayType);
	const calNoJobInsurance =
		noJobTaxWageSum === 0 ? 0 : noJobInsurance(noJobTaxWageSum, year, month);

	const healthCareTextOption =
		healthCarePayType === '건강보험료 공제안함'
			? null
			: healthCareText(
					healthCareBaseWage,
					healthCarePayType,
					year,
					calHealthCare
			  );
	const oldCareTextOption =
		oldCarePayType === '요양보험료 공제안함'
			? null
			: oldCareText(oldCareBaseWage, oldCarePayType, year, calHealthCare);
	const pensionTextOption =
		pensionPayType === '국민연금료 공제안함'
			? null
			: pensionText(pensionBaseWage, pensionPayType, calPension, visaType);

	const teacherPensionTextOption =
		teacherPensionPayType === '사학연금료 공제안함'
			? null
			: pensionText(
					teacherPensionBaseWage,
					teacherPensionPayType,
					calTeacherPension
			  );

	const noJobTextOption =
		noJobPayType === '고용보험료 공제안함'
			? null
			: noJobText(
					noJobBaseWage,
					noJobPayType,
					year,
					calNoJobInsurance,
					visaType,
					selfNoJobPay
			  );

	//공제계
	const dedectTaxEtc =
		calTax +
		calRegionTax +
		calPension +
		calHealthCare +
		calOldCare +
		calNoJobInsurance +
		calTeacherPension;

	//실수령액(실제)
	const deductWage = cTotalMonthWageAmount - dedectTaxEtc;
	// const overTimeTaxFree = () => {
	// 	if ((clientReq4In?.workField === '생산제조기능원/기계조작(조립)원'
	// 	|| clientReq4In?.workField === '청소/경비/주차/운전/돌봄서비스직'
	// 	|| clientReq4In?.workField === '건설/광업/제조관련 단순노무직'
	// 	|| clientReq4In?.workField === '미용/숙박/음식서비스/매장판매/통산관련판매직'
	// 	|| clientReq4In?.workField === '농림/어업/계기/자판기 등 기타 서비스 관련 단순노무직')
	// 	&& commaStringToNumber(clientReq4In?.lastYearNetWage) <= 30000000
	// 	&& taxWageSum <= 2100000) {
	// 		return '년간 120만원까지 비과세 대상임'
	// 	} else return '비과세 대상 아님'
	// }

	// 일용직 급여 계산
	// (입력된) 일용직 1일 근로시간 휴게 등
	const workStartTime = clientReq4In?.daylyPay?.workingStartTime
		? clientReq4In?.daylyPay?.workingStartTime
		: null;
	const workEndTime = clientReq4In?.daylyPay?.workingEndTime
		? clientReq4In?.daylyPay?.workingEndTime
		: null;
	const dayType = clientReq4In?.daylyPay?.workingEndTimeNextDayOrNot
		? clientReq4In?.daylyPay?.workingEndTimeNextDayOrNot
		: null;
	const dayRest =
		clientReq4In?.daylyPay?.dayBreakTimes === undefined
			? 0
			: clientReq4In?.daylyPay?.dayBreakTimes;
	const nightRest =
		clientReq4In?.daylyPay?.nightBreakTimes === undefined
			? 0
			: clientReq4In?.daylyPay?.nightBreakTimes;

	// 1일 실근로시간
	const workingTimeDayTotal = daylyPay
		? workingTimeDayTotalAmount(
				workEndTime,
				dayType,
				workStartTime,
				dayRest,
				nightRest
		  )
		: null;
	const workingTimeDayNight = daylyPay
		? numOfWorkers < 5
			? 0
			: workingTimeDayNightAmount(
					workStartTime,
					workEndTime,
					dayType,
					nightRest
			  )
		: null;
	const workingTimeDayOver = daylyPay
		? numOfWorkers < 5
			? 0
			: workingTimeDayTotal > 8
			? workingTimeDayTotal - 8
			: 0
		: null;
	const workingTimeDayBasic = daylyPay
		? numOfWorkers < 5
			? 0
			: workingTimeDayTotal >= 8
			? 8
			: workingTimeDayTotal
		: null;
	const dayWorkerPaidHolidayLBTime =
		clientReq4In?.week14OverBLTime === '주휴수당 포함'
			? workingTimeDayTotal >= 8
				? 8 * 0.2
				: workingTimeDayTotal * 0.2
			: 0;

	const dayTotalPaidConversedTime = daylyPay
		? numOfWorkers < 5
			? workingTimeDayTotal + dayWorkerPaidHolidayLBTime
			: workingTimeDayBasic +
			  workingTimeDayNight * 0.5 +
			  workingTimeDayOver * 1.5 +
			  dayWorkerPaidHolidayLBTime
		: null;
	const realTimeWage = daylyPay ? daylyPay / dayTotalPaidConversedTime : null;
	const insufficientTimeWage = daylyPay
		? lBLowestHourWage - realTimeWage > 0
			? lBLowestHourWage - realTimeWage
			: 0
		: null;
	const insufficientDayWage = daylyPay
		? insufficientTimeWage === 0
			? 0
			: insufficientTimeWage * dayTotalPaidConversedTime
		: null;

	const exCompanyOutDate = clientReq4In?.exCompanyOutDate;
	const worknetRegisterDate = clientReq4In?.worknetRegisterDate;
	const originalWorkerLeaveStartDate =
		clientReq4In?.originalWorkerLeaveStartDate;
	const originalWorkerLeaveEndDate = clientReq4In?.originalWorkerLeaveEndDate;
	const newOrNot = clientReq4In?.newOrNot;
	const altWorker = clientReq4In?.altWorker;

	const childLeaveOfAbsenceStartDateM = moment(
		clientReq4In?.childLeaveOfAbsenceStartDate
	);
	const childLeaveOfAbsenceEndDateM = moment(
		clientReq4In?.childLeaveOfAbsenceEndDate
	).add(1, 'days');

	const childSupportFund =
		childLeaveOfAbsenceEndDateM.diff(childLeaveOfAbsenceStartDateM, 'months') >=
		1
			? true
			: false;
	const childSupportFundPerson =
		childSupportFund && judgeLowestWageResult
			? '육아휴직(단축) 등 사업주 지원금 대상'
			: false;

	//청년채용특별지원금
	const workerYouth = clientReq4In?.workerYouthType;
	const youthSupportFundPerson = youthSupportFundPersonF(
		judgeLowestWageResult,
		enterDate,
		contractEndDate,
		netAge,
		weekLBTime,
		workerYouth
	);
	//코로나 특별 지원금
	const covid19SupportFundPerson = covid19SupportFundPersonF(
		judgeLowestWageResult,
		enterDate,
		contractEndDate,
		newOrNot,
		exCompanyOutDate,
		worknetRegisterDate,
		weekLBTime,
		cTotalMonthWageAmount
	);
	//대체인력 지원금
	const replacedWorkerFundPerson = replacedWorkerFundPersonF(
		judgeLowestWageResult,
		enterDate,
		contractEndDate,
		altWorker,
		originalWorkerLeaveStartDate,
		originalWorkerLeaveEndDate
	);

	const monthTotalT =
		weekLBTime * 4.345 + monthOWT + monthHoliBasicTime + monthOWTHoli;
	const monthPaidHolidayLBTime = paidHolidayLBTime * 4.345;

	// 휴게시간 준수여부
	const restViolation1 = restLawViolationJudge(
		endWT1,
		dayType1,
		startWT1,
		dayRT1,
		nigthRT1
	);
	const restViolation2 = startWT2
		? restLawViolationJudge(endWT2, dayType2, startWT2, dayRT2, nigthRT2)
		: true;
	const restViolation3 = startWT3
		? restLawViolationJudge(endWT3, dayType3, startWT3, dayRT3, nigthRT3)
		: true;
	const restViolation4 = startWT4
		? restLawViolationJudge(endWT4, dayType4, startWT4, dayRT4, nigthRT4)
		: true;
	const restViolation5 = startWT5
		? restLawViolationJudge(endWT5, dayType5, startWT5, dayRT5, nigthRT5)
		: true;
	const restViolation6 = startWT6
		? restLawViolationJudge(endWT6, dayType6, startWT6, dayRT6, nigthRT6)
		: true;

	const restTotalViolation =
		restViolation1 &&
		restViolation2 &&
		restViolation3 &&
		restViolation4 &&
		restViolation5 &&
		restViolation6;

	const restViolationDay = workStartTime
		? restLawViolationJudge(
				workEndTime,
				dayType,
				workStartTime,
				dayRest,
				nightRest
		  )
		: true;

	const maternityLeaves = clientReq4In?.maternityLeaves
		? clientReq4In?.maternityLeaves
		: [];
	const childLeaveOfAbsences = clientReq4In?.childLeaveOfAbsences
		? clientReq4In?.childLeaveOfAbsences
		: [];
	const childShortTimes = clientReq4In?.childShortTimes
		? clientReq4In?.childShortTimes
		: [];
	const leaveOfAbsences = clientReq4In?.leaveOfAbsences
		? clientReq4In?.leaveOfAbsences
		: [];
	try {
		const docRef = doc(db, 'workers', id);
		await updateDoc(docRef, {
			...encryptoClientReq4In,
			id: id,
			//산전후휴가, 휴직 등
			maternityLeaveStartDate:
				maternityLeaves.length > 0 ? clientReq4In?.maternityLeaveStartDate : '',
			maternityLeaveEndDate:
				maternityLeaves.length > 0 ? clientReq4In?.maternityLeaveEndDate : '',
			maternityLeaveType:
				maternityLeaves.length > 0 ? clientReq4In?.maternityLeaveType : '',
			childLeaveOfAbsenceStartDate:
				childLeaveOfAbsences.length > 0
					? clientReq4In?.childLeaveOfAbsenceStartDate
					: '',
			childLeaveOfAbsenceEndDate:
				childLeaveOfAbsences.length > 0
					? clientReq4In?.childLeaveOfAbsenceEndDate
					: '',
			childLeaveOfAbsenceType:
				childLeaveOfAbsences.length > 0
					? clientReq4In?.childLeaveOfAbsenceType
					: '',
			childShortTimeStartDate:
				childShortTimes.length > 0 ? clientReq4In?.childShortTimeStartDate : '',
			childShortTimeEndDate:
				childShortTimes.length > 0 ? clientReq4In?.childShortTimeEndDate : '',
			childShortTimeType:
				childShortTimes.length > 0 ? clientReq4In?.childShortTimeType : '',
			leaveOfAbsenceStartDate:
				leaveOfAbsences.length > 0 ? clientReq4In?.leaveOfAbsenceStartDate : '',
			leaveOfAbsenceEndDate:
				leaveOfAbsences.length > 0 ? clientReq4In?.leaveOfAbsenceEndDate : '',
			leaveOfAbsenceType:
				leaveOfAbsences.length > 0 ? clientReq4In?.leaveOfAbsenceType : '',

			workerEnteredDateArray: arrayUnion(clientReq4In?.workerEnteredDate) || [],
			contractEndDateArray:
				clientReq4In.contractEndDate !== undefined
					? arrayUnion(clientReq4In?.contractEndDate)
					: [],
			afterRetireAgainContractEndDateArray:
				clientReq4In.afterRetireAgainContractEndDate !== undefined
					? arrayUnion(clientReq4In?.afterRetireAgainContractEndDate)
					: [],
			againContractStartDateArray:
				clientReq4In.againContractStartDate !== undefined
					? arrayUnion(clientReq4In?.againContractStartDate)
					: [],
			afterRetireAgainContractStartDateArray:
				clientReq4In.afterRetireAgainContractStartDate !== undefined
					? arrayUnion(clientReq4In?.afterRetireAgainContractStartDate)
					: [],

			// 1 인적사항 및 월급
			totalMonthWageAmount: cTotalMonthWageAmount, //월급 총액
			retiredDate: encryptoClientReq4In?.retiredDate
				? encryptoClientReq4In?.retiredDate
				: '',
			deductWage: deductWage, // 실수령액

			isDayWorker: isDayWorker,
			isEtcWorker: isEtcWorker,
			isTimeWorker: isTimeWorker,
			isExceptTimeLawWorker: isExceptTimeLawWorker,
			sumOneWeekLBDWTETL: sumOneWeekLBDWTETL,
			fixedMonthRealWorkingHoursETL: fixedMonthRealWorkingHoursETL,
			sumOneWeekNWTETL: sumOneWeekNWTETL,
			fixedMonthRealNightWorkingHoursETL: fixedMonthRealNightWorkingHoursETL,
			expectYearly7thWorkDayCounts: expectYearly7thWorkDayCounts,
			expectWeeklyNightETRWorks: expectWeeklyNightETRWorks,
			expectWeeklyOverETRWorks: expectWeeklyOverETRWorks,

			annualLeavesIncludeOrNot: clientReq4In?.annualLeavesIncludeOrNot || '',
			annualLeavesIncludeMonthWageTime: annualLeavesIncludeMonthWageTime,
			annualLeavesIncludeMonthWage: annualLeavesIncludeMonthWage,
			overFiveStartDay: overFiveStartDay || '',

			agentName: encryptoClientReq4In?.agentName || agentName || '',
			agentType: encryptoClientReq4In?.agentType || agentType || '',
			agentUid: encryptoClientReq4In?.agentUid || agentUid || '',
			agentWorkerName:
				encryptoClientReq4In?.agentWorkerName || agentWorkerName || '',
			managerCode: encryptoClientReq4In?.managerCode || managerCode || '',
			insureManageType:
				encryptoClientReq4In?.insureManageType || insureManageType || '',

			//2. 근로시간(52시간 판단용 실근무시간)
			monthTotalT: roundUp2(monthTotalT), //1달 총 실근무시간(주휴,야간제외)
			monthOWT: roundUp2(monthOWT), //월간 연장시간(환산 전)
			monthNWT: roundUp2(monthNWT), //월 야간시간(환산 전)
			monthHoliBasicTime: roundUp2(monthHoliBasicTime), //월 휴일8시간(이하) 기본(환산 전)
			monthOWTHoli: roundUp2(monthOWTHoli), //월 휴일연장(환산 전)
			monthNOWTHoli: roundUp2(monthOWTHoli), //월 휴일연장(환산 전)

			monthNWTHoli: roundUp2(monthNWTHoli), //월 휴일야간(환산전)
			monthPaidHolidayLBTime: roundUp2(monthPaidHolidayLBTime), // 1달 주휴시간

			//3. 유급근무시간(월급계산용)
			monthLBTime: roundUp2(monthLBTime), //1달 통상임금 산정기준 시간수roundUp2(속칭 월 소정근로시간)
			converseMonthOWT: roundUp2(converseMonthOWT), //월간 연장 환산시간
			converseMonthNWT: roundUp2(converseMonthNWT), //월간 야간 환산시간
			converseMonthHoliBasicTime: roundUp2(converseMonthHoliBasicTime), //월간 휴일기본 환산시간
			conversemonthOWTHoli: roundUp2(conversemonthOWTHoli), //월간 휴일연장 환산시간
			converseMonthNWTHoli: roundUp2(converseMonthNWTHoli), //월간 휴일야간 환산시간
			mTPCTime: roundUp2(mTPCTime), //1달 총 유급시간(5인미만은 기본급 시간)

			// 4. 기본급 및 법정수당
			monthBasicWage: monthBasicWage, //월 기본급
			mOWage: mOWage, //월 연장근로 수당
			mNWage: mNWage, //월 야간근로 수당
			mHBWage: mHBWage, //월 휴일근로 기본 수당
			mHOWage: mHOWage, //월 휴일 연장근로수당,
			mHNWage: mHNWage, //월 휴일 야간근로 수당
			sumOfMonthONHWages: sumOfMonthONHWages, //월 법정수당 합계

			//5. 통상임금 수당
			cOWTitle: cOWTitle, //직책수당
			cOWJob: cOWJob, //직무수당
			cOWLicense: cOWLicense, //자격수당
			cOWLongTerm: cOWLongTerm, //장기근속수당
			cOWMonthlyBonus: cOWMonthlyBonus, //월상여금
			cOWEtc: cOWEtc, //기타 통상임금 소계
			cOWYearlyBonus: cOWYearlyBonus, //연간상여금

			//어린이집
			cOWChildManageStudyComment: cOWChildManageStudyComment, //육아수당, 연구수당
			cOWChildStudyComment: cOWChildStudyComment, //육아수당, 연구수당
			cOWChildCareComment: cOWChildCareComment, //육아수당, 연구수당
			cOWChildActivityComment: cOWChildActivityComment, //육아수당, 연구수당
			// 11월24일-공통
			//5-1. 일당 통상임금
			cOWDay1: cOWDay1,
			cOWDay2: cOWDay2,
			cOWDay3: cOWDay3,
			sumOfCOWDay: sumOfCOWDay, //일당 소계
			cOWDayHourWage: cOWDayHourWage, //일당 통상임금(일당소계/1일소정근로시간)
			// ------

			cTFMealWage: cTFMealWage, //식대
			cTFCarWage: cTFCarWage, //차량유지비
			cTFChildWage: cTFChildWage, //육아수당
			cTFStudyWage: cTFStudyWage, //연구수당
			cTFEtc: cTFEtc, //기타 통상임금 소계

			//6. 평균임금 수당
			cAWage1: cAWage1,
			cAWage2: cAWage2,
			cAWage3: cAWage3,
			cAWage4: cAWage4,
			cAWageTF1: cAWageTF1,
			cAWageTF2: cAWageTF2,
			cAWageTF3: cAWageTF3,
			cAWageTF4: cAWageTF4,

			//7. 공제액
			calTax: calTax === undefined ? 0 : calTax, //근로소득세
			calRegionTax: calRegionTax, // 지방소득세
			calPension: calPension, // 국민연금
			calHealthCare: calHealthCare, // 건강보험
			calOldCare: calOldCare, //노령연금
			calNoJobInsurance: calNoJobInsurance, //고용보험
			dedectTaxEtc: dedectTaxEtc, //기타 공제금

			healthCareText: healthCareTextOption,
			oldCareText: oldCareTextOption,
			pensionText: pensionTextOption,
			teacherPensionText: teacherPensionTextOption, //사학연금

			noJobText: noJobTextOption,

			hourOrdinaryWage: hourOrdinaryWage, //통상시급
			hourOrdinaryWageMonth: hourOrdinaryWageMonth, //통상시급 월급
			calHourLowestWage: calHourLowestWage, //최저임금 시급
			numOfWorkers: numOfWorkers, //전체 직원수
			upDatedDate: serverTimestamp(),
			upDatedDateArray: arrayUnion(new Date()),

			gender: gender,
			netAge: netAge,
			netAgeEntered: netAgeEntered,
			netAge15To18: netAge15To18 || '',
			youthSupportFundPerson: youthSupportFundPerson || '',
			covid19SupportFundPerson: covid19SupportFundPerson,
			replacedWorkerFundPerson: replacedWorkerFundPerson,
			childSupportFundPerson: childSupportFundPerson,

			companyName: companyName,
			companyBizNumber: companyBizNumber,
			companyAddress: companyAddress,
			ceoName: ceoName,
			companyBizItem: companyBizItem, // 종목

			annualLeaves: annualLeaves,
			finBaseDate: finBaseDate || null,
			normalWorkerWeekWDs: normalWorkerWeekWDs, //통상근로자 소정근무일수
			// lastYearNetWage: lastYearNetWage,
			dayWorker: isDayWorker
				? {
						daylyPay: daylyPay, // 일당
						timeWage: daylyTimePay || '',
						paidOffDays: daylyPaidOffDays || '',
						workingTimeDayTotal: workingTimeDayTotal, //1일 전체 근로시간
						workingTimeDayNight: workingTimeDayNight, //1일 순 야간
						workingTimeDayOver: workingTimeDayOver, //1일 순 연장
						workingTimeDayBasic: workingTimeDayBasic, // 1일 기본시간
						dayWorkerPaidHolidayLBTime: dayWorkerPaidHolidayLBTime,
						dayTotalPaidConversedTime: dayTotalPaidConversedTime, //1일 환산시간
						realTimeWage: realTimeWage, //시급
						insufficientDayWage: insufficientDayWage, //최저임금 부족 일당
						restViolationDay: restViolationDay, //휴게시간 판단
				  }
				: null,
			lawBase: !isDayWorker
				? {
						judge52: judge52, //52시간 판단
						judgeLowestWage: judgeLowestWageResult, //최저임금판단
						restViolation1: restViolation1, //휴게시간 판단
						restViolation2: restViolation2, //휴게시간 판단
						restViolation3: restViolation3, //휴게시간 판단
						restViolation4: restViolation4, //휴게시간 판단
						restViolation5: restViolation5, //휴게시간 판단
						restViolation6: restViolation6, //휴게시간 판단
						restTotalViolation: restTotalViolation, //휴게시간 판단
						insufficientWage: insufficientWageResult, //최저임금 부족액
						paidHolidayLBTime: paidHolidayLBTime, //1일 소정근로시간(주휴근거)
						weekLBTime: weekLBTime, // 1주 소정근로시간
						monthLBTime: monthLBTime, //1달 통상임금 산정기준 시간수(속칭 월 소정근로시간)
						hourOrdinaryWage: hourOrdinaryWage, //통상시급
						hourOrdinaryWageMonth: hourOrdinaryWageMonth, //통상시급 월급
						calTotalMonthLowestWage: calTotalMonthLowestWage, //최저임금 월급
						calHourLowestWage: calHourLowestWage, //최저임금 시급
				  }
				: null,
			realWorkTime: !isDayWorker
				? {
						sumOneWeekWT: sumOneWeekWT, //1주 전체 실근로시간(환산전)
						daysWT1: daysWT1, //첫번째 근로시간이 같은 요일의 1일 실근로시간
						daysWT2: daysWT2, //두번째 근로시간이 같은 요일의 1일 실근로시간
						daysWT3: daysWT3, //세번째 근로시간이 같은 요일의 1일 실근로시간
						daysWT4: daysWT4, //네번째 근로시간이 같은 요일의 1일 실근로시간
						daysWT5: daysWT5, //다섯번째 근로시간이 같은 요일의 1일 실근로시간
						daysWT6: daysWT6, //여섯번째 근로시간이 같은 요일의 1일 실근로시간

						days1Length: days1Length, //입력된 첫번째날 근무일 갯수
						days2Length: days2Length, //입력된 두번째날 근무일 갯수
						days3Length: days3Length, //입력된 세번째날 근무일 갯수
						days4Length: days4Length, //입력된 네번째날 근무일 갯수
						days5Length: days5Length, //입력된 다섯번째날 근무일 갯수
						days6Length: days6Length, //입력된 여섯번째날 근무일 갯수

						daysWTETL1: daysWTETL1, //첫번째 근로시간이 같은 요일의 1일 실근로시간
						daysWTETL2: daysWTETL2, //두번째 근로시간이 같은 요일의 1일 실근로시간
						daysWTETL3: daysWTETL3, //세번째 근로시간이 같은 요일의 1일 실근로시간
						daysWTETL4: daysWTETL4, //네번째 근로시간이 같은 요일의 1일 실근로시간
						daysWTETL5: daysWTETL5, //다섯번째 근로시간이 같은 요일의 1일 실근로시간
						daysWTETL6: daysWTETL6, //여섯번째 근로시간이 같은 요일의 1일 실근로시간
						daysWTETL7: daysWTETL7, //여섯번째 근로시간이 같은 요일의 1일 실근로시간

						daysETL1Length: daysETL1Length, //입력된 첫번째날 근무일 갯수
						daysETL2Length: daysETL2Length, //입력된 두번째날 근무일 갯수
						daysETL3Length: daysETL3Length, //입력된 세번째날 근무일 갯수
						daysETL4Length: daysETL4Length, //입력된 네번째날 근무일 갯수
						daysETL5Length: daysETL5Length, //입력된 다섯번째날 근무일 갯수
						daysETL6Length: daysETL6Length, //입력된 여섯번째날 근무일 갯수
						expectWeeklyOverETRWorks: expectWeeklyOverETRWorks,
						expectWeeklyNightETRWorks: expectWeeklyNightETRWorks,
						sumOneWeekLBDWTETL: sumOneWeekLBDWTETL,
						fixedMonthRealWorkingHoursETL: fixedMonthRealWorkingHoursETL,
						sumOneWeekNWTETL: sumOneWeekNWTETL,
						fixedMonthRealNightWorkingHoursETL:
							fixedMonthRealNightWorkingHoursETL,

						days1LBTime: days1LBTime, //첫번째 근로시간이 같은 요일 소정근로시간(일용직용)
						days2LBTime: daysWT2
							? days2LBTime(days1LBTime, days1Length, daysWT2, netAge15To18)
							: '', //두번째 근로시간이 같은 요일 소정근로시간
						days3LBTime: daysWT3
							? days3LBTime(
									days1LBTime,
									days1Length,
									daysWT2,
									days2Length,
									daysWT3,
									netAge15To18
							  )
							: '', //세번째 근로시간이 같은 요일 소정근로시간
						days4LBTime: daysWT4
							? days4LBTime(
									days1LBTime,
									days1Length,
									daysWT2,
									days2Length,
									daysWT3,
									days3Length,
									daysWT4,
									netAge15To18
							  )
							: '', //네번째 근로시간이 같은 요일 소정근로시간
						days5LBTime: daysWT5
							? days5LBTime(
									days1LBTime,
									days1Length,
									daysWT2,
									days2Length,
									daysWT3,
									days3Length,
									daysWT4,
									days4Length,
									daysWT5,
									netAge15To18
							  )
							: '', //다섯번째 근로시간이 같은 요일 소정근로시간
						days6LBTime: daysWT6
							? days6LBTime(
									days1LBTime,
									days1Length,
									daysWT2,
									days2Length,
									daysWT3,
									days3Length,
									daysWT4,
									days4Length,
									daysWT5,
									days5Length,
									daysWT6,
									netAge15To18
							  )
							: '', //여섯번째 근로시간이 같은 요일 소정근로시간

						oneWeekworkdaysCheck: oneWeekworkdaysCheck, //1주 근무일수 합계
						oneWeekworkdaysCheckETLWorker: oneWeekworkdaysCheckETLWorker,

						sumDaysOWT: sumDaysOWT, //1일 8시간 초과 연장 1주 합계(환산 전)
						expectWeeklyOverWorks: expectWeeklyOverWorks, //포괄 불특정 1주 연장
						weekBaseOWT: weekBaseOWT, //1주 40시간 기준 1주 연장시간(환산 전)
						finalWeekOWT: finalWeekOWT, //1주 최총 연장시간(max(8.VS.40))(환산 전)
						monthOWT: monthOWT, //월간 연장시간(환산 전)
						expectWeeklyNightWorks: expectWeeklyNightWorks, //1주 포괄 불특정 야간
						sumOneWeekNWT: sumOneWeekNWT, //1주 야간시간(환산 전)
						monthNWT: monthNWT, //월 야간시간(환산 전)
						monthHoliBasicTime: monthHoliBasicTime, //월 휴일8시간(이하) 기본(환산 전)
						monthOWTHoli: monthOWTHoli, //월 휴일연장(환산 전)
						monthNOWTHoli: monthOWTHoli, //월 휴일연장(환산 전)(monthOWTHoli 같은 놈)

						monthNWTHoli: monthNWTHoli, //월 휴일야간(환산전)
						monthHoliWD: monthHoliWD, //월간 휴일근로 일수
						numOfYearlyHoliWD: numOfYearlyHoliWD, //년간휴일근로일수
						daysWTHoli: daysWTHoli, // 1일 휴일 총 근무시간
						weekWTHoli: weekWTHoli, // 1주 평균 휴일 실근무시간
						daysHoliBasicTime: daysHoliBasicTime, //1일 휴일근로기본시간
						daysNOWTHoli: daysNOWTHoli, //1일 휴일연장시간
						daysNWTHoli: daysNWTHoli, //1일 휴일근로 야간시간
				  }
				: null,
			calWorkTime: !isDayWorker
				? {
						converseMonthOWT: converseMonthOWT, //월간 연장 환산시간
						converseMonthNWT: converseMonthNWT, //월간 야간 환산시간
						converseMonthHoliBasicTime: converseMonthHoliBasicTime, //월간 휴일기본 환산시간
						conversemonthOWTHoli: conversemonthOWTHoli, //월간 휴일연장 환산시간
						converseMonthNWTHoli: converseMonthNWTHoli, //월간 휴일야간 환산시간
						mTPCTime: mTPCTime, //1달 총 유급시간(5인미만은 기본급 시간)
				  }
				: null,
			calWorkWage: !isDayWorker
				? {
						monthBasicWage: monthBasicWage, //월 기본급
						mOWage: mOWage, //월 연장근로 수당
						mNWage: mNWage, //월 야간근로 수당
						mHBWage: mHBWage, //월 휴일근로 기본 수당
						mHOWage: mHOWage, //월 휴일 연장근로수당,
						mHNWage: mHNWage, //월 휴일 야간근로 수당
						sumOfMonthONHWages: sumOfMonthONHWages, //월 법정수당 합계
						sumOfAWages: sumOfAWages, //입력된 월 평균임금 합계(평균 일반 + 비과세.)-법정수당제외
						tWMinusTAW: tWMinusTAW, // 월 유급시간 계산임금(입력된 전체월급 - 평균임금수당)즉, 법정가산임금은 포함된 금액
						sumOfTFWages: sumOfTFWages, //월 비과세 수당합계(통상+평균 모두)
						taxWageSum: taxWageSum, //월 과세임금
						monthOrdinaryWage: monthOrdinaryWage, //월 통상임금(월 유급시간 계산입금-법정수당합계)
				  }
				: null,
			calTaxAnd4In: !isDayWorker
				? {
						calTax: calTax === undefined ? 0 : calTax, //근로소득세
						calRegionTax: calRegionTax, //지방소득세
						calPension: calPension, //국민연금
						calHealthCare: calHealthCare, //건강보험
						calOldCare: calOldCare, // 요양보험
						calNoJobInsurance: calNoJobInsurance, //실업급여
						calTeacherPension: calTeacherPension, //사학연금
						dedectTaxEtc: dedectTaxEtc, //공제계
						deductWage: deductWage, //실수령액
						// overTimeTaxFree: clientReq4In?.lastYearNetWage ? overTimeTaxFree() : null, //생산직등 비과세
				  }
				: null,
		});

		// 추가된 문서의 ID를 얻음
		const updatedDocumentId = id;
		return updatedDocumentId;
	} catch (error) {
		console.error('Error updating document:', error);
	}
	// 함수가 문서 ID를 반환하도록 할 수도 있음
}

export async function updateInsureInReportInFirestore(values) {
	return await updateDoc(doc(db, 'workers', values.workerId), values);
}

export async function updateInsureOutReportInFirestore(values) {
	return await updateDoc(doc(db, 'workers', values.workerId), values);
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Firestore에서 문서 삭제를 담당하는 함수
export function deletePaydocuInFirestore(paydocuId) {
	return deleteDoc(doc(db, 'payDocu', paydocuId)).catch((error) => {
		console.error('Error deleting document:', error);
		throw error; // 에러를 다시 던져서 상위 호출자에서 처리할 수 있도록 함
	});
}

export function deleteViewUserInFirestore(id) {
	const currentDate = new Date().toISOString();
	return updateDoc(doc(db, 'users', id), {
		deletedView: true,
		deletedAt: currentDate,
		authLevel: 5,
	}).catch((error) => {
		console.error('Error updating document:', error);
		throw error; // 에러를 다시 던져서 상위 호출자에서 처리할 수 있도록 함
	});
}

export function againViewUserInFirestore(id) {
	const currentDate = new Date().toISOString();
	return updateDoc(doc(db, 'users', id), {
		deletedView: false,
		deletedAt: '',
		reLivedAt: currentDate,
		serviceStartDate: currentDate,
	}).catch((error) => {
		console.error('Error updating document:', error);
		throw error; // 에러를 다시 던져서 상위 호출자에서 처리할 수 있도록 함
	});
}

//개별 근로자 불러오기
export function listenToPaydocuFromFirestore(paydocuId) {
	return doc(db, 'payDocu', paydocuId);
}
// --------

//임금명세서 교부확인
export function clickConfirmTiemOfWorker(paydocuId, isWorkerComformedTime) {
	return updateDoc(doc(db, 'payDocu', paydocuId), {
		isWorkerComformedTime: isWorkerComformedTime
			? isWorkerComformedTime
			: serverTimestamp(),
		isWorkerComformedTimeArray: arrayUnion(new Date()),
	});
}

// 임금명세서 급여 개별 확정 및 해제
export async function clickConfirmTimeOfCompany(
	paydocuId,
	isCompanyComformedTime
) {
	try {
		const docRef = doc(db, 'payDocu', paydocuId);
		await updateDoc(docRef, {
			isCompanyComformedTime: !isCompanyComformedTime,
			isCompanyComformedTimeArray: arrayUnion(new Date().toISOString()),
		});
	} catch (error) {
		console.error(`Failed to update document ${paydocuId}:`, error);
		throw error; // 에러를 상위 함수에서 처리할 수 있도록 throw
	}
}

// 임금명세서 급여 전체 확정 및 해제
export async function clickAllConfirmTimeOfCompany(paydocuId, allConfirmed) {
	try {
		const docRef = doc(db, 'payDocu', paydocuId);
		const timestamp = new Date().toISOString();

		await updateDoc(docRef, {
			// 전체 승인 시 최종 승인 시간 업데이트
			isCompanyComformedTime: allConfirmed ? timestamp : '',
			// 승인 시 배열에 새 타임스탬프 추가 (기존 데이터 유지)
			...(allConfirmed && {
				isCompanyComformedTimeArray: arrayUnion(timestamp),
			}),
		});
	} catch (error) {
		console.error(`Failed to update document ${paydocuId}:`, error);
		throw error;
	}
}

// 이메일 발송 상태 업데이트
export const markEmailAsSent = async (documentId, storageName) => {
	try {
		let collectionName;
		let fieldName;

		if (storageName === 'paydocu') {
			collectionName = 'payDocu';
			fieldName = 'isEmailSentArray';
		} else if (storageName === 'laborContract') {
			collectionName = 'workers';
			fieldName = 'isLaborContractEmailSentArray';
		} else if (storageName === 'wageContract') {
			// 임금계약서의 경우, 컬렉션은 'workers' (또는 필요에 따라 다른 이름)이고
			// 필드는 'isWageContractEmailSentArray'로 업데이트합니다.
			collectionName = 'workers';
			fieldName = 'isWageContractEmailSentArray';
		}

		const docRef = doc(db, collectionName, documentId);
		const timestamp = new Date().toISOString(); // 현재 시간을 타임스탬프로 저장

		await updateDoc(docRef, {
			[fieldName]: arrayUnion(timestamp), // 동적 필드명 사용
		});
	} catch (error) {
		console.error(`🚨 ${documentId}의 이메일 발송 상태 업데이트 실패:`, error);
	}
};
//근로계약서 교부확인
export function clickConfirmedTimeOfLaborContract(workerId) {
	return updateDoc(doc(db, 'workers', workerId), {
		confirmedTimeOfLaborContract: arrayUnion(new Date()),
	});
}
//임금계약서 교부확인
export function clickConfirmedTimeOfWageContract(workerId) {
	return updateDoc(doc(db, 'workers', workerId), {
		confirmedTimeOfWageContract: arrayUnion(new Date()),
	});
}

export function deleteClientReq4InInFirestore(clientReq4InId) {
	return deleteDoc(doc(db, 'workers', clientReq4InId));
}

//급여테이블에 있는 근로자 1명 삭제
export function deleteWageTableInInFirestore(wageTableId) {
	return deleteDoc(doc(db, 'wageTables', wageTableId));
}

export async function addPrintCount(workerId) {
	const user = auth.currentUser;

	const workerRef = doc(db, 'workers', workerId);
	const workerDoc = await getDoc(workerRef);
	const workerData = workerDoc.data();
	const workerName = workerData?.worker.name;
	const enterDate = workerData?.workerEnteredDate;
	const contractEndDate = workerData?.contractEndDate;
	const dayWorker = workerData?.dayWorker;
	const wageContractStartDate = workerData?.wageContractStartDate;

	const companyRef = doc(db, 'users', user.uid);
	const companyDoc = await getDoc(companyRef);
	const companyName = companyDoc.data().companyName;

	return await addDoc(collection(db, 'users', user.uid, 'printContract'), {
		hostUid: user.uid,
		printDate: serverTimestamp(),
		companyName,
		workerName,
		enterDate,
		contractEndDate: contractEndDate || null,
		dayWorker: dayWorker || null,
		wageContractStartDate: wageContractStartDate || null,
	});
}

//약관동의
export async function addUserContractAgreementToFirestore() {
	const user = auth?.currentUser;

	const companyRef = doc(db, 'users', user?.uid);
	const companyDoc = await getDoc(companyRef);
	const companyName = companyDoc.data()?.companyName;

	return await addDoc(
		collection(db, 'users', user?.uid, 'userContractAgreement'),
		{
			hostUid: user?.uid,
			agreementType: 'userContractAgreement',
			agreementDate: serverTimestamp(),
			companyName,
		}
	);
}

//개인정보 활용동의
export async function addUserPrivateAgreementToFirestore() {
	const user = auth?.currentUser;

	const companyRef = doc(db, 'users', user?.uid);
	const companyDoc = await getDoc(companyRef);
	const companyName = companyDoc.data()?.companyName;

	return await addDoc(
		collection(db, 'users', user?.uid, 'userPrivateAgreement'),
		{
			hostUid: user?.uid,
			agreementType: 'PrivateAgreement',
			agreementDate: serverTimestamp(),
			companyName,
		}
	);
}

//전산이용계약출력정보
export async function addProgramUseContractPrimtToFirestore() {
	const user = auth?.currentUser;

	const companyRef = doc(db, 'users', user?.uid);
	const companyDoc = await getDoc(companyRef);
	const companyName = companyDoc.data()?.companyName;

	return await addDoc(
		collection(db, 'users', user?.uid, 'programUseContractPrint'),
		{
			hostUid: user?.uid,
			agreementType: 'ProgramUseContractPrint',
			agreementDate: serverTimestamp(),
			companyName,
		}
	);
}

//고용안정지원금출력정보
export async function addSupportFundContractPrintToFirestore() {
	const user = auth.currentUser;

	const companyRef = doc(db, 'users', user.uid);
	const companyDoc = await getDoc(companyRef);
	const companyName = companyDoc.data().companyName;

	return await addDoc(
		collection(db, 'users', user.uid, 'supportFundContractPrint'),
		{
			hostUid: user.uid,
			agreementType: 'SupportFundContractPrint',
			agreementDate: serverTimestamp(),
			companyName,
		}
	);
}

//회사데이터
export async function fetchCompaniesFromFirestore() {
	const q = query(collection(db, 'users'), orderBy('createdAt', 'desc'));
	const snapshot = await getDocs(q);
	return snapshot;
}

//퇴사자 4대보험 신고 데이터
export async function fetchInsureOutFromFirestore() {
	const q = query(
		collection(db, 'workers'),
		where('insureManageType', 'in', [
			'4대보험 노무사 위탁관리',
			'4대보험 최상인업노무법인 위탁관리',
		]),
		where('retireInfo.isInsureOutRequest', '==', true)
	);
	const snapshot = await getDocs(q);
	return snapshot;
}

//퇴사자 4대보험 신고 데이터
export async function fetchInsureInFromFirestore() {
	const q = query(
		collection(db, 'workers'),
		where('insureManageType', 'in', [
			'4대보험 노무사 위탁관리',
			'4대보험 최상인업노무법인 위탁관리',
		]),
		where('isInsureInRequest', '==', true)
	);
	const snapshot = await getDocs(q);
	return snapshot;
}

//퇴사자 4대보험(개별회사 신고용) 신고 데이터
export async function fetchInsureOutCompanyFromFirestore(companyId) {
	const q = query(
		collection(db, 'workers'),
		where('retireInfo.isInsureOutRequest', '==', true),
		where('hostUid', '==', companyId)
	);
	const snapshot = await getDocs(q);
	return snapshot;
}

//퇴사자 4대보험 신고 데이터
export async function fetchInsureInCompanyFromFirestore(companyId) {
	const q = query(
		collection(db, 'workers'),
		where('isInsureInRequest', '==', true),
		where('hostUid', '==', companyId)
	);
	const snapshot = await getDocs(q);
	return snapshot;
}

//퇴사자 4대보험 신고 데이터
export async function fetchCompetencyFromFirestore() {
	const q = query(collection(db, 'monthlyCompetencyReports'));
	const snapshot = await getDocs(q);
	return snapshot;
}

//회사전산이용프린트
export function fetchCompanyProgramUseCPFromFirestore() {
	const user = auth.currentUser;
	return collection(db, 'users', user.id, 'programUseContractPrint');
}

//회사약관동의 데이터
export function fetchCompanyAgreementsFromFirestore() {
	const user = auth.currentUser;
	return collection(db, 'users', user.id, 'agreement');
}

export function deleteCompanyInFirestore(userId) {
	return deleteDoc(doc(db, 'users', userId));
}

// 결제 종류(기본, 고급, 맞춤) 생성

export async function addPaymentsInfoToFirestore(paymentsInfo) {
	const user = auth.currentUser;
	const authLevel = payedAuthLevel(
		paymentsInfo.price,
		paymentsInfo.numOfWorkers
	);
	return await addDoc(collection(db, 'paymentsBasicInfo'), {
		...paymentsInfo,
		authLevel: authLevel,
		hostUid: user.uid,
		paymentsDate: serverTimestamp(),
	});
}

//회사데이터
export function fetchPaymentsInfoFromFirestore() {
	return collection(db, 'paymentsBasicInfo');
}

// 결제 종류(custom5ey, authKey) 생성
export async function updatePaymentsInfoToFirestore(paymentsInfo) {
	try {
		await addDoc(collection(db, 'paymentsInfo'), {
			...paymentsInfo,
			paymentsUpdateDate: serverTimestamp(),
		});
	} catch (e) {
		console.error('Error adding document: ', e);
	}
}

// 결제 정보 불러오기
export function listenToPaymentsInfoFromFirestore(paymentsInfoId) {
	return doc(db, 'paymentsInfo', paymentsInfoId);
}

export function getMonthlyReport(reportId) {
	return doc(db, 'monthlyReports', reportId);
}
export function getCompetenciesQuery(activeTab) {
	let competenciesRef = collection(db, 'monthlyReports');
	switch (activeTab) {
		case 1: //past events
			return query(competenciesRef, where('toCustomer', '==', true));
		case 2: //hosting
			return query(
				competenciesRef,
				where('leaveAnnualLawBasePaid1', '==', true)
			);
		default:
			return competenciesRef;
	}
}

export async function fetchMonthlyCompetencyReportsFromFirestore(
	filter,
	yearMonth
) {
	const user = auth.currentUser;

	if (!user) {
		console.error('User not found');
		return;
	}
	let reportsRef = collection(db, 'monthlyCompetencyReports');

	try {
		let queryRef;
		switch (filter) {
			case 'isWorker': // 근로자
				queryRef = query(reportsRef, where('hostUid', '==', user.uid));
				break;

			case 'isAgent': // 대리인
				queryRef = query(
					reportsRef,
					where('agentUid', '==', user.uid),
					where('yearMonth', '==', yearMonth)
				);
				break;

			case 'isCompany': // 회사
				queryRef = query(
					reportsRef,
					where('companyId', '==', user.uid),
					where('yearMonth', '==', yearMonth)
				);
				break;

			default:
				// 필터가 일치하는 케이스가 없을 때 기본 쿼리
				queryRef = query(
					reportsRef,
					where('companyName', '==', filter),
					where('yearMonth', '==', yearMonth)
				);
		}

		const querySnapshot = await getDocs(queryRef); // getDocs를 사용하여 쿼리 스냅샷을 가져옵니다.
		const docs = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));
		return docs; // 결과를 반환합니다.
	} catch (error) {
		console.error('Error fetching reports:', error);
		throw error;
	}
}
// 해당월 역량모델링 삭제
export function deleteMonthlyCompetencyReportsInFirestore(
	monthlyCompetencyReportsId
) {
	return deleteDoc(
		doc(db, 'monthlyCompetencyReports', monthlyCompetencyReportsId)
	);
}

export async function addMonthlyCompetencyReportsToFirestore(
	values,
	companyId,
	workerId,
	weekNo,
	monthNo
) {
	const companyRef = doc(db, 'users', companyId);
	const companyDoc = await getDoc(companyRef);
	const companyData = companyDoc.data();

	const user = auth.currentUser;
	const userRef = doc(db, 'users', user.uid);
	const userDoc = await getDoc(userRef);
	const userData = userDoc.data();

	const workerInfoRef = doc(db, 'workers', workerId);
	const workerInfoDoc = await getDoc(workerInfoRef);
	const workerData = workerInfoDoc.data();

	const year = format(new Date(), 'yyyy');
	const month = format(new Date(), 'MM');

	const reportData = {
		...values,
		hostUid: user.uid,
		companyId: companyId,
		finBaseDate: companyData?.finBaseDate ?? null,
		annualLeaves: companyData?.annualLeaves ?? '입사일기준',
		companyName: companyData?.companyName ?? null,
		companyAddress: companyData?.companyAddress ?? null,
		companyRegion: companyData?.companyRegion ?? null,
		companyBizItem: companyData?.bizItem ?? null,
		numOfWorkers: companyData?.numOfWorkers ?? 5,
		ceoName: companyData?.ceoName ?? null,
		agentName: companyData?.agentName ?? null,
		agentUid: companyData?.agentUid ?? null,
		agentType: companyData?.agentType ?? null,
		workerId: workerId,
		paydocuWorkerUid: userData?.paydocuWorkerUid ?? null,
		workerName: userData?.workerName ?? companyData?.companyName ?? null,
		isWorker: userData?.isWorker ?? null,
		totalMonthWageAmount: workerData?.totalMonthWageAmount ?? null,
		calHourLowestWage: workerData?.calHourLowestWage ?? null,
		mTPCTime: workerData?.mTPCTime ?? null,
		workField: workerData?.workField ?? null,
		workDetailField: workerData?.workDetailField ?? null,
		workerDivisions: workerData?.workerDivisions ?? null,
		workerEnteredDate: workerData?.workerEnteredDate ?? null,
		workerTitles: workerData?.workerTitles ?? null,
		netAge: workerData?.netAge ?? null,
		gender: workerData?.gender ?? null,
		addDate: serverTimestamp(),
		year: year,
		month: month,
		yearMonth: year + month,
		weekNo: weekNo,
		monthNo: monthNo,
	};

	await addDoc(collection(db, 'monthlyCompetencyReports'), reportData);
}

export async function updateMonthlyCompetencyReportsInFirestore(values) {
	const monthlyCompetencyReportsRef = doc(
		db,
		'monthlyCompetencyReports',
		values.id
	);

	await updateDoc(monthlyCompetencyReportsRef, {
		...values,
		upDatedDate: serverTimestamp(),
	});
}

export function listenToMonthlyCompetencyReportsFromFirestore(valueId) {
	return doc(db, 'monthlyCompetencyReports', valueId);
}

export function listenToRiskSuspectReportsFromFirestore(valueId) {
	return doc(db, 'riskSuspectReports', valueId);
}
export async function addRiskSuspectReportsToFirestore(
	values,
	companyId,
	workerId
) {
	const companyRef = doc(db, 'users', companyId);
	const companySnap = await getDoc(companyRef);
	const companyData = companySnap.data();

	const user = auth.currentUser;
	const userRef = doc(db, 'users', user.uid);
	const userSnap = await getDoc(userRef);
	const userData = userSnap.data();

	const workerInfoRef = doc(db, 'workers', workerId);
	const workerInfoSnap = await getDoc(workerInfoRef);
	const workerInfoData = workerInfoSnap.data();

	const year = format(new Date(), 'yyyy');
	const month = format(new Date(), 'MM');

	const riskSuspectReportsRef = collection(db, 'riskSuspectReports');

	return await addDoc(riskSuspectReportsRef, {
		...values,
		hostUid: user.uid,

		companyId: companyId,
		finBaseDate: companyData?.finBaseDate || null,
		annualLeaves: companyData?.annualLeaves || '입사일기준',
		companyName: companyData?.companyName || null,
		companyAddress: companyData?.companyAddress || null,
		companyRegion: companyData?.companyRegion || null,
		companyBizItem: companyData?.bizItem || null,
		numOfWorkers: companyData?.numOfWorkers || 5,
		ceoName: companyData?.ceoName || null,
		agentName: companyData?.agentName || null,
		agentUid: companyData?.agentUid || null,
		agentType: companyData?.agentType || null,

		workerId: workerId,
		paydocuWorkerUid: userData?.paydocuWorkerUid || null,
		workerName: userData?.workerName || null,
		isWorker: userData?.isWorker || null,

		totalMonthWageAmount: workerInfoData?.totalMonthWageAmount || null,
		calHourLowestWage: workerInfoData?.calHourLowestWage || null,
		mTPCTime: workerInfoData?.mTPCTime || null,

		workField: workerInfoData?.workField || null,
		workDetailField: workerInfoData?.workDetailField || null,
		workerDivisions: workerInfoData?.workerDivisions || null,
		workerEnteredDate: workerInfoData?.workerEnteredDate || null,
		workerTitles: workerInfoData?.workerTitles || null,
		netAge: workerInfoData?.netAge || null,
		gender: workerInfoData?.gender || null,

		addDate: serverTimestamp(),
		year: year,
		month: month,
		yearMonth: year + month,
	});
}

export async function updateRiskSuspectReportsInFirestore(values) {
	return updateDoc(doc(db, 'riskSuspectReports', values.id), {
		...values,
		upDatedDate: serverTimestamp(),
	});
}

export async function fetchRiskSuspectReportsFromFirestore(filter, yearMonth) {
	const user = auth.currentUser;

	if (!user) {
		console.error('User not found');
		return;
	}

	let reportsRef = collection(db, 'riskSuspectReports');

	try {
		let queryRef;
		switch (filter) {
			case 'isWorker':
				queryRef = query(reportsRef, where('hostUid', '==', user?.uid));
				break;
			case 'isAgent':
				queryRef = query(
					reportsRef,
					where('agentUid', '==', user?.uid),
					where('yearMonth', '==', yearMonth)
				);
				break;
			case 'isCompany':
				queryRef = query(
					reportsRef,
					where('companyId', '==', user?.uid),
					where('yearMonth', '==', yearMonth)
				);
				break;
			default:
				queryRef = query(
					reportsRef,
					where('companyName', '==', filter),
					where('yearMonth', '==', yearMonth)
				);
		}
		const querySnapshot = await getDocs(queryRef); // getDocs를 사용하여 쿼리 스냅샷을 가져옵니다.
		const docs = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));
		return docs; // 결과를 반환합니다.
	} catch (error) {
		console.error('Error fetching reports:', error);
		throw error;
	}
}

export function deleteRiskSuspectReportsInFirestore(riskSuspectReportsId) {
	return deleteDoc(doc(db, 'riskSuspectReports', riskSuspectReportsId));
}

export function javascriptDate(data) {
	for (const prop in data) {
		if (data?.hasOwnProperty(prop)) {
			// Firebase Timestamp를 'yyyy-MM-DD' 형식으로 변환
			if (data[prop] instanceof Timestamp) {
				data[prop] = moment(new Date(data[prop].toDate().toString())).format(
					'yyyy-MM-DD'
				);
			}
			// 콤마를 포함한 문자열을 숫자로 변환
			else if (typeof data[prop] === 'string' && data[prop]?.includes(',')) {
				// 콤마 제거 후 숫자로 변환
				const numberValue = parseFloat(data[prop].replace(/,/g, ''));
				data[prop] = isNaN(numberValue) ? data[prop] : numberValue;
			}
		}
	}
	return {
		...data,
	};
}

export async function addRetiredInsureInfoToFirestore(workerId, retireInfo) {
	const workerRef = doc(db, 'workers', workerId);

	return await updateDoc(workerRef, {
		retireInfoSign: true,
		retireInfo: retireInfo,
	});
}

//직원 유저가 회사의 본인 데이터를 가져오는 함수
export async function fetchWorkerDataById(workerID) {
	const workersRef = collection(db, 'workers');
	const q = query(workersRef, where('id', '==', workerID));

	const querySnapshot = await getDocs(q);
	const workersData = [];
	querySnapshot.forEach((doc) => {
		workersData.push({ ...doc.data(), id: doc.id });
	});

	return workersData;
}

// safehr에서 사용하는 함수
// export async function fetchAgentCompaniesFromFirestore(agentId) {
// 	const q = query(
// 		collection(db, 'users'),
// 		where('agentUid', '==', agentId),
// 		orderBy('createdAt', 'desc')
// 	);
// 	const snapshot = await getDocs(q);
// 	return snapshot;
// }

// paydocus 연차 조회용
export async function fetchPaydocusAnnualLeavesFromFirestore(
	filter,
	paydocuWorkerUid,
	clientInfo,
	year
) {
	const user = auth.currentUser;
	const userUid = clientInfo?.id ? clientInfo?.id : user?.uid;

	// 공통 조건 초기화
	let payDocuQuery = null;

	try {
		if (filter === 'isHosting') {
			// 일반 회사 (회원사)
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('info.year', '==', year),
				where('workerInfo.workerId', '==', paydocuWorkerUid),
				where('companyInfo.companyId', '==', userUid)
			);
		} else if (filter === 'isWorker') {
			// 직원이 본인 아이디로 조회
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('workerInfo.workerId', '==', paydocuWorkerUid),
				orderBy('info.yearMonth', 'asc')
			);
		} else if (filter === 'isAgent') {
			// 노무사가 관리하는 회사
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('info.year', '==', year),
				where('companyInfo.agentUid', '==', user?.uid),
				where('companyInfo.companyId', '==', userUid),
				where('workerInfo.workerId', '==', paydocuWorkerUid)
			);
		} else {
			// 회사 이름이 필터로 들어온 경우
			// 회사 이름은 노무사(isAgent)나 관리자가 사용하는 필터
			if (filter && user?.uid) {
				payDocuQuery = query(
					collection(db, 'payDocu'),
					where('info.year', '==', year),
					where('companyInfo.companyId', '==', userUid),
					where('companyInfo.companyName', '==', filter),
					where('workerInfo.workerId', '==', paydocuWorkerUid)
				);
			} else {
				// 기본 조건: 직원 정보만으로 조회
				payDocuQuery = query(
					collection(db, 'payDocu'),
					where('workerInfo.workerId', '==', paydocuWorkerUid)
				);
			}
		}

		// Firestore 쿼리 실행
		const querySnapshot = await getDocs(payDocuQuery);
		return querySnapshot;
	} catch (error) {
		console.error('Error fetching paydocus:', error);
		throw error;
	}
}

//임금명세서 filteredList
export async function fetchPaydocusYearComFromFirestore(
	filter,
	year,
	clientInfo
) {
	const user = auth.currentUser;
	let payDocuQuery;
	const userUid = clientInfo?.id ? clientInfo?.id : user?.uid;

	switch (filter) {
		case 'isHosting': // 회원사
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('companyInfo.companyId', '==', user?.uid),
				where('info.year', '==', year),
				orderBy('workerInfo.worker', 'asc')
			);
			break;

		case 'isAgent': //
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('companyInfo.companyId', '==', userUid),
				where('info.year', '==', year),
				orderBy('workerInfo.worker', 'asc')
			);
			break;
		case filter: //
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('companyInfo.companyId', '==', userUid),
				where('info.year', '==', year),
				orderBy('workerInfo.worker', 'asc')
			);
			break;
		default: // 직원꺼
			payDocuQuery = query(
				collection(db, 'payDocu'),
				where('companyInfo.companyId', '==', userUid),
				where('info.year', '==', year)
			);
			break;
	}

	try {
		const querySnapshot = await getDocs(payDocuQuery);
		return querySnapshot;
	} catch (error) {
		console.error('Error fetching paydocus:', error);
		throw error;
	}
}
