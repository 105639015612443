import { format } from 'date-fns';
import Josa from 'josa-js';
import printJS from 'print-js';
import { taxTable2023 } from '../../api/taxTable2023';

import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { decrypto } from '../../firestore/firestoreService';
import { toast } from 'react-toastify';

export function monthPriceF(price) {
	return price === 'CS.Premium'
		? roundUp(premiumPrice(price))
		: price === 'CS.Basic'
		? roundUp(premiumPrice(price))
		: price === '전산.Premium'
		? roundUp(premiumPrice(price))
		: roundUp(basicPrice(price));
}

export function payTermDCRate(payTerm) {
	if (payTerm < 13) {
		return 1;
	} else if (payTerm < 25) {
		return 0.9;
	} else if (payTerm < 37) {
		return 0.85;
	} else if (payTerm >= 37) {
		return 0.8;
	}
}

// 기본 가격표
export const basicPrice = (price) => {
	if (price === '전산.Basic') {
		return 30000 * 1.1;
	} else if (price === '전산.Premium') {
		return 60000 * 1.1;
	} else {
		return 30000 * 1.1;
	}
};

// 고급형 가격표
export const premiumPrice = (price) => {
	if (price === 'CS.Basic') {
		return 60000 * 1.1;
	} else if (price === 'CS.Premium') {
		return 130000 * 1.1;
	} else {
		return 60000 * 1.1;
	}
};

export function payedAuthLevel(price) {
	if (price === '전산.Basic') {
		return 21;
	} else if (price === '전산.Premium') {
		return 24;
	} else if (price === 'CS.Basic') {
		return 22;
	} else if (price === 'CS.Premium') {
		return 25;
	}
}

export function customerDCRate(payTerm) {
	return (1 - payTermDCRate(payTerm)) * 100;
}

export function delay(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getFileExtension(filename) {
	return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
}

export function getType(data) {
	return Object.prototype.toString.call(data).slice(8, -1);
}

export function createDataTree(dataset) {
	let hashtable = Object.create(null);
	dataset.forEach((a) => (hashtable[a.id] = { ...a, childNodes: [] }));
	let dataTree = [];
	dataset.forEach((a) => {
		if (a.parentId) hashtable[a.parentId].childNodes.push(hashtable[a.id]);
		else dataTree.push(hashtable[a.id]);
	});
	return dataTree;
}

export function paydocuOwnerCompanyName() {
	return '노무법인 최상인업 세종지사';
}

export function contractOldWorkerF(
	contractOldWorker,
	afterRetireAgainContractEndDate,
	afterRetireAgainContractStartDate,
	netAge
) {
	if (
		contractOldWorker === '정년이후 촉탁계약직' &&
		(afterRetireAgainContractEndDate || afterRetireAgainContractStartDate) &&
		netAge >= 60
	) {
		return true;
	} else {
		return false;
	}
}

export function workerStatus(
	cEDate,
	weekLBTime,
	contractOldWorker,
	isExceptTimeLawWorker,
	netAge15To18
) {
	let result;
	if (!cEDate && weekLBTime < 40 && weekLBTime > 15 && !netAge15To18) {
		result = '';
	} else if (contractOldWorker) {
		result = '촉탁 계약직 ';
	} else if (!cEDate && weekLBTime < 15) {
		result = '초단시간 ';
	} else if (cEDate && weekLBTime < 15) {
		result = '초단시간 계약직 ';
	} else if (cEDate && weekLBTime < 40 && weekLBTime > 15) {
		result = '계약직 ';
	} else if (cEDate) {
		result = '계약직 ';
	} else if (isExceptTimeLawWorker) {
		result = '감단직 ';
	} else if (netAge15To18) {
		result = '연소근로자 ';
	} else {
		result = '정규직 ';
	}
	return result;
}

export function lowWageViolationComment() {
	return '최저임금 위반 가능 !!';
}

export function lowWageViolationDashboardComment() {
	return '최저임금 위반 가능 !!';
}

export function lowWageOKComment() {
	return '최저임금 OK!';
}

export function restViolationDashboardComment() {
	return '법정휴게시간 위반 가능 !!.';
}

export function restOKComment() {
	return '법정휴게시간 OK';
}

export function lowWageUnderWonComment() {
	return '원이 부족할 수 있습니다.';
}

export function week52OKComment() {
	return '주52시간 OK!';
}

export function week52ViolationComment() {
	return '주52시간제 위반 가능 !!.';
}

export function week52ViolationDashboardComment() {
	return '주52시간제 위반 가능 !!';
}

export function under5Comment() {
	return '5인미만 사업장은 주52시간제와 무관합니다.';
}

export function specialPermission52Comment() {
	return '주52시간제 특례 또는 특별연장근로 사업장';
}

export function printOKComment() {
	return '출력';
}

// 12월05일-공통
export function printOKCommentPaydocu() {
	return 'PDF 출력';
}
// -----

export function printNoComment(isWorkerUser) {
	const result = isWorkerUser ? '미교부 사유' : '출력(전문가 검토 필요)';
	return result;
}

export function commaStringToNumber(commmaStringNumber) {
	const number =
		typeof commmaStringNumber === 'string'
			? parseFloat(commmaStringNumber.replace(/,/g, ''))
			: parseFloat(commmaStringNumber);
	return number;
}

export function numberToCommaString(number) {
	// const roundNumber = Math.round(number)
	return number?.toLocaleString();
}

export function numberToRoundCommaString(number) {
	const roundNumber = Math.round(number);
	return roundNumber?.toLocaleString();
}

// obj 안에 obj 날짜 바꾸기
export function timestampToDate(obj) {
	for (const prop in obj) {
		if (obj.hasOwnProperty(prop)) {
			if (obj[prop] instanceof Timestamp) {
				obj[prop] = obj[prop].toDate();
			}
		}
	}
	return {
		...obj,
	};
}

// 퇴근시간 구하기
export function workEndTimeToFloat(workEndTime = '23:59', dayType) {
	const hoursMinutes = workEndTime?.split(/[.:]/);
	const hours = parseInt(hoursMinutes[0], 10);
	const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
	if (dayType === '익일') {
		return hours + 24 + minutes / 60;
	} else {
		return hours + minutes / 60;
	}
}
// 출근시간 구하기
export function workStartTimeToFloat(workStartTime = '09:00') {
	const hoursMinutes = workStartTime.split(/[.:]/);
	const hours = parseInt(hoursMinutes[0], 10);
	const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
	return hours + minutes / 60;
}
// 근무일 근무시간 구하기(휴게시간 제외)
export function workingTimeDayTotalAmount(
	workEndTime,
	dayType,
	workStartTime,
	dayRest = 0,
	nightRest = 0
) {
	const result =
		workEndTimeToFloat(workEndTime, dayType) -
		workStartTimeToFloat(workStartTime) -
		(dayRest ? dayRest / 60 : 0) -
		(nightRest ? nightRest / 60 : 0);
	return result <= 0 ? 0 : Math.round(result * 100) / 100;
}

// 휴게시간 위반 판단
export function restLawViolationJudge(
	workEndTime,
	dayType,
	workStartTime,
	dayRest = 0,
	nightRest = 0
) {
	const startEndTime =
		(workEndTime ? +workEndTimeToFloat(workEndTime, dayType) : 0) -
		(workEndTime ? +workStartTimeToFloat(workStartTime) : 0);
	const sumOfRests =
		+(dayRest === undefined ? 0 : dayRest) +
		+(nightRest === undefined ? 0 : nightRest);

	if (startEndTime - sumOfRests / 60 <= 4 && sumOfRests / 60 >= 0) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 8 && sumOfRests / 60 >= 0.5) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 12 && sumOfRests / 60 >= 1) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 16 && sumOfRests / 60 >= 1.5) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 20 && sumOfRests / 60 >= 2) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 24 && sumOfRests / 60 >= 2.5) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 28 && sumOfRests / 60 >= 3) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 32 && sumOfRests / 60 >= 3.5) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 36 && sumOfRests / 60 >= 4) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 40 && sumOfRests / 60 >= 4.5) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 44 && sumOfRests / 60 >= 5) {
		return true;
	} else if (startEndTime - sumOfRests / 60 <= 48 && sumOfRests / 60 >= 5.5) {
		return true;
	} else {
		return false;
	}
}

export function timeErrorAlert(workStartTime, workEndTime, dayType) {
	const workStartHour = workStartTimeToFloat(workStartTime);
	const workEndHour = workEndTimeToFloat(workEndTime, dayType);
	if (workEndHour - workStartHour <= 0) {
		alert('시간 입력오류 ?');
		alert('Step 2. (3)출근, (5)퇴근 시간의 오전/오후 확인 ?');
		alert('또는 Step 2. (4)당일/익일 구분 확인 ?.');
	}
}

// 근무일당 야간근로시간(휴게시간 제외)
export function workingTimeDayNightAmount(
	workStartTime,
	workEndTime,
	dayType,
	nightRest
) {
	const workStartHour = workStartTimeToFloat(workStartTime);
	const workEndHour = workEndTimeToFloat(workEndTime, dayType);

	if (workEndHour <= 22 && workStartHour >= 6) {
		return 0;
	} else {
		return workEndHour - 22 < 8
			? (workEndHour > 22 ? workEndHour - 22 : 0) +
					(workStartHour < 6 ? 6 - workStartHour : 0) -
					(nightRest ? nightRest / 60 : 0)
			: 8 +
					(workStartHour < 6 ? 6 - workStartHour : 0) -
					(nightRest ? nightRest / 60 : 0);
	}
}

export const roundUp2 = (number) => {
	return Math.ceil(number * 100) / 100;
};

export const roundUp = (number) => {
	return Math.ceil(number);
};

export const roundDown = (number) => {
	return Math.floor(number);
};

export const weekToMonthCal = (weekTime) => {
	return roundUp2(((weekTime / 7) * 365) / 12);
};

////////////////////////////////////////////////////////////////////////////////야간근로

// 1일 실야간근로시간(환산전)
export const daysNWT = (days, startWT, endWT, dayType, nigthRT) => {
	return days && endWT && dayType
		? workingTimeDayNightAmount(startWT, endWT, dayType, nigthRT) <= 0
			? 0
			: workingTimeDayNightAmount(startWT, endWT, dayType, nigthRT)
		: 0;
};
// 월 야간 환산시간(5인 판단)
export const cMNWTime = (monthNWT, numOfWorkers) => {
	return numOfWorkers >= 5 ? monthNWT * 0.5 : 0;
};

// 월 야간 환산금액(가산임금만 있음, 5인미만은 0원)
export const mNWWage = (hourOrdinaryWage, converseMonthNWT, numOfWorkers) => {
	return numOfWorkers >= 5 ? roundUp(hourOrdinaryWage * converseMonthNWT) : 0;
};

////////////////////////////////////////////////////////////////////////////휴일근로
// 월 휴일기본환산시간(5인판단포함)
export const cMHBTime = (monthHoliBasicTime, numOfWorkers) => {
	return numOfWorkers >= 5 ? monthHoliBasicTime * 1.5 : monthHoliBasicTime;
};

// 월 휴일기본 환산금액(5인이상: 가산임금 + 실제 일한 임금)((5인미만은 0원. 기본급)
export const mHBWWage = (
	hourOrdinaryWage,
	converseMonthHoliBasicTime,
	numOfWorkers
) => {
	return numOfWorkers >= 5
		? roundUp(hourOrdinaryWage * converseMonthHoliBasicTime)
		: 0;
};

// 월 휴일연장환산시간(5인판단포함)
export const cMHOTime = (monthOWTHoli, numOfWorkers) => {
	return numOfWorkers >= 5 ? monthOWTHoli * 2 : monthOWTHoli;
};

// 월 휴일연장 환산금액(5인이상: 가산임금 + 실제 일한 임금)((5인미만은 0원, 기본급에 있음.)
export const mHOWWage = (
	hourOrdinaryWage,
	conversemonthOWTHoli,
	numOfWorkers
) => {
	return numOfWorkers >= 5
		? roundUp(hourOrdinaryWage * conversemonthOWTHoli)
		: 0;
};

// 월 휴일야간환산시간(5인판단포함)
export const cMH0Time = (monthNWTHoli, numOfWorkers) => {
	return numOfWorkers >= 5 ? monthNWTHoli * 0.5 : 0;
};

// 월 휴일야간 환산금액(5인이상: 가산임금 + 실제 일한 임금)((5인미만은 0원, 기본급)
export const mHNWWage = (
	hourOrdinaryWage,
	converseMonthNWTHoli,
	numOfWorkers
) => {
	return numOfWorkers >= 5
		? roundUp(hourOrdinaryWage * converseMonthNWTHoli)
		: 0;
};
// 1일 실근로시간(휴게제외)
export const daysWT = (endWT, dayType, startWT, dayRT, nigthRT) => {
	return endWT && dayType && startWT
		? workingTimeDayTotalAmount(endWT, dayType, startWT, dayRT, nigthRT)
		: 0;
};

//////////////////////////////////////////////////////////////////////////////////소정근로

export function netAge15To18F(netAge) {
	if (netAge >= 15 && netAge < 18) {
		return true;
	} else {
		return false;
	}
}

export function netAge15To34F(netAge) {
	if (netAge >= 15 && netAge < 35) {
		return true;
	} else {
		return false;
	}
}

export function days1LBTimeF(daysWT1, netAge15To18) {
	if (netAge15To18) {
		return daysWT1 >= 7 ? 7 : daysWT1;
	} else {
		return daysWT1 >= 8 ? 8 : daysWT1;
	}
}

// 1일 8시간(18세미만은 7간) 초과 연장근로 구하기
export function workingTimeDayOverAmount(daysWT, dayLBTime) {
	if (daysWT <= dayLBTime) {
		return 0;
	} else {
		return daysWT - dayLBTime;
	}
}

// 1일 실연장근로시간(환산전)
export const daysOWT = (days, daysWT, daysLBTime) => {
	return days && daysWT && daysLBTime
		? workingTimeDayOverAmount(daysWT, daysLBTime)
		: 0;
};

//두번째 근로시간이 같은 날들의 1일 소정근로시간
export const days2LBTime = (
	days1LBTime,
	days1Length,
	daysWT2,
	netAge15To18
) => {
	const dayLBTimeNum = netAge15To18 ? 7 : 8;
	const weekLBTimeNum = netAge15To18 ? 35 : 40;

	const sumLBTime1 = days1LBTime * days1Length;

	if (sumLBTime1 >= weekLBTimeNum) {
		return 0;
	} else if (netAge15To18 ? sumLBTime1 >= 28 : sumLBTime1 >= 32) {
		return daysWT2 >= dayLBTimeNum
			? Math.min(dayLBTimeNum, weekLBTimeNum - sumLBTime1)
			: Math.min(daysWT2, weekLBTimeNum - sumLBTime1);
	} else {
		return daysWT2 >= dayLBTimeNum ? dayLBTimeNum : daysWT2;
	}
};

//세번째 근로시간이 같은 날들의 1일 소정근로시간
export const days3LBTime = (
	days1LBTime,
	days1Length,
	daysWT2,
	days2Length,
	daysWT3,
	netAge15To18
) => {
	const dayLBTimeNum = netAge15To18 ? 7 : 8;
	const weekLBTimeNum = netAge15To18 ? 35 : 40;

	const sumLBTime2 =
		days1LBTime * days1Length +
		days2LBTime(days1LBTime, days1Length, daysWT2, netAge15To18) * days2Length;
	if (sumLBTime2 >= weekLBTimeNum) {
		return 0;
	} else if (netAge15To18 ? sumLBTime2 >= 21 : sumLBTime2 >= 24) {
		return daysWT3 >= dayLBTimeNum
			? Math.min(dayLBTimeNum, weekLBTimeNum - sumLBTime2)
			: Math.min(daysWT3, weekLBTimeNum - sumLBTime2);
	} else {
		return daysWT3 >= dayLBTimeNum ? dayLBTimeNum : daysWT3;
	}
};

//네번째 근로시간이 같은 날들의 1일 소정근로시간
export const days4LBTime = (
	days1LBTime,
	days1Length,
	daysWT2,
	days2Length,
	daysWT3,
	days3Length,
	daysWT4,
	netAge15To18
) => {
	const sumLBTime3 =
		days1LBTime * days1Length +
		days2LBTime(days1LBTime, days1Length, daysWT2, netAge15To18) * days2Length +
		days3LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			netAge15To18
		) *
			days3Length;

	const dayLBTimeNum = netAge15To18 ? 7 : 8;
	const weekLBTimeNum = netAge15To18 ? 35 : 40;

	if (sumLBTime3 >= weekLBTimeNum) {
		return 0;
	} else if (netAge15To18 ? sumLBTime3 >= 14 : sumLBTime3 >= 16) {
		return daysWT4 >= dayLBTimeNum
			? Math.min(dayLBTimeNum, weekLBTimeNum - sumLBTime3)
			: Math.min(daysWT4, weekLBTimeNum - sumLBTime3);
	} else {
		return daysWT4 >= dayLBTimeNum ? dayLBTimeNum : daysWT4;
	}
};

//다섯번째 근로시간이 같은 날들의 1일 소정근로시간
export const days5LBTime = (
	days1LBTime,
	days1Length,
	daysWT2,
	days2Length,
	daysWT3,
	days3Length,
	daysWT4,
	days4Length,
	daysWT5,
	netAge15To18
) => {
	const sumLBTime4 =
		days1LBTime * days1Length +
		days2LBTime(days1LBTime, days1Length, daysWT2, netAge15To18) * days2Length +
		days3LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			netAge15To18
		) *
			days3Length +
		days4LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			days3Length,
			daysWT4,
			netAge15To18
		) *
			days4Length;

	const dayLBTimeNum = netAge15To18 ? 7 : 8;
	const weekLBTimeNum = netAge15To18 ? 35 : 40;

	if (sumLBTime4 >= weekLBTimeNum) {
		return 0;
	} else if (netAge15To18 ? sumLBTime4 >= 7 : sumLBTime4 >= 8) {
		return daysWT5 >= dayLBTimeNum
			? Math.min(dayLBTimeNum, weekLBTimeNum - sumLBTime4)
			: Math.min(daysWT5, weekLBTimeNum - sumLBTime4);
	} else {
		return daysWT5 >= dayLBTimeNum ? dayLBTimeNum : daysWT5;
	}
};

//여섯번째 근로시간이 같은 날들의 1일 소정근로시간
export const days6LBTime = (
	days1LBTime,
	days1Length,
	daysWT2,
	days2Length,
	daysWT3,
	days3Length,
	daysWT4,
	days4Length,
	daysWT5,
	days5Length,
	daysWT6,
	netAge15To18
) => {
	const sumLBTime5 =
		days1LBTime * days1Length +
		days2LBTime(days1LBTime, days1Length, daysWT2, netAge15To18) * days2Length +
		days3LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			netAge15To18
		) *
			days3Length +
		days4LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			days3Length,
			daysWT4,
			netAge15To18
		) *
			days4Length +
		days5LBTime(
			days1LBTime,
			days1Length,
			daysWT2,
			days2Length,
			daysWT3,
			days3Length,
			daysWT4,
			days4Length,
			daysWT5,
			netAge15To18
		) *
			days5Length;

	const dayLBTimeNum = netAge15To18 ? 7 : 8;
	const weekLBTimeNum = netAge15To18 ? 35 : 40;

	if (sumLBTime5 >= weekLBTimeNum) {
		return 0;
	} else if (sumLBTime5 >= 0) {
		return daysWT6 >= dayLBTimeNum
			? Math.min(dayLBTimeNum, weekLBTimeNum - sumLBTime5)
			: Math.min(daysWT6, weekLBTimeNum - sumLBTime5);
	} else {
		return daysWT6 >= dayLBTimeNum ? dayLBTimeNum : daysWT6;
	}
};

// 월 연장 환산시간(5인 판단포함)
export const cMOWTime = (monthOWT, numOfWorkers) => {
	return numOfWorkers >= 5 ? monthOWT * 1.5 : monthOWT;
};

// 월 연장 환산금액(5인이상: 가산임금 + 실제 일한 임금)(5인미만은 0원,기본급에 있음.)
export const mOWWage = (hourOrdinaryWage, converseMonthOWT, numOfWorkers) => {
	return numOfWorkers >= 5 ? roundUp(hourOrdinaryWage * converseMonthOWT) : 0;
};

// 1주 소정근로시간(5인미만은 40시간 이상 시간)
export const wLBTime = (sumOneWeekLBDWT, numOfWorkers, netAge15To18) => {
	if (netAge15To18) {
		return numOfWorkers >= 5
			? sumOneWeekLBDWT >= 35
				? 35
				: roundUp2(sumOneWeekLBDWT)
			: sumOneWeekLBDWT;
	} else {
		return numOfWorkers >= 5
			? sumOneWeekLBDWT >= 40
				? 40
				: roundUp2(sumOneWeekLBDWT)
			: sumOneWeekLBDWT;
	}
};

// 1일 소정근로시간(주휴수당근거수당)(기타직은 입력값)
export const paidHolidayLBTimeF = (
	etcPaidHolidayLBTime,
	weekLBTime,
	normalWorkerWeekWDs,
	numOfWorkers,
	netAge15To18
) => {
	let dayLBTimeNum =
		etcPaidHolidayLBTime >= 7 && netAge15To18 ? 7 : etcPaidHolidayLBTime;
	dayLBTimeNum = Math.min(dayLBTimeNum, 8);
	let result;
	if (etcPaidHolidayLBTime) {
		result = dayLBTimeNum;
	} else {
		result = paidHDLBTimeF(
			weekLBTime,
			normalWorkerWeekWDs,
			numOfWorkers,
			netAge15To18
		);
	}
	return result;
};

// 1주 소정근로시간(기타직은 입력값)
export const weekLBTimeF = (
	etcWeekLBTime,
	isExceptTimeLawWorker,
	sumOneWeekLBDWTETL,
	sumOneWeekLBDWT,
	numOfWorkers,
	netAge15To18
) => {
	let maxEtcWeekLBTime =
		etcWeekLBTime >= 35 && netAge15To18 ? 35 : etcWeekLBTime;
	maxEtcWeekLBTime = Math.min(maxEtcWeekLBTime, 40);
	let result;

	if (etcWeekLBTime) {
		result = maxEtcWeekLBTime;
	} else if (isExceptTimeLawWorker) {
		result = sumOneWeekLBDWTETL;
	} else {
		result = wLBTime(sumOneWeekLBDWT, numOfWorkers, netAge15To18);
	}
	return result;
};

// 주휴수당 시간
export const paidHDLBTimeF = (
	weekLBTime,
	normalWorkerWeekWDs = 5,
	numOfWorkers,
	netAge15To18
) => {
	const dayLBTimeNum = netAge15To18 ? 7 : 8;
	const weekLBTimeNum = netAge15To18 ? 35 : 40;

	let result;
	if (numOfWorkers >= 5 && weekLBTime >= 15 && weekLBTime < weekLBTimeNum) {
		result = (4 * weekLBTime) / (normalWorkerWeekWDs * 4);
	} else if (numOfWorkers >= 5 && weekLBTime >= weekLBTimeNum) {
		result = dayLBTimeNum;
	} else if (numOfWorkers >= 5 && weekLBTime < 15) {
		result = 0;
	} else if (
		numOfWorkers < 5 &&
		weekLBTime >= 15 &&
		weekLBTime < weekLBTimeNum
	) {
		result = (4 * weekLBTime) / (normalWorkerWeekWDs * 4);
	} else if (numOfWorkers < 5 && weekLBTime >= weekLBTimeNum) {
		result = (4 * weekLBTimeNum) / (normalWorkerWeekWDs * 4);
	} else if (numOfWorkers < 5 && weekLBTime < 15) {
		result = 0;
	}
	return result;
};

// 1달 통상임금산정기준 시간수
export const mLBT = (weekLBTime, paidHolidayLBTime) => {
	return Math.ceil(weekToMonthCal(+weekLBTime + +paidHolidayLBTime));
};
// 총 1달 유급시간(환산)
export const monthTotalPaidConversedTime = (
	mCOT,
	mCNT,
	mCHBT,
	mCHOT,
	mCHNT,
	mLBT
) => {
	return roundUp2(mCOT + mCNT + mCHBT + mCHOT + mCHNT + mLBT);
};

/////////////////////////////////////////////////////////////////임금계산

// 통상시급 (월 평균임금제외 임금(통상 + 법정수당)(총월급-평균임금) / 1달 총 유급시간) + 연간 통상상여금 / 12개월 / 1달 총 유급시간
export const hourOrdiWage = (tWMinusTAW, mTPCTime, cOWYearlyBonus) => {
	return (
		roundUp2(tWMinusTAW / mTPCTime) +
		(cOWYearlyBonus ? roundUp2(cOWYearlyBonus / 12 / mTPCTime) : 0)
	);
};

// 월급 통상시급 (월 평균임금제외 임금(통상 + 법정수당)(총월급-평균임금) / 1달 총 유급시간)
export const hourOrdiWageMonth = (tWMinusTAW, mTPCTime) => {
	return roundUp2(tWMinusTAW / mTPCTime);
};

//-----------------

// 5인이상 통상 일급(일당)-******5인미만은 요일별로 다를 수 있으므로 구하지 않음.
export const dayOrdiWage = (hourOrdinaryWage, paidHolidayLBTime) => {
	return hourOrdinaryWage * paidHolidayLBTime;
};

// 법정수당 합계
export const sMONHWage = (
	mOWage,
	mNWage,
	mHBWage,
	mHOWage,
	mHNWage,
	annualLeavesIncludeMonthWage
) => {
	return (
		mOWage + mNWage + mHBWage + mHOWage + mHNWage + annualLeavesIncludeMonthWage
	);
};

export const mTaxWage = (cTotalMonthWageAmount, sumOfTFWages) => {
	return roundUp(cTotalMonthWageAmount - sumOfTFWages);
};

// 월 통상임금
export const mOrdiWage = (
	cTotalMonthWageAmount,
	sumOfAWages,
	sumOfMonthONHWages
) => {
	return cTotalMonthWageAmount - sumOfAWages - sumOfMonthONHWages;
};

// 기본급 (총급여 - 평균임금계(비과세포함)(sumOfAWages) - 평균법정수당(sumOfMonthONHWages) - 비과세(통상+평균)(sumOfTFWages) - 통상수당(sumOfOWages) + 비과세평균(sumOfAWagesTFMinusAmount))
export const mBW = (
	numOfWorkers,
	cTotalMonthWageAmount,
	sumOfAWages,
	sumOfMonthONHWages,
	sumOfTFWages,
	sumOfOWages,
	sumOfAWagesTFMinusAmount
) => {
	return numOfWorkers >= 5
		? cTotalMonthWageAmount -
				sumOfAWages -
				sumOfMonthONHWages -
				sumOfTFWages -
				sumOfOWages +
				sumOfAWagesTFMinusAmount
		: cTotalMonthWageAmount -
				sumOfAWages -
				sumOfTFWages -
				sumOfOWages +
				sumOfAWagesTFMinusAmount;
};

export const laborContractStyle = {
	margin: '30px',
	fontSize: '17px',
};

//근로소득세 계산(과세급여합계) - 2021년 이후
export const tax1000Over = (
	taxWagesSumNumber,
	numOfTaxFamily = 0,
	numOfTaxFamily7To20 = 0
) => {
	const tax1000Range = [
		1507400, 1431570, 1200840, 1170840, 1140840, 1110840, 1080840, 1050840,
		1020840, 990840, 960840,
	];
	if (numOfTaxFamily - 1 + numOfTaxFamily7To20 >= tax1000Range.length) {
		return 'Index out of range'; // 배열 범위 초과 시 오류 메시지 반환
	}

	const tax1000 =
		tax1000Range[numOfTaxFamily - 1 + numOfTaxFamily7To20] || 1507400;
	const tax1400 = 1397000;
	const tax2800 = 6610600;
	const tax3000 = 7394600;
	const tax4500 = 13394600;
	const tax8700 = 31034600;

	if (taxWagesSumNumber === 10000000) {
		// 10000000일 때의 세금 계산
		return tax1000;
	} else if (taxWagesSumNumber > 10000000 && taxWagesSumNumber <= 14000000) {
		return tax1000 + (taxWagesSumNumber - 10000000) * 0.98 * 0.35 + 25000;
	} else if (taxWagesSumNumber > 14000000 && taxWagesSumNumber <= 28000000) {
		return tax1000 + tax1400 + (taxWagesSumNumber - 14000000) * 0.98 * 0.38;
	} else if (taxWagesSumNumber > 28000000 && taxWagesSumNumber <= 30000000) {
		return tax1000 + tax2800 + (taxWagesSumNumber - 28000000) * 0.98 * 0.4;
	} else if (taxWagesSumNumber > 30000000 && taxWagesSumNumber <= 45000000) {
		return tax1000 + tax3000 + (taxWagesSumNumber - 30000000) * 0.4;
	} else if (taxWagesSumNumber > 45000000 && taxWagesSumNumber <= 87000000) {
		return tax1000 + tax4500 + (taxWagesSumNumber - 45000000) * 0.42;
	} else if (taxWagesSumNumber > 87000000) {
		return tax1000 + tax8700 + (taxWagesSumNumber - 87000000) * 0.45;
	} else {
		// taxWagesSumNumber가 주어진 범위에 속하지 않는 경우
		return 'Invalid taxWagesSumNumber'; // 적절한 오류 메시지 또는 기본 처리
	}
};

export const taxIncludeFamily = (
	taxWagesSumNumber,
	numOfTaxFamily,
	numOfTaxFamily7To20 = 0
) => {
	for (let i = 0; i < taxTable2023.length; i++) {
		if (taxWagesSumNumber / 1000 < taxTable2023[i][0]) {
			return 0;
		}
		if (
			taxWagesSumNumber / 1000 >= taxTable2023[i][0] &&
			taxWagesSumNumber / 1000 < taxTable2023[i][1]
		) {
			return numOfTaxFamily
				? taxTable2023[i][numOfTaxFamily + 1 + numOfTaxFamily7To20]
				: taxTable2023[i][2];
		}
	}
};

//지방소득세 1천만미만 - 2021년 이후
export const calRegTaxFamily = (
	taxWagesSumNumber,
	numOfTaxFamily,
	numOfTaxFamily7To20 = 0
) => {
	return (
		Math.floor(
			taxIncludeFamily(taxWagesSumNumber, numOfTaxFamily, numOfTaxFamily7To20) /
				10 /
				10
		) * 10
	);
};

//지방소득세 1천만 이상 - 2021년 이후
export const calRegTax1000Over = (
	taxWagesSumNumber,
	numOfTaxFamily,
	numOfTaxFamily7To20
) => {
	return (
		Math.floor(
			tax1000Over(taxWagesSumNumber, numOfTaxFamily, numOfTaxFamily7To20) /
				10 /
				10
		) * 10
	);
};

//세후금액 출발 월급 (2021년1월1일 이후)
export const assumeWagef = (taxWagesSumNumber) => {
	let rate = '';
	switch (true) {
		case taxWagesSumNumber < 1060000:
			rate = 1.0912;
			break;
		case taxWagesSumNumber >= 1060000 && taxWagesSumNumber < 1620000:
			rate = 1.0913;
			break;
		case taxWagesSumNumber >= 1620000 && taxWagesSumNumber < 2520000:
			rate = 1.092;
			break;
		case taxWagesSumNumber >= 2520000 && taxWagesSumNumber < 2950000:
			rate = 1.093;
			break;
		case taxWagesSumNumber >= 2950000 && taxWagesSumNumber < 1620000:
			rate = 1.094;
			break;
		case taxWagesSumNumber >= 3360000 && taxWagesSumNumber < 3780000:
			rate = 1.095;
			break;
		case taxWagesSumNumber >= 3780000 && taxWagesSumNumber < 4240000:
			rate = 1.096;
			break;
		case taxWagesSumNumber >= 4240000 && taxWagesSumNumber < 4800000:
			rate = 1.097;
			break;
		case taxWagesSumNumber >= 4800000 && taxWagesSumNumber < 5560000:
			rate = 1.098;
			break;
		case taxWagesSumNumber >= 5560000 && taxWagesSumNumber < 5840000:
			rate = 1.099;
			break;
		case taxWagesSumNumber >= 5840000 && taxWagesSumNumber < 6280000:
			rate = 1.1;
			break;
		case taxWagesSumNumber >= 6280000 && taxWagesSumNumber < 6780000:
			rate = 1.101;
			break;
		case taxWagesSumNumber >= 6780000 && taxWagesSumNumber < 7400000:
			rate = 1.102;
			break;
		case taxWagesSumNumber >= 7400000 && taxWagesSumNumber < 8140000:
			rate = 1.103;
			break;
		case taxWagesSumNumber >= 8140000 && taxWagesSumNumber < 9000000:
			rate = 1.104;
			break;
		case taxWagesSumNumber >= 9000000 && taxWagesSumNumber < 9580000:
			rate = 1.105;
			break;
		case taxWagesSumNumber >= 9580000 && taxWagesSumNumber < 10000001:
			rate = 1.106;
			break;
		default:
			break;
	}
	const startSearchWage = roundUp((taxWagesSumNumber * rate) / 10) * 10;
	return startSearchWage;
};

//국민연금
export const pension = (taxWagesSumNumber, year, month) => {
	const numYear = +year;
	const numMonth = +month;

	const highWage = (() => {
		if (year === '2021' || numYear === 2021) return 5240000;
		if (year === '2022' || numYear === 2022)
			return numMonth < 7 ? 5240000 : 5530000;
		if (year === '2023' || numYear === 2023)
			return numMonth < 7 ? 5530000 : 5900000;
		if (year === '2024' || numYear === 2024)
			return numMonth < 7 ? 5900000 : 6170000;
		if (numYear > 2024 || (numYear === 2024 && numMonth >= 7)) return 6170000;
		return 6170000;
	})();

	const lowWage = (() => {
		if (year === '2021' || numYear === 2021) return 330000;
		if (year === '2022' || numYear === 2022)
			return numMonth < 7 ? 330000 : 350000;
		if (year === '2023' || numYear === 2023)
			return numMonth < 7 ? 350000 : 370000;
		if (year === '2024' || numYear === 2024)
			return numMonth < 7 ? 370000 : 390000;
		if (numYear > 2024 || (numYear === 2024 && numMonth >= 7)) return 390000;
		return 390000;
	})();

	if (taxWagesSumNumber === 0) {
		taxWagesSumNumber = 0;
	} else if (taxWagesSumNumber <= +lowWage) {
		taxWagesSumNumber = +lowWage;
	} else if (taxWagesSumNumber >= +highWage) {
		taxWagesSumNumber = +highWage;
	}

	const pensionRate = 0.045;

	return (
		Math.floor(
			(Math.floor(taxWagesSumNumber / 1000) * 1000 * pensionRate) / 10
		) * 10
	);
};

export const highFee2021 = 3523950;
export const lowFee2021 = 9570;
export const healthCareRate2021 = 0.0686;
export const highFee2022 = 3653550;
export const lowFee2022 = 9750;
export const healthCareRate2022 = 0.0699;
export const highFee2023 = 3911280;
export const lowFee2023 = 9890;
export const healthCareRate2023 = 0.0709;
export const highFee2024 = 4240710;
export const lowFee2024 = 9570;
export const healthCareRate2024 = 0.0709;
export const highFee2025 = 4504170;
export const lowFee2025 = 9570;
export const healthCareRate2025 = 0.0709;

//건강보험 2022년1월에 하한액, 상한액 다시 확인
export const healthCare = (taxWagesSumNumber, year, type) => {
	// const highFee = year === '2021' ? 3523950 : (year === '2022' || year === 2022) ? 3523950 : 3523950;
	// const lowFee = year === '2021' ? 9570 : (year === '2022' || year === 2022) ? 9570 : 9570;
	// const healthCareRate = year === '2021' ? 0.0343 : (year === '2022' || year === 2022) ? 0.03495 : 0.03495;
	const highFee =
		year === '2021' || year === 2021
			? highFee2021
			: year === '2022' || year === 2022
			? highFee2022
			: year === '2023' || year === 2023
			? highFee2023
			: year === '2024' || year === 2024
			? highFee2024
			: year === '2025' || year === 2025
			? highFee2025
			: highFee2025;
	const lowFee =
		year === '2021' || year === 2021
			? lowFee2021
			: year === '2022' || year === 2022
			? lowFee2022
			: year === '2023' || year === 2023
			? lowFee2023
			: year === '2024' || year === 2024
			? lowFee2024
			: year === '2025' || year === 2025
			? lowFee2025
			: lowFee2025;
	const healthCareRate =
		year === '2021' || year === 2021
			? healthCareRate2021 / 2
			: year === '2022' || year === 2022
			? healthCareRate2022 / 2
			: year === '2023' || year === 2023
			? healthCareRate2023 / 2
			: year === '2024' || year === 2024
			? healthCareRate2024 / 2
			: year === '2025' || year === 2025
			? healthCareRate2025 / 2
			: healthCareRate2025 / 2;

	let result =
		Math.floor((taxWagesSumNumber * healthCareRate + 0.001) / 10) * 10;
	if (type !== '당월과세금액(매월변동반영)') {
		if (result === 0) {
			result = 0;
		} else if (result <= lowFee) {
			result = lowFee;
		} else if (result >= highFee) {
			result = highFee;
		}
	}
	return result;
};

export const oldCareRate2023 = 0.009082;
export const oldCareRate2024 = 0.009182;
export const oldCareRate2025 = 0.009182;

//요양보험 (2021년1월1일 ~ 2021년 12월 31일)
export const oldCare = (taxWagesSumNumber, year) => {
	// const oldCareRate = year === '2021' ? 0.1152 : (year === '2022' || year === 2022) ? 0.1227 : 0.1227;
	const presentLongTermCareRate =
		year === '2023' || year === 2023
			? oldCareRate2023
			: year === '2024' || year === 2024
			? oldCareRate2024
			: year === '2025' || year === 2025
			? oldCareRate2025
			: oldCareRate2025;

	const presentHealthCareRate =
		year === '2023' || year === 2023
			? healthCareRate2023
			: year === '2024' || year === 2024
			? healthCareRate2024
			: year === '2025' || year === 2025
			? healthCareRate2025
			: healthCareRate2025;

	const oldCareRate =
		year === '2021' || year === 2021
			? 0.1152
			: year === '2022' || year === 2022
			? 0.1227
			: year === '2023' || year === 2023
			? presentLongTermCareRate / presentHealthCareRate
			: year === '2024' || year === 2024
			? presentLongTermCareRate / presentHealthCareRate
			: year === '2025' || year === 2025
			? presentLongTermCareRate / presentHealthCareRate
			: presentLongTermCareRate / presentHealthCareRate;

	if (taxWagesSumNumber === 0) {
		taxWagesSumNumber = 0;
	}
	return (
		Math.floor((healthCare(taxWagesSumNumber, year) * oldCareRate) / 10) * 10
	);
};

//2022년 7월 1일에 O.009로 변경해야 함.
export const noJobInsurance = (taxWagesSumNumber, year, month) => {
	const numYear = +year;
	const numMonth = +month;

	const noJobRate =
		year === '2021'
			? 0.008
			: (year === '2022' || year === 2022) && numMonth < 7
			? 0.008
			: ((year === '2022' || year === 2022) && numMonth >= 7) ||
			  (numYear > 2022 && numMonth >= 1)
			? 0.009
			: 0.009;
	if (taxWagesSumNumber === 0) {
		taxWagesSumNumber = 0;
	}
	const result = Math.floor((taxWagesSumNumber * noJobRate) / 10) * 10;
	return result >= 0 ? result : 0;
};

//주민번호로 만나이 구하기
export function getAgeFromSocialNumber(workerSocialNumberFront = '900207') {
	let dateObj = new Date(); // 날짜 Object 생성
	let age = 0; // 나이
	let yy = dateObj.getFullYear(); // 현재년도
	let mm = dateObj.getMonth() + 1; // 현재월
	let dd = dateObj.getDate(); // 현재일
	if (mm < 10) mm = '0' + mm; // 현재 월이 10보다 작을경우 '0' 문자 합한다
	if (dd < 10) dd = '0' + dd; // 현재 일이 10보다 작을경우 '0' 문자 합한다
	const nDate = yy + mm + dd;
	const ssn_no2 = parseInt(workerSocialNumberFront?.substring(0, 1));
	if (ssn_no2 === 0) {
		age = yy - (2000 + parseInt(workerSocialNumberFront?.substring(0, 2)));
	} else {
		age = yy - (1900 + parseInt(workerSocialNumberFront?.substring(0, 2)));
	}
	const myBirthday = workerSocialNumberFront?.substring(2, 6); // 주민번호 4자리 생일문자 추출
	if (nDate >= yy + myBirthday) {
		age += 1;
	}
	age = age - 1; // 만나이
	return age;
}

//입사일 현재 주민번호로 만나이 구하기
export function getAgeFromSocialNumberEnteredDate(
	workerSocialNumberFront = '900207',
	enteredDate
) {
	let dateObj = new Date(enteredDate); // 날짜 Object 생성
	let age = 0; // 나이
	let yy = dateObj.getFullYear(); // 현재년도
	let mm = dateObj.getMonth() + 1; // 현재월
	let dd = dateObj.getDate(); // 현재일
	if (mm < 10) mm = '0' + mm; // 현재 월이 10보다 작을경우 '0' 문자 합한다
	if (dd < 10) dd = '0' + dd; // 현재 일이 10보다 작을경우 '0' 문자 합한다
	const nDate = yy + mm + dd;
	const ssn_no2 = parseInt(workerSocialNumberFront?.substring(0, 1));
	if (ssn_no2 === 0) {
		age = yy - (2000 + parseInt(workerSocialNumberFront?.substring(0, 2)));
	} else {
		age = yy - (1900 + parseInt(workerSocialNumberFront?.substring(0, 2)));
	}
	const myBirthday = workerSocialNumberFront?.substring(2, 6); // 주민번호 4자리 생일문자 추출
	if (nDate >= yy + myBirthday) {
		age += 1;
	}
	age = age - 1; // 만나이
	return age;
}

//주민번호로 만나이 구하기 국민연금
export function getAgeFromSocialNumberPension(
	workerSocialNumberFront,
	year,
	month
) {
	let age = 0; // 나이
	const nDate = year + month + '01';
	const ssn_no2 = parseInt(workerSocialNumberFront?.substring(0, 1));
	if (ssn_no2 === 0) {
		age = year - (2000 + parseInt(workerSocialNumberFront?.substring(0, 2)));
	} else {
		age = year - (1900 + parseInt(workerSocialNumberFront?.substring(0, 2)));
	}
	const myBirthday = workerSocialNumberFront?.substring(2, 6); // 주민번호 4자리 생일문자 추출
	if (nDate >= year + myBirthday) {
		age += 1;
	}
	age = age - 1; // 만나이
	return age;
}

// 주민번호로 성별 구하기
export function getGenderFromSocialNumber(workerSocialNumberBack) {
	const genderNumber = parseInt(workerSocialNumberBack?.substring(0, 1));
	if (
		genderNumber === 2 ||
		genderNumber === 4 ||
		genderNumber === 6 ||
		genderNumber === 8
	) {
		return '여성';
	} else {
		return '남성';
	}
}

// 주민번호로 외국인 구하기
export function getForeignerFromSocialNumber(workerSocialNumberBack) {
	if (
		typeof workerSocialNumberBack !== 'string' ||
		workerSocialNumberBack.length === 0
	) {
		return false; // 입력이 빈 문자열이거나 문자열이 아닌 경우 false 반환
	}
	const genderNumber = parseInt(workerSocialNumberBack?.substring(0, 1));
	if (
		genderNumber === 5 ||
		genderNumber === 6 ||
		genderNumber === 7 ||
		genderNumber === 8
	) {
		return true;
	} else {
		return false;
	}
}

// 첫 번째 숫자가 5 또는 6이면 true를 반환하는 함수
export function isForeignerF(encryptedValue, cryptoKey) {
	const decryptedValue = decrypto(encryptedValue, cryptoKey);
	if (!decryptedValue) {
		return false; // 혹은 필요한 경우 다른 기본값 반환
	}
	const firstDigit = decryptedValue.charAt(0);
	const result =
		firstDigit === '5' ||
		firstDigit === '6' ||
		firstDigit === '7' ||
		firstDigit === '8'
			? true
			: false;
	return result;
}

// 단어에 따른 국어 조사 변경
export const josaUnType = (companyName) => Josa.r(companyName, '은/는');
export const josaUlType = (companyName) => Josa.r(companyName, '을/를');
export const josaGaType = (companyName) => Josa.r(companyName, '이/가');
export const josaWaType = (companyName) => Josa.r(companyName, '와/과');
export const josaRoType = (companyName) => Josa.r(companyName, '으로/로');

export const downloadHtmlAsPdf = async (
	html2pdf,
	htmlContentRef,
	company,
	worker,
	isRuleOfLaborCouncil,
	isWageContract,
	isLaborContract
) => {
	const htmlContent = htmlContentRef.current;
	const textRuleOfLaborCouncil = company + ' 노사협의회 규정.pdf';
	const textWageContract = worker + '의 임금계약서.pdf';
	const textLaborContract = worker + '의 근로계약서.pdf';

	const outputFilename = isRuleOfLaborCouncil
		? textRuleOfLaborCouncil
		: isWageContract
		? textWageContract
		: isLaborContract
		? textLaborContract
		: 'paydac출력물.pdf';

	htmlContent.style.transform = 'scaleY(0.89)';
	htmlContent.style.transformOrigin = 'top left';

	const opt = {
		margin: [44, 40, 44, 40],
		filename: outputFilename,
		image: { type: 'jpeg', quality: 1 },
		html2canvas: { scale: 2 },
		jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
	};

	html2pdf()
		.set(opt)
		.from(htmlContent)
		.save()
		.then(() => {
			// PDF 생성이 완료되면 원래 스타일로 돌려놓습니다.
			htmlContent.style.transform = '';
			htmlContent.style.transformOrigin = '';
		});
};

// 취업규칙 프린트
export const printRoe = () => {
	printJS({
		printable: 'roeContents',
		type: 'html',
		css: ['/print.css'],
		scanStyles: false,
		documentTitle: '규정',
		onError: (error) => alert(error.message),
	});
};

// 근로계약서 프린트
export const printForm = () => {
	printJS({
		printable: 'laborContact',
		type: 'html',
		css: ['/print.css'],
		scanStyles: false,
		documentTitle: '근로계약서',
		onError: (error) => alert(error.message),
	});
};

// React 컴포넌트 내부 pdf 암호화
export const printFormTest = async () => {
	const htmlContent = document.getElementById('laborContact').innerHTML;

	try {
		const response = await fetch('http://localhost:5000/create-secure-pdf', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ htmlContent }),
		});

		if (!response.ok) throw new Error('Server response error');

		// PDF 파일 다운로드
		const blob = await response.blob();
		const downloadUrl = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = downloadUrl;
		a.download = '임금계약서.pdf';
		document.body.appendChild(a);
		a.click();
		a.remove();
	} catch (error) {
		console.error('Error downloading PDF:', error);
		alert('PDF 다운로드 중 오류가 발생했습니다.');
	}
};

// 임금계약서 프린트
export const printLWContract = () => {
	printJS({
		printable: 'laborWageContract',
		type: 'html',
		css: ['/print.css'],
		scanStyles: false,
		documentTitle: '임금계약서',
		onError: (error) => alert(error.message),
	});
};

// 임금명세서 프린트
export const printWageTable = () => {
	printJS({
		printable: 'wageTable',
		type: 'html',
		css: ['/print.css'],
		scanStyles: false,
		// header: '임 금 명 세 서',
		onError: (error) => alert(error.message),
	});
};

// 연차대장 프린트
export const printAnnualLeavesWorker = () => {
	printJS({
		printable: 'annualLeavesWorker',
		type: 'html',
		style: [
			`
    table {
      width: 100%;
      border: 1px solid #444444;
      border-collapse: collapse;
      font-size : 0.5rem;
    }
    th, tr, td {
      border: 1px solid #444444;
      font-size : 0.5rem;
    }
    input {
      border:none;border-right:0px; border-top:0px; boder-left:0px; boder-bottom:0px; text-align : center;
    }
    .gumak {
      text-align : right
    }
    .gumak-title {
      text-align : center
    }
    .gubun {
      text-align : center
    }
    .hangmok {
      text-align : center
    }
  `,
		],
		// targetStyle: ['*'],
		// header: '연차사용내역',
		onError: (error) => alert(error.message),
	});
};

// 일용계약서 프린트
export const printDayWorker = () => {
	printJS({
		printable: 'dayWorkerContract',
		type: 'html',
		style: [
			`
      li {margin-bottom: 7px;}
      body {line-height : 120%;} 
      input {text-align : right;}
      ul {list-style-type : none;}
    `,
		],
		targetStyle: ['*'],
		// header: '근로 계약서',
		onError: (error) => alert(error.message),
	});
};

// 전산이용 계약서 프린트
export const printProgramUseContract = () => {
	printJS({
		printable: 'programUseContract',
		type: 'html',
		style: [
			`
      td .titlee {font-size: 1.2em;}  
      body {line-height : 150%;} 
      .text {position: relative;} 
      img {position: absolute; bottom: -150%; right: -10%; opercity: 0.5}
    `,
		],
		// header: 'AI NOMUSA 이용 계약서',
		onError: (error) => alert(error.message),
	});
};

// 고용안정지원금 계약서 프린트
export const printSupportFundContract = () => {
	printJS({
		printable: 'supportFundContract',
		type: 'html',
		style: [
			`
      td .titlee {font-size: 1.2em;}  
      body {line-height : 150%;} 
      .text {position: relative;} 
      img {position: absolute; bottom: -150%; right: -10%; opacity: 0.5}
    `,
		],
		// header: '고용안정지원금 컨설팅 계약서',
		onError: (error) => alert(error.message),
	});
};

// 고용안정지원금 위임장 프린트
export const printSupportFundWarrant = () => {
	printJS({
		printable: 'supportFundWarrant',
		type: 'html',
		style: [
			`
      td {text-align : center;}
      .body {text-align : left;}
      .stamp {text-align : right;}
    `,
		],
		// header: '공인노무사 위임장',
		onError: (error) => alert(error.message),
	});
};

// 고용안정지원금 위임장 프린트
export const printCmsContract = () => {
	printJS({
		printable: 'cmsContract',
		type: 'html',
		style: [
			`
      td {text-align : center;}
      .body {text-align : left;}
      .stamp {text-align : right;}
    `,
		],
		// header: 'CMS 이체 신청서',
		onError: (error) => alert(error.message),
	});
};

export const printUseContract = () => {
	printJS({
		printable: 'useContract',
		type: 'html',
		style: `
    td, th { 
        text-align: center; 
        padding: 5px 10px; 
        border: 0.5px solid black;
    }
    .body { text-align: left; font-size: 1em; }
    .stamp { text-align: right; font-size: 1em; color: gray; }
    h1, h3 { margin: 25px 0; text-align: center; font-weight: bold; }
    .cms-header { margin-bottom: 20px; font-size: 1.5em; }
    p { margin: 15px 0; line-height: 1.5; }
		`,
		onError: (error) => alert(error.message),
	});
};

// 청년채용특별장려금 사업주 확인서
export const printYouthSupportFund = () => {
	printJS({
		printable: 'youthSupportFund',
		type: 'html',
		targetStyle: ['*'],
		// header: '공인노무사 위임장',
		onError: (error) => alert(error.message),
	});
};

// 종소기업 지원사업 시스템 사업주 확인서
export const printYouthSFSystem = () => {
	printJS({
		printable: 'youthSFSystem',
		type: 'html',
		targetStyle: ['*'],
		// header: '공인노무사 위임장',
		onError: (error) => alert(error.message),
	});
};

// 청년채용특별장려금 개인정보 동의서
export const printYouthSFPersonalInfo = () => {
	printJS({
		printable: 'youthSFPersonalInfo',
		type: 'html',
		targetStyle: ['*'],
		// header: '공인노무사 위임장',
		onError: (error) => alert(error.message),
	});
};

// 2025년 청년특별장려금 판단 함수
export function youthSupportFundPersonF(
	judgeLowestWageResult,
	enterDate,
	contractEndDate,
	netAge,
	weekLBTime,
	workerYouth
) {
	const enterDateF = new Date(enterDate);
	const workerYouthYes =
		workerYouth === '1. 연속하여 4개월 이상 실업상태인 청년' ||
		workerYouth === '2. 고졸이하 학력인 청년' ||
		workerYouth === '3. 고용촉진장려금 지급 대상이 되는 청년' ||
		workerYouth ===
			'4. 국민취업지원제도에 참여하고 최초로 취업한 청년(국취 참여이후 최초 취업)' ||
		workerYouth === '5. 청년도전지원사업수료 청년' ||
		workerYouth ===
			'6. 가정과 학교의 보호를 받지 못하여 안정적 자립을 위한 정부 지원 필요성이 인정되는 청년' ||
		workerYouth === '7. 북한이탈청년(북한이탈주민등록확인서 발급)' ||
		workerYouth ===
			'8. 자영업 폐업 이후, 최초로 취업한 청년(생애최초가 아닌 자영업 폐업 이후 최초)' ||
		workerYouth ===
			'9. 최종학교 졸업일 이후 채용일까지 고용보험 총 가입기간 12개월 미만인 청년' ||
		workerYouth ===
			'10. 외국인의 경우 거주(F-2), 영주(F-5), 결혼이민자(F-6)비자 소지자 참여 가능'
			? true
			: false;

	const regularWorker =
		contractEndDate === '정년' ||
		contractEndDate === '' ||
		contractEndDate === null
			? true
			: false;
	const under34 = netAge < 35 ? true : false;
	const over14 = netAge > 14 ? true : false;
	const enter2025 =
		enterDateF >= new Date('2025-1-1') && enterDateF <= new Date('2025-12-31')
			? true
			: false;
	const over30workHour = weekLBTime >= 30 ? true : false;

	function alertText() {
		if (!regularWorker) {
			return toast('정규직이 아니여서 2025년 청년 장려금 대상이 아닙니다.');
		} else if (!over30workHour) {
			return toast(
				'주 소정근로시간이 30시간 미만으로 2025년 청년 장려금 대상이 아닙니다.'
			);
		} else if (!enter2025) {
			return toast(
				'2025년도 입사자가 아니므로 2025년 청년 장려금 대상이 아닙니다.'
			);
		} else if (!workerYouthYes) {
			return toast(
				'지원 대상 청년 요건 10가지에 해당하지 않아 2025년 청년 장려금 대상이 아닙니다.'
			);
		} else {
			return null;
		}
	}

	if (
		judgeLowestWageResult &&
		regularWorker &&
		enter2025 &&
		under34 &&
		over14 &&
		over30workHour &&
		workerYouthYes
	) {
		return '2024 청년 일자리 도약 장려금 후보';
	} else {
		return !under34 ? null : alertText();
	}
}

// 2021년 코로나특별장려금 판단 함수
export function covid19SupportFundPersonF(
	judgeLowestWageResult,
	enterDate,
	contractEndDate,
	newOrNot,
	exCompanyOutDate,
	worknetRegisterDate,
	weekLBTime,
	totalMonthWageAmount
) {
	const enterDateM = moment(new Date(enterDate));
	const contractEndDateM =
		contractEndDate === '' ? '9999-01-01' : moment(new Date(contractEndDate));
	const exCompanyOutDateM =
		exCompanyOutDate === '' ? null : moment(new Date(exCompanyOutDate));
	const worknetRegisterDateM =
		worknetRegisterDate === '' ? null : moment(new Date(worknetRegisterDate));
	// const expectSupportFund = numberToCommaString(Math.min(totalMonthWageAmount * 0.8, 800000))

	if (
		judgeLowestWageResult &&
		newOrNot === '전직장 있음' &&
		(contractEndDate === '정년' || contractEndDate === ''
			? '9999-01-01'
			: contractEndDateM.diff(enterDateM, 'months') >= 6) &&
		(exCompanyOutDateM === null
			? false
			: enterDateM.diff(exCompanyOutDateM, 'months') >= 1) &&
		(worknetRegisterDateM === null
			? false
			: enterDateM.diff(worknetRegisterDateM, 'months') <= 12) &&
		enterDateM > new Date('2020-12-31') &&
		enterDateM < new Date('2022-1-1') &&
		weekLBTime > 15
	) {
		return `특별고용촉진장려금 후보`;
	} else {
		return false;
	}
}

// 대체인력 지원금 판단 함수
export function replacedWorkerFundPersonF(
	judgeLowestWageResult,
	enterDate,
	contractEndDate,
	altWorker,
	originalWorkerLeaveStartDate,
	originalWorkerLeaveEndDate
) {
	const enterDateM = moment(new Date(enterDate));
	const contractEndDateM =
		contractEndDate === '' ? '9999-01-01' : moment(new Date(contractEndDate));
	const originalWorkerLeaveStartDateM =
		originalWorkerLeaveStartDate === ''
			? null
			: moment(new Date(originalWorkerLeaveStartDate));
	const originalWorkerLeaveEndDateM =
		originalWorkerLeaveEndDate === ''
			? null
			: moment(new Date(originalWorkerLeaveEndDate));

	if (
		judgeLowestWageResult &&
		(altWorker === '1. 출산전후휴가 대체인력(지원금 대상)' ||
			altWorker === '2. 육아기 근로시간단축 대체인력(지원금 대상자)') &&
		(contractEndDate === '정년' || contractEndDate === ''
			? '9999-01-01'
			: contractEndDateM.diff(enterDateM, 'days') >= 30) &&
		(originalWorkerLeaveStartDateM === null
			? false
			: originalWorkerLeaveStartDateM.diff(enterDateM, 'months') < 2) &&
		(originalWorkerLeaveEndDateM === null
			? false
			: originalWorkerLeaveEndDate > enterDate) &&
		(originalWorkerLeaveEndDateM === null
			? false
			: originalWorkerLeaveEndDateM.diff(
					originalWorkerLeaveStartDateM,
					'days'
			  ) >= 30)
	) {
		return `육아 관련 대체인력지원금 후보`;
	} else {
		return false;
	}
}

export function dayOrdIncludeComment5(
	sumOfCOWDay,
	monthOrdinaryWage,
	monthLBTime,
	paidHolidayLBTime,
	paidHolidayLBTimeDayPay,
	yearlyBonus
) {
	const haveNoOrdDay = `[통상임금의 합계 ${numberToCommaString(
		monthOrdinaryWage
	)}원[월통상임금과 주휴수당 기준]${
		yearlyBonus
			? ` + 년 통상 상여금(${numberToCommaString(
					roundUp(yearlyBonus)
			  )})원 / 12개월
		${numberToCommaString(roundUp2(yearlyBonus / 12))}원`
			: ''
	}] / 월 통상임금 산정기준 시간수 ${numberToCommaString(
		roundUp(monthLBTime)
	)}시간`;

	const haveOrdDay = `[(월급 기준 통상임금의 합계 ${numberToCommaString(
		monthOrdinaryWage
	)}원[월통상임금과 주휴수당 기준] + 년 통상 상여금(${numberToCommaString(
		roundUp(yearlyBonus)
	)})원 / 12개월 
	${numberToCommaString(
		roundUp2(yearlyBonus / 12)
	)}원] / 월 통상임금 산정기준 시간수 ${numberToCommaString(
		roundUp(monthLBTime)
	)}시간) + 
    (일급기준 통상임금 합계 ${numberToCommaString(
			sumOfCOWDay
		)}원 / 1일 소정근로시간 ${paidHolidayLBTime}시간 + 1일 주휴시간 ${paidHolidayLBTimeDayPay}))]`;
	const result = sumOfCOWDay ? haveOrdDay : haveNoOrdDay;
	return result;
}

export function dayOrdIncludeComment4(
	sumOfCOWDay,
	monthOrdinaryWage,
	mTPCTime,
	paidHolidayLBTime,
	paidHolidayLBTimeDayPay,
	yearlyBonus
) {
	const haveNoOrdDay = `[통상임금의 합계 ${numberToCommaString(
		monthOrdinaryWage
	)}원[월통상임금과 주휴수당 기준] ${
		yearlyBonus
			? `  + 년 통상 상여금(${numberToCommaString(
					roundUp(yearlyBonus)
			  )})원 / 12개월
		${numberToCommaString(roundUp2(yearlyBonus / 12))}원`
			: ''
	}] / 월 통상임금 산정기준 시간수 ${numberToCommaString(
		roundUp(mTPCTime)
	)}시간]`;

	const haveOrdDay = `[(월급 기준 통상임금의 합계 ${numberToCommaString(
		monthOrdinaryWage
	)}원[월통상임금과 주휴수당 기준] ${
		yearlyBonus
			? ` + 년 통상 상여금(${numberToCommaString(
					roundUp(yearlyBonus)
			  )})원 / 12개월 ${numberToCommaString(roundUp2(yearlyBonus / 12))}원`
			: ''
	}] / 월 통상임금 산정기준 시간수 ${numberToCommaString(
		roundUp(mTPCTime)
	)}시간) + 
	(일급기준 통상임금 합계 ${numberToCommaString(
		sumOfCOWDay
	)}원 / 1일 소정근로시간 ${paidHolidayLBTime}시간 + 1일 주휴시간 ${paidHolidayLBTimeDayPay}))]`;

	const result = sumOfCOWDay ? haveOrdDay : haveNoOrdDay;
	return result;
}

export function dayLowWageIncludeComment(
	sumOfCOWDay,
	calTotalMonthLowestWage,
	monthLBTime,
	paidHolidayLBTime,
	paidHolidayLBTimeDayPay
) {
	const haveNoOrdDay = `[최저임금 판단 전체월급 ${numberToCommaString(
		roundUp(calTotalMonthLowestWage)
	)}원 / 월 최저임금 산정기준 시간수 ${numberToCommaString(
		roundUp(monthLBTime)
	)}시간]`;
	const haveOrdDay = `[(월급 기준 최저임금 판단 전체월급 ${numberToCommaString(
		calTotalMonthLowestWage
	)}원 / 월 최저임금 산정기준 시간수 ${numberToCommaString(
		roundUp(monthLBTime)
	)}시간) + 
    (일급기준 최저임금 합계 ${numberToCommaString(
			sumOfCOWDay
		)}원 / (1일 최저임금 산정기준 시간수(소정근로시간) ${paidHolidayLBTime}시간 + 1일 주휴시간 ${paidHolidayLBTimeDayPay})]`;
	const result = sumOfCOWDay ? haveOrdDay : haveNoOrdDay;
	return result;
}
// ---------
export function dayLowWageIncludeComment4(
	sumOfCOWDay,
	calTotalMonthLowestWage,
	mTPCTime,
	paidHolidayLBTime,
	paidHolidayLBTimeDayPay
) {
	const haveNoOrdDay = `[최저임금 판단 전체월급 ${numberToCommaString(
		roundUp(calTotalMonthLowestWage)
	)}원 / 월 최저임금 산정기준 시간수 ${numberToCommaString(
		roundUp(mTPCTime)
	)}시간]`;
	const haveOrdDay = `[(월급 기준 최저임금 판단 전체월급 ${numberToCommaString(
		calTotalMonthLowestWage
	)}원 / 월 최저임금 산정기준 시간수 ${numberToCommaString(
		roundUp(mTPCTime)
	)}시간) + 
    (일급기준 최저임금 합계 ${numberToCommaString(
			sumOfCOWDay
		)}원 / (1일 최저임금 산정기준 시간수(소정근로시간) ${paidHolidayLBTime}시간 + 1일 주휴시간 ${paidHolidayLBTimeDayPay})]`;
	const result = sumOfCOWDay ? haveOrdDay : haveNoOrdDay;
	return result;
}
// ---------

//두 배열 비교해서 같은 값만 추출하기
export function compareArray(a, b) {
	for (let i = 0; i < a.length; i++) {
		for (let j = 0; j < b.length; j++) {
			if (a[i] === b[j]) {
				return a[i];
			}
		}
	}
}

//중도입사자
export function isInterInWorkerF(enteredDate, month, year) {
	const workerEnteredDateYear = enteredDate
		? format(new Date(enteredDate.toString()), 'yyyy')
		: '';
	const workerEnteredDateMonth = enteredDate
		? format(new Date(enteredDate.toString()), 'MM')
		: '';
	const workerEnteredDateDay = enteredDate
		? format(new Date(enteredDate.toString()), 'dd')
		: '';
	return workerEnteredDateMonth === month &&
		workerEnteredDateYear === year &&
		workerEnteredDateDay !== '01'
		? true
		: false;
}

export function isInterInDays(enteredDate, daysInMonth) {
	return daysInMonth - format(new Date(enteredDate.toString()), 'dd');
}

// 중도퇴사자
export function isInterOutWorkerF(retiredDate, month, year) {
	const workerRetiredDateYear = retiredDate
		? format(new Date(retiredDate.toString()), 'yyyy')
		: '';
	const workeRretiredDateMonth = retiredDate
		? format(new Date(retiredDate.toString()), 'MM')
		: '';
	const workerRetiredDateDay = retiredDate
		? format(new Date(retiredDate.toString()), 'dd')
		: '';
	const retiredMonthLastDay = retiredDate
		? format(new Date(workerRetiredDateYear, workeRretiredDateMonth, 0), 'dd')
		: '';
	return workeRretiredDateMonth === month &&
		workerRetiredDateYear === year &&
		workerRetiredDateDay !== retiredMonthLastDay
		? true
		: false;
}

export function hasWorkedOneMonth(startDate, endDate) {
	// "YYYY-MM-DD" 형식의 문자열로 날짜를 변환합니다.
	const start = moment(startDate, 'YYYY-MM-DD');
	const end = moment(endDate, 'YYYY-MM-DD');

	// 퇴사일이 입사일로부터 한 달 후인지, 그 이후인지 확인합니다.
	if (end.isSameOrAfter(start.clone().add(1, 'months'))) {
		return true;
	} else {
		return false;
	}
}

export function weekNumberByMonth(dateFormat) {
	const inputDate = new Date(dateFormat);

	// 인풋의 년, 월
	let year = inputDate.getFullYear();
	let month = inputDate.getMonth() + 1;

	// 목요일 기준 주차 구하기
	const weekNumberByThurFnc = (paramDate) => {
		const year = paramDate.getFullYear();
		const month = paramDate.getMonth();
		const date = paramDate.getDate();

		// 인풋한 달의 첫 날과 마지막 날의 요일
		const firstDate = new Date(year, month, 1);
		const lastDate = new Date(year, month + 1, 0);
		const firstDayOfWeek = firstDate.getDay() === 0 ? 7 : firstDate.getDay();
		const lastDayOfweek = lastDate.getDay();

		// 인풋한 달의 마지막 일
		const lastDay = lastDate.getDate();

		// 첫 날의 요일이 금, 토, 일요일 이라면 true
		const firstWeekCheck =
			firstDayOfWeek === 5 || firstDayOfWeek === 6 || firstDayOfWeek === 7;
		// 마지막 날의 요일이 월, 화, 수라면 true
		const lastWeekCheck =
			lastDayOfweek === 1 || lastDayOfweek === 2 || lastDayOfweek === 3;

		// 해당 달이 총 몇주까지 있는지
		const lastWeekNo = Math.ceil((firstDayOfWeek - 1 + lastDay) / 7);

		// 날짜 기준으로 몇주차 인지
		let weekNo = Math.ceil((firstDayOfWeek - 1 + date) / 7);

		// 인풋한 날짜가 첫 주에 있고 첫 날이 월, 화, 수로 시작한다면 'prev'(전달 마지막 주)
		if (weekNo === 1 && firstWeekCheck) weekNo = 'prev';
		// 인풋한 날짜가 마지막 주에 있고 마지막 날이 월, 화, 수로 끝난다면 'next'(다음달 첫 주)
		else if (weekNo === lastWeekNo && lastWeekCheck) weekNo = 'next';
		// 인풋한 날짜의 첫 주는 아니지만 첫날이 월, 화 수로 시작하면 -1;
		else if (firstWeekCheck) weekNo = weekNo - 1;
		return weekNo;
	};

	// 목요일 기준의 주차
	let weekNo = weekNumberByThurFnc(inputDate);

	// 이전달의 마지막 주차일 떄
	if (weekNo === 'prev') {
		// 이전 달의 마지막날
		const afterDate = new Date(year, month - 1, 0);
		year = month === 1 ? year - 1 : year;
		month = month === 1 ? 12 : month - 1;
		weekNo = weekNumberByThurFnc(afterDate);
	}
	// 다음달의 첫 주차일 때
	if (weekNo === 'next') {
		year = month === 12 ? year + 1 : year;
		month = month === 12 ? 1 : month + 1;
		weekNo = 1;
	}

	return { year, month, weekNo };
}

// ps노무사회사명
export function psNomusaCompanyUid(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '부산광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '대구광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '인천광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '광주광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '대전광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '울산광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '세종특별자치시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경기도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '강원도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '충청북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '충청남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '전라북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '전라남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경상북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경상남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '제주특별자치도') {
		return '노무법인 최상인업';
	} else {
		return '노무법인 최상인업';
	}
}

// ps노무사회사명
export function psNomusaCompanyName(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '부산광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '대구광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '인천광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '광주광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '대전광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '울산광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '세종특별자치시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경기도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '강원도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '충청북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '충청남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '전라북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '전라남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경상북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경상남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '제주특별자치도') {
		return '노무법인 최상인업';
	} else {
		return '노무법인 최상인업';
	}
}

// ps대표 노무사명
export function psNomusaCeoName(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '김경모';
	} else if (companyRegion === '부산광역시') {
		return '김경모';
	} else if (companyRegion === '대구광역시') {
		return '김경모';
	} else if (companyRegion === '인천광역시') {
		return '김경모';
	} else if (companyRegion === '광주광역시') {
		return '김경모';
	} else if (companyRegion === '대전광역시') {
		return '김경모';
	} else if (companyRegion === '울산광역시') {
		return '김경모';
	} else if (companyRegion === '세종특별자치시') {
		return '김경모';
	} else if (companyRegion === '경기도') {
		return '김경모';
	} else if (companyRegion === '강원도') {
		return '김경모';
	} else if (companyRegion === '충청북도') {
		return '김경모';
	} else if (companyRegion === '충청남도') {
		return '김경모';
	} else if (companyRegion === '전라북도') {
		return '김경모';
	} else if (companyRegion === '전라남도') {
		return '김경모';
	} else if (companyRegion === '경상북도') {
		return '김경모';
	} else if (companyRegion === '경상남도') {
		return '김경모';
	} else if (companyRegion === '제주특별자치도') {
		return '김경모';
	} else {
		return '김경모';
	}
}

// ps담당노무사명
export function psNomusaName(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '김경모 노무사';
	} else if (companyRegion === '부산광역시') {
		return '김경모 노무사';
	} else if (companyRegion === '대구광역시') {
		return '김경모 노무사';
	} else if (companyRegion === '인천광역시') {
		return '김경모 노무사';
	} else if (companyRegion === '광주광역시') {
		return '김경모 노무사';
	} else if (companyRegion === '대전광역시') {
		return '김경모 노무사';
	} else if (companyRegion === '울산광역시') {
		return '김경모 노무사';
	} else if (companyRegion === '세종특별자치시') {
		return '김경모 노무사';
	} else if (companyRegion === '경기도') {
		return '김경모 노무사';
	} else if (companyRegion === '강원도') {
		return '김경모 노무사';
	} else if (companyRegion === '충청북도') {
		return '김경모 노무사';
	} else if (companyRegion === '충청남도') {
		return '김경모 노무사';
	} else if (companyRegion === '전라북도') {
		return '김경모 노무사';
	} else if (companyRegion === '전라남도') {
		return '김경모 노무사';
	} else if (companyRegion === '경상북도') {
		return '김경모 노무사';
	} else if (companyRegion === '경상남도') {
		return '김경모 노무사';
	} else if (companyRegion === '제주특별자치도') {
		return '김경모 노무사';
	} else {
		return '김경모 노무사';
	}
}

// ps노무사 연락처
export function psNomusaPhone(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '02-555-9356';
	} else if (companyRegion === '부산광역시') {
		return '02-555-9356';
	} else if (companyRegion === '대구광역시') {
		return '02-555-9356';
	} else if (companyRegion === '인천광역시') {
		return '02-555-9356';
	} else if (companyRegion === '광주광역시') {
		return '02-555-9356';
	} else if (companyRegion === '대전광역시') {
		return '02-555-9356';
	} else if (companyRegion === '울산광역시') {
		return '02-555-9356';
	} else if (companyRegion === '세종특별자치시') {
		return '02-555-9356';
	} else if (companyRegion === '경기도') {
		return '02-555-9356';
	} else if (companyRegion === '강원도') {
		return '02-555-9356';
	} else if (companyRegion === '충청북도') {
		return '02-555-9356';
	} else if (companyRegion === '충청남도') {
		return '02-555-9356';
	} else if (companyRegion === '전라북도') {
		return '02-555-9356';
	} else if (companyRegion === '전라남도') {
		return '02-555-9356';
	} else if (companyRegion === '경상북도') {
		return '02-555-9356';
	} else if (companyRegion === '경상남도') {
		return '02-555-9356';
	} else if (companyRegion === '제주특별자치도') {
		return '02-555-9356';
	} else {
		return '02-555-9356';
	}
}

// ps노무사 주소
export function psNomusaAddress(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '부산광역시') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '대구광역시') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '인천광역시') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '광주광역시') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '대전광역시') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '울산광역시') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '세종특별자치시') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '경기도') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '강원도') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '충청북도') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '충청남도') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '전라북도') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '전라남도') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '경상북도') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '경상남도') {
		return '세종시 갈매로 351 4115호';
	} else if (companyRegion === '제주특별자치도') {
		return '세종시 갈매로 351 4115호';
	} else {
		return '세종시 갈매로 351 4115호';
	}
}

// ps노무사회사명
export function psSemusaCompanyUid(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '부산광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '대구광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '인천광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '광주광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '대전광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '울산광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '세종특별자치시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경기도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '강원도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '충청북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '충청남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '전라북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '전라남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경상북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경상남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '제주특별자치도') {
		return '노무법인 최상인업';
	} else {
		return '노무법인 최상인업';
	}
}

// ps노무사회사명
export function psSemusaCompanyName(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '삼도회계법인';
	} else if (companyRegion === '부산광역시') {
		return '삼도회계법인';
	} else if (companyRegion === '대구광역시') {
		return '삼도회계법인';
	} else if (companyRegion === '인천광역시') {
		return '삼도회계법인';
	} else if (companyRegion === '광주광역시') {
		return '삼도회계법인';
	} else if (companyRegion === '대전광역시') {
		return '삼도회계법인';
	} else if (companyRegion === '울산광역시') {
		return '삼도회계법인';
	} else if (companyRegion === '세종특별자치시') {
		return '삼도회계법인';
	} else if (companyRegion === '경기도') {
		return '삼도회계법인';
	} else if (companyRegion === '강원도') {
		return '삼도회계법인';
	} else if (companyRegion === '충청북도') {
		return '삼도회계법인';
	} else if (companyRegion === '충청남도') {
		return '삼도회계법인';
	} else if (companyRegion === '전라북도') {
		return '삼도회계법인';
	} else if (companyRegion === '전라남도') {
		return '삼도회계법인';
	} else if (companyRegion === '경상북도') {
		return '삼도회계법인';
	} else if (companyRegion === '경상남도') {
		return '삼도회계법인';
	} else if (companyRegion === '제주특별자치도') {
		return '삼도회계법인';
	} else {
		return '삼도회계법인';
	}
}

// ps대표 세무사명
export function psSemusaCeoName(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '부산광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '대구광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '인천광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '광주광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '대전광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '울산광역시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '세종특별자치시') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경기도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '강원도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '충청북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '충청남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '전라북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '전라남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경상북도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '경상남도') {
		return '노무법인 최상인업';
	} else if (companyRegion === '제주특별자치도') {
		return '노무법인 최상인업';
	} else {
		return '노무법인 최상인업';
	}
}

// ps대표 노무사명
export function psSemusaPhone(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '010-6831-4859';
	} else if (companyRegion === '부산광역시') {
		return '010-6831-4859';
	} else if (companyRegion === '대구광역시') {
		return '010-6831-4859';
	} else if (companyRegion === '인천광역시') {
		return '010-6831-4859';
	} else if (companyRegion === '광주광역시') {
		return '010-6831-4859';
	} else if (companyRegion === '대전광역시') {
		return '010-6831-4859';
	} else if (companyRegion === '울산광역시') {
		return '010-6831-4859';
	} else if (companyRegion === '세종특별자치시') {
		return '010-6831-4859';
	} else if (companyRegion === '경기도') {
		return '010-6831-4859';
	} else if (companyRegion === '강원도') {
		return '010-6831-4859';
	} else if (companyRegion === '충청북도') {
		return '010-6831-4859';
	} else if (companyRegion === '충청남도') {
		return '010-6831-4859';
	} else if (companyRegion === '전라북도') {
		return '010-6831-4859';
	} else if (companyRegion === '전라남도') {
		return '010-6831-4859';
	} else if (companyRegion === '경상북도') {
		return '010-6831-4859';
	} else if (companyRegion === '경상남도') {
		return '010-6831-4859';
	} else if (companyRegion === '제주특별자치도') {
		return '010-6831-4859';
	} else {
		return '010-6831-4859';
	}
}

// ps담당노무사명
export function psSemusaName(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '윤재현 세무사';
	} else if (companyRegion === '부산광역시') {
		return '윤재현 세무사';
	} else if (companyRegion === '대구광역시') {
		return '윤재현 세무사';
	} else if (companyRegion === '인천광역시') {
		return '윤재현 세무사';
	} else if (companyRegion === '광주광역시') {
		return '윤재현 세무사';
	} else if (companyRegion === '대전광역시') {
		return '윤재현 세무사';
	} else if (companyRegion === '울산광역시') {
		return '윤재현 세무사';
	} else if (companyRegion === '세종특별자치시') {
		return '윤재현 세무사';
	} else if (companyRegion === '경기도') {
		return '윤재현 세무사';
	} else if (companyRegion === '강원도') {
		return '윤재현 세무사';
	} else if (companyRegion === '충청북도') {
		return '윤재현 세무사';
	} else if (companyRegion === '충청남도') {
		return '윤재현 세무사';
	} else if (companyRegion === '전라북도') {
		return '윤재현 세무사';
	} else if (companyRegion === '전라남도') {
		return '윤재현 세무사';
	} else if (companyRegion === '경상북도') {
		return '윤재현 세무사';
	} else if (companyRegion === '경상남도') {
		return '윤재현 세무사';
	} else if (companyRegion === '제주특별자치도') {
		return '윤재현 세무사';
	} else {
		return '윤재현 세무사';
	}
}

// ps노무사 연락처
export function psSemusaAddress(companyRegion) {
	if (companyRegion === '서울특별시') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '부산광역시') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '대구광역시') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '인천광역시') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '광주광역시') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '대전광역시') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '울산광역시') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '세종특별자치시') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '경기도') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '강원도') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '충청북도') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '충청남도') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '전라북도') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '전라남도') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '경상북도') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '경상남도') {
		return '서울시 서초구 사평대로 361';
	} else if (companyRegion === '제주특별자치도') {
		return '서울시 서초구 사평대로 361';
	} else {
		return '서울시 서초구 사평대로 361';
	}
}

//평일 연장근로수당 함수
export function workDayOTimePaidWageF(
	monthOWT,
	plusMonthOWT,
	dayWokerTimeWage,
	numOfWorkers,
	isExceptTimeLawWorker
) {
	const numOWT = numStringToNumber(monthOWT);
	const numPlusMonthOWT = numStringToNumber(plusMonthOWT);
	const numHourOrdWage = numStringToNumber(dayWokerTimeWage);
	const overTimeWage =
		isExceptTimeLawWorker || numOfWorkers < 5
			? (numOWT + numPlusMonthOWT) * numHourOrdWage * 1
			: (numOWT + numPlusMonthOWT) * numHourOrdWage * 1.5;
	return numberToCommaString(roundUp(overTimeWage));
}

//평일 야간근로수당 함수
export function workDayNTimePaidWageF(
	monthNWT,
	plusMonthNWT,
	dayWokerTimeWage,
	numOfWorkers,
	isExceptTimeLawWorker
) {
	const numNWT = numStringToNumber(monthNWT);
	const numPlusMonthNWT = numStringToNumber(plusMonthNWT);
	const numHourOrdWage = numStringToNumber(dayWokerTimeWage);
	const nightTimeWage =
		numOfWorkers < 5 ? 0 : (numNWT + numPlusMonthNWT) * numHourOrdWage * 0.5;
	return numberToCommaString(roundUp(nightTimeWage));
}

//휴일 기본근로수당 함수
export function holiBTimePaidWageF(
	monthHoliBasicTime,
	plusMonthHoliBasicTime,
	dayWokerTimeWage,
	numOfWorkers,
	isExceptTimeLawWorker
) {
	const numHBWT = numStringToNumber(monthHoliBasicTime);
	const numPlusMonthHoliBasicTime = numStringToNumber(plusMonthHoliBasicTime);
	const numHourOrdWage = numStringToNumber(dayWokerTimeWage);
	const holiBTimeWage =
		numOfWorkers < 5
			? (numHBWT + numPlusMonthHoliBasicTime) * numHourOrdWage * 1
			: (numHBWT + numPlusMonthHoliBasicTime) * numHourOrdWage * 1.5;
	return numberToCommaString(roundUp(holiBTimeWage));
}

//휴일 연장근로수당 함수
export function holiOTimePaidWageF(
	monthOWTHoli,
	plusMonthOWTHoli,
	dayWokerTimeWage,
	numOfWorkers,
	isExceptTimeLawWorker
) {
	const numHOWT = numStringToNumber(monthOWTHoli);
	const numPlusMonthOWTHoli = numStringToNumber(plusMonthOWTHoli);
	const numHourOrdWage = numStringToNumber(dayWokerTimeWage);
	const holiOTimeWage =
		numOfWorkers < 5
			? (numHOWT + numPlusMonthOWTHoli) * numHourOrdWage * 1
			: (numHOWT + numPlusMonthOWTHoli) * numHourOrdWage * 2;
	return numberToCommaString(roundUp(holiOTimeWage));
}

//휴일 야간근로수당 함수
export function holiNTimePaidWageF(
	monthNWTHoli,
	plusMonthNWTHoli,
	dayWokerTimeWage,
	numOfWorkers,
	isExceptTimeLawWorker
) {
	const numHNWT = numStringToNumber(monthNWTHoli);
	const numPlusMonthNWTHoli = numStringToNumber(plusMonthNWTHoli);
	const numHourOrdWage = numStringToNumber(dayWokerTimeWage);
	const holiNTimeWage =
		numOfWorkers < 5
			? 0
			: (numHNWT + numPlusMonthNWTHoli) * numHourOrdWage * 0.5;
	return numberToCommaString(roundUp(holiNTimeWage));
}

export function overTimeText(
	monthOWT,
	plusMonthOWT,
	dayWokerTimeWage,
	numOfWorkers,
	isExceptTimeLawWorker
) {
	const sumOfMonthOWT = roundUp2(+monthOWT + +plusMonthOWT);
	return numOfWorkers < 5 || isExceptTimeLawWorker
		? '연장 ' +
				numberToCommaString(sumOfMonthOWT) +
				' 시간 * 통상시급 ' +
				numberToCommaString(dayWokerTimeWage) +
				'원 * 1배'
		: '초과 ' +
				numberToCommaString(sumOfMonthOWT) +
				' 시간 * 통상시급 ' +
				numberToCommaString(dayWokerTimeWage) +
				'원 * 1.5배';
}

export function nightTimeText(
	monthNWT,
	plusMonthNWT,
	dayWokerTimeWage,
	numOfWorkers,
	isExceptTimeLawWorker
) {
	const sumOfMonthNWT = roundUp2(+monthNWT + +plusMonthNWT);
	return numOfWorkers >= 5
		? '소정근로일 22시~06시 야간근로 ' +
				sumOfMonthNWT +
				' 시간 * 통상시급 ' +
				numberToCommaString(dayWokerTimeWage) +
				'원 * 0.5배'
		: '상시근로자 5인미만 해당사항 없음.';
}

export function holiBasicTimeText(
	monthHoliBasicTime,
	plusMonthHoliBasicTime,
	dayWokerTimeWage,
	numOfWorkers,
	isExceptTimeLawWorker
) {
	const sumOfHoliBasic = roundUp2(
		+monthHoliBasicTime + +plusMonthHoliBasicTime
	);
	return numOfWorkers >= 5
		? '휴일 8시간 이내 기본 ' +
				numberToCommaString(sumOfHoliBasic) +
				' 시간 * 통상시급 ' +
				numberToCommaString(dayWokerTimeWage) +
				'원 * 1.5배'
		: '휴일 8시간 이내 기본 ' +
				numberToCommaString(sumOfHoliBasic) +
				' 시간 * 통상시급 ' +
				numberToCommaString(dayWokerTimeWage) +
				'원 * 1배';
}

export function holiOverTimeText(
	monthOWTHoli,
	plusMonthOWTHoli,
	dayWokerTimeWage,
	numOfWorkers,
	isExceptTimeLawWorker
) {
	const sumOfHoliOver = roundUp2(+monthOWTHoli + +plusMonthOWTHoli);
	return numOfWorkers >= 5
		? '휴일 8시간 이상 연장 ' +
				numberToCommaString(sumOfHoliOver) +
				' 시간 * 통상시급 ' +
				numberToCommaString(dayWokerTimeWage) +
				'원 * 2배'
		: '휴일 8시간 이상 초과 ' +
				numberToCommaString(sumOfHoliOver) +
				' 시간 * 통상시급 ' +
				numberToCommaString(dayWokerTimeWage) +
				'원 * 1배';
}

export function holiNightTimeText(
	monthNWTHoli,
	plusMonthNWTHoli,
	dayWokerTimeWage,
	numOfWorkers,
	isExceptTimeLawWorker
) {
	const sumOfHoliNight = roundUp2(+monthNWTHoli + +plusMonthNWTHoli);
	return numOfWorkers >= 5
		? '휴일 야간 ' +
				numberToCommaString(sumOfHoliNight) +
				' 시간 * 통상시급 ' +
				numberToCommaString(dayWokerTimeWage) +
				'원 * 0.5배'
		: '상시근로자 5인미만 해당사항 없음.';
}

export function dayWorkerTax(dayWorkerWage) {
	return (
		Math.floor(
			(dayWorkerWage < 187000 ? 0 : ((dayWorkerWage - 187000) * 2.7) / 100) / 10
		) * 10
	);
}
export function dayOverWorkerTax(dayWorkerOverWorkDayWage) {
	return (
		Math.floor(
			(dayWorkerOverWorkDayWage < 187000
				? 0
				: ((dayWorkerOverWorkDayWage - 187000) * 2.7) / 100) / 10
		) * 10
	);
}

export function dayHoliWorkerTax(dayWorkerHoliWage) {
	return (
		Math.floor(
			(dayWorkerHoliWage < 187000
				? 0
				: ((dayWorkerHoliWage - 187000) * 2.7) / 100) / 10
		) * 10
	);
}

export function taxText(taxPayType, dayWorker, dayWorkerTax) {
	return dayWorker && dayWorkerTax === '일용근로자세금'
		? taxPayType ===
		  '당월 과세 임금에 대한 소득세법상 일용직 근로소득세 ' + taxPayType
			? taxPayType
			: '100%'
		: taxPayType === '당월 과세 임금에 대한 소득세법상 근로소득세 ' + taxPayType
		? taxPayType
		: '100%';
}

export function regionTaxText(taxPayType, dayWorker, dayWorkerTax) {
	return dayWorker && dayWorkerTax === '일용근로자세금'
		? taxPayType ===
		  '당월 과세 임금에 대한 소득세법상 일용직 지방소득세 ' + taxPayType
			? taxPayType
			: '100%'
		: taxPayType === '당월 과세 임금에 대한 소득세법상 지방소득세 ' + taxPayType
		? taxPayType
		: '100%';
}

export function absentDayWageText( //결근공제 월통상임금 설명문구
	monthOrdinaryWage,
	daysInMonth,
	absentDays,
	isShortTimeRegularWorker,
	ordinaryDayWorkingTime,
	hourOrdinaryWage
) {
	let result;
	if (isShortTimeRegularWorker) {
		result = `통상시급 ${numberToCommaString(
			hourOrdinaryWage
		)}원 * 1일 통상근무시간 ${roundUp2(
			ordinaryDayWorkingTime
		)} * 결근 등 공제 일수 ${absentDays}일`;
	} else {
		result = `월통상임금 ${numberToCommaString(
			monthOrdinaryWage
		)}원 / 달력상 총 일수 ${daysInMonth}일 * 결근 등 공제 일수 ${absentDays}일`;
	}
	return result;
}

export function absentDaysOrdinaryWageF( //결근공제 월통상임금 금액
	monthOrdinaryWage,
	daysInMonth,
	numAbsentDays,
	isShortTimeRegularWorker,
	ordinaryDayWorkingTime,
	hourOrdinaryWage
) {
	let result;
	if (isShortTimeRegularWorker) {
		result = +ordinaryDayWorkingTime * +hourOrdinaryWage * numAbsentDays;
	} else {
		result = (monthOrdinaryWage / daysInMonth) * numAbsentDays;
	}
	return roundDown(result);
}

export function workingDaysInWeekF( //주근무일수
	day1Length,
	day2Length,
	day3Length,
	day4Length,
	day5Length,
	day6Length,
	day7Length
) {
	const result =
		day1Length +
		day2Length +
		day3Length +
		day4Length +
		day5Length +
		day6Length +
		day7Length;

	return result;
}

export function isShortTimeRegularWorkerF(weekBLTime) {
	//단시간근로자 여부
	const result = weekBLTime < 40 ? true : false;
	return result;
}

export function absentDaysLawOverWageF( //결근공제 약정법정가산수당 금액
	sumOfLawOverWage,
	daysInMonth,
	numAbsentDays,
	isShortTimeRegularWorker,
	workingDaysInWeek
) {
	let result;
	const lawOverWagePerDay = sumOfLawOverWage / 4.345 / workingDaysInWeek;

	if (isShortTimeRegularWorker) {
		result = lawOverWagePerDay * numAbsentDays;
	} else {
		result = (sumOfLawOverWage / daysInMonth) * numAbsentDays;
	}
	return roundDown(result);
}

export function absentDaysLawOverWageText( //결근공제 약정법정가산수당 설명문구
	sumOfLawOverWage,
	daysInMonth,
	absentDays,
	isShortTimeRegularWorker,
	workingDaysInWeek
) {
	let result;
	if (isShortTimeRegularWorker) {
		result = `약정 법정가산 수당 합계 ${numberToCommaString(
			sumOfLawOverWage
		)}원 / 4.345주 / 1주 근무일 ${workingDaysInWeek}일 * 결근 등 공제 일수 ${absentDays}일`;
	} else {
		result = `약정 법정가산 수당 합계 ${numberToCommaString(
			sumOfLawOverWage
		)}원 / 달력상 총 일수 ${daysInMonth}일 * 결근 등 공제일수 ${absentDays}일`;
	}
	return result;
}

export function daysOfInterInAbusentOrdinaryWageText(
	monthOrdinaryWage,
	daysInMonth,
	daysOfInterInAbusent
) {
	return `월통상임금 ${numberToCommaString(
		monthOrdinaryWage
	)}원 / 달력상 총 일수 ${daysInMonth}일 * 중도입사 공제일수 ${daysOfInterInAbusent}일`;
}

export function daysOfInterInAbusentLawOverWageText(
	sumOfLawOverWage,
	daysInMonth,
	daysOfInterInAbusent
) {
	return `약정 법정가산 수당 합계 ${numberToCommaString(
		sumOfLawOverWage
	)}원 / 달력상 총 일수 ${daysInMonth}일 * 중도입사 공제일수 ${daysOfInterInAbusent}일`;
}

export function daysOfInterOutAbusentOrdinaryWageText(
	monthOrdinaryWage,
	daysInMonth,
	daysOfInterOutAbusent
) {
	return `월통상임금 ${numberToCommaString(
		monthOrdinaryWage
	)}원 / 달력상 총 일수 ${daysInMonth}일 * 중도퇴사 공제일수 ${daysOfInterOutAbusent}일`;
}

export function daysOfInterOutAbusentLawOverWageText(
	sumOfLawOverWage,
	daysInMonth,
	daysOfInterOutAbusent
) {
	return `약정 법정가산 수당 합계 ${numberToCommaString(
		sumOfLawOverWage
	)}원 / 달력상 총 일수 ${daysInMonth}일 * 중도퇴사 공제일수 ${daysOfInterOutAbusent}일`;
}

export function sumOfBasicWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.basicWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfMealWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.mealWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCarWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.carWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfChildWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.childWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfStudyWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.studyWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfTFEtcWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.tFEtcWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfJobWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.jobWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfTitleWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.titleWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfLicenseWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.licenseWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfLongTermWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.longTermWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfMonthlyBonusWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.monthlyBonusWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfOWEtcWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.oWEtcWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCOWChildManageStudyWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.cOWChildManageStudyWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCOWChildStudyWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.cOWChildStudyWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCOWChildCareWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.cOWChildCareWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCOWChildActivityWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.fixedWage?.cOWChildActivityWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAnnualLeavesIncludeMonthWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.annualLeavesIncludeMonthWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfWorkDayOTimePaidWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.lawOverTimeWage?.workDayOTimePaidWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfWorkDayNTimePaidWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.lawOverTimeWage?.workDayNTimePaidWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfHoliBTimePaidWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.lawOverTimeWage?.holiBTimePaidWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfHoliOTimePaidWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.lawOverTimeWage?.holiOTimePaidWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfHoliNTimePaidWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.lawOverTimeWage?.holiNTimePaidWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageTF1WagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.userFixedCAWageTF1
				? searchResult?.fixedWage?.userFixedCAWageTF1
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageTF2WagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.userFixedCAWageTF2
				? searchResult?.fixedWage?.userFixedCAWageTF2
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageTF3WagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.userFixedCAWageTF3
				? searchResult?.fixedWage?.userFixedCAWageTF3
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageTF4WagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.userFixedCAWageTF4
				? searchResult?.fixedWage?.userFixedCAWageTF4
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWage1WagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.userFixedCAWage1
				? searchResult?.fixedWage?.userFixedCAWage1
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWage2WagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.userFixedCAWage2
				? searchResult?.fixedWage?.userFixedCAWage2
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWage3WagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.userFixedCAWage3
				? searchResult?.fixedWage?.userFixedCAWage3
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWage4WagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.userFixedCAWage4
				? searchResult?.fixedWage?.userFixedCAWage4
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusRegularBonusCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.addAverWage?.plusRegularBonus
				? searchResult?.addAverWage?.plusRegularBonus
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusIrregularBonusCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.addAverWage?.plusIrregularBonus
				? searchResult?.addAverWage?.plusIrregularBonus
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusUnusedAnnualLeavesWageCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.addAverWage?.plusUnusedAnnualLeavesWage
				? searchResult?.addAverWage?.plusUnusedAnnualLeavesWage
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusLastYearUnusedAnnualLeavesWageCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.addAverWage?.plusLastYearUnusedAnnualLeavesWage
				? searchResult?.addAverWage?.plusLastYearUnusedAnnualLeavesWage
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusLastMonthAverWageCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.addAverWage?.plusLastMonthAverWage
				? searchResult?.addAverWage?.plusLastMonthAverWage
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusPersonalIncentiveCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.addAverWage?.plusPersonalIncentive
				? searchResult?.addAverWage?.plusPersonalIncentive
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWage5WagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.addAverWage?.aWage5Wage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWage6WagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.addAverWage?.aWage6Wage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}
export function sumOfAWage7WagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.addAverWage?.aWage7Wage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusIrregularDayMealTFWageCom(searchResults) {
	return searchResults
		.map(
			(searchResult) => searchResult?.addAverWage?.plusIrregularDayMealTFWage
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAbsentDaysOrdinaryWagesCom(searchResults) {
	return searchResults
		.map(
			(searchResult) => searchResult?.absentMinusWage?.absentDaysOrdinaryWage
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAbsentPaidHoliDaysOrdinaryWagesCom(searchResults) {
	return searchResults
		.map(
			(searchResult) =>
				searchResult?.absentMinusWage?.absentPaidHoliDaysOrdinaryWage
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAbsentDaysLawOverWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.absentMinusWage?.absentDaysLawOverWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdaysOfInterInAbusentOrdinaryWagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterInAbusentOrdinaryWage
				? searchResult?.absentMinusWage?.daysOfInterInAbusentOrdinaryWage
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdaysOfInterInAbusentLawOverWagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterInAbusentLawOverWage
				? searchResult?.absentMinusWage?.daysOfInterInAbusentLawOverWage
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdaysOfInterOutAbusentOrdinaryWagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterOutAbusentOrdinaryWage
				? searchResult?.absentMinusWage?.daysOfInterOutAbusentOrdinaryWage
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdaysOfInterOutAbusentLawOverWagesCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterOutAbusentLawOverWage
				? searchResult?.absentMinusWage?.daysOfInterOutAbusentLawOverWage
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAbsentTimeWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.absentMinusWage?.absentTimeWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfMaternityFinalDedectLawOverWagesCom(searchResults) {
	return searchResults
		.map(
			(searchResult) =>
				searchResult?.absentMinusWage?.maternityFinalDedectLawOverWage
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfMaternityFinalDedectOrdWagesCom(searchResults) {
	return searchResults
		.map(
			(searchResult) =>
				searchResult?.absentMinusWage?.maternityFinalDedectOrdWage
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusDeductHealthCareCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.plusDeductHealthCare
				? searchResult?.deductTaxAnd4In?.plusDeductHealthCare
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusDeductOldCareCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.plusDeductOldCare
				? searchResult?.deductTaxAnd4In?.plusDeductOldCare
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}
export function sumOfAWageplusDeductWorkTaxCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.plusDeductWorkTax
				? searchResult?.deductTaxAnd4In?.plusDeductWorkTax
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusDeductWorkRegionTaxCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.plusDeductWorkRegionTax
				? searchResult?.deductTaxAnd4In?.plusDeductWorkRegionTax
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfFinalRetireHealthCareRecalFeeCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.finalRetireHealthCareRecalFee
				? searchResult?.deductTaxAnd4In?.finalRetireHealthCareRecalFee
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfFinalRetireOldCareRecalFeeCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.finalRetireOldCareRecalFee
				? searchResult?.deductTaxAnd4In?.finalRetireOldCareRecalFee
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusDeductDuruCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.plusDeductDuru
				? searchResult?.deductTaxAnd4In?.plusDeductDuru
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusMinus4InssCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.plusMinus4Ins
				? searchResult?.deductTaxAnd4In?.plusMinus4Ins
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusDeductWorkerCompanyLoanCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.plusDeductWorkerCompanyLoan
				? searchResult?.deductTaxAnd4In?.plusDeductWorkerCompanyLoan
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfNumDeductEtcAWage1sCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.deductTaxAnd4In?.numDeductEtcAWage1)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfNumDeductEtcAWage2sCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.deductTaxAnd4In?.numDeductEtcAWage2)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfNumDeductEtcAWage3sCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.deductTaxAnd4In?.numDeductEtcAWage3)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCalTaxsSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.userFixedTax !== '' &&
			!isNaN(searchResult?.deductTaxAnd4In?.userFixedTax)
				? searchResult?.deductTaxAnd4In?.userFixedTax
				: searchResult?.deductTaxAnd4In?.calTax
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCalRegionTaxsSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.userFixedRegionTax !== '' &&
			!isNaN(searchResult?.deductTaxAnd4In?.userFixedRegionTax)
				? searchResult?.deductTaxAnd4In?.userFixedRegionTax
				: searchResult?.deductTaxAnd4In?.calRegionTax
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCalPensionsSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.userFixedPension !== '' &&
			!isNaN(searchResult?.deductTaxAnd4In?.userFixedPension)
				? searchResult?.deductTaxAnd4In?.userFixedPension
				: searchResult?.deductTaxAnd4In?.calPension
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCalHealthCaresSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.userFixedHealthCare !== '' &&
			!isNaN(searchResult?.deductTaxAnd4In?.userFixedHealthCare)
				? searchResult?.deductTaxAnd4In?.userFixedHealthCare
				: searchResult?.deductTaxAnd4In?.calHealthCare
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCalOldCaresSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.userFixedOldCare !== '' &&
			!isNaN(searchResult?.deductTaxAnd4In?.userFixedOldCare)
				? searchResult?.deductTaxAnd4In?.userFixedOldCare
				: searchResult?.deductTaxAnd4In?.calOldCare
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfCalNoJobInsurancesSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.userFixedNoJobInsurance !== '' &&
			!isNaN(searchResult?.deductTaxAnd4In?.userFixedNoJobInsurance)
				? searchResult?.deductTaxAnd4In?.userFixedNoJobInsurance
				: searchResult?.deductTaxAnd4In?.calNoJobInsurance
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfDedectTaxEtcsSumCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.deductTaxAnd4In?.dedectTaxEtc)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfMonthWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => searchResult?.info?.totalMonthWageAmount)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfMonthDeductedWagesCom(searchResults) {
	return searchResults
		.map((searchResult) => Number(searchResult?.info?.deductWage) || 0)
		.reduce((prev, curr) => prev + curr, 0);
}

export function sumOfMonthDeductedTaxWagesCom(searchResults) {
	return searchResults
		.map(
			(searchResult) => Number(searchResult?.deductTaxAnd4In?.taxWageSum) || 0
		)
		.reduce((prev, curr) => prev + curr, 0);
}

export function sumOfMonthDeductedTaxFreeWagesCom(searchResults) {
	return searchResults
		.map(
			(searchResult) =>
				Number(searchResult?.deductTaxAnd4In?.taxFreeWageSum) || 0
		)
		.reduce((prev, curr) => prev + curr, 0);
}

// 근로자 합계
export function sumOfUsedALsWorker(paydocus) {
	return paydocus
		.map((workerData) => workerData?.usedAnnualLeaves)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfMonthWagesWorker(paydocus) {
	return paydocus
		.map((workerData) => workerData?.info?.totalMonthWageAmount)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfMonthDeductedWagesWorker(paydocus) {
	return paydocus
		.map((workerData) => workerData?.info?.deductWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOftotalDayWorkerWagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.totalDayWorkerWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOftotalTimeWorkerWagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.totalTimeWorkerWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOftotalDayWorkerHoliWagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.totalDayWorkerHoliWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOftotalTimeDayWorkerPaidHoliDaysCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.paidHoliWageDays)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOftotalTimeDayWorkerRealWorkingDaysCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.workerInfo?.workDates?.length)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOftotalDayWorkerOverWorkDayWagesCom(paydocus) {
	return paydocus
		.map(
			(workerData) => workerData?.dayWorkerInfo?.totalDayWorkerOverWorkDayWage
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCAWage5sCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerCAWage5)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCAWage6sCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerCAWage6)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCAWage7sCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerCAWage7)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfnumDayWorkerOWagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numDayWorkerOWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfnumOWageForTWCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numOWageForTW)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sunOfnumNWageForTWCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numNWageForTW)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfnumOffWageForTWCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numOffWageForTW)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfnumOffOWageForTWCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numOffOWageForTW)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfnumHBWageForTWCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numHBWageForTW)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfnumHOWageForTWCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numHOWageForTW)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfnumDayWorkerNWagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numDayWorkerNWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfnumDayWorkerHBwagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numDayWorkerHBwage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfnumDayWorkerHOwagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numDayWorkerHOwage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfnumDayWorkerHNwagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numDayWorkerHNwage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerAbsentTimeWagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerAbsentTimeWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfTimeWorkerSpecialBonusCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.timeWorkerSpecialBonus)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfDayWorkerSpecialBonusCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerSpecialBonus)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerTotalMonthWageAmountsCom(paydocus) {
	return paydocus
		.map(
			(workerData) => workerData?.dayWorkerInfo?.dayWorkerTotalMonthWageAmount
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerUserFixedTaxsCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerUserFixedTax)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalTaxsCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerCalTax)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerUserFixedRegionTaxsCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerUserFixedRegionTax)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalRegionTaxsCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerCalRegionTax)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerUserFixedHealthCaresCom(paydocus) {
	return paydocus
		.map(
			(workerData) => workerData?.dayWorkerInfo?.dayWorkerUserFixedHealthCare
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalHealthCaresCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerCalHealthCare)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerUserFixedOldCaresCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerUserFixedOldCare)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalOldCaresCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerCalOldCare)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerUserFixedPensionsCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerUserFixedPension)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalPensionsCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerCalPension)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerUserFixedNoJobInsurancesCom(paydocus) {
	return paydocus
		.map(
			(workerData) =>
				workerData?.dayWorkerInfo?.dayWorkerUserFixedNoJobInsurance
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalNoJobInsurancesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerCalNoJobInsurance)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerPlusDeductHealthCaresCom(paydocus) {
	return paydocus
		.map(
			(workerData) => workerData?.dayWorkerInfo?.dayWorkerPlusDeductHealthCare
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerPlusDeductOldCaresCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerPlusDeductOldCare)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerPlusDeductWorkTaxsCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerPlusDeductWorkTax)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerPlusDeductWorkRegionTaxsCom(paydocus) {
	return paydocus
		.map(
			(workerData) =>
				workerData?.dayWorkerInfo?.dayWorkerPlusDeductWorkRegionTax
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerPlusDeductDurusCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerPlusDeductDuru)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}
export function sumOfdayWorkerPlusMinus4InssCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerPlusMinus4Ins)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}
export function sumOfdayWorkerPlusDeductWorkerCompanyLoansCom(paydocus) {
	return paydocus
		.map(
			(workerData) =>
				workerData?.dayWorkerInfo?.dayWorkerPlusDeductWorkerCompanyLoan
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerDeductEtcWage1sCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerDeductEtcWage1)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerDeductEtcWage2sCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerDeductEtcWage2)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerDeductEtcWage3sCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerDeductEtcWage3)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerDedectTaxEtcsCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerDedectTaxEtc)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerDeductWagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerDeductWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalTaxsSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.dayWorkerInfo?.dayWorkerUserFixedTax !== '' &&
			!isNaN(searchResult?.dayWorkerInfo?.dayWorkerUserFixedTax)
				? searchResult?.dayWorkerInfo?.dayWorkerUserFixedTax
				: searchResult?.dayWorkerInfo?.dayWorkerCalTax
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalRegionTaxsSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.dayWorkerInfo?.dayWorkerUserFixedRegionTax !== '' &&
			!isNaN(searchResult?.dayWorkerInfo?.dayWorkerUserFixedRegionTax)
				? searchResult?.dayWorkerInfo?.dayWorkerUserFixedRegionTax
				: searchResult?.dayWorkerInfo?.dayWorkerCalRegionTax
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalPensionsSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.dayWorkerInfo?.dayWorkerUserFixedPension !== '' &&
			!isNaN(searchResult?.dayWorkerInfo?.dayWorkerUserFixedPension)
				? searchResult?.dayWorkerInfo?.dayWorkerUserFixedPension
				: searchResult?.dayWorkerInfo?.dayWorkerCalPension
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalHealthCaresSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.dayWorkerInfo?.dayWorkerUserFixedHealthCare !== '' &&
			!isNaN(searchResult?.dayWorkerInfo?.dayWorkerUserFixedHealthCare)
				? searchResult?.dayWorkerInfo?.dayWorkerUserFixedHealthCare
				: searchResult?.dayWorkerInfo?.dayWorkerCalHealthCare
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalOldCaresSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.dayWorkerInfo?.dayWorkerUserFixedOldCare !== '' &&
			!isNaN(searchResult?.dayWorkerInfo?.dayWorkerUserFixedOldCare)
				? searchResult?.dayWorkerInfo?.dayWorkerUserFixedOldCare
				: searchResult?.dayWorkerInfo?.dayWorkerCalOldCare
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerCalNoJobInsurancesSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.dayWorkerInfo?.dayWorkerUserFixedNoJobInsurance !== '' &&
			!isNaN(searchResult?.dayWorkerInfo?.dayWorkerUserFixedNoJobInsurance)
				? searchResult?.dayWorkerInfo?.dayWorkerUserFixedNoJobInsurance
				: searchResult?.dayWorkerInfo?.dayWorkerCalNoJobInsurance
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerMonthWagesWorker(paydocus) {
	return paydocus
		.map(
			(workerData) => workerData?.dayWorkerInfo?.dayWorkerTotalMonthWageAmount
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdayWorkerMonthDeductedWagesWorker(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.dayWorkerDeductWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function paydacOwnerCompanyName() {
	return '노무법인 최상인업';
}

export function numStringToNumber(value) {
	return typeof value === 'string'
		? value < 1
			? +value
			: commaStringToNumber(value)
		: value;
}

export function sumOfAWageplusRetiredUnusedAnnualLeavesWageCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.plusRetiredUnusedAnnualLeavesWage
				? searchResult?.plusRetiredUnusedAnnualLeavesWage
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAWageplusSpecialRandomWageCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.plusSpecialRandomWage
				? searchResult?.plusSpecialRandomWage
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAnnualLeavesIncludeMonthWageCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.annualLeavesIncludeMonthWage
				? searchResult?.annualLeavesIncludeMonthWage
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfJobImproveProposalSumHr(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.hrEvaluationInfo?.jobImproveProposal[0]
				? searchResult?.hrEvaluationInfo?.jobImproveProposal[0]
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfQualityOfWorkSumHr(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.hrEvaluationInfo?.qualityOfWork[0]
				? searchResult?.hrEvaluationInfo?.qualityOfWork[0]
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAmounOfWorkSumHr(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.hrEvaluationInfo?.amounOfWork[0]
				? searchResult?.hrEvaluationInfo?.amounOfWork[0]
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfSupportColleagueSumHr(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.hrEvaluationInfo?.supportColleague[0]
				? searchResult?.hrEvaluationInfo?.supportColleague[0]
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfAttenanceManageSumHr(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.hrEvaluationInfo?.attenanceManage[0]
				? searchResult?.hrEvaluationInfo?.attenanceManage[0]
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfSupportCumstomerSumHr(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.hrEvaluationInfo?.supportCumstomer[0]
				? searchResult?.hrEvaluationInfo?.supportCumstomer[0]
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfSelfStudySumHr(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.hrEvaluationInfo?.selfStudy[0]
				? searchResult?.hrEvaluationInfo?.selfStudy[0]
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfReportAttitudeSumHr(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.hrEvaluationInfo?.reportAttitude[0]
				? searchResult?.hrEvaluationInfo?.reportAttitude[0]
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfPersonnalEveTotalSumHr(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.hrEvaluationInfo?.personnalEveSum
				? searchResult?.hrEvaluationInfo?.personnalEveSum
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

//주휴수당이 포함된 시급 대상자 주휴수당 합계
export function sumOfTotalTimeWorkerPaidHoliWagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.totalTimeWorkerPaidHoliWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

//주14시간 이상만 주휴수당 계산
export function sumOftotalPaidHoliTimeWagesCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.dayWorkerInfo?.numPaidHoliTimeW)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}
//주휴미포함만 계산
export function sumOftotalPaidHoliTimeWagesNotIncludeCom(paydocus) {
	return paydocus
		.map((workerData) =>
			workerData?.dayWorkerInfo?.week14OverBLTime === '주휴수당 미포함'
				? workerData?.dayWorkerInfo?.numPaidHoliTimeW
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfTotalMonthWageAmountsCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.info?.totalMonthWageAmount)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfRetirementPensionDCMonthsCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.fixedWage?.retirementPensionDCMonths)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfBizIncomeCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.bizIncome)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfBizTotalIncomeCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.bizTotalIncome)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfBizIncomeTaxCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.bizIncomeTax)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfBizIncomeRegionTaxCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.bizIncomeRegionTax)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfBizDeductWageCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.bizDeductWage)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfBizDeductAfterTaxCom(paydocus) {
	return paydocus
		.map((workerData) => workerData?.bizDeductionAfterTax)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfPersonnalEveRateSumHr(searchResults) {
	const lengthOfsearchResults = searchResults ? searchResults.length : 1;
	const sumOfPersonnalEveRate = searchResults
		.map((searchResult) =>
			searchResult?.hrEvaluationInfo?.personnalEveRate
				? searchResult?.hrEvaluationInfo?.personnalEveRate
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
	return sumOfPersonnalEveRate / lengthOfsearchResults;
}

export function sumOfgovSupportMeal(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.govSupportMeal
				? searchResult?.fixedWage?.govSupportMeal
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfgovSupportOvertimeMoney(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.govSupportOvertimeMoney
				? searchResult?.fixedWage?.govSupportOvertimeMoney
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}
export function sumOfgovSupportLongtermMoney(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.govSupportLongtermMoney
				? searchResult?.fixedWage?.govSupportLongtermMoney
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfgovSupportImproveMoney(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.govSupportImproveMoney
				? searchResult?.fixedWage?.govSupportImproveMoney
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfgovSupportImproveEmvMoney(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.govSupportImproveEmvMoney
				? searchResult?.fixedWage?.govSupportImproveEmvMoney
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfgovSupportDisabledMoney(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.govSupportDisabledMoney
				? searchResult?.fixedWage?.govSupportDisabledMoney
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfgovSupportChildcareMoney(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.fixedWage?.govSupportChildcareMoney
				? searchResult?.fixedWage?.govSupportChildcareMoney
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfdcRetireFund(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.info?.totalMonthWageAmount
				? roundUp(searchResult?.info?.totalMonthWageAmount / 12) //월급액을 12로 나누어 연금보험료 계산
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfsumOfgovSupport(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.info?.sumOfGovSupport
				? searchResult?.info?.sumOfGovSupport
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

export function sumOfDedectTaxEtcPlusGovSupport(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.info?.dedectTaxEtcPlusGovSupport
				? searchResult?.info?.dedectTaxEtcPlusGovSupport
				: 0
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}

//해당월 평균임금(해당월 수령액 - 상여, 연차, 특상, 퇴직연차)
export function averWageOfOneMonth(
	targetDate,
	yearMonth,
	daysInMonth,
	calDays,
	paydocus
) {
	const retireMonthTotalWageArray = paydocus.map((paydocu) =>
		targetDate && paydocu.info.yearMonth === yearMonth
			? paydocu.info.totalMonthWageAmount
			: ''
	);
	const retireMonthTotalWage = retireMonthTotalWageArray.find((a) => a);

	const annualWageArray = paydocus.map((paydocu) =>
		targetDate && paydocu.info.yearMonth === yearMonth
			? paydocu.addAverWage.plusUnusedAnnualLeavesWage
			: ''
	);
	const annualWageTemp = annualWageArray.find((a) => a);
	const annualWage = annualWageTemp ? annualWageTemp : 0;

	const retireUnusedMonthAnnualWageArray = paydocus.map((paydocu) =>
		targetDate && paydocu.info.yearMonth === yearMonth
			? paydocu.plusRetiredUnusedAnnualLeavesWage
			: ''
	);
	const retireUnusedMonthAnnualWageTemp = retireUnusedMonthAnnualWageArray.find(
		(a) => a
	);
	const retireUnusedMonthAnnualWage = retireUnusedMonthAnnualWageTemp
		? retireUnusedMonthAnnualWageTemp
		: 0;

	const specialRandomWageArray = paydocus.map((paydocu) =>
		targetDate && paydocu.info.yearMonth === yearMonth
			? paydocu.plusSpecialRandomWage
			: ''
	);
	const specialRandomWageTemp = specialRandomWageArray.find((a) => a);
	const specialRandomWage = specialRandomWageTemp ? specialRandomWageTemp : 0;

	const bonusWageArray = paydocus.map((paydocu) =>
		targetDate && paydocu.info.yearMonth === yearMonth
			? paydocu.addAverWage.plusRegularBonus +
			  paydocu.addAverWage.plusIrregularBonus
			: ''
	);
	const bonusWageTemp = bonusWageArray.find((a) => a);
	const bonusWage = bonusWageTemp ? bonusWageTemp : 0;

	const realAverWageOfRetiredMonth =
		retireMonthTotalWage -
		annualWage -
		bonusWage -
		retireUnusedMonthAnnualWage -
		specialRandomWage;
	return roundUp((realAverWageOfRetiredMonth / daysInMonth) * calDays);
}

export const lowestTimeWageOf2024 = 9860;
export const lowestTimeWageOf2025 = 10030;

export function lawlowestWageF(
	year,
	sumOfTFWagesForBLLowWage,
	cOWMonthlyBonus,
	numOfWorkers,
	cTotalMonthWageAmount,
	sumOfAWages,
	monthOrdinaryWage,
	mTPCTime,
	cOWDayHourWage,
	monthLBTime,
	daylyPay,
	week14OverBLTime,
	workEndTime,
	dayType,
	workStartTime,
	dayRest,
	nightRest
) {
	// 법정연도별 최저임금
	const lBLowestHourWage =
		year === 2025
			? lowestTimeWageOf2025
			: year === 2024
			? lowestTimeWageOf2024
			: year === 2023
			? 9620
			: year === 2022
			? 9160
			: year === 2021
			? 8720
			: year === 2020
			? 8590
			: year === 2019
			? 8350
			: 0;
	const lBLowestMonthWage = lBLowestHourWage * 209;
	// 법정복리후생비 공제 비율 및 금액
	const lBWelfareFeeDeductRate =
		year === 2023
			? 0.01
			: year === 2022
			? 0.02
			: year === 2021
			? 0.03
			: year === 2020
			? 0.05
			: year === 2019
			? 0.07
			: 0;
	const lBWelfareFeeDeductWage = lBLowestMonthWage * lBWelfareFeeDeductRate;
	// 법정월정기상여금 공제 비율 및 금액
	const lBMonthBonusDeductRate =
		year === 2023
			? 0.05
			: year === 2022
			? 0.1
			: year === 2021
			? 0.15
			: year === 2020
			? 0.2
			: year === 2019
			? 0.25
			: 0;
	const lBMonthBonusDeductWage = lBLowestMonthWage * lBMonthBonusDeductRate;

	// 계산된 최저임금 복리후생비 공제액
	const calWelfareWage =
		sumOfTFWagesForBLLowWage > lBWelfareFeeDeductWage
			? sumOfTFWagesForBLLowWage - lBWelfareFeeDeductWage
			: 0;
	// 계산된 최저임금 월상여금 공제액
	const calMonthBonus =
		cOWMonthlyBonus > lBMonthBonusDeductWage
			? cOWMonthlyBonus - lBMonthBonusDeductWage
			: 0;

	// 계산된 최저임금 대상금액(전체월급 - 평균임금수당 - 공제액)
	const calTotalMonthLowestWage =
		numOfWorkers < 5
			? cTotalMonthWageAmount -
			  sumOfTFWagesForBLLowWage -
			  cOWMonthlyBonus -
			  sumOfAWages +
			  calWelfareWage +
			  calMonthBonus
			: monthOrdinaryWage -
			  sumOfTFWagesForBLLowWage -
			  cOWMonthlyBonus +
			  calWelfareWage +
			  calMonthBonus;

	// 계산된 회사 최저 시급
	const calHourLowestWage =
		numOfWorkers < 5
			? calTotalMonthLowestWage / mTPCTime +
			  (cOWDayHourWage ? cOWDayHourWage : 0)
			: calTotalMonthLowestWage / monthLBTime +
			  (cOWDayHourWage ? cOWDayHourWage : 0); // ------------------

	// 최저임금법 위반 판단
	const judgeLowestWageResult = daylyPay
		? null
		: calHourLowestWage >= lBLowestHourWage
		? true
		: false;

	// // 해당년도 해당 업체 근무시간에 따른 최저임금(월급)
	// const calLBMonthLowestWage = lBLowestHourWage * mTPCTime + sumOfAWages;
	// const insufficientWageResult = clientReq4In.daylyPay ? null : (calLBMonthLowestWage-cTotalMonthWageAmount > 0 ? calLBMonthLowestWage - cTotalMonthWageAmount : 0);

	// 해당년도 해당 업체 근무시간에 따른 최저임금(월급)
	const calLawBaseWageMinusCompanyBaseWage =
		judgeLowestWageResult === false
			? ((lBLowestHourWage * monthLBTime - calHourLowestWage * monthLBTime) *
					mTPCTime) /
			  monthLBTime
			: 0;
	// const calLBMonthLowestWage = lBLowestHourWage * mTPCTime + sumOfAWages;
	const insufficientWageResult = daylyPay
		? null
		: calLawBaseWageMinusCompanyBaseWage > 0
		? roundUp(calLawBaseWageMinusCompanyBaseWage)
		: 0;

	const workingTimeDayTotal = daylyPay
		? workingTimeDayTotalAmount(
				workEndTime,
				dayType,
				workStartTime,
				dayRest,
				nightRest
		  )
		: null;
	const workingTimeDayNight = daylyPay
		? numOfWorkers < 5
			? 0
			: workingTimeDayNightAmount(
					workStartTime,
					workEndTime,
					dayType,
					nightRest
			  )
		: null;
	const workingTimeDayOver = daylyPay
		? numOfWorkers < 5
			? 0
			: workingTimeDayTotal > 8
			? workingTimeDayTotal - 8
			: 0
		: null;
	const workingTimeDayBasic = daylyPay
		? numOfWorkers < 5
			? 0
			: workingTimeDayTotal >= 8
			? 8
			: workingTimeDayTotal
		: null;
	const dayWorkerPaidHolidayLBTime =
		week14OverBLTime === '주휴수당 포함'
			? workingTimeDayTotal >= 8
				? 8 * 0.2
				: workingTimeDayTotal * 0.2
			: 0;

	const dayTotalPaidConversedTime = daylyPay
		? numOfWorkers < 5
			? workingTimeDayTotal + dayWorkerPaidHolidayLBTime
			: workingTimeDayBasic +
			  workingTimeDayNight * 0.5 +
			  workingTimeDayOver * 1.5 +
			  dayWorkerPaidHolidayLBTime
		: null;
	const realTimeWage = daylyPay ? daylyPay / dayTotalPaidConversedTime : null;
	const insufficientTimeWage = daylyPay
		? lBLowestHourWage - realTimeWage > 0
			? lBLowestHourWage - realTimeWage
			: 0
		: null;
	const insufficientDayWage = daylyPay
		? insufficientTimeWage === 0
			? 0
			: insufficientTimeWage * dayTotalPaidConversedTime
		: null;

	return {
		calTotalMonthLowestWage: calTotalMonthLowestWage,
		insufficientWageResult: insufficientWageResult,
		judgeLowestWageResult: judgeLowestWageResult,
		calLawBaseWageMinusCompanyBaseWage: calLawBaseWageMinusCompanyBaseWage,
		calHourLowestWage: calHourLowestWage, //일용
		insufficientDayWage: insufficientDayWage, //일용
		dayWorkerPaidHolidayLBTime: dayWorkerPaidHolidayLBTime, //일용
		dayTotalPaidConversedTime: dayTotalPaidConversedTime, //일용
		realTimeWage: realTimeWage, //일용
	};
}

export function absentDaysYearCntForALF(enterDate, paydocus, workerId) {
	const oneYearMonMinusOne = moment(enterDate)
		.add(11, 'months')
		.format('yyyy-MM');
	const thisYear = format(new Date(), 'yyyy');
	const lastYear = +thisYear - 1;

	let arrayAbsentDaysCount = [];
	let arrayAbsentYearMonth = [];
	let lessOneYearAbsentYearMonth = {};
	let lessOneYearAbsentYearMonthLastYear = [];
	let lessOneYearAbsentYearMonthThisYear = [];
	let lessOneYearAbsentYearMonthLastYearCnt = [];
	let lessOneYearAbsentYearMonthThisYearCnt = [];
	for (let i in paydocus) {
		if (
			workerId === paydocus[i]?.workerInfo?.workerId &&
			paydocus[i]?.info.yearMonth <= oneYearMonMinusOne.replace('-', '')
		) {
			if (paydocus[i]?.absentMinusWage?.absentDays) {
				arrayAbsentDaysCount.push(paydocus[i]?.absentMinusWage?.absentDays);
				arrayAbsentYearMonth.push(paydocus[i].info.yearMonth);
			}
			for (let j = 0; j < arrayAbsentYearMonth.length; j++) {
				lessOneYearAbsentYearMonth[arrayAbsentYearMonth[j]] =
					arrayAbsentDaysCount[j];
			}
		}
	}
	for (let k = 0; k < arrayAbsentYearMonth.length; k++) {
		if (arrayAbsentYearMonth[k]?.substring(0, 4) === lastYear.toString()) {
			lessOneYearAbsentYearMonthLastYear.push(arrayAbsentYearMonth[k]);
		}
		if (arrayAbsentYearMonth[k]?.substring(0, 4) === thisYear.toString()) {
			lessOneYearAbsentYearMonthThisYear.push(arrayAbsentYearMonth[k]);
		}
	}
	for (let l = 0; l < lessOneYearAbsentYearMonthLastYear.length; l++) {
		lessOneYearAbsentYearMonthLastYearCnt.push(arrayAbsentDaysCount[l]);
	}
	for (
		let m = lessOneYearAbsentYearMonthLastYear.length;
		m < arrayAbsentYearMonth.length;
		m++
	) {
		lessOneYearAbsentYearMonthThisYearCnt.push(arrayAbsentDaysCount[m]);
	}
	return [
		workerId,
		lessOneYearAbsentYearMonthLastYear.length,
		lessOneYearAbsentYearMonthThisYearCnt.length,
	];
}

export function absentDaysYearCntForALFSpecificYear(
	enterDate,
	paydocus,
	workerId,
	specificYear
) {
	const oneYearMonMinusOne = moment(enterDate)
		.add(11, 'months')
		.format('yyyy-MM');
	const thisYear = specificYear;
	const lastYear = +thisYear - 1;

	let arrayAbsentDaysCount = [];
	let arrayAbsentYearMonth = [];
	let lessOneYearAbsentYearMonth = {};
	let lessOneYearAbsentYearMonthLastYear = [];
	let lessOneYearAbsentYearMonthThisYear = [];
	let lessOneYearAbsentYearMonthLastYearCnt = [];
	let lessOneYearAbsentYearMonthThisYearCnt = [];
	for (let i in paydocus) {
		if (
			workerId === paydocus[i]?.workerInfo?.workerId &&
			paydocus[i]?.info.yearMonth <= oneYearMonMinusOne.replace('-', '')
		) {
			if (paydocus[i]?.absentMinusWage?.absentDays) {
				arrayAbsentDaysCount.push(paydocus[i]?.absentMinusWage?.absentDays);
				arrayAbsentYearMonth.push(paydocus[i].info.yearMonth);
			}
			for (let j = 0; j < arrayAbsentYearMonth.length; j++) {
				lessOneYearAbsentYearMonth[arrayAbsentYearMonth[j]] =
					arrayAbsentDaysCount[j];
			}
		}
	}
	for (let k = 0; k < arrayAbsentYearMonth.length; k++) {
		if (arrayAbsentYearMonth[k]?.substring(0, 4) === lastYear.toString()) {
			lessOneYearAbsentYearMonthLastYear.push(arrayAbsentYearMonth[k]);
		}
		if (arrayAbsentYearMonth[k]?.substring(0, 4) === thisYear.toString()) {
			lessOneYearAbsentYearMonthThisYear.push(arrayAbsentYearMonth[k]);
		}
	}
	for (let l = 0; l < lessOneYearAbsentYearMonthLastYear.length; l++) {
		lessOneYearAbsentYearMonthLastYearCnt.push(arrayAbsentDaysCount[l]);
	}
	for (
		let m = lessOneYearAbsentYearMonthLastYear.length;
		m < arrayAbsentYearMonth.length;
		m++
	) {
		lessOneYearAbsentYearMonthThisYearCnt.push(arrayAbsentDaysCount[m]);
	}
	return [
		workerId,
		lessOneYearAbsentYearMonthLastYear.length,
		lessOneYearAbsentYearMonthThisYearCnt.length,
	];
}

// 작년 사용연차 갯수 세기
export function usedAnnualLeavesInLastYearSumF(paydocus, workerId) {
	let annualLeavesUsedInLastYear = [];
	const thisYear = format(new Date(), 'yyyy');
	const lastYear = +thisYear - 1;
	for (let i in paydocus) {
		if (workerId === paydocus[i]?.workerInfo?.workerId) {
			if (paydocus[i]?.info.yearMonth?.substr(0, 4) === lastYear.toString()) {
				annualLeavesUsedInLastYear.push(paydocus[i]);
			}
		}
	}
	const usedAnnualLeavesInLastYearSum = annualLeavesUsedInLastYear
		? annualLeavesUsedInLastYear
				?.map((item) => (+item.usedAnnualLeaves ? +item.usedAnnualLeaves : 0))
				.reduce((prev, curr) => prev + curr, 0)
		: 0;

	return usedAnnualLeavesInLastYearSum;
}

// 올해 사용연차 갯수 세기
export function usedAnnualLeavesInThisYearSumF(paydocus, workerId) {
	const thisYear = format(new Date(), 'yyyy');
	let annualLeavesUsedInThisYear = [];
	for (let i in paydocus) {
		if (workerId === paydocus[i]?.workerInfo?.workerId) {
			if (paydocus[i]?.info?.yearMonth.substr(0, 4) === thisYear) {
				annualLeavesUsedInThisYear.push(paydocus[i]);
			}
		}
	}
	const usedAnnualLeavesInThisYearSum = annualLeavesUsedInThisYear
		? annualLeavesUsedInThisYear
				?.map((item) => (item?.usedAnnualLeaves ? +item?.usedAnnualLeaves : 0))
				.reduce((prev, curr) => prev + curr, 0)
		: 0;
	return usedAnnualLeavesInThisYearSum;
}

// 올해 지급한 연차수당 갯수 세기(법정기준)
export function paidAnnualLeavesInThisYearSumF(paydocus, workerId) {
	const thisYear = format(new Date(), 'yyyy');
	let paidAnnualLeavesUsedInThisYear = [];
	for (let i in paydocus) {
		if (workerId === paydocus[i]?.workerInfo?.workerId) {
			if (paydocus[i]?.info?.year === thisYear) {
				paidAnnualLeavesUsedInThisYear.push(paydocus[i]);
			}
		}
	}
	const usedAnnualLeavesInThisYearSum = paidAnnualLeavesUsedInThisYear
		? paidAnnualLeavesUsedInThisYear
				?.map((item) =>
					item?.addAverWage?.plusUnusedAnnualLeavesDays
						? +item?.addAverWage?.plusUnusedAnnualLeavesDays
						: 0
				)
				.reduce((prev, curr) => prev + curr, 0)
		: 0;
	return usedAnnualLeavesInThisYearSum;
}

// 올해 지급한 전년도 연차수당 갯수 세기(법정기준)
export function paidLastYearAnnualLeavesInThisYearSumF(paydocus, workerId) {
	const thisYear = format(new Date(), 'yyyy');
	let paidLastYearAnnualLeavesUsedInThisYear = [];
	for (let i in paydocus) {
		if (workerId === paydocus[i]?.workerInfo?.workerId) {
			if (paydocus[i]?.info?.year === thisYear) {
				paidLastYearAnnualLeavesUsedInThisYear.push(paydocus[i]);
			}
		}
	}
	const paidLastYearAnnualLeavesUsedInThisYearSum =
		paidLastYearAnnualLeavesUsedInThisYear
			? paidLastYearAnnualLeavesUsedInThisYear
					?.map((item) =>
						item?.addAverWage?.plusLastYearUnusedAnnualLeavesDays
							? +item?.addAverWage?.plusLastYearUnusedAnnualLeavesDays
							: 0
					)
					.reduce((prev, curr) => prev + curr, 0)
			: 0;
	return paidLastYearAnnualLeavesUsedInThisYearSum;
}

// 올해 사용연차 갯수 세기(회계년도)
export function usedAnnualLeavesInThisYearSumFSpecificYear(
	paydocus,
	workerId,
	specificYear
) {
	const thisYear = specificYear;
	let annualLeavesUsedInThisYear = [];
	for (let i in paydocus) {
		if (workerId === paydocus[i]?.workerInfo?.workerId) {
			if (paydocus[i]?.info?.year === thisYear) {
				annualLeavesUsedInThisYear.push(paydocus[i]);
			}
		}
	}
	const usedAnnualLeavesInThisYearSum = annualLeavesUsedInThisYear
		? annualLeavesUsedInThisYear
				?.map((item) => (item?.usedAnnualLeaves ? +item?.usedAnnualLeaves : 0))
				.reduce((prev, curr) => prev + curr, 0)
		: 0;
	return usedAnnualLeavesInThisYearSum;
}

// 올해 지급한 전년도 미사용 연차(수당) 갯수 세기(회계년도)
export function usedAnnualLeavesInLastYearSumFSpecificYear(
	paydocus,
	workerId,
	specificYear
) {
	const thisYear = specificYear + 1;
	let annualLeavesUsedInThisYear = [];
	for (let i in paydocus) {
		if (workerId === paydocus[i]?.workerInfo?.workerId) {
			if (paydocus[i]?.info?.year === thisYear) {
				annualLeavesUsedInThisYear.push(paydocus[i]);
			}
		}
	}
	const usedAnnualLeavesInLastYearSum = annualLeavesUsedInThisYear
		? annualLeavesUsedInThisYear
				?.map((item) =>
					item?.addAverWage?.plusLastYearUnusedAnnualLeavesDays
						? +item?.addAverWage?.plusLastYearUnusedAnnualLeavesDays
						: 0
				)
				.reduce((prev, curr) => prev + curr, 0)
		: 0;
	return usedAnnualLeavesInLastYearSum;
}

export function freeComClient5(clientReqs4In, authLevel, ceoName) {
	return clientReqs4In?.length <= 5 && authLevel === 10 && ceoName !== null;
}

export function freeComClient(authLevel, ceoName) {
	return +authLevel === 10 && ceoName !== null ? true : false;
}
export const cTFMealWageLimit = '(소득세법상 20만원까지 비과세)';
export const cTFCarWageLimit = '(소득세법상 20만원까지 비과세)';
export const cTFChildWageLimit = '(소득세법상 20만원까지 비과세)';
export const cTFStudyWageLimit = '(소득세법상 20만원까지 비과세)';

export function taxFreeMealWageLimitF(thisYear) {
	return thisYear >= 2023 ? 200000 : 100000;
}

export function taxFreeCarWageLimitF(thisYear) {
	return thisYear >= 2023 ? 200000 : 200000;
}

//2024년 이후 200,000원 상향
export function taxFreeChildWageLimitF(thisYear) {
	return thisYear >= 2024 ? 200000 : 100000;
}

export function taxFreeStudyWageLimitF(thisYear) {
	return thisYear >= 2023 ? 200000 : 200000;
}

export function sumOneWeekLBDWTF(
	numOfWorkers,
	daysWT1,
	daysWT2,
	daysWT3,
	daysWT4,
	daysWT5,
	daysWT6,
	days1Length,
	days2Length,
	days3Length,
	days4Length,
	days5Length,
	days6Length,
	skipWeek1,
	skipWeek2,
	skipWeek3,
	skipWeek4,
	skipWeek5,
	skipWeek6
) {
	let result;
	if (numOfWorkers >= 5) {
		result =
			(daysWT1 >= 8
				? skipWeek1 === '격주근무'
					? 8 / 2
					: 8
				: skipWeek1 === '격주근무'
				? daysWT1 / 2
				: daysWT1) *
				days1Length +
			(daysWT2 >= 8
				? skipWeek2 === '격주근무'
					? 8 / 2
					: 8
				: skipWeek2 === '격주근무'
				? daysWT2 / 2
				: daysWT2) *
				days2Length +
			(daysWT3 >= 8
				? skipWeek3 === '격주근무'
					? 8 / 2
					: 8
				: skipWeek3 === '격주근무'
				? daysWT3 / 2
				: daysWT3) *
				days3Length +
			(daysWT4 >= 8
				? skipWeek4 === '격주근무'
					? 8 / 2
					: 8
				: skipWeek4 === '격주근무'
				? daysWT4 / 2
				: daysWT4) *
				days4Length +
			(daysWT5 >= 8
				? skipWeek5 === '격주근무'
					? 8 / 2
					: 8
				: skipWeek5 === '격주근무'
				? daysWT5 / 2
				: daysWT5) *
				days5Length +
			(daysWT6 >= 8
				? skipWeek6 === '격주근무'
					? 8 / 2
					: 8
				: skipWeek6 === '격주근무'
				? daysWT6 / 2
				: daysWT6) *
				days6Length;
	} else {
		result =
			(skipWeek1 === '격주근무' ? daysWT1 / 2 : daysWT1) * days1Length +
			(skipWeek2 === '격주근무' ? daysWT2 / 2 : daysWT2) * days2Length +
			(skipWeek3 === '격주근무' ? daysWT3 / 2 : daysWT3) * days3Length +
			(skipWeek4 === '격주근무' ? daysWT4 / 2 : daysWT4) * days4Length +
			(skipWeek5 === '격주근무' ? daysWT5 / 2 : daysWT5) * days5Length +
			(skipWeek6 === '격주근무' ? daysWT6 / 2 : daysWT6) * days6Length;
	}
	return result;
}

export const sumDaysOWTF = (
	daysOWT1,
	daysOWT2,
	daysOWT3,
	daysOWT4,
	daysOWT5,
	daysOWT6,
	days1Length,
	days2Length,
	days3Length,
	days4Length,
	days5Length,
	days6Length,
	skipWeek1,
	skipWeek2,
	skipWeek3,
	skipWeek4,
	skipWeek5,
	skipWeek6
) => {
	let result;
	result =
		(skipWeek1 === '격주근무' ? daysOWT1 / 2 : daysOWT1) * days1Length +
		(skipWeek2 === '격주근무' ? daysOWT2 / 2 : daysOWT2) * days2Length +
		(skipWeek3 === '격주근무' ? daysOWT3 / 2 : daysOWT3) * days3Length +
		(skipWeek4 === '격주근무' ? daysOWT4 / 2 : daysOWT4) * days4Length +
		(skipWeek5 === '격주근무' ? daysOWT5 / 2 : daysOWT5) * days5Length +
		(skipWeek6 === '격주근무' ? daysOWT6 / 2 : daysOWT6) * days6Length;
	return result;
};

export const sumOneWeekWTF = (
	daysWT1,
	daysWT2,
	daysWT3,
	daysWT4,
	daysWT5,
	daysWT6,
	days1Length,
	days2Length,
	days3Length,
	days4Length,
	days5Length,
	days6Length,
	skipWeek1,
	skipWeek2,
	skipWeek3,
	skipWeek4,
	skipWeek5,
	skipWeek6,
	expectWeeklyOverWorks,
	weekWTHoli
) => {
	let result;
	result =
		(skipWeek1 === '격주근무' ? daysWT1 / 2 : daysWT1) * days1Length +
		(skipWeek2 === '격주근무' ? daysWT2 / 2 : daysWT2) * days2Length +
		(skipWeek3 === '격주근무' ? daysWT3 / 2 : daysWT3) * days3Length +
		(skipWeek4 === '격주근무' ? daysWT4 / 2 : daysWT4) * days4Length +
		(skipWeek5 === '격주근무' ? daysWT5 / 2 : daysWT5) * days5Length +
		(skipWeek6 === '격주근무' ? daysWT6 / 2 : daysWT6) * days6Length +
		expectWeeklyOverWorks +
		weekWTHoli;
	return result;
};

export const sumOneWeekWTForOverTimeF = (
	daysWT1,
	daysWT2,
	daysWT3,
	daysWT4,
	daysWT5,
	daysWT6,
	days1Length,
	days2Length,
	days3Length,
	days4Length,
	days5Length,
	days6Length,
	skipWeek1,
	skipWeek2,
	skipWeek3,
	skipWeek4,
	skipWeek5,
	skipWeek6,
	expectWeeklyOverWorks
) => {
	let result;
	result =
		(skipWeek1 === '격주근무' ? daysWT1 / 2 : daysWT1) * days1Length +
		(skipWeek2 === '격주근무' ? daysWT2 / 2 : daysWT2) * days2Length +
		(skipWeek3 === '격주근무' ? daysWT3 / 2 : daysWT3) * days3Length +
		(skipWeek4 === '격주근무' ? daysWT4 / 2 : daysWT4) * days4Length +
		(skipWeek5 === '격주근무' ? daysWT5 / 2 : daysWT5) * days5Length +
		(skipWeek6 === '격주근무' ? daysWT6 / 2 : daysWT6) * days6Length +
		expectWeeklyOverWorks;
	return result;
};

export const sumOneWeekNWTF = (
	daysNWT1,
	daysNWT2,
	daysNWT3,
	daysNWT4,
	daysNWT5,
	daysNWT6,
	days1Length,
	days2Length,
	days3Length,
	days4Length,
	days5Length,
	days6Length,
	skipWeek1,
	skipWeek2,
	skipWeek3,
	skipWeek4,
	skipWeek5,
	skipWeek6,
	expectWeeklyNightWorks
) => {
	let result;
	result =
		(skipWeek1 ? daysNWT1 / 2 : daysNWT1) * days1Length +
		(skipWeek2 ? daysNWT2 / 2 : daysNWT2) * days2Length +
		(skipWeek3 ? daysNWT3 / 2 : daysNWT3) * days3Length +
		(skipWeek4 ? daysNWT4 / 2 : daysNWT4) * days4Length +
		(skipWeek5 ? daysNWT5 / 2 : daysNWT5) * days5Length +
		(skipWeek6 ? daysNWT6 / 2 : daysNWT6) * days6Length +
		expectWeeklyNightWorks;
	return result;
};

export const lastPreventDateF = (clientReqs4In) => {
	const workerNameArray = clientReqs4In.map(
		(workerDb) => workerDb?.worker.name
	);
	const eiOutTypeArray = clientReqs4In.map(
		(workerDb) => workerDb?.eiOutDetailType
	);
	const worekrRetiredDateArray = clientReqs4In.map(
		(workerDb) => workerDb?.retiredDate
	);

	const retireTypeForSF = {};
	for (let i = 0; i < workerNameArray.length; i++) {
		retireTypeForSF[workerNameArray[i]] = [
			eiOutTypeArray[i],
			worekrRetiredDateArray[i],
		];
	}

	const filteredObj = {}; // 퇴사일이 있는 {}

	for (const key in retireTypeForSF) {
		const filteredArray = retireTypeForSF[key].filter((element) => {
			return (
				element !== null &&
				element !== undefined &&
				element !== '' &&
				element !== false &&
				element !== 0
			);
		});
		if (filteredArray.length > 0) {
			filteredObj[key] = filteredArray;
		}
	}

	const filteredLastDateObj = {}; // 직원 중 마지막 감원방지 기간
	let latestDate = new Date(0); // 가장 초기 날짜로 설정
	let latestKey = null;

	for (const key in filteredObj) {
		const keyValue = filteredObj[key][0];
		const dateValue = new Date(filteredObj[key][1]);

		let suggestiveResignation2301 = false;
		let suggestiveResignation2302 = false;
		let suggestiveResignation2303 = false;
		let suggestiveResignation2304 = false;
		let suggestiveResignation2305 = false;
		let suggestiveResignation2306 = false;
		let suggestiveResignation2307 = false;
		let suggestiveResignation2308 = false;
		let suggestiveResignation2603 = false;

		if (typeof keyValue === 'string') {
			suggestiveResignation2301 = keyValue?.includes(
				'01경영상 필요에 의한 인원 감축으로 인한 해고에 의해 이직한 경우'
			);
			suggestiveResignation2302 = keyValue?.includes(
				'02사업의 양도∙양수∙합병과정에서 본인 의사와 관계없이 고용승계가 배제되어 이직한 경우'
			);
			suggestiveResignation2303 = keyValue?.includes(
				'03경영상 필요에 의한 인원 감축이 불가피하여 실시된 고용조정계획 또는 대량의 감원 예정에 따른 사업주의 퇴직 권고에 의하여 이직한 경우'
			);
			suggestiveResignation2304 = keyValue?.includes(
				'04사업∙부서가 폐지되어 신설된 법인으로 이직한 경우'
			);
			suggestiveResignation2305 = keyValue?.includes(
				'05직제개편에 따른 조직의 폐지∙축소, 회사의 업종전환, 일부 사업 또는 작업형태의 변경으로 인해 사업주의 퇴직 권고에 의하여 이직한 경우'
			);
			suggestiveResignation2306 = keyValue?.includes(
				'06회사의 주문량∙작업량 감소 등과 같은 경영의 악화로 인해 이직한 경우'
			);
			suggestiveResignation2307 = keyValue?.includes(
				'07결혼, 임신, 출산, 군입대 등의 경우에 퇴직이 관행이 된 사업장에서 이직한 경우'
			);
			suggestiveResignation2308 = keyValue?.includes(
				'08근로자의 귀책사유 없는 해고(예술인 또는 노무제공자의 귀책사유는 없지만 사업주의 요구에 따라 계약이 파기∙해지된 경우)'
			);
			suggestiveResignation2603 = keyValue?.includes(
				'03근로자의 업무상 과실(업무능력 미달 포함) 등 귀책사유가 징계해고 정도는 아니지만 사업주가 퇴직을 권유하여 이직한 경우'
			);
		}

		if (
			(suggestiveResignation2302 ||
				suggestiveResignation2301 ||
				suggestiveResignation2303 ||
				suggestiveResignation2304 ||
				suggestiveResignation2305 ||
				suggestiveResignation2306 ||
				suggestiveResignation2307 ||
				suggestiveResignation2308 ||
				suggestiveResignation2603) &&
			dateValue > latestDate
		) {
			latestDate = dateValue;
			latestKey = key;
		}
	}

	if (latestKey !== null) {
		filteredLastDateObj[latestKey] = filteredObj[latestKey];
	}

	const entries = Object.entries(filteredLastDateObj);

	if (entries.length > 0) {
		const [key, value] = entries[0];
		const lastPreventRetireWorker = key;
		const lastPreventRetireReson = value[0];
		const lastPreventRetireDate = moment(new Date(value[1])).format(
			'yyyy-MM-DD'
		);

		const availableSupportFundStartDate = moment(lastPreventRetireDate)
			.add(3, 'month')
			.format('yyyy-MM-DD');

		const startDateText = (
			<p>
				<span style={{ fontSize: '1.2rem' }}>고용안정지원금 : </span>귀사는{' '}
				<span style={{ color: 'green' }}>감원방지의무기간</span> 때문에{' '}
				<span style={{ color: 'green' }}>
					2024 청년일자리도약장려금, 산전후휴가, 육아기 근로시각 단축 지원금
					대상자
				</span>
				가 있는 경우는{' '}
				<span style={{ color: 'green' }}>
					{availableSupportFundStartDate} 이후 입사자
				</span>
				부터 지원금 대상이 됩니다.
			</p>
		);
		const lastPreventDataText = (
			<p>
				마지막 감원방지의무기간 중 권고사직 등으로 퇴사한{' '}
				<span style={{ color: 'green' }}>
					직원은 {lastPreventRetireWorker}이고, 사유는 '{lastPreventRetireReson}
					' 이며 날짜는 {lastPreventRetireDate}로 퇴사 후 3개월이 지나야
					감원방지의무기간이 종료
				</span>
				되기 때문입니다.
			</p>
		);
		const afterOneYearText = (
			<p>
				또한, 지원금 수령시{' '}
				<span style={{ color: 'green' }}>
					해당 지원금 대상자 채용 후 1년간 권고사직, 경영상 해고
				</span>{' '}
				등 감원방지의무를 이행하지 아니할 경우{' '}
				<span style={{ color: 'green' }}>
					수령했던 지원금 전체가 소급해서 추징됨
				</span>
				을 안내해 드립니다.
			</p>
		);

		return [
			key,
			value[0],
			value[1],
			startDateText,
			lastPreventDataText,
			afterOneYearText,
			availableSupportFundStartDate,
		];
	} else {
		return null;
	}
};

export function isThreeMonthsOrMoreApart(date1, date2) {
	// 입력된 날짜를 Moment 객체로 변환합니다.
	const momentDate1 = moment(date1);
	const momentDate2 = moment(date2);

	// 두 날짜 간의 차이를 개월로 계산합니다.
	const monthsDifference = momentDate1.diff(momentDate2, 'months');
	// 차이가 3달 이상인 경우 true를 반환하고, 그렇지 않으면 false를 반환합니다.
	return monthsDifference >= 3 || momentDate1 <= momentDate2 ? true : false;
}

export function dateDifference(date1, date2) {
	const startDate = moment(date1, 'YYYY-MM-DD');
	const endDate = moment(date2, 'YYYY-MM-DD');

	const duration = moment.duration(endDate.diff(startDate));
	const years = Math.abs(duration.years());
	const months = Math.abs(duration.months());
	const days = Math.abs(duration.days());

	return `${years}년 ${months}개월 ${days}일`;
}

export function workerEnteredDateValue(values) {
	const workerEnteredDate = values?.workerEnteredDate
		? moment(values.workerEnteredDate)
		: null;
	return workerEnteredDate
		? workerEnteredDate.format('YYYY-MM-DD')
		: '달력 사용(또는 0000-00-00  형식으로 입력요망)';
}

export function wageContarctEndDateValue(values) {
	const workerEnteredDate = values?.workerEnteredDate
		? moment(values.workerEnteredDate)
		: null;
	const contractEndDate = values?.contractEndDate
		? moment(values.contractEndDate)
		: null;
	const result = contractEndDate
		? contractEndDate.format('YYYY-MM-DD')
		: values?.wageContractStartDate
		? moment(values.wageContractStartDate)
				.clone()
				.add(1, 'years')
				.subtract(1, 'days')
				.format('YYYY-MM-DD')
		: workerEnteredDate
		? workerEnteredDate
				.clone()
				.add(1, 'years')
				.subtract(1, 'days')
				.format('YYYY-MM-DD')
		: '달력 사용(또는 0000-00-00  형식으로 입력요망)';
	return result;
}

export function traineeEndDateValue(values) {
	const traineeStartDate = values?.traineeStartDate
		? moment(values.traineeStartDate)
		: null;
	const workerEnteredDate = values?.workerEnteredDate
		? moment(values.workerEnteredDate)
		: null;
	return traineeStartDate
		? traineeStartDate
				.clone()
				.add(3, 'months')
				.subtract(1, 'days')
				.format('YYYY-MM-DD')
		: workerEnteredDate
		? workerEnteredDate
				.clone()
				.add(3, 'months')
				.subtract(1, 'days')
				.format('YYYY-MM-DD')
		: '달력 사용(또는 0000-00-00  형식으로 입력요망)';
}

export function oneOrManyChildCount(values) {
	const result =
		values?.maternityLeaveType === '다자녀 출산전후휴가(120일)' ? 119 : 89;
	return result;
}

export function maternityLeaveEndDateValue(values) {
	const maternityLeaveStartDate = values?.maternityLeaveStartDate
		? moment(values.maternityLeaveStartDate)
		: null;
	const maternityLeaveEndDate = maternityLeaveStartDate
		? maternityLeaveStartDate.clone().add(oneOrManyChildCount(values), 'days')
		: null;
	return maternityLeaveEndDate
		? maternityLeaveEndDate.format('YYYY-MM-DD')
		: '달력 사용(또는 0000-00-00  형식으로 입력요망)';
}

export function childLeaveOfAbsenceStartDateValue(values) {
	const maternityLeaveStartDate = values?.maternityLeaveStartDate
		? moment(values.maternityLeaveStartDate)
		: null;
	const maternityLeaveEndDate = maternityLeaveStartDate
		? maternityLeaveStartDate.clone().add(oneOrManyChildCount(values), 'days')
		: null;
	const childLeaveOfAbsenceStartDate = maternityLeaveStartDate
		? maternityLeaveStartDate
				.clone()
				.add(oneOrManyChildCount(values) + 1, 'days')
		: null;
	return maternityLeaveEndDate
		? childLeaveOfAbsenceStartDate.format('YYYY-MM-DD')
		: '달력 사용(또는 0000-00-00  형식으로 입력요망)';
}

export function childLeaveOfAbsenceEndDateValue(values) {
	const maternityLeaveStartDate = values?.maternityLeaveStartDate
		? moment(values.maternityLeaveStartDate)
		: null;
	const childLeaveOfAbsenceStartDate = maternityLeaveStartDate
		? maternityLeaveStartDate
				.clone()
				.add(oneOrManyChildCount(values) + 1, 'days')
		: null;
	const childLeaveOfAbsenceEndDate =
		values?.childLeaveOfAbsenceTypeOption === '출산후 육아휴직 1년 전체사용' &&
		values.childLeaveOfAbsenceStartDate
			? moment(values.childLeaveOfAbsenceStartDate)
					.clone()
					.add(1, 'years')
					.subtract(1, 'days')
			: values?.childLeaveOfAbsenceTypeOption ===
					'출산후 육아휴직 1년 전체사용' && childLeaveOfAbsenceStartDate
			? childLeaveOfAbsenceStartDate.clone().add(1, 'years').subtract(1, 'days')
			: null;
	return childLeaveOfAbsenceEndDate
		? childLeaveOfAbsenceEndDate.format('YYYY-MM-DD')
		: '달력 사용(또는 0000-00-00  형식으로 입력요망)';
}

export function childShortTimeEndDateValue(values) {
	const maternityLeaveStartDate = values?.maternityLeaveStartDate
		? moment(values.maternityLeaveStartDate)
		: null;
	const childLeaveOfAbsenceStartDate = maternityLeaveStartDate
		? maternityLeaveStartDate
				.clone()
				.add(oneOrManyChildCount(values) + 1, 'days')
		: null;
	const childShortTimeEndDate =
		values?.childShortTimeType === '육아기 근로시간 단축 1년 전체사용' &&
		values.childShortTimeStartDate
			? moment(values.childShortTimeStartDate)
					.clone()
					.add(1, 'years')
					.subtract(1, 'days')
			: values?.childShortTimeType === '육아기 근로시간 단축 1년 전체사용' &&
			  childLeaveOfAbsenceStartDate
			? childLeaveOfAbsenceStartDate.clone().add(1, 'years').subtract(1, 'days')
			: null;
	return childShortTimeEndDate
		? childShortTimeEndDate.format('YYYY-MM-DD')
		: '달력 사용(또는 0000-00-00  형식으로 입력요망)';
}

export function extractMonthsAndDaysBetweenDatesWithLimits(
	startDate,
	endDate,
	firstLimitDays,
	secondLimitDays
) {
	function extractMonthsAndDaysBetweenDates(startDate, endDate) {
		const start = moment(startDate);
		const end = moment(endDate);
		const monthsAndDays = [];

		let currentMonth = start.clone().startOf('month');

		while (currentMonth <= end) {
			const month = currentMonth.format('YYYY-MM');
			const firstDayInMonth = moment.max(start, currentMonth);
			const lastDayInMonth = moment.min(
				end,
				currentMonth.clone().endOf('month')
			);
			const days = lastDayInMonth.diff(firstDayInMonth, 'days') + 1;

			monthsAndDays.push({ month, days });
			currentMonth.add(1, 'month');
		}

		return monthsAndDays;
	}

	const firstPhaseEnd = moment(startDate).add(firstLimitDays, 'days');
	const secondPhaseEnd = moment.min(
		moment(startDate).add(secondLimitDays, 'days'),
		moment(endDate)
	);

	const firstPhaseMonthsAndDays = extractMonthsAndDaysBetweenDates(
		startDate,
		firstPhaseEnd
	);
	const secondPhaseMonthsAndDays = extractMonthsAndDaysBetweenDates(
		firstPhaseEnd,
		secondPhaseEnd
	);

	const combinedMonthsAndDays = [
		...firstPhaseMonthsAndDays,
		...secondPhaseMonthsAndDays,
	];

	// Merge results of the same months
	const mergedMonthsAndDays = combinedMonthsAndDays.reduce(
		(accumulator, currentValue) => {
			const existingMonth = accumulator.find(
				(item) => item.month === currentValue.month
			);

			if (existingMonth) {
				existingMonth.days += currentValue.days;
			} else {
				accumulator.push(currentValue);
			}

			return accumulator;
		},
		[]
	);

	// Set days to 0 for months in the second phase that exceed the secondLimitDays
	let remainingDays = secondLimitDays;
	for (const currentMonth of secondPhaseMonthsAndDays) {
		const existingMonth = mergedMonthsAndDays.find(
			(item) => item.month === currentMonth.month
		);

		if (existingMonth) {
			const allowedDays = Math.min(existingMonth.days, remainingDays);
			existingMonth.days = allowedDays;
			remainingDays -= allowedDays;

			if (remainingDays === 0) {
				break;
			}
		}
	}

	// Remove days exceeding the firstLimitDays
	let totalDays = 0;
	for (const currentMonth of mergedMonthsAndDays) {
		totalDays += currentMonth.days;

		if (totalDays > secondLimitDays) {
			currentMonth.days -= totalDays - secondLimitDays;
			totalDays = secondLimitDays;
		}
	}

	return mergedMonthsAndDays;
}

export function isCurrentMonthInArray(array, year, Month) {
	const currentMonth = moment().format(year + '-' + Month);
	return array.some((item) => item.month === currentMonth);
}

export function getCurrentMonthDays(array, year, month) {
	const currentMonth = moment().format(year + '-' + month);
	for (const item of array) {
		if (item.month === currentMonth) {
			return item.days;
		}
	}
	return -1;
}

export function calculateWeeklyHours(arr = []) {
	const result = {};

	for (let i = 0; i < arr.length; i++) {
		const item = arr[i];
		const week = item.weekNumber;
		const hours = +item.value;
		if (week in result) {
			result[week] += hours;
		} else {
			result[week] = hours;
		}
	}

	return result;
}

export function combineObjects(obj1, obj2) {
	// obj2를 기본으로 복사
	const combined = { ...obj1 };

	// 일반적인 주간 데이터 합산
	Object.keys(obj2).forEach((key) => {
		if (combined[key]) {
			combined[key] = +combined[key] + +obj2[key];
		} else {
			combined[key] = obj2[key];
		}
	});

	// 1주와 53주가 동시에 존재하는 경우 합산 처리
	if (combined['1'] !== undefined && combined['53'] !== undefined) {
		combined['1'] = +combined['1'] + +combined['53'];
		delete combined['53']; // 53주는 삭제
	}

	return combined;
}

export function capValuesAt40(obj) {
	return Object.fromEntries(
		Object.entries(obj).map(([key, value]) => [key, Math.min(value, 40)])
	);
}

export const calculateWeeks = (month, year) => {
	const weeks = [];
	const daysInCurrentMonth = new Date(year, month, 0).getDate();

	let currentWeek = Array(7).fill(null);
	let dayOfWeek;

	for (let day = 1; day <= daysInCurrentMonth; day++) {
		dayOfWeek = new Date(year, month - 1, day).getDay();

		currentWeek[dayOfWeek] = { id: day, name: `${day}` };

		if (dayOfWeek === 6 || day === daysInCurrentMonth) {
			weeks.push(currentWeek);
			currentWeek = Array(7).fill(null);
		}
	}

	return weeks;
};

export const calPaidHoliWageCount = (month, year) => {
	const weeks = [];
	const daysInCurrentMonth = new Date(year, month, 0).getDate();

	let currentWeek = Array(7).fill(null);
	let dayOfWeek;

	const getWeek = (date) => {
		const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
		const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
		return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
	};

	for (let day = 1; day <= daysInCurrentMonth; day++) {
		const date = new Date(year, month - 1, day);
		dayOfWeek = date.getDay();

		currentWeek[dayOfWeek] = {
			id: day,
			name: `${day}`,
			weekNumber: getWeek(date),
		};

		if (dayOfWeek === 6 || day === daysInCurrentMonth) {
			weeks.push(currentWeek);
			currentWeek = Array(7).fill(null);
		}
	}

	// Check if the last week is a complete week, and remove it if it's not
	const lastWeek = weeks[weeks.length - 1];
	if (lastWeek[0] === null || lastWeek[6] === null) {
		weeks.pop();
	}

	return weeks;
};

export const getWeekNumbers = (weeks) => {
	return weeks
		.map((week) => {
			// Find the first non-null day in the week and return its weekNumber
			const firstDayOfWeek = week.find((day) => day !== null);
			return firstDayOfWeek ? firstDayOfWeek.weekNumber : null;
		})
		.filter((weekNumber) => weekNumber !== null); // Remove null values
};

export const getCommonWeeks = (weekData, weekNumbers) => {
	const commonWeeks = weekNumbers.reduce((acc, weekNumber) => {
		if (weekData.hasOwnProperty(weekNumber.toString())) {
			acc[weekNumber] = weekData[weekNumber];
		}
		return acc;
	}, {});
	return commonWeeks;
};

export const filterByValueRange = (obj, min, max) => {
	const filteredObj = Object.fromEntries(
		Object.entries(obj).filter(([key, value]) => value >= min && value <= max)
	);
	return filteredObj;
};

// Get the ISO week date week number
export const getWeek = (date) => {
	const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
	const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
	return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const sumOfWeek = (weekIndex, weekInputs) => {
	const inputs = weekInputs[weekIndex] || {};
	const sum = Object.values(inputs).reduce((a, b) => +a + +b, 0);
	return sum;
};

export const calculateDaySums = (weekInputs) => {
	const daySums = [0, 0, 0, 0, 0, 0, 0]; // 월요일부터 일요일까지의 합계를 저장할 배열

	Object.values(weekInputs).forEach((week) => {
		Object.entries(week).forEach(([dayIndex, value]) => {
			daySums[dayIndex] += parseFloat(value) || 0; // 값을 요일별로 합산
		});
	});

	return daySums;
};
export const calculateTotalSum = (daySums) => {
	const result = daySums.reduce((a, b) => +a + +b, 0);
	return Math.floor(result * 100) / 100;
};

export const convertObjectToText = (obj) => {
	const result =
		'소정근로시간 ' +
		Object.entries(obj)
			.map(([key, value]) => `${key}주 : ${value}시간`)
			.join(', ');
	return result;
};

export function daylyWorkerTotalMonthWageAmountF(
	week14OverBLTime,
	monthTotalWorkTime,
	timeWorkerTimeWage,
	timeWorkerTimePaidHoliWage,
	countOfNomalDayWork,
	dayWorkerWage,
	countOfHoliDayWork,
	dayWorkerHoliWage,
	countOfOverWorkingDayWork,
	dayWorkerOverWorkDayWage,
	dayWorkerPlusMonthOWT,
	dayWokerTimeWage,
	numOfWorkers,
	dayWorkerPlusMonthNWT,
	dayWorkerPlusMonthHoliBasicTime,
	dayWorkerPlusMonthOWTHoli,
	dayWorkerPlusMonthNWTHoli,
	dayWorkerCAWage5,
	dayWorkerCAWage6,
	dayWorkerCAWage7,
	dayWorkerAbsentTimes,
	timeWorkerPlusMonthOWT,
	timeWorkerPlusMonthNWT,
	timeWorkerPlusMonthHoliBasicTime,
	timeWorkerPlusMonthOWTHoli,
	timeWorkerPlusMonthOffBasicTime,
	timeWorkerPlusMonthOWTOff,
	finalPaidWeekObj,
	finalPaidHoliWageTimeSum,
	normalWorkerWeekWorkDays,
	daylyTimePay,
	lawBasePaidWeekObj,
	lawBaseWorkDatesWorkHourThisMonthOnlySum,
	timeWorkerSpecialBonus,
	dayWorkerSpecialBonus
) {
	const totalTimeWorkerWage = monthTotalWorkTime * timeWorkerTimeWage;

	const totalTimeWorkerPaidHoliWage =
		monthTotalWorkTime * timeWorkerTimePaidHoliWage;

	const totalDayWorkerWage = countOfNomalDayWork * dayWorkerWage;
	const totalDayWorkerHoliWage = countOfHoliDayWork * dayWorkerHoliWage;
	const totalDayWorkerOverWorkDayWage =
		countOfOverWorkingDayWork * dayWorkerOverWorkDayWage;
	const totalDayWorkerMonthWage =
		+totalDayWorkerWage +
		+totalDayWorkerHoliWage +
		+totalDayWorkerOverWorkDayWage;

	const totalCountOfWorkingDay =
		+countOfNomalDayWork + +countOfHoliDayWork + +countOfOverWorkingDayWork;

	const oWage = workDayOTimePaidWageF(
		0,
		dayWorkerPlusMonthOWT,
		dayWokerTimeWage,
		numOfWorkers
	);
	const nWage = workDayNTimePaidWageF(
		0,
		dayWorkerPlusMonthNWT,
		dayWokerTimeWage,
		numOfWorkers
	);
	const hBWage = holiBTimePaidWageF(
		0,
		dayWorkerPlusMonthHoliBasicTime,
		dayWokerTimeWage,
		numOfWorkers
	);
	const hOWage = holiOTimePaidWageF(
		0,
		dayWorkerPlusMonthOWTHoli,
		dayWokerTimeWage,
		numOfWorkers
	);
	const hNWage = holiNTimePaidWageF(
		0,
		dayWorkerPlusMonthNWTHoli,
		dayWokerTimeWage,
		numOfWorkers
	);

	const numOWage = oWage ? numStringToNumber(oWage) : 0;
	const numNWage = nWage ? numStringToNumber(nWage) : 0;
	const numHBwage = hBWage ? numStringToNumber(hBWage) : 0;
	const numHOwage = hOWage ? numStringToNumber(hOWage) : 0;
	const numHNwage = hNWage ? numStringToNumber(hNWage) : 0;

	const numcAWage5Wage = dayWorkerCAWage5
		? numStringToNumber(dayWorkerCAWage5)
		: 0;
	const numcAWage6Wage = dayWorkerCAWage6
		? numStringToNumber(dayWorkerCAWage6)
		: 0;
	const numcAWage7Wage = dayWorkerCAWage7
		? numStringToNumber(dayWorkerCAWage7)
		: 0;
	const numdayWorkerAbsentTimes = dayWorkerAbsentTimes
		? numStringToNumber(dayWorkerAbsentTimes)
		: 0;
	const absentTimeWage = roundDown(dayWokerTimeWage * numdayWorkerAbsentTimes);

	const oWageTimeW = workDayOTimePaidWageF(
		0,
		timeWorkerPlusMonthOWT,
		timeWorkerTimeWage,
		numOfWorkers,
		''
	); //1.5
	const nWageTimeW = workDayNTimePaidWageF(
		0,
		timeWorkerPlusMonthNWT,
		timeWorkerTimeWage,
		numOfWorkers,
		''
	); //0.5
	const hBWageTimeW = workDayNTimePaidWageF(
		0,
		timeWorkerPlusMonthHoliBasicTime,
		timeWorkerTimeWage,
		numOfWorkers,
		''
	); //0.5
	const hOWageTimeW = holiOTimePaidWageF(
		0,
		timeWorkerPlusMonthOWTHoli,
		timeWorkerTimeWage,
		numOfWorkers,
		''
	); //2
	const offWageTimeW = workDayNTimePaidWageF(
		0,
		timeWorkerPlusMonthOffBasicTime,
		timeWorkerTimeWage,
		numOfWorkers,
		''
	); //0.5
	const offOWageTimeW = workDayOTimePaidWageF(
		0,
		timeWorkerPlusMonthOWTOff,
		timeWorkerTimeWage,
		numOfWorkers,
		''
	); //1.5

	const numOWageTimeW = oWageTimeW ? numStringToNumber(oWageTimeW) : 0;
	const numNWageTimeW = nWageTimeW ? numStringToNumber(nWageTimeW) : 0;
	const numHBWageTimeW = hBWageTimeW ? numStringToNumber(hBWageTimeW) : 0;
	const numHOWageTimeW = hOWageTimeW ? numStringToNumber(hOWageTimeW) : 0;
	const numOffWageTimeW = offWageTimeW ? numStringToNumber(offWageTimeW) : 0;
	const numOffOWageTimeW = offOWageTimeW ? numStringToNumber(offOWageTimeW) : 0;
	const paidHoliWageDays = Object.keys(finalPaidWeekObj).length;
	const notZeroPaidHoliWageDays = paidHoliWageDays === 0 ? 1 : paidHoliWageDays;
	const finalWeekPaidBLTime =
		finalPaidHoliWageTimeSum / notZeroPaidHoliWageDays;

	const lawBasePaidHoliWageDaysThisMonth =
		Object.keys(lawBasePaidWeekObj).length;
	const notZeroLawBasePaidHoliWageDaysThisMonth =
		lawBasePaidHoliWageDaysThisMonth === 0
			? 1
			: lawBasePaidHoliWageDaysThisMonth;
	const lawBaseWeekPaidBLTimeThisMonth =
		lawBaseWorkDatesWorkHourThisMonthOnlySum /
		notZeroLawBasePaidHoliWageDaysThisMonth;

	const finaldayPaidBLTime =
		(finalWeekPaidBLTime * 4) /
		(4 * (normalWorkerWeekWorkDays ? normalWorkerWeekWorkDays : 5));
	const finalPaidHoliWageTime = finaldayPaidBLTime * paidHoliWageDays;
	const numPaidHoliTimeW = timeWorkerTimeWage * finalPaidHoliWageTime || 0;
	const numPaidHoliDayW =
		week14OverBLTime === '주휴수당 미포함'
			? dayWokerTimeWage * finalPaidHoliWageTime
			: 0;

	//주휴수당 포함인 경우 totalTimeWorkerPaidHoliWage
	const finalPaidHoliWageMonth =
		daylyTimePay > 0
			? totalTimeWorkerPaidHoliWage > 0
				? totalTimeWorkerPaidHoliWage
				: numPaidHoliTimeW
			: numPaidHoliDayW;

	const totalMonthWageAmount =
		daylyTimePay > 0
			? totalTimeWorkerWage +
			  (timeWorkerSpecialBonus ? +timeWorkerSpecialBonus : 0) +
			  (finalPaidHoliWageMonth ? finalPaidHoliWageMonth : 0) +
			  (numOWageTimeW ? numOWageTimeW : 0) +
			  (numNWageTimeW ? numNWageTimeW : 0) +
			  (numHBWageTimeW ? numHBWageTimeW : 0) +
			  (numHOWageTimeW ? numHOWageTimeW : 0) +
			  (numOffWageTimeW ? numOffWageTimeW : 0) +
			  (numOffOWageTimeW ? numOffOWageTimeW : 0) +
			  (numcAWage5Wage ? numcAWage5Wage : 0) +
			  (numcAWage6Wage ? numcAWage6Wage : 0) +
			  (numcAWage7Wage ? numcAWage7Wage : 0)
			: +totalDayWorkerMonthWage +
			  (dayWorkerSpecialBonus ? +dayWorkerSpecialBonus : 0) +
			  (finalPaidHoliWageMonth ? finalPaidHoliWageMonth : 0) +
			  (numOWage ? numOWage : 0) +
			  (numNWage ? numNWage : 0) +
			  (numHBwage ? numHBwage : 0) +
			  (numHOwage ? numHOwage : 0) +
			  (numHNwage ? numHNwage : 0) +
			  (numcAWage5Wage ? numcAWage5Wage : 0) +
			  (numcAWage6Wage ? numcAWage6Wage : 0) +
			  (numcAWage7Wage ? numcAWage7Wage : 0) -
			  (absentTimeWage ? absentTimeWage : 0);

	return {
		totalCountOfWorkingDay,
		totalMonthWageAmount,
		finalWeekPaidBLTime,
		totalTimeWorkerWage,
		totalTimeWorkerPaidHoliWage,
		totalDayWorkerWage,
		totalDayWorkerHoliWage,
		totalDayWorkerOverWorkDayWage,
		numdayWorkerAbsentTimes,
		absentTimeWage,
		numOWageTimeW,
		numNWageTimeW,
		numHBWageTimeW,
		numHOWageTimeW,
		numOffWageTimeW,
		numOffOWageTimeW,
		paidHoliWageDays,
		finalPaidHoliWageTime,
		numPaidHoliTimeW,
		lawBaseWeekPaidBLTimeThisMonth,
		totalDayWorkerMonthWage,
		numPaidHoliDayW,
		timeWorkerSpecialBonus,
		dayWorkerSpecialBonus,
	};
}

export function netAgeF(selectedClientReq4In, values) {
	const workerSocialNumberFront = decrypto(
		selectedClientReq4In?.workerSocialNumberFront,
		String(process.env.CRYPTO_KEY)
	);
	const netAge = getAgeFromSocialNumberPension(
		workerSocialNumberFront,
		values?.thisYear,
		values?.thisMonth
	);
	return netAge;
}

export function netAgeEnteredF(selectedClientReq4In) {
	const workerSocialNumberFront = decrypto(
		selectedClientReq4In?.workerSocialNumberFront,
		String(process.env.CRYPTO_KEY)
	);
	const netAgeEnteredValue = getAgeFromSocialNumberEnteredDate(
		workerSocialNumberFront,
		selectedClientReq4In?.workerEnteredDate
	);
	return netAgeEnteredValue;
}

export function under8daysPerMonthF(
	timeWorkerTotalWorkDays,
	totalCountOfWorkingDay
) {
	const under8daysPerMonth =
		(totalCountOfWorkingDay || timeWorkerTotalWorkDays) < 8 ? true : false;
	return under8daysPerMonth;
}

export function deductWageF(
	isDayOrTimeWorker,
	selectedClientReq4In,
	lawBaseWorkDatesWorkHourThisMonthOnlySum,
	values,
	constructOrNot,
	totalCountOfWorkingDay,
	totalMonthWageAmount,
	timeWorkerTotalWorkDays,
	dayWorkerPlusDeductHealthCare,
	dayWorkerPlusDeductOldCare,
	dayWorkerPlusDeductWorkTax,
	dayWorkerPlusDeductWorkRegionTax,
	dayWorkerPlusDeductDuru,
	dayWorkerPlusMinus4Ins,
	dayWorkerPlusDeductWorkerCompanyLoan,
	dayWorkerDeductEtcWage1,
	dayWorkerDeductEtcWage2,
	dayWorkerDeductEtcWage3,
	daylyTimePay,
	dayWorkerWage,
	countOfNomalDayWork,
	dayWorkerOverWorkDayWage,
	countOfOverWorkingDayWork,
	dayWorkerHoliWage,
	countOfHoliDayWork,
	thisYear,
	thisMonth,
	dayWorkerUserFixedTax,
	dayWorkerUserFixedRegionTax,
	dayWorkerUserFixedHealthCare,
	dayWorkerUserFixedOldCare,
	dayWorkerUserFixedPension,
	dayWorkerUserFixedNoJobInsurance,
	weekBLTimeTWThisMonth,
	dayWorkerDayBasicWorkingTime,
	dayWorkerTaxText,
	isInterInWorkerV,
	selfNoJobPay,
	netAgeEntered
) {
	const netAgePension = netAgeF(selectedClientReq4In, values);

	const taxPayType = selectedClientReq4In?.taxPayType;
	const pensionPayType = selectedClientReq4In?.pensionPayType;
	const healthCarePayType = selectedClientReq4In?.healthCarePayType;
	const oldCarePayType = selectedClientReq4In?.oldCarePayType;

	const noJobPayType = selectedClientReq4In?.noJobPayType;
	const weekLBTime = selectedClientReq4In?.lawBase?.weekLBTime;

	const pensionBaseWage = selectedClientReq4In?.pensionBaseWage
		? numStringToNumber(selectedClientReq4In?.pensionBaseWage)
		: null;
	const healthCareBaseWage = selectedClientReq4In?.healthCareBaseWage
		? numStringToNumber(selectedClientReq4In?.healthCareBaseWage)
		: null;
	const oldCareBaseWage = selectedClientReq4In?.oldCareBaseWage
		? numStringToNumber(selectedClientReq4In?.oldCareBaseWage)
		: null;
	const noJobBaseWage = selectedClientReq4In?.noJobBaseWage
		? numStringToNumber(selectedClientReq4In?.noJobBaseWage)
		: null;
	const visaType = selectedClientReq4In?.foreigner?.visaType;

	const taxWageSum = totalMonthWageAmount;
	const under8daysPerMonth = under8daysPerMonthF(
		timeWorkerTotalWorkDays,
		totalCountOfWorkingDay
	);

	const pensionWage =
		pensionPayType === '기준소득월액(국민연금공단에 신고된 금액)' &&
		pensionBaseWage
			? pensionBaseWage
			: taxWageSum;
	const healthCareWage =
		healthCarePayType === '보수월액(건강보험공단에 신고된 금액)' &&
		healthCareBaseWage
			? healthCareBaseWage
			: taxWageSum;
	const oldCareWage =
		oldCarePayType === '보수월액(건강보험공단에 신고된 금액)' && oldCareBaseWage
			? oldCareBaseWage
			: taxWageSum;
	const noJobPayWage =
		noJobPayType === '월평균보수월액(근로복지공단에 신고된 금액)' &&
		noJobBaseWage
			? noJobBaseWage
			: taxWageSum;

	const lawBaseWorkDatesWorkHourThisMonthForDW =
		dayWorkerDayBasicWorkingTime * countOfNomalDayWork;
	const is60OverMonth =
		(lawBaseWorkDatesWorkHourThisMonthOnlySum ||
			lawBaseWorkDatesWorkHourThisMonthForDW) >= 60;

	const pensionNoForeigner = visaType && !checkVisaPensionType(visaType);
	const foreignerNoNoJobVisa =
		visaType && !checkVisaNoJobPayType(visaType, selfNoJobPay);
	const pensionTaxWageSum =
		pensionPayType === '국민연금료 공제안함' ||
		netAgePension >= 60 ||
		pensionNoForeigner
			? 0
			: constructOrNot === '건설업'
			? totalMonthWageAmount > 2200000 || !under8daysPerMonth
				? pensionWage
				: 0
			: totalMonthWageAmount > 2200000 ||
			  is60OverMonth >= 60 ||
			  !under8daysPerMonth
			? pensionWage
			: 0;

	const healthCareTaxWageSum =
		healthCarePayType === '건강보험료 공제안함'
			? 0
			: is60OverMonth >= 60 || !under8daysPerMonth
			? healthCareWage
			: 0;

	const oldCareTaxWageSum =
		oldCarePayType === '요양보험료 공제안함'
			? 0
			: is60OverMonth >= 60 || !under8daysPerMonth
			? oldCareWage
			: 0;

	const noJobTaxWageSum =
		noJobPayType === '고용보험료 공제안함' || foreignerNoNoJobVisa
			? 0
			: netAgeEntered >= 65
			? 0
			: noJobPayWage;

	const numDayWorkerPlusDeductHealthCare = dayWorkerPlusDeductHealthCare
		? numStringToNumber(dayWorkerPlusDeductHealthCare)
		: 0;
	const numDayWorkerPlusDeductOldCare = dayWorkerPlusDeductOldCare
		? numStringToNumber(dayWorkerPlusDeductOldCare)
		: 0;
	const numDayWorkerPlusDeductWorkTax = dayWorkerPlusDeductWorkTax
		? numStringToNumber(dayWorkerPlusDeductWorkTax)
		: 0;
	const numDayWorkerPlusDeductWorkRegionTax = dayWorkerPlusDeductWorkRegionTax
		? numStringToNumber(dayWorkerPlusDeductWorkRegionTax)
		: 0;
	const numDayWorkerPlusDeductDuru = dayWorkerPlusDeductDuru
		? numStringToNumber(dayWorkerPlusDeductDuru)
		: 0;
	const numDayWorkerPlusMinus4Ins = dayWorkerPlusMinus4Ins
		? numStringToNumber(dayWorkerPlusMinus4Ins)
		: 0;
	const numDayWorkerPlusDeductWorkerCompanyLoan =
		dayWorkerPlusDeductWorkerCompanyLoan
			? numStringToNumber(dayWorkerPlusDeductWorkerCompanyLoan)
			: 0;

	const numDayWorkerDeductEtcWage1 = dayWorkerDeductEtcWage1
		? numStringToNumber(dayWorkerDeductEtcWage1)
		: 0;
	const numdayWorkerDeductEtcWage2 = dayWorkerDeductEtcWage2
		? numStringToNumber(dayWorkerDeductEtcWage2)
		: 0;
	const numDayWorkerDeductEtcWage3 = dayWorkerDeductEtcWage3
		? numStringToNumber(dayWorkerDeductEtcWage3)
		: 0;

	//근로소득세, 지방소득세, 연금, 건보, 요양, 고용

	const calTaxRate =
		taxPayType === '120%' ? 1.2 : taxPayType === '80%' ? 0.8 : 1;

	function dayWorkerTaxF(dayWorkerWage) {
		// 일당에서 15만원을 공제하고 공제금의 (6%-6%*55%)=2.7%를 공제
		// 15만원 이상이라도 187000원 이하인 경우 세금이 1000원 미만이므로 0원 처리

		return (
			Math.floor(
				(dayWorkerWage < 187000 ? 0 : ((dayWorkerWage - 150000) * 2.7) / 100) /
					10
			) * 10
		);
	}

	const calTax =
		dayWorkerTaxText === '일반근로자세금'
			? Math.floor(
					((taxWageSum < 10000000
						? taxIncludeFamily(taxWageSum, 0)
						: Math.floor(tax1000Over(taxWageSum) / 10) * 10) *
						calTaxRate) /
						10
			  ) * 10
			: daylyTimePay > 0
			? dayWorkerTaxF(
					taxWageSum /
						(timeWorkerTotalWorkDays === 0 ? 1 : timeWorkerTotalWorkDays)
			  )
			: dayWorkerTaxF(dayWorkerWage) * countOfNomalDayWork +
			  dayWorkerTaxF(dayWorkerOverWorkDayWage) * countOfOverWorkingDayWork +
			  dayWorkerTaxF(dayWorkerHoliWage) * countOfHoliDayWork;

	const calTaxComment =
		dayWorkerTaxText === '일반근로자세금'
			? '당월 과세 임금에 대한 소득세법상 일반 근로자 소득세 100%'
			: '당월 과세 임금에 대한 소득세법상 일용 근로자 소득세 100%';
	const calRegionTax = Math.floor(calTax / 10 / 10) * 10;
	const calRegionTaxComment =
		dayWorkerTaxText === '일반근로자세금'
			? '당월 과세 임금에 대한 소득세법상 일반 근로자 지방소득세 100%'
			: '당월 과세 임금에 대한 소득세법상 일용 근로자 지방소득세 100%';

	const calPension =
		pensionTaxWageSum === 0
			? 0
			: pension(pensionTaxWageSum, thisYear, thisMonth);
	const calHealthCare =
		healthCareTaxWageSum === 0
			? 0
			: healthCare(healthCareTaxWageSum, thisYear, healthCarePayType);
	const calOldCare =
		oldCareTaxWageSum === 0
			? 0
			: oldCare(oldCareTaxWageSum, thisYear, healthCarePayType);
	const calNoJobInsurance =
		noJobTaxWageSum === 0
			? 0
			: noJobInsurance(noJobTaxWageSum, thisYear, thisMonth);

	const numOfdayWorkerUserFixedTax =
		dayWorkerUserFixedTax === 0
			? 0
			: dayWorkerUserFixedTax
			? numStringToNumber(dayWorkerUserFixedTax)
			: calTax;
	const numOfdayWorkerUserFixedRegionTax =
		dayWorkerUserFixedRegionTax === 0
			? 0
			: dayWorkerUserFixedRegionTax
			? numStringToNumber(dayWorkerUserFixedRegionTax)
			: calRegionTax;
	const numOfdayWorkerUserFixedHealthCare =
		dayWorkerUserFixedHealthCare === 0
			? 0
			: dayWorkerUserFixedHealthCare
			? numStringToNumber(dayWorkerUserFixedHealthCare)
			: calHealthCare;
	const numOfdayWorkerUserFixedOldCare =
		dayWorkerUserFixedOldCare === 0
			? 0
			: dayWorkerUserFixedOldCare
			? numStringToNumber(dayWorkerUserFixedOldCare)
			: calOldCare;
	const numOfdayWorkerUserFixedPension =
		dayWorkerUserFixedPension === 0
			? 0
			: dayWorkerUserFixedPension
			? numStringToNumber(dayWorkerUserFixedPension)
			: calPension;
	const numOfdayWorkerUserFixedNoJobInsurance =
		dayWorkerUserFixedNoJobInsurance === 0
			? 0
			: dayWorkerUserFixedNoJobInsurance
			? numStringToNumber(dayWorkerUserFixedNoJobInsurance)
			: calNoJobInsurance;

	const sumOfDedectTax =
		numOfdayWorkerUserFixedTax +
		numOfdayWorkerUserFixedRegionTax +
		numOfdayWorkerUserFixedHealthCare +
		numOfdayWorkerUserFixedOldCare +
		numOfdayWorkerUserFixedPension +
		numOfdayWorkerUserFixedNoJobInsurance +
		numDayWorkerPlusDeductHealthCare +
		numDayWorkerPlusDeductOldCare +
		numDayWorkerPlusDeductWorkTax +
		numDayWorkerPlusDeductWorkRegionTax +
		numDayWorkerPlusMinus4Ins +
		numDayWorkerPlusDeductWorkerCompanyLoan +
		numDayWorkerDeductEtcWage1 +
		numdayWorkerDeductEtcWage2 +
		numDayWorkerDeductEtcWage3 +
		numDayWorkerPlusDeductDuru;

	//실수령액(실제)
	const deductWage = taxWageSum - sumOfDedectTax;

	return {
		netAgePension,
		netAgeEntered,
		calTax,
		taxPayType,
		calRegionTax,
		weekLBTime,
		healthCarePayType,
		calHealthCare,
		healthCareBaseWage,
		oldCarePayType,
		calOldCare,
		oldCareBaseWage,
		under8daysPerMonth,
		pensionPayType,
		pensionBaseWage,
		calPension,
		calNoJobInsurance,
		noJobBaseWage,
		noJobPayType,
		numDayWorkerPlusDeductHealthCare,
		sumOfDedectTax,
		deductWage,
		taxWageSum,
		healthCareTaxWageSum,
		calTaxComment,
		calRegionTaxComment,
	};
}

export function healthCareText(
	healthCareBaseWage,
	healthCarePayType,
	thisYear,
	calHealthCare
) {
	const yearHealthRate = {
		2022: '(요율 : 3.495%)',
		2023: '(요율 : 3.545%)',
		2024: '(요율 : 3.545%)',
		2025: '(요율 : 3.545%)',
	};
	const defaultYear = 2025;

	const wageText = (baseWage, year) => {
		return healthCarePayType === '보수월액(건강보험공단에 신고된 금액)' &&
			baseWage
			? `보수월액(건강보험공단에 신고된 금액) ${numberToCommaString(
					baseWage
			  )}원에 대한 건강보험료${yearHealthRate[year]}`
			: `당월 과세 임금에 대한 건강보험료${yearHealthRate[year]}`;
	};
	const year =
		thisYear === '2022' ||
		thisYear === '2023' ||
		thisYear === 2022 ||
		thisYear === 2023 ||
		thisYear === '2024' ||
		thisYear === '2025' ||
		thisYear === 2024 ||
		thisYear === 2025
			? thisYear
			: defaultYear;

	if (calHealthCare === 0) {
		return '8일미만 , 소정근로 60시간 미만, 중도입사자는 건강보험료를 납부하지 않습니다.';
	} else {
		return wageText(healthCareBaseWage, year);
	}
}

export function oldCareText(
	oldCareBaseWage,
	oldCarePayType,
	thisYear,
	calOldCare
) {
	const yearOldCareRate = {
		2022: '(요율 : 건강보험료 * 12.27%)',
		2023: '(요율 : 건강보험료 * 약 12.81%)',
		2024: '(요율 : 건강보험료 * 약 12.95%)',
		2025: '(요율 : 건강보험료 * 약 12.95%)',
	};
	const defaultYear = 2025;

	const wageText = (baseWage, year) => {
		return oldCarePayType === '보수월액(건강보험공단에 신고된 금액)' && baseWage
			? `보수월액(건강보험공단에 신고된 금액) ${numberToCommaString(
					baseWage
			  )}원에 대한 장기요양보험료${yearOldCareRate[year]}`
			: `당월 과세 임금에 대한 장기요양보험료${yearOldCareRate[year]}`;
	};

	const year =
		thisYear === '2022' ||
		thisYear === '2023' ||
		thisYear === '2024' ||
		thisYear === '2025' ||
		thisYear === 2022 ||
		thisYear === 2023 ||
		thisYear === 2024 ||
		thisYear === 2025
			? thisYear
			: defaultYear;
	if (calOldCare === 0) {
		return '8일미만 , 소정근로 60시간 미만, 중도입사자는 장기요양료를 납부하지 않습니다.';
	} else {
		return wageText(oldCareBaseWage, year);
	}
}

export function pensionText(
	pensionBaseWage,
	pensionPayType,
	calPension,
	visaType
) {
	const pensionRate = '(요율 : 4.5%)';
	const foreignerNoPensionVisa = visaType && !checkVisaPensionType(visaType);

	const pensionText = (baseWage) => {
		return pensionPayType === '기준소득월액(국민연금공단에 신고된 금액)' &&
			baseWage
			? `기준소득월액(국민연금공단에 신고된 금액) ${numberToCommaString(
					baseWage
			  )}원에 대한 국민연금${pensionRate}`
			: `당월 과세 임금에 대한 국민연금${pensionRate}`;
	};

	if (foreignerNoPensionVisa) {
		return '해당 비자는 국민연금료 공제 대상이 아닙니다.';
	} else if (calPension === 0) {
		return '만60세이상 또는 월 소정60시간미만(월 220만원 미만), 중도입사자인 경우 , (일용/시급 8일미만, 퇴사월)국민연금료 납부의무 없음.';
	} else {
		return pensionText(pensionBaseWage);
	}
}

function extractVisaPrefix(visaType) {
	return visaType?.slice(0, 2);
}

export function noJobText(
	noJobBaseWage,
	noJobPayType,
	thisYear,
	calNoJobInsurance,
	visaType,
	selfNoJobPay
) {
	const insuranceRateText = (rate) => `고용보험(요율 : ${rate})`;
	let foreignerNoNoJobVisa = false;

	if (visaType) {
		foreignerNoNoJobVisa =
			visaType && !checkVisaNoJobPayType(visaType, selfNoJobPay);
	}

	const insuranceText = (baseWage, rate) => {
		return noJobPayType === '월평균보수월액(근로복지공단에 신고된 금액)' &&
			baseWage
			? `월평균보수월액(근로복지공단에 신고된 금액) ${numberToCommaString(
					baseWage
			  )}원에 대한 ${insuranceRateText(rate)}`
			: `당월 과세 임금에 대한 ${insuranceRateText(rate)}`;
	};

	if (foreignerNoNoJobVisa) {
		return '해당 비자는 고용보험료 공제 대상이 아닙니다.';
	} else if (calNoJobInsurance === 0) {
		return '중도입사자, 계약기간이 3개월 미만의 1주 소정근로 15시간(또는 월 60시간)미만 또는 65세 이상인 경우 공제안함';
	}

	switch (thisYear) {
		case '2022':
			return insuranceText(noJobBaseWage, '0.9% *2022년6월까지 0.8%');
		case '2021':
			return insuranceText(noJobBaseWage, '0.8%');
		default:
			return insuranceText(noJobBaseWage, '0.9%');
	}
}

export function isThreeMonthsOrMore(enteredDate, contractEndDate) {
	// Firestore의 Timestamp 객체에서 Date 객체로 변환
	const workerEnteredDate = enteredDate ? moment(enteredDate) : null;

	const normalizedContractEndDate =
		contractEndDate === '정년' || contractEndDate === ''
			? null
			: contractEndDate;
	const contractEndDateF = normalizedContractEndDate
		? moment(normalizedContractEndDate)
		: null;

	if (workerEnteredDate && contractEndDateF) {
		// 두 날짜 간의 차이를 월 단위로 계산
		const monthsDiff =
			contractEndDate === undefined
				? 5
				: contractEndDateF.diff(workerEnteredDate, 'months');

		if (monthsDiff >= 3) {
			return true;
		} else {
			return false;
		}
	} else if (workerEnteredDate && contractEndDateF === null) {
		return true;
	} else {
		return false;
	}
}

export const validVisasPension = [
	'D5',
	'D7',
	'D8',
	'D9',
	'D10',
	'E1',
	'E2',
	'E3',
	'E4',
	'E5',
	'E6',
	'E7',
	'E8',
	'E9',
	'E10',
	'F2',
	'F4',
	'F5',
	'F6',
	'H1',
	'H2',
]; // 2024년 4월 1일 기준 국민연금 가입대상 비자

export const checkVisaPensionType = (visaType) => {
	const isValid = validVisasPension.some((visa) =>
		extractVisaPrefix(visaType)?.includes(visa)
	);
	const result = isValid ? true : false;
	return result;
};

export function noJobPayDetailF(noJobPayDetail) {
	if (!noJobPayDetail) {
		return; // or some default value
	}
	const result = noJobPayDetail.split(' ')[0];
	return result;
}

export function pensionTaxWageSumF(
	pensionLimitWage2023,
	pensionPayType,
	taxWageSum,
	pensionBaseWage,
	netAgePension,
	weekLBTime,
	isInterInWorker = false,
	visaType = '',
	workerEnteredDate
) {
	const afterForeignerProgramedWorker =
		moment(workerEnteredDate).isAfter('2024-05-20'); // 5월 20일 외국인 고용보험 기능 구현 이후 입사자
	const foreignerNoPensionVisa =
		visaType &&
		!checkVisaPensionType(visaType) &&
		afterForeignerProgramedWorker;

	let pensionTaxWageSum = 0;

	if (
		pensionPayType === '국민연금료 공제안함' ||
		foreignerNoPensionVisa ||
		netAgePension >= 60 ||
		(weekLBTime < 13.8 && taxWageSum < pensionLimitWage2023) ||
		isInterInWorker
	) {
		// 이 조건에서는 연금 공제를 하지 않음
		pensionTaxWageSum = 0;
	} else if (pensionPayType === '당월과세금액(매월변동반영)') {
		// 과세 금액을 기준으로 연금 공제
		pensionTaxWageSum = taxWageSum;
	} else if (
		pensionPayType === '기준소득월액(국민연금공단에 신고된 금액)' &&
		pensionBaseWage
	) {
		// 기준 소득 월액을 기준으로 연금 공제
		pensionTaxWageSum = pensionBaseWage;
	} else {
		// 기본적으로 과세 금액을 기준으로 연금 공제
		pensionTaxWageSum = taxWageSum;
	}

	return pensionTaxWageSum;
}

export function healthCareTaxWageSumF(
	healthCarePayType,
	taxWageSum,
	healthCareBaseWage,
	weekLBTime,
	isInterInWorker = false
) {
	let healthCareTaxWageSum = 0;

	if (
		healthCarePayType === '건강보험료 공제안함' ||
		weekLBTime < 13.8 ||
		isInterInWorker
	) {
		// 건강보험료를 공제하지 않는 경우
		healthCareTaxWageSum = 0;
	} else if (healthCarePayType === '당월과세금액(매월변동반영)') {
		// 과세 금액을 기준으로 건강보험료를 공제하는 경우
		healthCareTaxWageSum = taxWageSum;
	} else if (
		healthCarePayType === '보수월액(건강보험공단에 신고된 금액)' &&
		healthCareBaseWage
	) {
		// 보수월액을 기준으로 건강보험료를 공제하는 경우
		healthCareTaxWageSum = healthCareBaseWage;
	} else {
		// 기본적으로 과세 금액을 기준으로 건강보험료를 공제
		healthCareTaxWageSum = taxWageSum;
	}

	return healthCareTaxWageSum;
}

export function oldCareTaxWageSumF(
	oldCarePayType,
	taxWageSum,
	oldCareBaseWage,
	weekLBTime,
	isInterInWorker = false
) {
	let oldCareTaxWageSum = 0;

	if (
		oldCarePayType === '요양보험료 공제안함' ||
		weekLBTime < 13.8 ||
		isInterInWorker
	) {
		// 요양보험료를 공제하지 않는 경우
		oldCareTaxWageSum = 0;
	} else if (oldCarePayType === '당월과세금액(매월변동반영)') {
		// 과세 금액을 기준으로 요양보험료를 공제하는 경우
		oldCareTaxWageSum = taxWageSum;
	} else if (
		oldCarePayType === '보수월액(건강보험공단에 신고된 금액)' &&
		oldCareBaseWage
	) {
		// 보수월액을 기준으로 요양보험료를 공제하는 경우
		oldCareTaxWageSum = oldCareBaseWage;
	} else {
		// 기본적으로 과세 금액을 기준으로 요양보험료를 공제
		oldCareTaxWageSum = taxWageSum;
	}

	return oldCareTaxWageSum;
}

export const selectValidVisaNoJobPay = [
	'F4',
	'C4',
	'E1',
	'E2',
	'E3',
	'E4',
	'E5',
	'E6',
	'E7',
	'E8',
	'E9',
	'E10',
	'H2',
];

export const checkVisaNoJobPayType = (visaType, selfNoJobPay) => {
	const validVisasNoJobPay = ['F2', 'F5', 'F6']; // 21년 1월 1일 이후 고용보험 가입대상 비자
	const isValid = validVisasNoJobPay.some((visa) =>
		extractVisaPrefix(visaType)?.includes(visa)
	);

	const isValid2 =
		selfNoJobPay === '임의가입' &&
		selectValidVisaNoJobPay.some((visa) =>
			extractVisaPrefix(visaType)?.includes(visa)
		);

	return isValid || isValid2;
};

export function noJobTaxWageSumF(
	noJobPayType,
	taxWageSum,
	noJobBaseWage,
	netAgeEntered,
	weekLBTime,
	over3MonthsWorker,
	visaType = '',
	selfNoJobPay = '',
	isInterInWorker = false,
	endless65EmploymentInsurance
) {
	let noJobTaxWageSum = 0;
	let foreignerNoNoJobVisa = false;

	if (visaType) {
		foreignerNoNoJobVisa =
			visaType && !checkVisaNoJobPayType(visaType, selfNoJobPay);
	}

	if (
		noJobPayType === '고용보험료 공제안함' ||
		foreignerNoNoJobVisa ||
		(netAgeEntered >= 65 &&
			endless65EmploymentInsurance !== '65세 이전이후 근로단절 없이 입사') ||
		(weekLBTime < 15 && !over3MonthsWorker) ||
		isInterInWorker
	) {
		// 고용보험료를 공제하지 않는 조건
		noJobTaxWageSum = 0;
	} else if (noJobPayType === '당월과세금액(매월변동반영)') {
		// 과세 금액을 기준으로 고용보험료를 공제하는 경우
		noJobTaxWageSum = taxWageSum;
	} else if (
		noJobPayType === '월평균보수월액(근로복지공단에 신고된 금액)' &&
		noJobBaseWage
	) {
		// 월평균보수월액을 기준으로 고용보험료를 공제하는 경우
		noJobTaxWageSum = noJobBaseWage;
	} else {
		// 기본적으로 과세 금액을 기준으로 고용보험료를 공제
		noJobTaxWageSum = taxWageSum;
	}

	return noJobTaxWageSum;
}

export function calTaxF(
	taxWageSum,
	numOfTaxFamily,
	numOfTaxFamily7To20,
	calTaxRate,
	retiredMonthYes
) {
	const calTax =
		Math.floor(
			((taxWageSum < 10000000
				? taxIncludeFamily(taxWageSum, numOfTaxFamily, numOfTaxFamily7To20)
				: Math.floor(
						tax1000Over(taxWageSum, numOfTaxFamily, numOfTaxFamily7To20) / 10
				  ) * 10) *
				calTaxRate) /
				10
		) * 10;
	return retiredMonthYes ? 0 : calTax;
}

export function calRegionTaxF(calTax) {
	const calRegionTax = Math.floor(calTax / 10 / 10) * 10;
	return calRegionTax;
}

export function safeStringCompare(a, b) {
	if (a === null || a === undefined) a = '';
	if (b === null || b === undefined) b = '';

	if (typeof a === 'number') a = String(a);
	if (typeof b === 'number') b = String(b);

	// 객체나 배열이 들어오는 경우 JSON 문자열로 변환
	if (typeof a === 'object') a = JSON.stringify(a);
	if (typeof b === 'object') b = JSON.stringify(b);

	// 숫자로 변환 가능한지 체크하고, 가능하면 숫자로 비교
	const numA = commaStringToNumber(a);
	const numB = commaStringToNumber(b);
	if (!isNaN(numA) && !isNaN(numB)) {
		return numA - numB;
	}

	return a.localeCompare(b, 'ko');
}

export function convertConversationsToArray(conversations) {
	return Object.keys(conversations).map((conversationId) => {
		const messages = Object.keys(conversations[conversationId])
			.filter((key) => key !== 'id') // 'id' 키 제외
			.map((messageKey) => ({
				...conversations[conversationId][messageKey],
				messageId: messageKey,
			}));

		return {
			id: conversationId,
			messages,
		};
	});
}

export function padToTwoDigits(number) {
	if (number === undefined || number === null || isNaN(number)) {
		number = 0;
	}
	return number.toString().padStart(2, '0');
}

export function getYearFromDate(dateString) {
	return new Date(dateString).getFullYear();
}

export function getMonthFromDate(dateString) {
	return new Date(dateString).getMonth() + 1;
}

export function getDayFromDate(dateString) {
	return new Date(dateString).getDate();
}

export function isAgentF(agentType, authLevel) {
	const result =
		authLevel >= 50 && (agentType === 'bs노무사' || agentType === 'ps노무사')
			? true
			: false;
	return result;
}

export const checkNoUserInfo = (userProfile) => {
	return !(userProfile?.ceoName || userProfile?.workerInputCompanyName);
};

export const redirectToAppropriatePage = (userProfile, history) => {
	alert('회원가입 후 회원 정보를 입력해야 사용가능합니다.');
	if (userProfile?.id) {
		history.push(`/profile/${userProfile.id}`);
	} else {
		history.push('/pricePolicyPaper');
	}
};

export const childCompanyCOWTitleName = (companyName) => {
	return companyName?.includes('어린이집') ? (
		<span>직책급(원장,팀장,주임 등)</span>
	) : (
		'관리감독수당'
	);
};

export const handleNaN = (value) => (isNaN(value) ? 0 : value);

export function sumPaidAnnualLeaveDaysForYear(paydocus, targetYear) {
	let totalDays = 0;

	paydocus.forEach((paydocu) => {
		const year = paydocu?.info?.yearMonth?.slice(0, 4);
		const days = paydocu?.addAverWage?.plusUnusedAnnualLeavesDays;

		if (year === targetYear && days != null && days !== '') {
			totalDays += Number(days);
		}
	});

	return totalDays;
}

export function sumLastYearPaidAnnualLeaveDaysForYear(paydocus, targetYear) {
	let totalDays = 0;
	paydocus.forEach((paydocu) => {
		const year = paydocu?.info?.yearMonth?.slice(0, 4);
		const days = paydocu?.addAverWage?.plusLastYearUnusedAnnualLeavesDays;
		if (
			Number(year) === Number(targetYear) + 1 &&
			days != null &&
			days !== ''
		) {
			totalDays += Number(days);
		}
	});

	return totalDays;
}

export function calculateEmploymentMonths(startDate, endDate) {
	// 날짜를 Date 객체로 변환
	let start = new Date(startDate);
	let end = new Date(endDate);

	// 연도와 월을 계산
	let startYear = start.getFullYear();
	let startMonth = start.getMonth(); // 0부터 시작하므로 5월은 4
	let endYear = end.getFullYear();
	let endMonth = end.getMonth(); // 7월은 6

	// 총 월수 계산
	let months = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;

	return months;
}

export const checkRetirementFundCompliance = (
	retiredDateM,
	retiredFundPaidDateM,
	retiredFundPaidDate
) => {
	// retiredFundPaidDateM 값이 없는 경우
	if (!retiredFundPaidDate) {
		return '퇴직일로부터 14일 이내에 금품이 지급되어야 합니다.';
	}

	// 두 날짜의 차이를 일 단위로 계산
	const daysDifference = retiredFundPaidDateM.diff(retiredDateM, 'days');

	// 14일을 초과하는지 확인
	if (daysDifference > 14) {
		return `지급기일 연장 동의(서)가 없는 이상 퇴직일로부터 14일 이내에 금품청산이 이루어져야 합니다. 현재 ${daysDifference}일 초과되었습니다. (근기법 제36조)`;
	} else {
		return '금품청산의무 준수';
	}
};

export const pricingData = [
	{ employees: '5인 미만', systemPrice: 130000, settingPrice: 300000 },
	{ employees: '10인 미만', systemPrice: 180000, settingPrice: 500000 },
	{ employees: '30인 미만', systemPrice: 270000, settingPrice: 700000 },
	{ employees: '50인 미만', systemPrice: 450000, settingPrice: 900000 },
	{ employees: '100인 미만', systemPrice: 800000, settingPrice: 1300000 },
	{
		employees: '100인 이상',
		systemPrice: '800,000 + 100명당 추가 300,000',
		settingPrice: '별도 협의',
	},
];

export const pricingData1 = [
	{
		category: '① 4대보험 입퇴사 신고 대행',
		price: '5,000원 (월)',
		description: '회사 직원 수 (정규직, 비정규직, 사업소득직 포함) 1인당',
	},
	{
		category: '② 매월 4대보험료 정산 공제 임금대장 작성 대행',
		price: '5,000원 (월)',
		description: '회사 직원 수 (정규직, 비정규직, 사업소득직 포함) 1인당',
	},
	{
		category: '③ 매월 지급 임금대장 작성 대행',
		price: '5,000원 (월)',
		description: '회사 직원 수 (정규직, 비정규직, 사업소득직 포함) 1인당',
	},
	{
		category: `④ 입퇴사자 전산 노무 관리 대행`,
		price: '10,000원 (월)',
		description: '회사 직원 수 (정규직, 비정규직, 사업소득직 포함) 1인당',
	},
	{
		category: `⑤ 방문 노무관리 대행`,
		price: '10,000원 (월)',
		description: '회사 직원 수 (정규직, 비정규직, 사업소득직 포함) 1인당',
	},
];

export const pricingDataPackage = [
	{
		category: '⑥ Basic 패키지( ① + ② ) - 4대보험 신고 및 공제 임금대장 작성',
		price: '8,000원 (월)',
		description: '회사 직원 수 (정규직, 비정규직, 사업소득직 포함) 1인당',
	},
	{
		category:
			'⑦ Standard 패키지( ① + ② + ③ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성',
		price: '12,000원 (월)',
		description: '회사 직원 수 (정규직, 비정규직, 사업소득직 포함) 1인당',
	},
	{
		category:
			'⑧ Premium 패키지( ① + ② + ③ + ④ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성 및 입퇴사자 전산 노무 관리',
		price: '20,000원 (월)',
		description: '회사 직원 수 (정규직, 비정규직, 사업소득직 포함) 1인당',
	},
	{
		category:
			'⑩ VIP 패키지( ① + ② + ③ + ④ + ⑤ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성 및 입퇴사자 전산 노무 관리 및 방문 노무관리',
		price: '27,000원 (월)',
		description: '회사 직원 수 (정규직, 비정규직, 사업소득직 포함) 1인당',
	},
];

export const getSystemPrice = (numOfWorkers) => {
	if (numOfWorkers < 5) return pricingData[0].systemPrice;
	if (numOfWorkers < 10) return pricingData[1].systemPrice;
	if (numOfWorkers < 30) return pricingData[2].systemPrice;
	if (numOfWorkers < 50) return pricingData[3].systemPrice;
	if (numOfWorkers < 100) return pricingData[4].systemPrice;

	// For 100 or more employees
	const basePrice = 800000;
	const additionalCost = Math.floor((numOfWorkers - 100) / 100) * 300000;
	return basePrice + additionalCost;
};

export function calculateTotalPrice(numOfWorkers, nomuService = []) {
	let totalPrice = 0;
	let totalPricePerWorker = 0;

	nomuService.forEach((category) => {
		const selectedService = pricingData1?.find(
			(item) => item.category === category
		);

		if (selectedService) {
			// Convert price to a number by removing non-numeric characters
			const price = parseInt(selectedService.price.replace(/[^0-9]/g, ''), 10);
			totalPrice += price * numOfWorkers;
			totalPricePerWorker += price;
		}
	});

	return [totalPrice, totalPricePerWorker];
}

export function calculatePackagePrice(numOfWorkers, selectedCategory) {
	// 배열일 경우, 첫 번째 항목을 사용하거나 필요한 속성만 추출하여 사용
	const categoryToMatch = Array.isArray(selectedCategory)
		? selectedCategory[0]
		: selectedCategory;

	const selectedPackage = pricingDataPackage.find(
		(item) => item.category === categoryToMatch
	);

	if (!selectedPackage) {
		return [0, 0];
	}

	const pricePerWorker = parseInt(
		selectedPackage.price.replace(/[^0-9]/g, ''),
		10
	);
	return [pricePerWorker * numOfWorkers, pricePerWorker];
}

export function calculateSettingPrice(numOfEmployees) {
	const selectedTier = pricingData.find((tier) => {
		// 직원 수 조건을 확인할 때 숫자가 아닌 경우 0으로 처리합니다
		const maxEmployees =
			parseInt(tier.employees.replace(/[^0-9]/g, ''), 10) || 0;
		if (tier.employees.includes('미만')) {
			return numOfEmployees < maxEmployees;
		} else if (tier.employees.includes('이상')) {
			return numOfEmployees >= maxEmployees; // 100인 이상은 100 포함
		}
		return false;
	});

	// tier를 찾지 못한 경우 기본값 반환
	if (!selectedTier) {
		return '0원';
	}

	// 100인 이상 별도 협의
	if (selectedTier.employees === '100인 이상') {
		return '별도 협의';
	}

	// settingPrice가 숫자인 경우만 toLocaleString() 적용
	const settingPrice = Number(selectedTier.settingPrice);
	return `${settingPrice.toLocaleString()}원`;
}

export const companyBankAccountSeajong = [
	'송금계좌 : 농협 317-5674-7750',
	'예금주 : 노무법인 최상인업 세종지점',
];
export const companyBankAccountSeoul = [
	'송금계좌 : 하나 165-910010-89704',
	'예금주 : 노무법인 최상인업',
];

export const companyBankAccountSeoul1 = [
	'송금계좌 : 하나 1005-001-407791',
	'예금주 : 노무법인 최상인업',
];

export function renderWithNewline(text) {
	return text.split('\n').map((line, index) => (
		<span key={index}>
			{line}
			<br />
		</span>
	));
}

// export function absentDaysYearCntForALFNew(
// 	enterDate,
// 	paydocus,
// 	workerId,
// 	year
// ) {
// 	const oneYearMonMinusOne = moment(enterDate)
// 		.add(11, 'months')
// 		.format('yyyy-MM');

// 	const lastYear = +year - 1;

// 	let arrayAbsentDaysCount = [];
// 	let arrayAbsentYearMonth = [];
// 	let lessOneYearAbsentYearMonth = {};
// 	let lessOneYearAbsentYearMonthLastYear = [];
// 	let lessOneYearAbsentYearMonthThisYear = [];
// 	let lessOneYearAbsentYearMonthLastYearCnt = [];
// 	let lessOneYearAbsentYearMonthThisYearCnt = [];
// 	for (let i in paydocus) {
// 		if (
// 			workerId === paydocus[i]?.workerInfo?.workerId &&
// 			paydocus[i]?.info.yearMonth <= oneYearMonMinusOne.replace('-', '')
// 		) {
// 			if (paydocus[i]?.absentMinusWage?.absentDays) {
// 				arrayAbsentDaysCount.push(paydocus[i]?.absentMinusWage?.absentDays);
// 				arrayAbsentYearMonth.push(paydocus[i].info.yearMonth);
// 			}
// 			for (let j = 0; j < arrayAbsentYearMonth.length; j++) {
// 				lessOneYearAbsentYearMonth[arrayAbsentYearMonth[j]] =
// 					arrayAbsentDaysCount[j];
// 			}
// 		}
// 	}
// 	for (let k = 0; k < arrayAbsentYearMonth.length; k++) {
// 		if (arrayAbsentYearMonth[k]?.substring(0, 4) === lastYear.toString()) {
// 			lessOneYearAbsentYearMonthLastYear.push(arrayAbsentYearMonth[k]);
// 		}
// 		if (arrayAbsentYearMonth[k]?.substring(0, 4) === year.toString()) {
// 			lessOneYearAbsentYearMonthThisYear.push(arrayAbsentYearMonth[k]);
// 		}
// 	}
// 	for (let l = 0; l < lessOneYearAbsentYearMonthLastYear.length; l++) {
// 		lessOneYearAbsentYearMonthLastYearCnt.push(arrayAbsentDaysCount[l]);
// 	}
// 	for (
// 		let m = lessOneYearAbsentYearMonthLastYear.length;
// 		m < arrayAbsentYearMonth.length;
// 		m++
// 	) {
// 		lessOneYearAbsentYearMonthThisYearCnt.push(arrayAbsentDaysCount[m]);
// 	}

// 	const thisYearAbsentData = lessOneYearAbsentYearMonthThisYearCnt?.length;
// 	const lastYearAbsentData = lessOneYearAbsentYearMonthLastYear?.length;

// 	return [workerId, lastYearAbsentData, thisYearAbsentData];
// }

// 올해 사용연차 갯수 세기
// export function usedAnnualLeavesInThisYearSumFNew(paydocus, workerId, year) {
// 	let annualLeavesUsedInThisYear = [];
// 	for (let i in paydocus) {
// 		if (workerId === paydocus[i]?.workerInfo?.workerId) {
// 			if (paydocus[i]?.info?.yearMonth.substr(0, 4) === year) {
// 				annualLeavesUsedInThisYear.push(paydocus[i]);
// 			}
// 		}
// 	}
// 	const usedAnnualLeavesInThisYearSum = annualLeavesUsedInThisYear
// 		? annualLeavesUsedInThisYear
// 				?.map((item) => (item?.usedAnnualLeaves ? +item?.usedAnnualLeaves : 0))
// 				.reduce((prev, curr) => prev + curr, 0)
// 		: 0;
// 	return [workerId, usedAnnualLeavesInThisYearSum];
// }

// export function sumPaidAnnualLeaveDaysForYearNew(
// 	workerId,
// 	paydocus,
// 	targetYear
// ) {
// 	let totalDays = 0;

// 	// 특정 workerId와 일치하는 paydocu만 처리
// 	for (let i in paydocus) {
// 		if (workerId === paydocus[i]?.workerInfo?.workerId) {
// 			const year = paydocus[i]?.info?.yearMonth?.slice(0, 4);
// 			const days = paydocus[i]?.addAverWage?.plusUnusedAnnualLeavesDays;

// 			if (year === targetYear && days != null && days !== '') {
// 				totalDays += Number(days);
// 			}
// 		}
// 	}

// 	return [workerId, totalDays];
// }

// export function sumLastYearPaidAnnualLeaveDaysForYearNew(
// 	workerId,
// 	paydocus,
// 	targetYear
// ) {
// 	let totalDaysLastYear = 0;
// 	for (let i in paydocus) {
// 		if (workerId === paydocus[i]?.workerInfo?.workerId) {
// 			const year = paydocus[i]?.info?.yearMonth?.slice(0, 4);
// 			const days = paydocus[i]?.addAverWage?.plusLastYearUnusedAnnualLeavesDays;
// 			if (
// 				Number(year) === Number(targetYear) + 1 &&
// 				days != null &&
// 				days !== ''
// 			) {
// 				totalDaysLastYear += Number(days);
// 			}
// 		}
// 	}

// 	return [workerId, totalDaysLastYear];
// }

export function absentDaysYearCntForALFNew(workerId, filteredPaydocus, year) {
	const lastYear = +year - 1;

	const thisYearAbsentData = filteredPaydocus.filter(
		(doc) =>
			doc.workerId === workerId &&
			doc.yearMonth.startsWith(year) &&
			doc.absentDays
	).length;

	const lastYearAbsentData = filteredPaydocus.filter(
		(doc) =>
			doc.workerId === workerId &&
			doc.yearMonth.startsWith(lastYear.toString()) &&
			doc.absentDays
	).length;

	return [workerId, lastYearAbsentData, thisYearAbsentData];
}

export function usedAnnualLeavesInThisYearSumFNew(
	filteredPaydocus,
	workerId,
	year
) {
	// 중복 데이터 제거 (workerId + yearMonth 기준)
	const uniquePaydocus = Array.from(
		new Map(
			filteredPaydocus
				.filter(
					(doc) =>
						doc.workerId === workerId &&
						doc.yearMonth.startsWith(year) &&
						doc.usedAnnualLeaves
				)
				.map((doc) => [`${doc.workerId}-${doc.yearMonth}`, doc])
		).values()
	);

	const usedAnnualLeavesInThisYearSum = uniquePaydocus.reduce(
		(sum, doc) => sum + Number(doc.usedAnnualLeaves || 0),
		0
	);

	return [workerId, usedAnnualLeavesInThisYearSum];
}

export function sumPaidAnnualLeaveDaysForYearNew(
	workerId,
	filteredPaydocus,
	targetYear
) {
	const totalDays = filteredPaydocus
		.filter(
			(doc) =>
				doc.workerId === workerId &&
				doc.yearMonth.startsWith(targetYear) &&
				doc.plusUnusedAnnualLeavesDays
		)
		.reduce((sum, doc) => sum + Number(doc.plusUnusedAnnualLeavesDays || 0), 0);

	return [workerId, totalDays];
}

export function sumLastYearPaidAnnualLeaveDaysForYearNew(
	workerId,
	filteredPaydocus,
	targetYear
) {
	const lastYear = +targetYear + 1;
	const totalDaysLastYear = filteredPaydocus
		.filter(
			(doc) =>
				doc.workerId === workerId &&
				doc.yearMonth.startsWith(lastYear.toString()) &&
				doc.plusLastYearUnusedAnnualLeavesDays
		)
		.reduce(
			(sum, doc) => sum + Number(doc.plusLastYearUnusedAnnualLeavesDays || 0),
			0
		);

	return [workerId, totalDaysLastYear];
}

export const typeOfAbsentTime = ['지각', '조퇴', '외출'];

export const selectkDates = [
	'1일',
	'2일',
	'3일',
	'4일',
	'5일',
	'6일',
	'7일',
	'8일',
	'9일',
	'10일',
	'11일',
	'12일',
	'13일',
	'14일',
	'15일',
	'16일',
	'17일',
	'18일',
	'19일',
	'20일',
	'21일',
	'22일',
	'23일',
	'24일',
	'25일',
	'26일',
	'27일',
	'28일',
	'29일',
	'30일',
	'31일',
];

export const formatAbsentTimeDates = (absentTimeDates) => {
	// Check if absentTimeDates is a string
	if (typeof absentTimeDates === 'string') {
		// Return the string as-is (e.g., "지각 1일")
		return absentTimeDates;
	}

	// Check if usedAnnualLeaveDates is an array
	if (Array.isArray(absentTimeDates))
		if (typeof absentTimeDates[0] === 'object' && absentTimeDates[0] !== null) {
			// Check if the array contains objects
			// Handle array with objects (e.g., [{ date: '2025-01-19', content: '연차' }])
			return absentTimeDates
				.map((entry) => `${entry.type}(${entry.date}: ${entry.content || '0'})`)
				.join(', ');
		}

	// Handle other cases (e.g., null, undefined, invalid data types)
	return '없음';
};

export const formatUsedAnnualLeavesContent = (usedAnnualLeaveDates) => {
	// Check if usedAnnualLeaveDates is a string
	if (typeof usedAnnualLeaveDates === 'string') {
		// Return the string as-is (e.g., "지각 1일")
		return usedAnnualLeaveDates;
	}

	// Check if usedAnnualLeaveDates is an array
	if (Array.isArray(usedAnnualLeaveDates)) {
		// Check if the array contains objects
		if (typeof usedAnnualLeaveDates[0] === 'object') {
			// Handle array with objects (e.g., [{ date: '2025-01-19', content: '연차' }])
			return usedAnnualLeaveDates[0]?.date
				? usedAnnualLeaveDates
						.map(
							(entry) =>
								`${entry.date || '날짜 없음'}: ${entry.content || '내용 없음'}`
						)
						.join(', ')
				: null;
		} else {
			// Handle array with strings (e.g., ['2025-01-19', '2025-01-20'])
			return usedAnnualLeaveDates?.join(', ');
		}
	}

	// Handle other cases (e.g., null, undefined, invalid data types)
	return '없음';
};

export const koreanHolidays2023 = [
	'2023-01-01', // 신정
	'2023-01-21', // 설날 연휴 시작
	'2023-01-22', // 설날
	'2023-01-23', // 설날 연휴 종료
	'2023-01-24', // 설날 대체공휴일
	'2023-03-01', // 삼일절
	'2023-05-01', // 근로자의 날
	'2023-05-05', // 어린이날
	'2023-05-27', // 부처님 오신 날
	'2023-06-06', // 현충일
	'2023-08-15', // 광복절
	'2023-09-28', // 추석 연휴 시작
	'2023-09-29', // 추석
	'2023-09-30', // 추석 연휴 종료
	'2023-10-03', // 개천절
	'2023-10-09', // 한글날
	'2023-12-25', // 성탄절
];

// 대한민국 국공휴일 배열 (YYYY-MM-DD 형식)
export const koreanHolidays2024 = [
	'2024-01-01', // 신정
	'2024-02-09',
	'2024-02-10',
	'2024-02-11',
	'2024-02-12', // 설날 연휴
	'2024-03-01', // 삼일절
	'2024-05-01',
	'2024-05-05',
	'2024-05-06', // 어린이날 및 대체공휴일
	'2024-06-06', // 현충일
	'2024-08-15', // 광복절
	'2024-09-16',
	'2024-09-17',
	'2024-09-18', // 추석 연휴
	'2024-10-03', // 개천절
	'2024-10-09', // 한글날
	'2024-12-25', // 성탄절
];

export const koreanHolidays2025 = [
	'2025-01-01', // 신정
	'2025-01-28', // 설날 연휴 시작
	'2025-01-29', // 설날
	'2025-01-30', // 설날 연휴 종료
	'2025-03-01', // 삼일절
	'2025-03-03', // 삼일절 대체공휴일
	'2024-05-01', // 근로자의 날
	'2025-05-05', // 어린이날 및 부처님 오신 날
	'2025-05-06', // 어린이날 대체공휴일
	'2025-06-06', // 현충일
	'2025-08-15', // 광복절
	'2025-10-03', // 개천절
	'2025-10-05', // 추석 연휴 시작
	'2025-10-06', // 추석
	'2025-10-07', // 추석 연휴 종료
	'2025-10-08', // 추석 대체공휴일
	'2025-10-09', // 한글날
	'2025-12-25', // 성탄절
];

// 해당 월의 전체 근무일수 계산
export const calculateWorkingDays = (year, month, holidays = []) => {
	const lastDay = new Date(year, month + 1, 0).getDate(); // 해당 월의 마지막 날
	let workingDays = 0;

	for (let day = 1; day <= lastDay; day++) {
		const dateObj = new Date(year, month, day);
		const dayOfWeek = dateObj.getDay(); // 0: 일요일, 6: 토요일
		const dateStr = `${year}-${String(month + 1).padStart(2, '0')}-${String(
			day
		).padStart(2, '0')}`;

		// 주말(토요일, 일요일)이 아니고 공휴일이 아닌 경우 근무일로 카운트
		if (dayOfWeek !== 0 && dayOfWeek !== 6 && !holidays.includes(dateStr)) {
			workingDays++;
		}
	}
	return workingDays;
};

export function generateDateOptions(year, month, publicHolidays = []) {
	const opts = [];
	const lastDay = new Date(year, month + 1, 0).getDate();
	const dayOfWeekNames = ['일', '월', '화', '수', '목', '금', '토'];

	for (let day = 1; day <= lastDay; day++) {
		const dateObj = new Date(year, month, day);
		const dayOfWeek = dayOfWeekNames[dateObj.getDay()];
		const dateStr = `${String(day).padStart(2, '0')}`;
		let label = `${dateStr} (${dayOfWeek})`;
		if (publicHolidays.includes(dateStr)) {
			label += ' (공휴일)';
		}
		opts.push({
			value: dateStr,
			label: label,
		});
	}
	return opts;
}

export const koreanHolidays = {
	2021: [
		'2021-01-01', // 신정
		'2021-02-11',
		'2021-02-12',
		'2021-02-13', // 설날 연휴
		'2021-03-01', // 삼일절
		'2021-05-05', // 어린이날
		'2021-05-19', // 석가탄신일
		'2021-06-06', // 현충일
		'2021-08-15',
		'2021-08-16', // 광복절 및 대체공휴일
		'2021-09-20',
		'2021-09-21',
		'2021-09-22', // 추석 연휴
		'2021-10-03', // 개천절
		'2021-10-09', // 한글날
		'2021-12-25', // 성탄절
	],
	2022: [
		'2022-01-01', // 신정
		'2022-01-31',
		'2022-02-01',
		'2022-02-02', // 설날 연휴
		'2022-03-01', // 삼일절
		'2022-05-05', // 어린이날
		'2022-05-08', // 석가탄신일
		'2022-06-06', // 현충일
		'2022-08-15', // 광복절
		'2022-09-09',
		'2022-09-10',
		'2022-09-11',
		'2022-09-12', // 추석 연휴
		'2022-10-03', // 개천절
		'2022-10-09', // 한글날
		'2022-12-25', // 성탄절
	],
	2023: [
		'2023-01-01', // 신정
		'2023-01-21',
		'2023-01-22',
		'2023-01-23', // 설날 연휴
		'2023-03-01', // 삼일절
		'2023-05-05', // 어린이날
		'2023-05-27', // 석가탄신일
		'2023-06-06', // 현충일
		'2023-08-15', // 광복절
		'2023-09-28',
		'2023-09-29',
		'2023-09-30', // 추석 연휴
		'2023-10-03', // 개천절
		'2023-10-09', // 한글날
		'2023-12-25', // 성탄절
	],
	2024: [
		'2024-01-01', // 신정
		'2024-02-09',
		'2024-02-10',
		'2024-02-11',
		'2024-02-12', // 설날 연휴
		'2024-03-01', // 삼일절
		'2024-05-05',
		'2024-05-06', // 어린이날 및 대체공휴일
		'2024-06-06', // 현충일
		'2024-08-15', // 광복절
		'2024-09-16',
		'2024-09-17',
		'2024-09-18', // 추석 연휴
		'2024-10-03', // 개천절
		'2024-10-09', // 한글날
		'2024-12-25', // 성탄절
	],
	2025: [
		'2025-01-01', // 신정
		'2025-01-28',
		'2025-01-29',
		'2025-01-30', // 설날 연휴
		'2025-03-01', // 삼일절
		'2025-05-05', // 어린이날
		'2025-05-15', // 석가탄신일
		'2025-06-06', // 현충일
		'2025-08-15', // 광복절
		'2025-10-06',
		'2025-10-07',
		'2025-10-08', // 추석 연휴
		'2025-10-03', // 개천절
		'2025-10-09', // 한글날
		'2025-12-25', // 성탄절
	],
};

export function validateBusinessRegistrationNumber(input) {
	// 숫자 이외의 문자를 제거
	const num = (input || '').replace(/[^0-9]/g, '');

	// 사업자등록번호는 10자리여야 합니다.
	if (num.length !== 10) return false;

	const multipliers = [1, 3, 7, 1, 3, 7, 1, 3, 5];
	let sum = 0;

	// 첫 9자리 숫자에 대해 가중치를 곱한 값을 합산
	for (let i = 0; i < 9; i++) {
		const digit = parseInt(num.charAt(i), 10);
		let product = digit * multipliers[i];

		// 9번째 자리(인덱스 8)는 곱한 결과가 두 자리일 수 있으므로 각 자릿수를 분리하여 합산
		if (i === 8) {
			sum += Math.floor(product / 10) + (product % 10);
		} else {
			sum += product;
		}
	}

	// 체크 디지트 계산
	const mod = sum % 10;
	const checkDigit = (10 - mod) % 10;

	// 입력값의 마지막 자리와 비교
	return checkDigit === parseInt(num.charAt(9), 10);
}

//사학연금
export const teacherPension = (taxWagesSumNumber, year, privateSchool) => {
	// privateSchool이 '사립학교법 적용'이 아니면 무조건 0 반환
	if (privateSchool !== '사립학교법 적용') {
		return 0;
	}

	const numYear = +year;

	const highWage = (() => {
		if (numYear >= 2024) return 8832000;
		return 8832000;
	})();

	if (taxWagesSumNumber === 0) {
		taxWagesSumNumber = 0;
	} else if (taxWagesSumNumber >= +highWage) {
		taxWagesSumNumber = +highWage;
	}

	const pensionRate = 0.09;

	return (
		Math.floor(
			(Math.floor(taxWagesSumNumber / 1000) * 1000 * pensionRate) / 10
		) * 10
	);
};

export function teacherPensionText(
	teacherPensionBaseWage,
	teacherPensionPayType,
	calTeacherPension
) {
	const pensionRate = '(요율 : 9%)';

	const teacherPensionText = (baseWage) => {
		return teacherPensionPayType ===
			'기준소득월액(사학연금공단에 신고된 금액)' && baseWage
			? `기준소득월액(사학연금공단에 신고된 금액) ${numberToCommaString(
					baseWage
			  )}원에 대한 사학연금${pensionRate}`
			: `당월 과세 임금에 대한 사학연금${pensionRate}`;
	};

	if (calTeacherPension === 0) {
		return '사학연금료 납부의무 없음.';
	} else {
		return teacherPensionText(teacherPensionBaseWage);
	}
}

export function teacherPensionTaxWageSumF(
	teacherPensionPayType,
	taxWageSum,
	teacherPensionBaseWage,
	privateSchool
) {
	// privateSchool이 '사립학교법 적용'이 아니면 무조건 0 반환
	if (privateSchool !== '사립학교법 적용') {
		return 0;
	}

	let teacherPensionTaxWageSum = 0;

	if (teacherPensionPayType === '사학연금료 공제안함') {
		// 이 조건에서는 연금 공제를 하지 않음
		teacherPensionTaxWageSum = 0;
	} else if (teacherPensionPayType === '당월과세금액(매월변동반영)') {
		// 과세 금액을 기준으로 연금 공제
		teacherPensionTaxWageSum = taxWageSum;
	} else if (
		teacherPensionPayType === '기준소득월액(사학연금공단에 신고된 금액)' &&
		teacherPensionBaseWage
	) {
		// 기준 소득 월액을 기준으로 연금 공제
		teacherPensionTaxWageSum = teacherPensionBaseWage;
	} else {
		// 기본적으로 과세 금액을 기준으로 연금 공제
		teacherPensionTaxWageSum = taxWageSum;
	}

	return teacherPensionTaxWageSum;
}

export function sumOfCalTeacherPensionsSumCom(searchResults) {
	return searchResults
		.map((searchResult) =>
			searchResult?.deductTaxAnd4In?.userFixedTeacherPension !== '' &&
			!isNaN(searchResult?.deductTaxAnd4In?.userFixedTeacherPension)
				? searchResult?.deductTaxAnd4In?.userFixedTeacherPension
				: searchResult?.deductTaxAnd4In?.calTeacherPension
		)
		.reduce(
			(prev, curr) =>
				(prev === undefined ? 0 : +prev) + (curr === undefined ? 0 : +curr),
			0
		);
}
