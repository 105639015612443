import React, { useState } from 'react';
import {
	collection,
	query,
	where,
	getDocs,
	writeBatch,
} from 'firebase/firestore';
import { db } from '../../app/config/firebase';

const UpdateEmployeeWage = () => {
	const [companyId, setCompanyId] = useState('');
	const [timeWage, setTimeWage] = useState('');
	const [newTimeWage, setNewTimeWage] = useState('');
	const [updateMode, setUpdateMode] = useState('two'); // 기본값: 2명만 업데이트

	const inputStyle = {
		width: '400px',
		height: '40px',
		fontSize: '16px',
		padding: '10px',
		margin: '10px 0',
	};

	const handleUpdate = async () => {
		if (!companyId || !timeWage || !newTimeWage) {
			alert('모든 입력값을 입력해주세요 (Company ID, 현재 임금, 새로운 임금)');
			return;
		}

		try {
			const workersRef = collection(db, 'workers');
			const q = query(
				workersRef,
				where('hostUid', '==', companyId),
				where('daylyPay.timeWage', '==', parseInt(timeWage))
			);
			const querySnapshot = await getDocs(q);

			if (querySnapshot.empty) {
				alert('조건에 맞는 직원이 없습니다.');
				return;
			}

			const batch = writeBatch(db);
			const updatedWorkers = [];
			let count = 0;

			// 각 문서를 순회하면서 업데이트
			querySnapshot.forEach((doc) => {
				// 2명만 업데이트하는 경우
				if (updateMode === 'two' && count >= 2) return;

				const docRef = doc.ref;
				const workerData = doc.data();
				updatedWorkers.push(workerData.worker.name || doc.id); // 이름 또는 ID 저장

				batch.update(docRef, {
					// 'dayWorker.timeWage': parseInt(newTimeWage),
					'daylyPay.timeWage': newTimeWage,
				});

				count++;
			});

			await batch.commit();
			alert(`임금 업데이트 성공! 변경된 직원: ${updatedWorkers.join(', ')}`);
		} catch (error) {
			console.error('Error updating time wage: ', error);
			alert('임금 업데이트에 실패했습니다.');
		}
	};

	return (
		<div>
			<h2>Update Employee Time Wage</h2>
			<div>
				회사ID:
				<input
					type='text'
					placeholder='Company ID'
					value={companyId}
					onChange={(e) => setCompanyId(e.target.value)}
					style={inputStyle}
				/>
			</div>
			<div>
				현재 시간당 임금:
				<input
					type='text'
					placeholder='Current Time Wage'
					value={timeWage}
					onChange={(e) => setTimeWage(e.target.value)}
					style={inputStyle}
				/>
			</div>
			<div>
				새로운 시간당 임금:
				<input
					type='text'
					placeholder='New Time Wage'
					value={newTimeWage}
					onChange={(e) => setNewTimeWage(e.target.value)}
					style={inputStyle}
				/>
			</div>

			<div>
				업데이트 모드:
				<select
					value={updateMode}
					onChange={(e) => setUpdateMode(e.target.value)}
					style={{ padding: '10px', fontSize: '16px', margin: '10px 0' }}>
					<option value='two'>2명만 업데이트</option>
					<option value='all'>전체 업데이트</option>
				</select>
			</div>

			<button
				onClick={handleUpdate}
				style={{ margin: '20px 0', padding: '10px 20px', fontSize: '16px' }}>
				Update Time Wage
			</button>
		</div>
	);
};

export default UpdateEmployeeWage;
