import React from 'react';
import { Table } from 'semantic-ui-react';
import { numberToCommaString, roundUp } from '../../../app/common/util/util';

export default function PaydocuDeductDetail({
	isBizWorker,
	workerRetiredDate,
	selectedClientReq4In,
	numFinalRetireHealthCareRecalFee,
	numFinalRetireOldCareRecalFee,
	dayWorker,
	selectedPaydocu,

	plusDeductHealthCare,
	plusDeductHealthCareComment,
	plusDeductOldCare,
	plusDeductOldCareComment,
	plusDeductWorkTax,
	plusDeductWorkTaxComment,
	plusDeductWorkRegionTax,
	plusDeductWorkRegionTaxComment,
	plusDeductDuru,
	plusMinus4Ins,
	plusDeductDuruComment,
	plusMinus4InsComment,
	plusDeductWorkerCompanyLoan,
	plusDeductWorkerCompanyLoanComment,

	deductEtcName1,
	deductEtcAWage1,
	deductEtcWage1Comment,
	deductEtcName2,
	deductEtcAWage2,
	deductEtcWage2Comment,
	deductEtcName3,
	deductEtcAWage3,
	deductEtcWage3Comment,

	userFixedTax,
	userFixedRegionTax,
	userFixedHealthCare,
	userFixedOldCare,
	userFixedPension,

	userFixedTeacherPension, // 사학연금
	privateSchool,

	userFixedNoJobInsurance,
	userFixedTaxComment,
	userFixedRegionTaxComment,
	userFixedHealthCareComment,
	userFixedOldCareComment,
	userFixedPensionComment,

	userFixedTeacherPensionComment, // 사학연금

	userFixedNoJobInsuranceComment,

	calTax,
	calRegionTax,
	calPension,

	calTeacherPension, // 사학연금

	calHealthCare,
	calOldCare,
	calNoJobInsurance,
	dedectTaxEtc,
	deductWage,

	noJobText,
	pensionText,

	teacherPensionText, // 사학연금

	oldCareText,
	healthCareText,

	taxPayType,
	pensionPayType,

	teacherPensionPayType, // 사학연금

	healthCarePayType,
	oldCarePayType,

	noJobPayType,
	centerStyle,
	dayWorkerUserFixedTax,
	dayWorkerUserFixedTaxComment,
	dayWorkerCalTax,

	dayWorkerUserFixedRegionTax,
	dayWorkerUserFixedRegionTaxComment,
	dayWorkerCalRegionTax,

	dayWorkerUserFixedHealthCare,
	dayWorkerUserFixedHealthCareComment,
	dayWorkerCalHealthCare,

	dayWorkerUserFixedOldCare,
	dayWorkerUserFixedOldCareComment,
	dayWorkerCalOldCare,

	dayWorkerUserFixedPension,
	dayWorkerUserFixedPensionComment,
	dayWorkerCalPension,

	dayWorkerUserFixedNoJobInsurance,
	dayWorkerUserFixedNoJobInsuranceComment,
	dayWorkerCalNoJobInsurance,

	dayWorkerTaxTextOption,
	dayWorkerRegionTaxTextOption,

	dayWorkerDeductEtcWage1,
	dayWorkerDeductEtcName1,
	dayWorkerDeductEtcWage1Comment,

	dayWorkerDeductEtcWage2,
	dayWorkerDeductEtcName2,
	dayWorkerDeductEtcWage2Comment,

	dayWorkerDeductEtcWage3,
	dayWorkerDeductEtcName3,
	dayWorkerDeductEtcWage3Comment,

	dayWorkerDedectTaxEtc,
	dayWorkerDeductWage,

	dayWorkerPlusDeductHealthCare,
	dayWorkerPlusDeductHealthCareComment,
	dayWorkerPlusDeductOldCare,
	dayWorkerPlusDeductOldCareComment,
	dayWorkerPlusDeductWorkTax,
	dayWorkerPlusDeductWorkTaxComment,
	dayWorkerPlusDeductWorkRegionTax,
	dayWorkerPlusDeductWorkRegionTaxComment,
	dayWorkerPlusDeductDuru,
	dayWorkerPlusMinus4Ins,
	dayWorkerPlusDeductDuruComment,
	dayWorkerPlusMinus4InsComment,
	dayWorkerPlusDeductWorkerCompanyLoan,
	dayWorkerPlusDeductWorkerCompanyLoanComment,

	dayWorkerHealthCareTextOption,
	dayWorkerNoJobTextOption,
	dayWorkerPensionTextOption,
	dayWorkerOldCareTextOption,
	bizDeductionAfterTax,
	bizDeductionAfterTaxComment,
	bizIncomeTax,
	bizIncomeRegionTax,
	bizDeductWage,
	bizDeductedTotal,
}) {
	const weekLBTime = selectedPaydocu?.lawBase?.weekLBTime;
	const gumakStyle = { textAlign: 'right' };
	const under8daysPerMonth =
		selectedClientReq4In?.realWorkTime?.oneWeekworkdaysCheck === 1 ||
		selectedClientReq4In?.realWorkTime?.oneWeekworkdaysCheckETLWorker === 1
			? true
			: false;
	const taxComment = workerRetiredDate
		? '퇴직월 공제안함.'
		: `당월 과세 임금에 대한 소득세법상 근로소득세 ${
				taxPayType ? taxPayType : '100%'
		  }`;
	const regionTaxComment = workerRetiredDate
		? '퇴직월 공제안함.'
		: `당월 과세 임금에 대한 소득세법상 지방소득세 ${
				taxPayType ? taxPayType : '100%'
		  }`;

	return (
		<>
			{!dayWorker ? (
				<Table.Row>
					<Table.Cell className='gubun' style={centerStyle}>
						공제
					</Table.Cell>
					<Table.Cell className='hangmok' style={centerStyle}>
						{isBizWorker ? '사업소득세' : '소득세'}
					</Table.Cell>
					{userFixedTax !== '' && !isNaN(userFixedTax) ? (
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(userFixedTax)}
						</Table.Cell>
					) : (
						<Table.Cell className='gumak' style={gumakStyle}>
							{isBizWorker
								? numberToCommaString(bizIncomeTax)
								: numberToCommaString(calTax)}
						</Table.Cell>
					)}
					{userFixedTaxComment ? (
						<Table.Cell colSpan='6' className='sulmung'>
							{userFixedTaxComment}
						</Table.Cell>
					) : (
						<Table.Cell colSpan='6' className='sulmung'>
							{isBizWorker ? '사업소득에 대한 3% 사업소득세' : taxComment}
						</Table.Cell>
					)}
				</Table.Row>
			) : (
				<Table.Row>
					<Table.Cell className='gubun' style={centerStyle}>
						공제
					</Table.Cell>
					<Table.Cell className='hangmok' style={centerStyle}>
						소득세
					</Table.Cell>
					{dayWorkerUserFixedTax !== '' && !isNaN(dayWorkerUserFixedTax) ? (
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(dayWorkerUserFixedTax)}
						</Table.Cell>
					) : (
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(dayWorkerCalTax)}
						</Table.Cell>
					)}
					{dayWorkerUserFixedTaxComment ? (
						<Table.Cell colSpan='6' className='sulmung'>
							{dayWorkerUserFixedTaxComment}
						</Table.Cell>
					) : (
						<Table.Cell colSpan='6' className='sulmung'>
							{dayWorkerTaxTextOption}
						</Table.Cell>
					)}
				</Table.Row>
			)}
			{!dayWorker ? (
				<Table.Row>
					<Table.Cell className='gubun' style={centerStyle}>
						공제
					</Table.Cell>
					<Table.Cell className='hangmok' style={centerStyle}>
						{isBizWorker ? '(사업)지방소득세' : '지방소득세'}
					</Table.Cell>
					{userFixedRegionTax !== '' && !isNaN(userFixedRegionTax) ? (
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(userFixedRegionTax)}
						</Table.Cell>
					) : (
						<Table.Cell className='gumak' style={gumakStyle}>
							{isBizWorker
								? numberToCommaString(bizIncomeRegionTax)
								: numberToCommaString(calRegionTax)}
						</Table.Cell>
					)}
					{userFixedRegionTaxComment ? (
						<Table.Cell colSpan='6' className='sulmung'>
							{userFixedRegionTaxComment}
						</Table.Cell>
					) : (
						<Table.Cell colSpan='6' className='sulmung'>
							{isBizWorker
								? '사업소득에 대한 0.3% 지방소득세'
								: regionTaxComment}
						</Table.Cell>
					)}
				</Table.Row>
			) : (
				<Table.Row>
					<Table.Cell className='gubun' style={centerStyle}>
						공제
					</Table.Cell>
					<Table.Cell className='hangmok' style={centerStyle}>
						지방소득세
					</Table.Cell>
					{dayWorkerUserFixedRegionTax !== '' &&
					!isNaN(dayWorkerUserFixedRegionTax) ? (
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(dayWorkerUserFixedRegionTax)}
						</Table.Cell>
					) : (
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(dayWorkerCalRegionTax)}
						</Table.Cell>
					)}
					{dayWorkerUserFixedRegionTaxComment ? (
						<Table.Cell colSpan='6' className='sulmung'>
							{dayWorkerUserFixedRegionTaxComment}
						</Table.Cell>
					) : (
						<Table.Cell colSpan='6' className='sulmung'>
							{dayWorkerRegionTaxTextOption}
						</Table.Cell>
					)}
				</Table.Row>
			)}
			{isBizWorker ? (
				<>
					{bizDeductionAfterTax && (
						<Table.Row>
							<Table.Cell className='gubun' style={centerStyle}>
								공제
							</Table.Cell>
							<Table.Cell className='hangmok' style={centerStyle}>
								{bizDeductionAfterTaxComment}
							</Table.Cell>
							<Table.Cell className='gumak' style={gumakStyle}>
								{numberToCommaString(bizDeductionAfterTax)}
							</Table.Cell>
							<Table.Cell colSpan='6' className='sulmung'>
								{bizDeductionAfterTaxComment}
							</Table.Cell>
						</Table.Row>
					)}
					<Table.Row>
						<Table.Cell className='gubun' style={centerStyle}>
							공제합계
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							공제합계
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+bizDeductedTotal)}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							사업소득세, 지방소득세, 세후 공제금 등 합계
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell className='gubun' style={centerStyle}>
							차인지급액
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							실수령액
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(bizDeductWage)}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							사업소득에서 공제금을 제외한 실수령액
						</Table.Cell>
					</Table.Row>
				</>
			) : (
				<>
					{weekLBTime < 13.8 ? null : (
						<>
							{healthCarePayType === '건강보험료 공제안함' ? null : (
								<>
									{!dayWorker ? (
										<Table.Row>
											<Table.Cell className='gubun' style={centerStyle}>
												공제
											</Table.Cell>
											<Table.Cell className='hangmok' style={centerStyle}>
												건강보험료
											</Table.Cell>
											{userFixedHealthCare !== '' &&
											!isNaN(userFixedHealthCare) ? (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(userFixedHealthCare)}
												</Table.Cell>
											) : (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(calHealthCare)}
												</Table.Cell>
											)}
											{userFixedHealthCareComment > 0 ? (
												<Table.Cell colSpan='6' className='sulmung'>
													{userFixedHealthCareComment}
												</Table.Cell>
											) : (
												<Table.Cell colSpan='6' className='sulmung'>
													{healthCareText}
												</Table.Cell>
											)}
										</Table.Row>
									) : (
										<Table.Row>
											<Table.Cell className='gubun' style={centerStyle}>
												공제
											</Table.Cell>
											<Table.Cell className='hangmok' style={centerStyle}>
												건강보험료
											</Table.Cell>
											{dayWorkerUserFixedHealthCare !== '' &&
											!isNaN(dayWorkerUserFixedHealthCare) ? (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(dayWorkerUserFixedHealthCare)}
												</Table.Cell>
											) : (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(dayWorkerCalHealthCare)}
												</Table.Cell>
											)}
											{dayWorkerUserFixedHealthCareComment > 0 ? (
												<Table.Cell colSpan='6' className='sulmung'>
													{dayWorkerUserFixedHealthCareComment}
												</Table.Cell>
											) : (
												<Table.Cell colSpan='6' className='sulmung'>
													{dayWorkerHealthCareTextOption}
												</Table.Cell>
											)}
										</Table.Row>
									)}
								</>
							)}
							{oldCarePayType === '요양보험료 공제안함' ? null : (
								<>
									{!dayWorker ? (
										<Table.Row>
											<Table.Cell className='gubun' style={centerStyle}>
												공제
											</Table.Cell>
											<Table.Cell className='hangmok' style={centerStyle}>
												요양보험료
											</Table.Cell>
											{userFixedOldCare !== '' && !isNaN(userFixedOldCare) ? (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(userFixedOldCare)}
												</Table.Cell>
											) : (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(calOldCare)}
												</Table.Cell>
											)}
											{userFixedOldCareComment ? (
												<Table.Cell colSpan='6' className='sulmung'>
													{userFixedOldCareComment}
												</Table.Cell>
											) : (
												<Table.Cell colSpan='6' className='sulmung'>
													{oldCareText}
												</Table.Cell>
											)}
										</Table.Row>
									) : (
										<Table.Row>
											<Table.Cell className='gubun' style={centerStyle}>
												공제
											</Table.Cell>
											<Table.Cell className='hangmok' style={centerStyle}>
												요양보험료
											</Table.Cell>
											{dayWorkerUserFixedOldCare !== '' &&
											!isNaN(dayWorkerUserFixedOldCare) ? (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(dayWorkerUserFixedOldCare)}
												</Table.Cell>
											) : (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(dayWorkerCalOldCare)}
												</Table.Cell>
											)}
											{dayWorkerUserFixedOldCareComment ? (
												<Table.Cell colSpan='6' className='sulmung'>
													{dayWorkerUserFixedOldCareComment}
												</Table.Cell>
											) : (
												<Table.Cell colSpan='6' className='sulmung'>
													{dayWorkerOldCareTextOption}
												</Table.Cell>
											)}
										</Table.Row>
									)}
								</>
							)}
						</>
					)}
					{weekLBTime < 13.8 && under8daysPerMonth ? null : (
						<>
							{pensionPayType === '국민연금료 공제안함' ? null : (
								<>
									{!dayWorker ? (
										<Table.Row>
											<Table.Cell className='gubun' style={centerStyle}>
												공제
											</Table.Cell>
											<Table.Cell className='hangmok' style={centerStyle}>
												국민연금
											</Table.Cell>
											{userFixedPension !== '' && !isNaN(userFixedPension) ? (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(userFixedPension)}
												</Table.Cell>
											) : (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(calPension)}
												</Table.Cell>
											)}
											{userFixedPensionComment ? (
												<Table.Cell colSpan='6' className='sulmung'>
													{userFixedPensionComment}
												</Table.Cell>
											) : (
												<Table.Cell colSpan='6' className='sulmung'>
													{pensionText}
												</Table.Cell>
											)}
										</Table.Row>
									) : (
										<Table.Row>
											<Table.Cell className='gubun' style={centerStyle}>
												공제
											</Table.Cell>
											<Table.Cell className='hangmok' style={centerStyle}>
												국민연금
											</Table.Cell>
											{dayWorkerUserFixedPension !== '' &&
											!isNaN(dayWorkerUserFixedPension) ? (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(dayWorkerUserFixedPension)}
												</Table.Cell>
											) : (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(dayWorkerCalPension)}
												</Table.Cell>
											)}
											{dayWorkerUserFixedPensionComment ? (
												<Table.Cell colSpan='6' className='sulmung'>
													{dayWorkerUserFixedPensionComment}
												</Table.Cell>
											) : (
												<Table.Cell colSpan='6' className='sulmung'>
													{dayWorkerPensionTextOption}
												</Table.Cell>
											)}
										</Table.Row>
									)}
								</>
							)}
						</>
					)}

					{privateSchool !== '사립학교법 적용' ? null : (
						<>
							{teacherPensionPayType === '사학연금료 공제안함' ? null : (
								<>
									{!dayWorker ? (
										<Table.Row>
											<Table.Cell className='gubun' style={centerStyle}>
												공제
											</Table.Cell>
											<Table.Cell className='hangmok' style={centerStyle}>
												사학연금
											</Table.Cell>
											{userFixedTeacherPension !== '' &&
											!isNaN(userFixedTeacherPension) ? (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(userFixedTeacherPension)}
												</Table.Cell>
											) : (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(calTeacherPension)}
												</Table.Cell>
											)}
											{userFixedTeacherPensionComment ? (
												<Table.Cell colSpan='6' className='sulmung'>
													{userFixedTeacherPensionComment}
												</Table.Cell>
											) : (
												<Table.Cell colSpan='6' className='sulmung'>
													{teacherPensionText}
												</Table.Cell>
											)}
										</Table.Row>
									) : (
										<Table.Row>
											<Table.Cell className='gubun' style={centerStyle}>
												공제
											</Table.Cell>
											<Table.Cell className='hangmok' style={centerStyle}>
												국민연금
											</Table.Cell>
											{dayWorkerUserFixedPension !== '' &&
											!isNaN(dayWorkerUserFixedPension) ? (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(dayWorkerUserFixedPension)}
												</Table.Cell>
											) : (
												<Table.Cell className='gumak' style={gumakStyle}>
													{numberToCommaString(dayWorkerCalPension)}
												</Table.Cell>
											)}
											{dayWorkerUserFixedPensionComment ? (
												<Table.Cell colSpan='6' className='sulmung'>
													{dayWorkerUserFixedPensionComment}
												</Table.Cell>
											) : (
												<Table.Cell colSpan='6' className='sulmung'>
													{dayWorkerPensionTextOption}
												</Table.Cell>
											)}
										</Table.Row>
									)}
								</>
							)}
						</>
					)}
					{noJobPayType === '고용보험료 공제안함' ? null : (
						<>
							{!dayWorker ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										고용보험료
									</Table.Cell>
									{userFixedNoJobInsurance !== '' &&
									!isNaN(userFixedPension) ? (
										<Table.Cell className='gumak' style={gumakStyle}>
											{numberToCommaString(userFixedNoJobInsurance)}
										</Table.Cell>
									) : (
										<Table.Cell className='gumak' style={gumakStyle}>
											{numberToCommaString(calNoJobInsurance)}
										</Table.Cell>
									)}
									{userFixedNoJobInsuranceComment ? (
										<Table.Cell colSpan='6' className='sulmung'>
											{userFixedNoJobInsuranceComment}
										</Table.Cell>
									) : (
										<Table.Cell colSpan='6' className='sulmung'>
											{noJobText}
										</Table.Cell>
									)}
								</Table.Row>
							) : (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										고용보험료
									</Table.Cell>
									{dayWorkerUserFixedNoJobInsurance !== '' &&
									!isNaN(userFixedPension) ? (
										<Table.Cell className='gumak' style={gumakStyle}>
											{numberToCommaString(dayWorkerUserFixedNoJobInsurance)}
										</Table.Cell>
									) : (
										<Table.Cell className='gumak' style={gumakStyle}>
											{numberToCommaString(dayWorkerCalNoJobInsurance)}
										</Table.Cell>
									)}
									{dayWorkerUserFixedNoJobInsuranceComment ? (
										<Table.Cell colSpan='6' className='sulmung'>
											{dayWorkerUserFixedNoJobInsuranceComment}
										</Table.Cell>
									) : (
										<Table.Cell colSpan='6' className='sulmung'>
											{dayWorkerNoJobTextOption}
										</Table.Cell>
									)}
								</Table.Row>
							)}
						</>
					)}
					{!dayWorker ? (
						<>
							{plusDeductHealthCare ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										건강보험료 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(plusDeductHealthCare)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{plusDeductHealthCareComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{plusDeductOldCare ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										요양보험료 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(plusDeductOldCare)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{plusDeductOldCareComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{plusDeductWorkTax ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										근로소득세 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(plusDeductWorkTax)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{plusDeductWorkTaxComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{plusDeductWorkRegionTax ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										지방소득세 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(plusDeductWorkRegionTax)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{plusDeductWorkRegionTaxComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{plusDeductWorkerCompanyLoan ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										대여금 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(plusDeductWorkerCompanyLoan)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{plusDeductWorkerCompanyLoanComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{plusDeductDuru ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										두루누리 지원금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(plusDeductDuru)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										4대보험 두루누리 지원금
									</Table.Cell>
								</Table.Row>
							) : null}
							{plusMinus4Ins ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										공제 조정
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(plusMinus4Ins)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{plusMinus4InsComment}
									</Table.Cell>
								</Table.Row>
							) : null}

							{numFinalRetireHealthCareRecalFee ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										건강보험 퇴직 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(numFinalRetireHealthCareRecalFee)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										건강보험 퇴직 정산금
									</Table.Cell>
								</Table.Row>
							) : null}

							{numFinalRetireOldCareRecalFee ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										요양보험 퇴직 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(numFinalRetireOldCareRecalFee)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										요양보험 퇴직 정산금
									</Table.Cell>
								</Table.Row>
							) : null}

							{deductEtcAWage1 ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='gumak' style={centerStyle}>
										{deductEtcName1}
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(deductEtcAWage1)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{deductEtcWage1Comment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{deductEtcAWage2 ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='gumak' style={centerStyle}>
										{deductEtcName2}
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(deductEtcAWage2)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{deductEtcWage2Comment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{deductEtcAWage3 ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='gumak' style={centerStyle}>
										{deductEtcName3}
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(deductEtcAWage3)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{deductEtcWage3Comment}
									</Table.Cell>
								</Table.Row>
							) : null}
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									공제
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									공제 합계
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(dedectTaxEtc)}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									세금 및 4대보험 기타 공제 합계
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									차인지급액
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									실수령액
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(deductWage))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									실수령액
								</Table.Cell>
							</Table.Row>
						</>
					) : (
						<>
							{dayWorkerPlusDeductHealthCare ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										건강보험료 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(dayWorkerPlusDeductHealthCare)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{dayWorkerPlusDeductHealthCareComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{dayWorkerPlusDeductOldCare ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										요양보험료 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(dayWorkerPlusDeductOldCare)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{dayWorkerPlusDeductOldCareComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{dayWorkerPlusDeductWorkTax ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										근로소득세 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(dayWorkerPlusDeductWorkTax)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{dayWorkerPlusDeductWorkTaxComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{dayWorkerPlusDeductWorkRegionTax ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										지방소득세 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(dayWorkerPlusDeductWorkRegionTax)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{dayWorkerPlusDeductWorkRegionTaxComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{dayWorkerPlusDeductWorkerCompanyLoan ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										대여금 정산금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(dayWorkerPlusDeductWorkerCompanyLoan)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{dayWorkerPlusDeductWorkerCompanyLoanComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{dayWorkerPlusDeductDuru ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										두루누리 지원금
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(dayWorkerPlusDeductDuru)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										4대보험 두루누리 지원금
									</Table.Cell>
								</Table.Row>
							) : null}
							{dayWorkerPlusMinus4Ins ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										공제 조정
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(dayWorkerPlusMinus4Ins)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{dayWorkerPlusMinus4InsComment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{dayWorkerDeductEtcWage1 ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										{dayWorkerDeductEtcName1}
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(dayWorkerDeductEtcWage1)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{dayWorkerDeductEtcWage1Comment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{dayWorkerDeductEtcWage2 ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										{dayWorkerDeductEtcName2}
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(dayWorkerDeductEtcWage2)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{dayWorkerDeductEtcWage2Comment}
									</Table.Cell>
								</Table.Row>
							) : null}
							{dayWorkerDeductEtcWage3 ? (
								<Table.Row>
									<Table.Cell className='gubun' style={centerStyle}>
										공제
									</Table.Cell>
									<Table.Cell className='hangmok' style={centerStyle}>
										{dayWorkerDeductEtcName3}
									</Table.Cell>
									<Table.Cell className='gumak' style={gumakStyle}>
										{numberToCommaString(dayWorkerDeductEtcWage3)}
									</Table.Cell>
									<Table.Cell colSpan='6' className='sulmung'>
										{dayWorkerDeductEtcWage3Comment}
									</Table.Cell>
								</Table.Row>
							) : null}
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									공제
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									공제 합계
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(dayWorkerDedectTaxEtc)}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									세금 및 4대보험 기타 공제 합계
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell className='gubun' style={centerStyle}>
									차인지급액
								</Table.Cell>
								<Table.Cell className='hangmok' style={centerStyle}>
									실수령액
								</Table.Cell>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(roundUp(dayWorkerDeductWage))}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									실수령액
								</Table.Cell>
							</Table.Row>
						</>
					)}
				</>
			)}
		</>
	);
}
