import { format } from 'date-fns';
const thisYear = format(new Date(), 'yyyy');

export const year = [
	{ key: '', text: ' ', value: '' },
	{ key: +thisYear - 4, text: `${+thisYear - 4}`, value: +thisYear - 4 },
	{ key: +thisYear - 3, text: `${+thisYear - 3}`, value: +thisYear - 3 },
	{ key: +thisYear - 2, text: `${+thisYear - 2}`, value: +thisYear - 2 },
	{ key: +thisYear - 1, text: `${+thisYear - 1}`, value: +thisYear - 1 },
	{ key: thisYear, text: `${thisYear}`, value: thisYear },
];

export const copyYear = [
	{ key: '', text: ' ', value: '' },
	{ key: +thisYear - 1, text: `${+thisYear - 1}`, value: +thisYear - 1 },
	{ key: thisYear, text: `${thisYear}`, value: thisYear },
	{ key: +thisYear + 1, text: `${+thisYear + 1}`, value: +thisYear + 1 },
];

export const month = [
	{ key: '', text: ' ', value: '' },
	{ key: '01', text: '01', value: '01' },
	{ key: '02', text: '02', value: '02' },
	{ key: '03', text: '03', value: '03' },
	{ key: '04', text: '04', value: '04' },
	{ key: '05', text: '05', value: '05' },
	{ key: '06', text: '06', value: '06' },
	{ key: '07', text: '07', value: '07' },
	{ key: '08', text: '08', value: '08' },
	{ key: '09', text: '09', value: '09' },
	{ key: '10', text: '10', value: '10' },
	{ key: '11', text: '11', value: '11' },
	{ key: '12', text: '12', value: '12' },
];

export const customerType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'firmWork', text: '1. INUP 내부', value: 'INUP 내부' },
	{ key: 'clientCivil', text: '2. 민간자문사', value: '민간자문사' },
	{ key: 'clientGov', text: '3. 공공자문사', value: '공공자문사' },
	{ key: 'outsourcing', text: '4. 아웃소싱사', value: '아웃소싱사' },
	{
		key: 'companyCasePaid',
		text: '5. 사용자상담/사건(유료)',
		value: '사용자상담/사건(유료)',
	},
	{
		key: 'companyCase',
		text: '6. 사용자상담(무료)',
		value: '사업자상담(무료)',
	},
	{
		key: 'workerCasePaid',
		text: '7. 근로자상담/사건(유료)',
		value: '근로자상담/사건(유료)',
	},
	{ key: 'workerCase', text: '8. 근로자상담(무료)', value: '근로자상담(무료)' },
	{ key: 'etcClient', text: '9. 기타고객', value: '기타고객' },
];

export const communicationType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'inHouse', text: '1. INUP 내부업무', value: 'INUP 내부업무' },
	{ key: 'regularVisit', text: '2. 자문사 정기방문', value: '자문사 정기방문' },
	{ key: 'callVisit', text: '3. 자문사 요청방문', value: '자문사 요청방문' },
	{
		key: 'salesVisit',
		text: '4. 일반 영업목적 방문',
		value: '일반 영업목적 방문',
	},
	{ key: 'pesonalPhone', text: '5. 개인휴대폰', value: '개인 휴대폰' },
	{ key: 'phone', text: '6. 회사유선', value: '회사유선' },
	{
		key: 'sns',
		text: '7. SNS(문자, 카톡, 메신저 등)',
		value: 'SNS(문자, 카톡, 메신저 등)',
	},
	{ key: 'email', text: '8. 이메일', value: '이메일' },
	{ key: 'office', text: '9. 내방', value: '내방' },
	{ key: 'cam', text: '10. 화상', value: '화상' },
	{ key: 'inupSystem', text: '11. INUP 시스템', value: 'INUPSystem' },
];

export const daySpecificType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'workingDay', text: '1. 근무일', value: '근무일' },
	{ key: 'absentDay', text: '2. 결근일', value: '결근일' },
	{ key: 'paidWeekHoliday', text: '3. 주휴일', value: '주휴일' },
	{ key: 'paidgongHoliday', text: '4. 공휴일', value: '공휴일' },
	{ key: 'paid70ComOffDay', text: '5. 회사휴업일', value: '회사휴업일' },
	{ key: 'unPaidWorkerOffDay', text: '6. 본인휴직일', value: '본인휴직일' },
	{ key: 'paidAnnualleaveDay', text: '7. 연차휴가일', value: '연차휴가일' },
	{ key: 'unPaidLadyOffDay', text: '8. 생리무급휴가', value: '생리무급휴가' },
	{ key: 'unPaidLadyOffDay', text: '9. 공민권행사일', value: '공민권행사일' },
	{ key: 'paidWorkSickOffDay', text: '10. 산재휴업일', value: '산재휴업일' },
];

export const answerType = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'pesonalPhoneAnswer',
		text: '1. 개인휴대폰답변',
		value: '개인휴대폰답변',
	},
	{ key: 'phoneAnswer', text: '2. 회사유선답변', value: '회사유선답변' },
	{
		key: 'snsAnswer',
		text: '3. SNS(문자, 카톡, 메신저 등)답변',
		value: 'SNS(문자, 카톡, 메신저 등)답변',
	},
	{ key: 'emailAnswer', text: '4. 이메일답변', value: '이메일답변' },
	{ key: 'visitAnswer', text: '5. 출장답변', value: '출장답변' },
	{ key: 'officeAnswer', text: '6. 내방답변', value: '내방답변' },
	{ key: 'paperAnswer', text: '7. 서면답변', value: '서면답변' },
	{ key: 'camAnswer', text: '8. 화상답변', value: '화상답변' },
	{
		key: 'inupSystemAnswer',
		text: '9. INUP 시스템 답변',
		value: 'INUP 시스템 답변',
	},
];

export const workType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'companyWork', text: '1. 업무', value: '업무' },
	{ key: 'caseWork', text: '2. 사건', value: '사건' },
	{ key: 'counseilingWork', text: '3. 상담', value: '상담' },
];

export const ownType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'corporation', text: '법인', value: '법인' },
	{ key: 'indivisual', text: '개인', value: '개인' },
];

export const personalEvaluationUseType = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'use',
		text: 'BSC 기반 인사고과 기능 사용',
		value: 'BSC 기반 인사고과 기능 사용',
	},
	{
		key: 'noUse',
		text: 'BSC 기반 인사고과 기능 사용안함',
		value: 'BSC 기반 인사고과 기능 사용안함',
	},
];

export const retirementPayType = [
	{ key: '', text: ' ', value: '' },
	{ key: '법정퇴직금', text: '법정퇴직금', value: '법정퇴직금' },
	{ key: 'DC형 퇴직연금', text: 'DC형 퇴직연금', value: 'DC형 퇴직연금' },
	{ key: 'DB형 퇴직연금', text: 'DB형 퇴직연금', value: 'DB형 퇴직연금' },
];

export const roeConsultingType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '취업규칙 컨설팅 요청',
		text: '취업규칙 컨설팅 요청',
		value: '취업규칙 컨설팅 요청',
	},
	{ key: '노동법률 자문사', text: '노동법률 자문사', value: '노동법률 자문사' },
	{
		key: '취업규칙 컨설팅 요청 안함',
		text: '취업규칙 컨설팅 요청 안함',
		value: '취업규칙 컨설팅 요청 안함',
	},
];

export const ruleLaborCouncilConsultingType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '노사협의회 컨설팅 요청',
		text: '노사협의회 컨설팅 요청',
		value: '노사협의회 컨설팅 요청',
	},
	{ key: '노동법률 자문사', text: '노동법률 자문사', value: '노동법률 자문사' },
	{
		key: '노사협의회 컨설팅 요청 안함',
		text: '노사협의회 컨설팅 요청 안함',
		value: '노사협의회 컨설팅 요청 안함',
	},
];

export const rOEBizSecretConsultingType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '영업비밀 컨설팅 요청',
		text: '영업비밀 컨설팅 요청',
		value: '영업비밀 컨설팅 요청',
	},
	{
		key: '영업비밀 노동법률 자문사',
		text: '영업비밀 노동법률 자문사',
		value: '영업비밀 노동법률 자문사',
	},
	{
		key: '영업비밀 컨설팅 요청 안함',
		text: '영업비밀 컨설팅 요청 안함',
		value: '영업비밀 컨설팅 요청 안함',
	},
];

export const personalEvaluationUseViewType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '직원 임금명세서에 역량모델링 내용 표시',
		text: '직원 임금명세서에 역량모델링 내용 표시',
		value: '직원 임금명세서에 역량모델링 내용 표시',
	},
	{
		key: '직원 임금명세서에 표시하지 않고 내부 의시결정에 활용',
		text: '직원 임금명세서에 표시하지 않고 내부 의시결정에 활용',
		value: '직원 임금명세서에 표시하지 않고 내부 의시결정에 활용',
	},
];

export const priceType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'basic', text: 'Basic', value: 'Basic' },
	{ key: 'premium', text: 'Premium', value: 'Premium' },
	{ key: 'customized', text: 'Customized', value: 'Customized' },
];

export const counselingCategory = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'serviceCall',
		text: '0. 자문, 사건 등 계약업무',
		value: '자문, 사건 등 계약업무',
	},
	{ key: 'firmWork', text: '1. INUP 행정업무', value: 'INUP 행정업무' },
	{ key: 'firmWorkSales', text: '2. INUP 영업업무', value: 'INUP 영업업무' },
	{ key: 'firmWorkPlanning', text: '3. INUP 기획업무', value: 'INUP 기획업무' },
	{ key: 'firmWorkProgramming', text: '4. INUP IT업무', value: 'INUP IT업무' },
	{
		key: 'paperWork',
		text: '5. 임금, 근로계약, 취업규칙 등 설계업무',
		value: '임금, 근로계약, 취업규칙 등 설계업무',
	},
	{
		key: 'payrollWork',
		text: '6. 아웃소싱사 급여작업',
		value: '아웃소싱사 급여작업',
	},
	{
		key: 'govWork',
		text: '7. 아웃소싱사 공단 등 신고업무',
		value: '아웃소싱사 공단 등 신고업무',
	},
	{
		key: 'infoWork',
		text: '8. 거래처(자문사,아웃소싱사 등) 안내업무',
		value: '거래처(자문사,아웃소싱사 등) 안내업무',
	},
	{ key: 'hierContract', text: '9. 채용분야', value: '채용분야' },
	{ key: 'laborContract', text: '10. 근로계약', value: '근로분야' },
	{ key: 'wage', text: '11. 임금 및 퇴직금', value: '임금 및 퇴직금' },
	{
		key: 'workingTime',
		text: '12. 근로시간 및 휴게',
		value: '근로시간 및 휴게',
	},
	{ key: 'leave', text: '13. 휴가 및 휴일, 휴직', value: '휴가 및 휴일, 휴직' },
	{
		key: 'dismiss',
		text: '14. 퇴직, 징계 및 해고',
		value: '퇴직, 징계 및 해고',
	},
	{
		key: 'hrSystem',
		text: '15. 인사노무관리(승진,보상,평가 등)',
		value: '인사노무관리(승진,보상,평가 등)',
	},
	{ key: 'samje', text: '16. 산업재해', value: '산업재해' },
	{ key: '4insure', text: '17. 4대보험', value: '4대보험' },
	{
		key: 'union',
		text: '18. 노동조합 및 단체교섭, 쟁의행위 등',
		value: '노동조합 및 단체교섭, 쟁의행위 등',
	},
	{ key: 'supportFund', text: '19. 고용 등 정부지원금', value: '정부지원금' },
	{
		key: 'education',
		text: '20. 교육, 강의(노동관계법규 등)',
		value: '교육, 강의(노동관계법규 등)',
	},
	{ key: 'unemploymentBenefit', text: '21. 실업급여', value: '실업급여' },
	{
		key: 'etcLaborLaw',
		text: '22. 기타 노동관계법률',
		value: '기타 노동관계법률',
	},
	{ key: 'etc', text: '23. 기타 일반상담', value: '기타 일반상담' },
];

export const counselingBasis = [
	{ key: '', text: ' ', value: '' },
	{ key: 'law', text: '1. 법률', value: '법문' },
	{ key: 'lawCase', text: '2. 판례', value: '판례' },
	{ key: 'administrative', text: '3. 행정해석', value: '행정해석' },
	{ key: 'gosi', text: '4. 고시/지침', value: '고시지침' },
	{ key: 'theory', text: '5. 학설', value: '학설' },
	{
		key: 'governmentManOpinion',
		text: '6. 담당기관 담당자 의견',
		value: '담당기관 담당자 의견',
	},
	{ key: 'ROE', text: '7. 내부 취업규칙 등 운영규정', value: '취업규칙등' },
	{
		key: 'experience',
		text: '8. 인사노무 운영경험',
		value: '인사노무운영경험',
	},
	{ key: 'commonSence', text: '9. 사회통념', value: '사회통념' },
	{ key: 'opinion', text: '10. 기타 본인의견', value: '기타본인의견' },
];

// 바우처 사업
export const untactCustomerType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'client', text: '자문사', value: '자문사' },
	{ key: 'outsourcing', text: '아웃소싱사', value: '아웃소싱사' },
	{ key: 'newCompanyUniver', text: '순천향대업체영업', value: '순천향대' },
	{ key: 'newCompanyTaxCon', text: '세무사영업', value: '세무사' },
	{ key: 'newCompanyHospital', text: '병원영업', value: '병원' },
	{ key: 'newCompanyIntro', text: '소개영업', value: '소개' },
	{ key: 'newCompany', text: '기타신규영업', value: '기타신규' },
];

export const salesType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'regularVisit', text: '거래처 정기 방문', value: '정기방문' },
	{ key: 'callVisit', text: '거래처 요청 방문', value: '요청방문' },
	{ key: 'salesVisit', text: '영업 목적 방문', value: '영업방문' },
	{ key: 'phone', text: '유선 상담', value: '유선상담' },
	{ key: 'online', text: '온라인 상담', value: '온라인상담' },
];

export const salesFailType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'succeeding', text: '영업성공', value: '영업성공' },
	{ key: 'unpaidTax', text: '세금미납', value: '세금미납' },
	{ key: 'outOfRangeMoney', text: '매출액 초과', value: '매출액초과' },
	{ key: 'foreignFirm', text: '와국계기업', value: '외국계기업' },
	{ key: 'noNeedManual', text: '매뉴얼 불필요', value: '매뉴얼불필요' },
	{
		key: 'complicationOfProcesure',
		text: '복잡한 신청 절차로 포기',
		value: '절차포기',
	},
	{
		key: 'complicationOfZeroPay',
		text: '복잡한 제로페이로 포기',
		value: '제로페이포기',
	},
	{ key: 'lackOfUnderstanding', text: '이해 부족', value: '이해 부족' },
	{ key: 'lackOfInterest', text: '관심 부족', value: '관심부족' },
	{ key: 'etc', text: '기타', value: '기타' },
];

export const inupApplyOkNo = [
	{ key: '', text: ' ', value: '' },
	{ key: 'inupApplyOk', text: '인업서비스신청', value: '인업서비스신청' },
	{ key: 'inupApplyNo', text: '인업신청안함', value: '인업신청안함' },
];

export const over52Permission = [
	{ key: '', text: ' ', value: '' },
	{ key: 'permissionOk', text: '52특례사업장', value: '52특례사업장' },
	{ key: 'normal', text: '일반사업장', value: '일반사업장' },
];

export const vauchorApplyOkNo = [
	{ key: '', text: ' ', value: '' },
	{ key: 'vauchorApplyOk', text: '바우처신청완료', value: '바우처신청완료' },
	{ key: 'vauchorApplyNo', text: '바우처신청안함', value: '바우처신청안함' },
];

export const vauchorConfirmOkNo = [
	{ key: '', text: ' ', value: '' },
	{ key: 'vauchorConfirmOk', text: '바우처 선정', value: '바우처선정' },
	{ key: 'vauchorConfirmNo', text: '바우처 탈락', value: '바우처탈락' },
];

export const zeroPayOkNo = [
	{ key: '', text: ' ', value: '' },
	{ key: 'zeroPayOk', text: '제로페이 구매', value: '제로페이구매' },
	{ key: 'zeroPayNo', text: '제로페이 구매안함', value: '제로페이구매안함' },
];

export const inupSelectOkNo = [
	{ key: '', text: ' ', value: '' },
	{ key: 'inupSelectOk', text: '인업 선택', value: '인업선택' },
	{ key: 'inupSelectNo', text: '인업 선택 안함', value: '인업선택안함' },
];

export const jobanalysisOkNo = [
	{ key: '', text: ' ', value: '' },
	{ key: 'jobanalysisOk', text: '직무분석 실시', value: '직무분석실시' },
	{ key: 'jobanalysisNo', text: '직무분석 안함', value: '직무분석안함' },
];

export const manuallDoneOkNo = [
	{ key: '', text: ' ', value: '' },
	{ key: 'manuallDoneOk', text: '매뉴얼 납품 완료', value: '매뉴얼남품완료' },
	{ key: 'manuallDoneNo', text: '매뉴얼 남품 안함', value: '매뉴얼남품안함' },
];

// 입퇴시자 관리
//체크박스
export const ckboxWageIncluded = [
	{ key: '', text: ' ', value: '' },
	{ key: '연장', value: 'overTime' },
	{ key: '야간', value: 'nightTime' },
	{ key: '휴일', value: 'holyTime' },
];

export const ckboxNonTaxWages = [
	{ key: '', text: ' ', value: '' },
	{ key: '식대', value: '식대' },
	{ key: '차량유지비', value: '차량유지비' },
	{ key: '육아수당', value: '육아수당' },
	{ key: '연구수당', value: '연구수당' },
	{ key: '기타 비과세수당', value: '기타 비과세' },
];

export const selectWorkDays = [
	{ key: '', text: ' ', value: '' },
	{ key: 'mon', text: '월', value: '월' },
	{ key: 'tue', text: '화', value: '화' },
	{ key: 'wen', text: '수', value: '수' },
	{ key: 'thu', text: '목', value: '목' },
	{ key: 'fri', text: '금', value: '금' },
	{ key: 'sat', text: '토', value: '토' },
	{ key: 'sun', text: '일', value: '일' },
];

export const workingDayOr = [
	{ key: '', text: ' ', value: '' },
	{ key: 'workDay', text: '근무일', value: '근무일' },
	{ key: 'holiday', text: '휴일', value: '휴일' },
	{ key: 'notWorkDay', text: '휴무일', value: '휴무일' },
];

export const paidOffDayOr = [
	{ key: '', text: ' ', value: '' },
	{ key: 'paid', text: '유급', value: '유급' },
	{ key: 'unpaid', text: '무급', value: '무급' },
];

export const selectPaidWeekHolidays = [
	{ key: '', text: ' ', value: '' },
	{ key: 'mon', text: '월', value: '월' },
	{ key: 'tue', text: '화', value: '화' },
	{ key: 'wen', text: '수', value: '수' },
	{ key: 'thu', text: '목', value: '목' },
	{ key: 'fri', text: '금', value: '금' },
	{ key: 'sat', text: '토', value: '토' },
	{ key: 'sun', text: '일', value: '일' },
];

export const selectUnpaidWeekHolidays = [
	{ key: '', text: ' ', value: '' },
	{ key: 'nothing', text: '없음', value: '없음' },
	{ key: 'mon', text: '월', value: '월' },
	{ key: 'tue', text: '화', value: '화' },
	{ key: 'wen', text: '수', value: '수' },
	{ key: 'thu', text: '목', value: '목' },
	{ key: 'fri', text: '금', value: '금' },
	{ key: 'sat', text: '토', value: '토' },
	{ key: 'sun', text: '일', value: '일' },
];

export const shiftWorkType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'shift2_1Yes', text: '2조 1교대', value: '2조 1교대' },
	{ key: 'shift2_2Yes', text: '2조 2교대', value: '2조 2교대' },
	{
		key: 'shift3_1Yes',
		text: '3조 1교대(3조 격일제)',
		value: '3조 1교대(3조 격일제)',
	},
	{ key: 'shift3_2Yes', text: '3조 2교대', value: '3조 2교대' },
	{ key: 'shift3_3Yes', text: '3조 3교대', value: '3조 3교대' },
	{ key: 'shift4_2Yes', text: '4조 2교대', value: '4조 2교대' },
	{ key: 'shift4_3Yes', text: '4조 3교대', value: '4조 3교대' },
	{ key: 'shift4_4Yes', text: '4조 4교대', value: '4조 4교대' },
	{ key: 'shift5_3Yes', text: '5조 3교대', value: '5조 3교대' },
	{ key: 'shift5_4Yes', text: '5조 4교대', value: '5조 4교대' },
	{ key: 'shiftEtcYes', text: '6조 이상 교대제', value: '6조 이상 교대제' },
];

export const workerStatus = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'permanentFullTime',
		text: '1. 정규직(FullTime)',
		value: '정규직(FullTime)',
	},
	{
		key: 'permanentPartTime',
		text: '2. 정규직(PartTime)',
		value: '정규직(PartTime)',
	},
	{
		key: 'periodFullTime',
		text: '3. 계약직(FullTime)',
		value: '계약직(FullTime)',
	},
	{
		key: 'periodPartTime',
		text: '4. 계약직(PartTime)',
		value: '계약직(PartTime)',
	},
	{
		key: 'periodAgeLimitOver',
		text: '5. 계약직(촉탁직)',
		value: '계약직(촉탁직)',
	},
	{
		key: 'oneDayWorker',
		text: '6. 일용직(연속근무(X))',
		value: '일용직(연속근무(X))',
	},
];

export const wageComfirmBase = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'wageManPowerTest',
		text: '1. 직원 능력 중심 임금제(역량모델링 기반)',
		value: '직원 능력 중심 임금제(역량모델링 기반)',
	},
	{
		key: 'wageManPowerManagerThink',
		text: '2. 직원 능력 중심 임금제(관리자 주관 기반)',
		value: '직원 능력 중심 임금제(관리자 주관 기반)',
	},
	{
		key: 'wageJobBaseJobDescription',
		text: '3. 직무 및 역할에 따른 임금제(직무명세 기반)',
		value: '직무 및 역할에 따른 임금제(직무명세 기반)',
	},
	{
		key: 'wageJobBaseManagerThink',
		text: '4. 직무 및 역할에 따른 임금제(관리자 주관 기반)',
		value: '직무 및 역할에 따른 임금제(관리자 주관 기반)',
	},
	{
		key: 'wagePersonalGoal',
		text: '5. 개인 목표달성 평가 중심 임금제',
		value: '개인 목표달성 평가 중심 임금제',
	},
	{
		key: 'wageTeamGoal',
		text: '6. 공통 목표달성 평가 중심 임금제',
		value: '공통 목표달성 평가 중심 임금제',
	},
	{
		key: 'wageWithoutTest',
		text: '7. 평가 없는 연공서열 중심 임금제',
		value: '평가 없는 연공서열 중심 임금제',
	},
	{
		key: 'wageEtc',
		text: '8. 위와 다른 독자적 임금제',
		value: '기타 임금체계 운영',
	},
];

export const wageTermType = [
	{ key: '', text: ' ', value: '' },
	{ key: '매월', text: '1 월급제(매월1회) 지급', value: '월급제' },
	{ key: '매주', text: '2 주급제(매주1회) 지급', value: '주급제' },
	{ key: '매일', text: '3. 일급제(매일1회) 지급', value: '일급제' },
];

// export const wageCalType = [
//   {key: '', text: ' ', value: ''},
//   {key: 'wagemCalMonth', text: '1. 월급기준(월고정급)', value: '월급기준'},
//   {key: 'wageCalWeek', text: '2. 주급기준(주급 * 근무주수)', value: '주급기준'},
//   {key: 'wageCalDay', text: '3. 일급기준(일급 * 근무일수)', value: '일급기준'},
//   {key: 'wageCalTime', text: '4. 시급기준(시급 * 근무시간)', value: '시급기준'},
//   {key: 'wageCalProduct', text: '5. 완성물량기준(단가 * 수량)', value: '도급제'},
// ];

export const beforeAfterTax = [
	{ key: '', text: ' ', value: '' },
	{ key: 'beforeTax', text: '세금공제전', value: '세금공제전' },
	{ key: 'afterTax', text: '세금공제후', value: '세금공제후' },
];

// 11월23일-공통
export const annualLeaves = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'noAnnualLeave',
		text: '5인미만 연차미부여',
		value: '5인미만 연차미부여',
	},
	{ key: 'lawBase', text: '입사일(법정)기준', value: '입사일(법정)기준' },
	{
		key: 'accountYearBase',
		text: '특정일(회계년도 등)기준',
		value: '특정일(회계년도 등)기준',
	},
];
// -------

export const workField = [
	{ key: '', text: ' ', value: '' },
	{ key: 'salesWorkOut', text: '외근직', value: '판매직을 제외한 영업/마케팅' },
	{
		key: 'salesWorkIn',
		text: '내근직',
		value: '판매직을 제외한 영업/마케팅(외근없음)',
	},
];

export const workerYouthTypeOption = [
	{ key: '', text: ' ', value: '' },
	{
		key: '1. 연속하여 4개월 이상 실업상태인 청년',
		text: '1. 연속하여 4개월 이상 실업상태인 청년',
		value: '1. 연속하여 4개월 이상 실업상태인 청년',
	},
	{
		key: '2. 고졸이하 학력인 청년',
		text: '2. 고졸이하 학력인 청년',
		value: '2. 고졸이하 학력인 청년',
	},
	{
		key: '3. 고용촉진장려금 지급 대상이 되는 청년',
		text: '3. 고용촉진장려금 지급 대상이 되는 청년',
		value: '3. 고용촉진장려금 지급 대상이 되는 청년',
	},
	{
		key: '4. 국민취업지원제도에 참여하고 최초로 취업한 청년(국취 참여이후 최초 취업)',
		text: '4. 국민취업지원제도에 참여하고 최초로 취업한 청년(국취 참여이후 최초 취업)',
		value:
			'4. 국민취업지원제도에 참여하고 최초로 취업한 청년(국취 참여이후 최초 취업)',
	},
	{
		key: '5. 청년도전지원사업수료 청년',
		text: '5. 청년도전지원사업수료 청년',
		value: '5. 청년도전지원사업수료 청년',
	},
	{
		key: '6. 가정과 학교의 보호를 받지 못하여 안정적 자립을 위한 정부 지원 필요성이 인정되는 청년',
		text: '6. 가정과 학교의 보호를 받지 못하여 안정적 자립을 위한 정부 지원 필요성이 인정되는 청년',
		value:
			'6. 가정과 학교의 보호를 받지 못하여 안정적 자립을 위한 정부 지원 필요성이 인정되는 청년',
	},
	{
		key: '7. 북한이탈청년(북한이탈주민등록확인서 발급)',
		text: '7. 북한이탈청년(북한이탈주민등록확인서 발급)',
		value: '7. 북한이탈청년(북한이탈주민등록확인서 발급)',
	},
	{
		key: '8. 자영업 폐업 이후, 최초로 취업한 청년(생애최초가 아닌 자영업 폐업 이후 최초)',
		text: '8. 자영업 폐업 이후, 최초로 취업한 청년(생애최초가 아닌 자영업 폐업 이후 최초)',
		value:
			'8. 자영업 폐업 이후, 최초로 취업한 청년(생애최초가 아닌 자영업 폐업 이후 최초)',
	},
	{
		key: '9. 최종학교 졸업일 이후 채용일까지 고용보험 총 가입기간 12개월 미만인 청년',
		text: '9. 최종학교 졸업일 이후 채용일까지 고용보험 총 가입기간 12개월 미만인 청년',
		value:
			'9. 최종학교 졸업일 이후 채용일까지 고용보험 총 가입기간 12개월 미만인 청년',
	},
	{
		key: '10. 외국인의 경우 거주(F-2), 영주(F-5), 결혼이민자(F-6)비자 소지자 참여 가능',
		text: '10. 외국인의 경우 거주(F-2), 영주(F-5), 결혼이민자(F-6)비자 소지자 참여 가능',
		value:
			'10. 외국인의 경우 거주(F-2), 영주(F-5), 결혼이민자(F-6)비자 소지자 참여 가능',
	},
	{
		key: '11. 위 10가지 경우에 해당되지 않습니다.',
		text: '11. 위 10가지 경우에 해당되지 않습니다.',
		value: '11. 위 10가지 경우에 해당되지 않습니다.',
	},
];

export const maternityLeaveTypeOption = [
	{ key: '', text: ' ', value: '' },
	{
		key: '한자녀 출산전후휴가(90일)',
		text: '한자녀 출산전후휴가(90일)',
		value: '한자녀 출산전후휴가(90일)',
	},
	{
		key: '미숙아 출산전후휴가(100일)',
		text: '미숙아 출산전후휴가(100일)',
		value: '미숙아 출산전후휴가(100일)',
	},
	{
		key: '다자녀 출산전후휴가(120일)',
		text: '다자녀 출산전후휴가(120일)',
		value: '다자녀 출산전후휴가(120일)',
	},
	{ key: '유사산휴가', text: '유사산휴가', value: '유사산휴가' },
	{
		key: '배우자 출산 휴가 1차',
		text: '배우자 출산 휴가 1차',
		value: '배우자 출산 휴가 1차',
	},
	{
		key: '배우자 출산 휴가 2차',
		text: '배우자 출산 휴가 2차',
		value: '배우자 출산 휴가 2차',
	},
	{
		key: '배우자 출산 휴가 3차',
		text: '배우자 출산 휴가 3차',
		value: '배우자 출산 휴가 3차',
	},
	{
		key: '배우자 출산 휴가 4차',
		text: '배우자 출산 휴가 4차',
		value: '배우자 출산 휴가 4차',
	},
];

export const leaveOfAbsenceTypeOption = [
	{ key: '', text: ' ', value: '' },
	{
		key: '1. 근로자 개인 귀책 휴직(무급 대상)',
		text: '1. 근로자 개인 귀책 휴직(무급 대상)',
		value: '1. 근로자 개인 귀책 휴직(무급 대상)',
	},
	{
		key: '2. 근로자 개인 귀책 징계 정직(무급 대상)',
		text: '2. 근로자 개인 귀책 징계 정직(무급 대상)',
		value: '2. 근로자 개인 귀책 징계 정직(무급 대상)',
	},
	{
		key: '3. 경영상 휴직 등 회사 귀책 휴직(평균임금 70% 지급 대상)',
		text: '3. 경영상 휴직 등 회사 귀책 휴직(평균임금 70% 지급 대상)',
		value: '3. 경영상 휴직 등 회사 귀책 휴직(평균임금 70% 지급 대상)',
	},
	{
		key: '4. 징계 등 인사발령 휴직(평균임금 70% 지급 대상)',
		text: '4. 징계 등 인사발령 휴직(평균임금 70% 지급 대상)',
		value: '4. 징계 등 인사발령 휴직(평균임금 70% 지급 대상)',
	},
	{ key: '5. 기타 휴직', text: '5. 기타 휴직', value: '5. 기타 휴직' },
];

export const childLeaveOfAbsenceTypeOption = [
	{ key: '', text: ' ', value: '' },
	{ key: '임신중 육아휴직', text: '임신중 육아휴직', value: '임신중 육아휴직' },
	{
		key: '출산후 육아휴직 1년 전체사용',
		text: '출산후 육아휴직 1년 전체사용',
		value: '출산후 육아휴직 1년 전체사용',
	},
	{
		key: '출산후 육아휴직 1차',
		text: '출산후 육아휴직 1차',
		value: '출산후 육아휴직 1차',
	},
	{
		key: '출산후 육아휴직 2차',
		text: '출산후 육아휴직 2차',
		value: '출산후 육아휴직 2차',
	},
	{
		key: '출산후 육아휴직 3차',
		text: '출산후 육아휴직 3차',
		value: '출산후 육아휴직 3차',
	},
	{
		key: '출산후 육아휴직 4차(마지막)',
		text: '출산후 육아휴직 4차(마지막)',
		value: '출산후 육아휴직 4차(마지막)',
	},
];

export const childShortTimeTypeOption = [
	{ key: '', text: ' ', value: '' },
	{
		key: '육아기 근로시간 단축 1년 전체사용',
		text: '육아기 근로시간 단축 1년 전체사용',
		value: '육아기 근로시간 단축 1년 전체사용',
	},
	{
		key: '육아기 근로시간 단축1차',
		text: '육아기 근로시간 단축1차',
		value: '육아기 근로시간 단축1차',
	},
	{
		key: '육아기 근로시간 단축2차',
		text: '육아기 근로시간 단축2차',
		value: '육아기 근로시간 단축2차',
	},
	{
		key: '육아기 근로시간 단축3차',
		text: '육아기 근로시간 단축3차',
		value: '육아기 근로시간 단축3차',
	},
	{
		key: '육아기 근로시간 단축4차(육아휴직 1년 사용시 마지막)',
		text: '육아기 근로시간 단축4차(육아휴직 1년 사용시 마지막)',
		value: '육아기 근로시간 단축4차(육아휴직 1년 사용시 마지막)',
	},
	{
		key: '육아기 근로시간 단축5차(육아휴직 미사용분 있는 경우)',
		text: '육아기 근로시간 단축5차(육아휴직 미사용분 있는 경우)',
		value: '육아기 근로시간 단축5차(육아휴직 미사용분 있는 경우)',
	},
	{
		key: '육아기 근로시간 단축6차(육아휴직 미사용분 있는 경우)',
		text: '육아기 근로시간 단축6차(육아휴직 미사용분 있는 경우)',
		value: '육아기 근로시간 단축6차(육아휴직 미사용분 있는 경우)',
	},
	{
		key: '육아기 근로시간 단축7차(육아휴직 미사용분 있는 경우)',
		text: '육아기 근로시간 단축7차(육아휴직 미사용분 있는 경우)',
		value: '육아기 근로시간 단축7차(육아휴직 미사용분 있는 경우)',
	},
	{
		key: '육아기 근로시간 단축8차(육아휴직 미사용분 있는 경우)',
		text: '육아기 근로시간 단축8차(육아휴직 미사용분 있는 경우)',
		value: '육아기 근로시간 단축8차(육아휴직 미사용분 있는 경우)',
	},
];

export const workSupportFund = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'yesSure',
		text: '일자리안정자금 신청요망',
		value: '일자리자금신청요망',
	},
	{
		key: 'yesConfuse',
		text: '일자리안정자금 신청안함',
		value: '일자리자금신청안함',
	},
];

//근로소득세
export const taxPayTypeOption = [
	{ key: '', text: ' ', value: '' },
	{ key: '80Tax', text: '80%', value: '80%' },
	{ key: '100Tax', text: '100%', value: '100%' },
	{ key: '120Tax', text: '120%', value: '120%' },
];

//국민연금
export const pensionPayTypeOption = [
	{ key: '', text: ' ', value: '' },
	{ key: 'none', text: '국민연금료 공제안함', value: '국민연금료 공제안함' },
	// {
	// 	key: 'thisMonthWage',
	// 	text: '당월과세금액(매월변동반영)',
	// 	value: '당월과세금액(매월변동반영)',
	// },
	{
		key: 'fixed',
		text: '기준소득월액(국민연금공단에 신고된 금액)',
		value: '기준소득월액(국민연금공단에 신고된 금액)',
	},
];

//건강보험
export const healthCarePayTypeOption = [
	{ key: '', text: ' ', value: '' },
	{ key: 'none', text: '건강보험료 공제안함', value: '건강보험료 공제안함' },
	{
		key: 'thisMonthWage',
		text: '당월과세금액(매월변동반영)',
		value: '당월과세금액(매월변동반영)',
	},
	{
		key: 'averageWage',
		text: '보수월액(건강보험공단에 신고된 금액)',
		value: '보수월액(건강보험공단에 신고된 금액)',
	},
];

//요양보험
export const oldCarePayTypeOption = [
	{ key: '', text: ' ', value: '' },
	{ key: 'none', text: '요양보험료 공제안함', value: '요양보험료 공제안함' },
	{
		key: 'thisMonthWage',
		text: '당월과세금액(매월변동반영)',
		value: '당월과세금액(매월변동반영)',
	},
	{
		key: 'averageWage',
		text: '보수월액(건강보험공단에 신고된 금액)',
		value: '보수월액(건강보험공단에 신고된 금액)',
	},
];

//고용보험
export const noJobPayTypeOption = [
	{ key: '', text: ' ', value: '' },
	{ key: 'none', text: '고용보험료 공제안함', value: '고용보험료 공제안함' },
	{
		key: 'thisMonthWage',
		text: '당월과세금액(매월변동반영)',
		value: '당월과세금액(매월변동반영)',
	},
	{
		key: 'fixed',
		text: '월평균보수월액(근로복지공단에 신고된 금액)',
		value: '월평균보수월액(근로복지공단에 신고된 금액)',
	},
];

//고용보험
export const workAccidenceTypeOption = [
	{ key: '', text: ' ', value: '' },
	{ key: '산재보험 미가입', text: '산재보험 미가입', value: '산재보험 미가입' },
];

export const worknetOption = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'worknetOK',
		text: '워크넷 등록(실업급여 수령포함)',
		value: '워크넷 등록(실업급여 수령포함)',
	},
	{ key: 'worknetNo', text: '워크넷 미등록', value: '워크넷 미등록' },
];

export const newOrNotOption = [
	{ key: '', text: ' ', value: '' },
	{ key: 'newOK', text: '전직장 없음', value: '전직장 없음' },
	{ key: 'newNo', text: '전직장 있음', value: '전직장 있음' },
];

export const selfNoJobPayTypeOption = [
	{ key: '', text: ' ', value: '' },
	{ key: '임의가입', text: '임의가입', value: '임의가입' },
	{ key: '임의가입안함', text: '임의가입안함', value: '임의가입안함' },
];

export const foreignerVisaType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'A1', text: '외교(A-1)', value: 'A1' },
	{ key: 'A2', text: '공무(A-2)', value: 'A2' },
	{ key: 'B1', text: '사증면제(B-1)', value: 'B1' },
	{ key: 'C1', text: '일시취재(C-1)', value: 'C1' },
	{ key: 'C3', text: '단기종합(C-3)', value: 'C3' },
	{ key: 'D1', text: '문화예술(D-1)', value: 'D1' },
	{ key: 'D2', text: '유학(D-2)', value: 'D2' },
	{ key: 'D3', text: '해투기술연수(D-3)', value: 'D3' },
	{ key: 'D4', text: '일반연수(D-4)', value: 'D4' },
	{ key: 'D5', text: '취재(D-5)', value: 'D5' },
	{ key: 'D6', text: '종교(D-6)', value: 'D6' },
	{ key: 'D7', text: '상사주재(D-7)', value: 'D7' },
	{ key: 'D8', text: '기업투자(D-8)', value: 'D8' },
	{ key: 'D9', text: '무역경영(D-9)', value: 'D9' },
	{ key: 'D10', text: '구직(D-10)', value: 'D10' },
	{ key: 'E1', text: '교수(E-1)', value: 'E1' },
	{ key: 'E2', text: '회화지도(E-2)', value: 'E2' },
	{ key: 'E3', text: '연구(E-3)', value: 'E3' },
	{ key: 'E4', text: '기술지도(E-4)', value: 'E4' },
	{ key: 'E5', text: '전문직업(E-5)', value: 'E5' },
	{ key: 'E6', text: '예술흥행(E-6)', value: 'E6' },
	{ key: 'E7', text: '특정활동(E-7)', value: 'E7' },
	{ key: 'E8', text: '계절근로(E-8)', value: 'E8' },
	{ key: 'E9', text: '비전문취업(E-9)', value: 'E9' },
	{ key: 'E10', text: '선원취업(E-10)', value: 'E10' },
	{ key: 'F1', text: '방문동거(F-1)', value: 'F1' },
	{ key: 'F2', text: '거주(F-2)', value: 'F2' },
	{ key: 'F3', text: '동반(F-3)', value: 'F3' },
	{ key: 'F4', text: '재외동포(F-4)', value: 'F4' },
	{ key: 'F5', text: '영주(F-5)', value: 'F5' },
	{ key: 'F6', text: '결혼이민(F-6)', value: 'F6' },
	{ key: 'G1', text: '기타(G-1)', value: 'G1' },
	{ key: 'H1', text: '관광취업(H-1)', value: 'H1' },
	{ key: 'H2', text: '방문취업(H-2)', value: 'H2' },
	{ key: 'T11', text: '관광상륙(T-1-1)', value: 'T11' },
];

export const foreignerVisaTypeAll = [
	{ key: '', text: ' ', value: '' },
	{ key: '0-0', text: '체류자격없음', value: '0-0' },
	{ key: '00', text: '체류자격없음(0-0)', value: '00' },
	{ key: 'A1', text: '외교(A-1)', value: 'A1' },
	{ key: 'A2', text: '공무(A-2)', value: 'A2' },
	{ key: 'A31', text: '미군현역(A-3-1)', value: 'A31' },
	{ key: 'A32', text: '미군군속(A-3-2)', value: 'A32' },
	{ key: 'A399', text: '기타협정(A-3-99)', value: 'A399' },
	{ key: 'B1', text: '사증면제(B-1)', value: 'B1' },
	{ key: 'B21', text: '일반무사증(B-2-1)', value: 'B21' },
	{ key: 'B22', text: '제주무사증(B-2-2)', value: 'B22' },
	{ key: 'C1', text: '일시취재(C-1)', value: 'C1' },
	{ key: 'C21', text: '단기상용(C-2-1)', value: 'C21' },
	{ key: 'C22', text: '우대기업(C-2-2)', value: 'C22' },
	{ key: 'C291', text: 'FTA상용(C-2-91)', value: 'C291' },
	{ key: 'C3', text: '단기종합(C-3)', value: 'C3' },
	{ key: 'C31', text: '단기일반(C-3-1)', value: 'C31' },
	{ key: 'C32', text: '단체관광등(C-3-2)', value: 'C32' },
	{ key: 'C33', text: '의료관광(C-3-3)', value: 'C33' },
	{ key: 'C34', text: '일반상용(C-3-4)', value: 'C34' },
	{ key: 'C35', text: '협정단기상용(C-3-5)', value: 'C35' },
	{ key: 'C36', text: '단기상용(C-3-6)', value: 'C36' },
	{ key: 'C37', text: '도착관광(C-3-7)', value: 'C37' },
	{ key: 'C38', text: '동포방문(C-3-8)', value: 'C38' },
	{ key: 'C39', text: '일반관광(C-3-9)', value: 'C39' },
	{ key: 'C3M', text: '의료관광(C-3-M)', value: 'C3M' },
	{ key: 'C4', text: '단기취업(C-4)', value: 'C4' },
	{ key: 'D1', text: '문화예술(D-1)', value: 'D1' },
	{ key: 'D10', text: '구직(D-10)', value: 'D10' },
	{ key: 'D101', text: '구직활동(D-10-1)', value: 'D101' },
	{ key: 'D102', text: '기술창업활동(D-10-2)', value: 'D102' },
	{ key: 'D2', text: '유학(D-2)', value: 'D2' },
	{ key: 'D21', text: '전문학사(D-2-1)', value: 'D21' },
	{ key: 'D22', text: '학사유학(D-2-2)', value: 'D22' },
	{ key: 'D23', text: '석사유학(D-2-3)', value: 'D23' },
	{ key: 'D24', text: '박사유학(D-2-4)', value: 'D24' },
	{ key: 'D25', text: '연구유학(D-2-5)', value: 'D25' },
	{ key: 'D26', text: '교환학생(D-2-6)', value: 'D26' },
	{ key: 'D2F', text: '교환학생(D-2-F)', value: 'D2F' },
	{ key: 'D2S', text: '시간취업(D-2-S)', value: 'D2S' },
	{ key: 'D31', text: '해투기술연수(D-3-1)', value: 'D31' },
	{ key: 'D311', text: '해외직접(D-3-11)', value: 'D311' },
	{ key: 'D312', text: '기술수출(D-3-12)', value: 'D312' },
	{ key: 'D313', text: '플랜트수출(D-3-13)', value: 'D313' },
	{ key: 'D3B', text: '중기협(D-3-B)', value: 'D3B' },
	{ key: 'D3C', text: '수산협(D-3-C)', value: 'D3C' },
	{ key: 'D3D', text: '각부처(D-3-D)', value: 'D3D' },
	{ key: 'D3E', text: '건설협(D-3-E)', value: 'D3E' },
	{ key: 'D3F', text: '농협(D-3-F)', value: 'D3F' },
	{ key: 'D4', text: '일반연수(D-4)', value: 'D4' },
	{ key: 'D41', text: '대학부설어학원연수(D-4-1)', value: 'D41' },
	{ key: 'D42', text: '기타기관연수(D-4-2)', value: 'D42' },
	{ key: 'D43', text: '초중고생(D-4-3)', value: 'D43' },
	{ key: 'D44', text: '동포연수(D-4-4)', value: 'D44' },
	{ key: 'D45', text: '한식조리연수(D-4-5)', value: 'D45' },
	{ key: 'D46', text: '사설기관연수(D-4-6)', value: 'D46' },
	{ key: 'D47', text: '외국어연수(D-4-7)', value: 'D47' },
	{ key: 'D5', text: '취재(D-5)', value: 'D5' },
	{ key: 'D6', text: '종교(D-6)', value: 'D6' },
	{ key: 'D7', text: '상사주재(D-7)', value: 'D7' },
	{ key: 'D71', text: '외국기업(D-7-1)', value: 'D71' },
	{ key: 'D72', text: '내국기업(D-7-2)', value: 'D72' },
	{ key: 'D791', text: 'FTA전근(D-7-91)', value: 'D791' },
	{ key: 'D792', text: 'FTA계약(D-7-92)', value: 'D792' },
	{ key: 'D8', text: '기업투자(D-8)', value: 'D8' },
	{ key: 'D81', text: '법인에투자(D-8-1)', value: 'D81' },
	{ key: 'D82', text: '벤처투자(D-8-2)', value: 'D82' },
	{ key: 'D83', text: '개인기업투자(D-8-3)', value: 'D83' },
	{ key: 'D84', text: '기술창업(D-8-4)', value: 'D84' },
	{ key: 'D891', text: 'FTA전근(D-8-91)', value: 'D891' },
	{ key: 'D9', text: '무역경영(D-9)', value: 'D9' },
	{ key: 'D91', text: '무역고유거래(D-9-1)', value: 'D91' },
	{ key: 'D92', text: '수출설비(D-9-2)', value: 'D92' },
	{ key: 'D93', text: '선박설비(D-9-3)', value: 'D93' },
	{ key: 'D94', text: '경영영리사업(D-9-4)', value: 'D94' },
	{ key: 'E1', text: '교수(E-1)', value: 'E1' },
	{ key: 'E10', text: '선원취업', value: 'E10' },
	{ key: 'E101', text: '내항선원(E-10-1)', value: 'E101' },
	{ key: 'E102', text: '어선원(E-10-2)', value: 'E102' },
	{ key: 'E103', text: '순항선원(E-10-3)', value: 'E103' },
	{ key: 'E2', text: '회화지도', value: 'E2' },
	{ key: 'E21', text: '일반회화강사(E-2-1)', value: 'E21' },
	{ key: 'E22', text: '학교보조교사(E-2-2)', value: 'E22' },
	{ key: 'E291', text: 'FTA영어(E-2-91)', value: 'E291' },
	{ key: 'E3', text: '연구(E-3)', value: 'E3' },
	{ key: 'E4', text: '기술지도(E-4)', value: 'E4' },
	{ key: 'E5', text: '전문직업(E-5)', value: 'E5' },
	{ key: 'E6', text: '예술흥행(E-6)', value: 'E6' },
	{ key: 'E61', text: '예술연예(E-6-1)', value: 'E61' },
	{ key: 'E62', text: '호텔유흥(E-6-2)', value: 'E62' },
	{ key: 'E63', text: '운동(E-6-3)', value: 'E63' },
	{ key: 'E7', text: '특정활동', value: 'E7' },
	{ key: 'E71', text: '전문인력(E-7-1)', value: 'E71' },
	{ key: 'E72', text: '준전문인력(E-7-2)', value: 'E72' },
	{ key: 'E73', text: '일반기능인력(E-7-3)', value: 'E73' },
	{ key: 'E74', text: '숙련기능인력(E-7-4)', value: 'E74' },
	{ key: 'E791', text: 'FTA독립(E-7-91)', value: 'E791' },
	{
		key: 'E7S1',
		text: '네거티브방식의 전문인력(고소득자) E-7-S-1',
		value: 'E7S1',
	},
	{
		key: 'E7S2',
		text: '네거티브방식의 전문인력(첨단산업) E-7-S-2',
		value: 'E7S2',
	},
	{ key: 'E8', text: '계절근로', value: 'E8' },
	{
		key: 'E81',
		text: '국내외지자체간 MOU 체결로 선정(농업)(E-8-1)',
		value: 'E81',
	},
	{
		key: 'E82',
		text: '결혼이민자가 해외거주 친척 추천(농업)(E-8-2)',
		value: 'E82',
	},
	{
		key: 'E83',
		text: '국내외지자체간 MOU 체결로 선정(어업)(E-8-3)',
		value: 'E83',
	},
	{
		key: 'E84',
		text: '결혼이민자가 해외거주 친척 추천(어업)(E-8-4)',
		value: 'E84',
	},
	{
		key: 'E85',
		text: '기타(G-1) 자격으로 계절근로 활동 후 재입국 추천(농업)(E-8-5)',
		value: 'E85',
	},
	{
		key: 'E86',
		text: '기타(G-1) 자격으로 계절근로 활동 후 재입국 추천(어업)',
		value: 'E86',
	},
	{ key: 'E899', text: '언어소통 도우미 등 기타 보조 인력', value: 'E899' },
	{ key: 'E9', text: '비전문취업', value: 'E9' },
	{ key: 'E91', text: '제조업(E-9-1)', value: 'E91' },
	{ key: 'E92', text: '건설업(E-9-2)', value: 'E92' },
	{ key: 'E93', text: '농업(E-9-3)', value: 'E93' },
	{ key: 'E94', text: '어업(E-9-4)', value: 'E94' },
	{ key: 'E95', text: '서비스업(E-9-5)', value: 'E95' },
	{ key: 'E96', text: '재료수집(E-9-6)', value: 'E96' },
	{ key: 'E97', text: '관광호텔(E-9-7)', value: 'E97' },
	{ key: 'E98', text: '축산업(E-9-8)', value: 'E98' },
	{ key: 'E995', text: '과거추천연수(E-9-95)', value: 'E995' },
	{ key: 'E996', text: '과거연수취업(E-9-96)', value: 'E996' },
	{ key: 'E997', text: '과거특례고용(E-9-97)', value: 'E997' },
	{ key: 'E998', text: '과거합법조치(E-9-98)', value: 'E998' },
	{ key: 'E9A', text: '음식업(E-9-A)', value: 'E9A' },
	{ key: 'E9B', text: '청소업(E-9-B)', value: 'E9B' },
	{ key: 'E9C', text: '간병가사(E-9-C)', value: 'E9C' },
	{ key: 'E9D', text: '건설업(E-9-D)', value: 'E9D' },
	{ key: 'E9E', text: '자차수리(E-9-E)', value: 'E9E' },
	{ key: 'E9F', text: '제조업(E-9-F)', value: 'E9F' },
	{ key: 'E9G', text: '농축산업(E-9-G)', value: 'E9G' },
	{ key: 'E9H', text: '연근해업(E-9-H)', value: 'E9H' },
	{ key: 'E9I', text: '욕탕업(E-9-I)', value: 'E9I' },
	{ key: 'E9J', text: '재료수집(E-9-J)', value: 'E9J' },
	{ key: 'E9K', text: '냉장냉동(E-9-K)', value: 'E9K' },
	{ key: 'E9L', text: '비전문취업(E-9-L)', value: 'E9L' },
	{ key: 'F1', text: '방문동거(F-1)', value: 'F1' },
	{ key: 'F11', text: '방문동거(F-1-1)', value: 'F11' },
	{ key: 'F110', text: '동포고령(F-1-10)', value: 'F110' },
	{ key: 'F111', text: '방문취업자녀(F-1-11)', value: 'F111' },
	{ key: 'F112', text: '거주배우자(F-1-12)', value: 'F112' },
	{ key: 'F113', text: '유학생부모(F-1-13)', value: 'F113' },
	{ key: 'F114', text: '입양외국인(F-1-14)', value: 'F114' },
	{ key: 'F12', text: '가사보조(F-1-2)', value: 'F12' },
	{ key: 'F121', text: '외교가사보조(F-1-21)', value: 'F121' },
	{ key: 'F122', text: '고액가사보조(F-1-22)', value: 'F122' },
	{ key: 'F123', text: '첨단가사보조(F-1-23)', value: 'F123' },
	{ key: 'F124', text: '전문가사보조(F-1-24)', value: 'F124' },
	{ key: 'F13', text: '외교동거(F-1-3)', value: 'F13' },
	{ key: 'F15', text: '결혼이민가족(F-1-5)', value: 'F15' },
	{ key: 'F16', text: '결혼가사정리(F-1-6)', value: 'F16' },
	{ key: 'F17', text: '국적신청(F-1-7)', value: 'F17' },
	{ key: 'F171', text: '국적신청가족(F-1-71)', value: 'F171' },
	{ key: 'F172', text: '영주신청가족(F-1-72)', value: 'F172' },
	{ key: 'F18', text: '합법출생자녀(F-1-8)', value: 'F18' },
	{ key: 'F19', text: '동포배우자등(F-1-9)', value: 'F19' },
	{ key: 'F199', text: '기타동거(F-1-99)', value: 'F199' },
	{ key: 'F2', text: '거주(F-2)', value: 'F2' },
	{ key: 'F21', text: '국민배우자(F-2-1)', value: 'F21' },
	{ key: 'F210', text: '자녀양육(F-2-10)', value: 'F210' },
	{ key: 'F211', text: '공무임용(F-2-11)', value: 'F211' },
	{ key: 'F212', text: '공익사업투자(F-2-12)', value: 'F212' },
	{ key: 'F213', text: '공익은퇴가족(F-2-13)', value: 'F213' },
	{ key: 'F214', text: '은퇴이민투자(F-2-14)', value: 'F214' },
	{ key: 'F215', text: '자녀가성년이된자녀양육자(F-2-15)', value: 'F215' },
	{ key: 'F216', text: '특별기여·공익증진(F-2-16)', value: 'F216' },
	{ key: 'F22', text: '국민자녀(F-2-2)', value: 'F22' },
	{ key: 'F23', text: '영주자가족(F-2-3)', value: 'F23' },
	{ key: 'F24', text: '난민(F-2-4)', value: 'F24' },
	{ key: 'F25', text: '고액투자(F-2-5)', value: 'F25' },
	{ key: 'F26', text: '숙련기능(F-2-6)', value: 'F26' },
	{ key: 'F27', text: '점수우수인력(F-2-7)', value: 'F27' },
	{ key: 'F271', text: '점수가족(F-2-71)', value: 'F271' },
	{ key: 'F27S', text: '잠재적 우수인재(F-2-7S)', value: 'F27S' },
	{ key: 'F28', text: '부동산투자(F-2-8)', value: 'F28' },
	{ key: 'F281', text: '부동가족(F-2-81)', value: 'F281' },
	{ key: 'F29', text: '영주상실(F-2-9)', value: 'F29' },
	{ key: 'F299', text: '기타장기(F-2-99)', value: 'F299' },
	{ key: 'F2R', text: '지역우수인재(F-2-R)', value: 'F2R' },
	{ key: 'F31', text: '동반(F-3-1)', value: 'F31' },
	{ key: 'F391', text: 'FTA동반(F-3-91)', value: 'F391' },
	{ key: 'F4', text: '재외동포', value: 'F4' },
	{ key: 'F41', text: '기여동포(F-4-1)', value: 'F41' },
	{ key: 'F411', text: '재외동포본인(F-4-11)', value: 'F411' },
	{ key: 'F412', text: '재외동포직계가족(F-4-12)', value: 'F412' },
	{ key: 'F413', text: 'DE계열6개월이상체류자(F-4-13)', value: 'F413' },
	{ key: 'F414', text: '대학졸업자(F-4-14)', value: 'F414' },
	{ key: 'F415', text: 'OECD영주자(F-4-15)', value: 'F415' },
	{ key: 'F416', text: '법인대표등(F-4-16)', value: 'F416' },
	{ key: 'F417', text: '10만불기업가(F-4-17)', value: 'F417' },
	{ key: 'F418', text: '다국적기업(F-4-18)', value: 'F418' },
	{ key: 'F419', text: '동포단체대표(F-4-19)', value: 'F419' },
	{ key: 'F42', text: '일반동포(F-4-2)', value: 'F42' },
	{ key: 'F420', text: '공무원등(F-4-20)', value: 'F420' },
	{ key: 'F421', text: '교원(F-4-21)', value: 'F421' },
	{ key: 'F422', text: '개인사업가(F-4-22)', value: 'F422' },
	{ key: 'F423', text: '빈번출입자(F-4-23)', value: 'F423' },
	{ key: 'F424', text: '제조등근속자(F-4-24)', value: 'F424' },
	{ key: 'F425', text: '60세이상자(F-4-25)', value: 'F425' },
	{ key: 'F426', text: '수교전입국자(F-4-26)', value: 'F426' },
	{ key: 'F427', text: '자격증소지자(F-4-27)', value: 'F427' },
	{ key: 'F428', text: '과거 재외동포(F4)자격소지자', value: 'F428' },
	{ key: 'F429', text: '국내고등학교졸업자(F-4-29)', value: 'F429' },
	{
		key: 'F430',
		text: '국내 초·중·고교 재학 동포 자녀(F-4-30)',
		value: 'F430',
	},
	{ key: 'F499', text: '재외동포기타(F-4-99)', value: 'F499' },
	{ key: 'F4R', text: '지역특화형 재외동포(F-4-0R)', value: 'F4R' },
	{ key: 'F5', text: '영주(F-5)', value: 'F5' },
	{ key: 'F51', text: '장기체류(F-5-1)', value: 'F51' },
	{ key: 'F510', text: '첨단학사(F-5-10)', value: 'F510' },
	{ key: 'F511', text: '특정능력(F-5-11)', value: 'F511' },
	{ key: 'F512', text: '특별공로(F-5-12)', value: 'F512' },
	{ key: 'F513', text: '연금수혜(F-5-13)', value: 'F513' },
	{ key: 'F514', text: '방문취업4년(F-5-14)', value: 'F514' },
	{ key: 'F515', text: '국내박사(F-5-15)', value: 'F515' },
	{ key: 'F516', text: '점수제(F-5-16)', value: 'F516' },
	{ key: 'F517', text: '부동산투자(F-5-17)', value: 'F517' },
	{ key: 'F518', text: '점수가족(F-5-18)', value: 'F518' },
	{ key: 'F519', text: '부동가족(F-5-19)', value: 'F519' },
	{ key: 'F52', text: '국민배우자(F-5-2)', value: 'F52' },
	{ key: 'F520', text: '영주출생(F-5-20)', value: 'F520' },
	{ key: 'F521', text: '공익사업투자(F-5-21)', value: 'F521' },
	{ key: 'F522', text: '공익은퇴가족(F-5-22)', value: 'F522' },
	{ key: 'F523', text: '은퇴이민투자(F-5-23)', value: 'F523' },
	{ key: 'F524', text: '법인창업영주(F-5-24)', value: 'F524' },
	{ key: 'F525', text: '고액투자조건부영주(F-5-25)', value: 'F525' },
	{ key: 'F527', text: '난민인정자(F-5-27)', value: 'F527' },
	{ key: 'F53', text: '국민자녀(F-5-3)', value: 'F53' },
	{ key: 'F54', text: '영주가족(F-5-4)', value: 'F54' },
	{ key: 'F55', text: '고액투자(F-5-5)', value: 'F55' },
	{ key: 'F56', text: '재외동포2년(F-5-6)', value: 'F56' },
	{ key: 'F56R', text: '지역특화형 제외동포 영주', value: 'F56R' },
	{ key: 'F57', text: '동포국적요건(F-5-7)', value: 'F57' },
	{ key: 'F58', text: '재한화교(F-5-8)', value: 'F58' },
	{ key: 'F59', text: '첨단박사(F-5-9)', value: 'F59' },
	{ key: 'F6', text: '결혼이민(F-6)', value: 'F6' },
	{ key: 'F61', text: '국민배우자(F-6-1)', value: 'F61' },
	{ key: 'F62', text: '자녀양육(F-6-2)', value: 'F62' },
	{ key: 'F63', text: '혼인단절(F-6-3)', value: 'F63' },
	{ key: 'G1', text: '기타(G-1)', value: 'G1' },
	{ key: 'G11', text: '산재보상(G-1-1)', value: 'G11' },
	{ key: 'G110', text: '치료요양(G-1-10)', value: 'G110' },
	{ key: 'G111', text: '성매매피해자(G-1-11)', value: 'G111' },
	{ key: 'G12', text: '질병사고(G-1-2)', value: 'G12' },
	{ key: 'G13', text: '소송진행(G-1-3)', value: 'G13' },
	{ key: 'G14', text: '체임중재(G-1-4)', value: 'G14' },
	{ key: 'G15', text: '난민신청(G-1-5)', value: 'G15' },
	{ key: 'G16', text: '난민인허(G-1-6)', value: 'G16' },
	{ key: 'G17', text: '가족사망(G-1-7)', value: 'G17' },
	{ key: 'G19', text: '임신출산(G-1-9)', value: 'G19' },
	{ key: 'G199', text: '기타(G-1-99)', value: 'G199' },
	{ key: 'G1M', text: '의료관광(G-1-M)', value: 'G1M' },
	{ key: 'H1', text: '관광취업(H-1)', value: 'H1' },
	{ key: 'H2', text: '방문취업', value: 'H2' },
	{ key: 'H21', text: '연고방취(H-2-1)', value: 'H21' },
	{ key: 'H211', text: '연고방취(H-2-1)', value: 'H211' },
	{ key: 'H212', text: '연고방취(H-2-1)', value: 'H212' },
	{ key: 'H22', text: '유학방취(H-2-2)', value: 'H22' },
	{ key: 'H221', text: '유학방취(H-2-2)', value: 'H221' },
	{ key: 'H222', text: '유학방취(H-2-2)', value: 'H222' },
	{ key: 'H23', text: '자진방취(H-2-3)', value: 'H23' },
	{ key: 'H231', text: '자진방취(H-2-3)', value: 'H231' },
	{ key: 'H232', text: '자진방취(H-2-3)', value: 'H232' },
	{ key: 'H24', text: '연수방취(H-2-4)', value: 'H24' },
	{ key: 'H241', text: '연수방취(H-2-4)', value: 'H241' },
	{ key: 'H242', text: '연수방취(H-2-4)', value: 'H242' },
	{ key: 'H25', text: '추첨방취(H-2-5)', value: 'H25' },
	{ key: 'H251', text: '추첨방취(H-2-5)', value: 'H251' },
	{ key: 'H252', text: '추첨방취(H-2-5)', value: 'H252' },
	{ key: 'H26', text: '변경방취(H-2-6)', value: 'H26' },
	{ key: 'H261', text: '변경방취(H-2-6)', value: 'H261' },
	{ key: 'H262', text: '변경방취(H-2-6)', value: 'H262' },
	{ key: 'H27', text: '만기방취(H-2-7)', value: 'H27' },
	{ key: 'H271', text: '만기방취(H-2-7)', value: 'H271' },
	{ key: 'H272', text: '만기방취(H-2-7)', value: 'H272' },
	{ key: 'H291', text: '기타방취(H-2-91)', value: 'H291' },
	{ key: 'H299', text: '기타방취(H-2-99)', value: 'H299' },
	{ key: 'T11', text: '관광상륙(T-1-1)', value: 'T11' },
];

export const foreignerVisaTypeDayWorker = [
	{ key: '0-0', text: '체류자격없음(0-0)', value: '00' },
	{ key: 'A-1', text: '외교(A-1)', value: 'A1' },
	{ key: 'A-2', text: '공무(A-2)', value: 'A2' },
	{ key: 'A-3-1', text: '미군현역(A-3-1)', value: 'A31' },
	{ key: 'A-3-2', text: '미군군속(A-3-2)', value: 'A32' },
	{ key: 'A-3-99', text: '기타협정(A-3-99)', value: 'A399' },
	{ key: 'B-1', text: '사증면제(B-1)', value: 'B1' },
	{ key: 'B-2-1', text: '일반무사증(B-2-1)', value: 'B21' },
	{ key: 'B-2-2', text: '제주무사증(B-2-2)', value: 'B22' },
	{ key: 'C-1', text: '일시취재(C-1)', value: 'C1' },
	{ key: 'C-2-1', text: '단기상용(C-2-1)', value: 'C21' },
	{ key: 'C-2-2', text: '우대기업(C-2-2)', value: 'C22' },
	{ key: 'C-2-91', text: 'FTA상용(C-2-91)', value: 'C291' },
	{ key: 'C-3', text: '단기종합(C-3)', value: 'C3' },
	{ key: 'C-3-1', text: '단기일반(C-3-1)', value: 'C31' },
	{ key: 'C-3-2', text: '단체관광등(C-3-2)', value: 'C32' },
	{ key: 'C-3-3', text: '의료관광(C-3-3)', value: 'C33' },
	{ key: 'C-3-4', text: '일반상용(C-3-4)', value: 'C34' },
	{ key: 'C-3-5', text: '협정단기상용(C-3-5)', value: 'C35' },
	{ key: 'C-3-6', text: '단기상용(C-3-6)', value: 'C36' },
	{ key: 'C-3-7', text: '도착관광(C-3-7)', value: 'C37' },
	{ key: 'C-3-8', text: '동포방문(C-3-8)', value: 'C38' },
	{ key: 'C-3-9', text: '일반관광(C-3-9)', value: 'C39' },
	{ key: 'C-3-M', text: '의료관광(C-3-M)', value: 'C3M' },
	{ key: 'C-4', text: '단기취업(C-4)', value: 'C4' },
	{ key: 'D-1', text: '문화예술(D-1)', value: 'D1' },
	{ key: 'D-10', text: '구직(D-10)', value: 'D10' },
	{ key: 'D-10-1', text: '구직활동(D-10-1)', value: 'D101' },
	{ key: 'D-10-2', text: '기술창업활동(D-10-2)', value: 'D102' },
	{ key: 'D-2', text: '유학(D-2)', value: 'D2' },
	{ key: 'D-2-1', text: '전문학사(D-2-1)', value: 'D21' },
	{ key: 'D-2-2', text: '학사유학(D-2-2)', value: 'D22' },
	{ key: 'D-2-3', text: '석사유학(D-2-3)', value: 'D23' },
	{ key: 'D-2-4', text: '박사유학(D-2-4)', value: 'D24' },
	{ key: 'D-2-5', text: '연구유학(D-2-5)', value: 'D25' },
	{ key: 'D-2-6', text: '교환학생(D-2-6)', value: 'D26' },
	{ key: 'D-2-F', text: '교환학생(D-2-F)', value: 'D2F' },
	{ key: 'D-2-S', text: '시간취업(D-2-S)', value: 'D2S' },
	{ key: 'D-3-1', text: '해투기술연수(D-3-1)', value: 'D31' },
	{ key: 'D-3-B', text: '중기협(D-3-B)', value: 'D3B' },
	{ key: 'D-3-C', text: '수산협(D-3-C)', value: 'D3C' },
	{ key: 'D-3-D', text: '각부처(D-3-D)', value: 'D3D' },
	{ key: 'D-3-E', text: '건설협(D-3-E)', value: 'D3E' },
	{ key: 'D-3-F', text: '농협(D-3-F)', value: 'D3F' },
	{ key: 'D-3-11', text: '해외직접(D-3-11)', value: 'D311' },
	{ key: 'D-3-12', text: '기술수출(D-3-12)', value: 'D312' },
	{ key: 'D-3-13', text: '플랜트수출(D-3-13)', value: 'D313' },
	{ key: 'D-4', text: '일반연수(D-4)', value: 'D4' },
	{ key: 'D-4-1', text: '대학부설어학원연수(D-4-1)', value: 'D41' },
	{ key: 'D-4-2', text: '기타기관연수(D-4-2)', value: 'D42' },
	{ key: 'D-4-3', text: '초중고생(D-4-3)', value: 'D43' },
	{ key: 'D-4-4', text: '동포연수(D-4-4)', value: 'D44' },
	{ key: 'D-4-5', text: '한식조리연수(D-4-5)', value: 'D45' },
	{ key: 'D-4-6', text: '사설기관연수(D-4-6)', value: 'D46' },
	{ key: 'D-4-7', text: '외국어연수(D-4-7)', value: 'D47' },
	{ key: 'D-5', text: '취재(D-5)', value: 'D5' },
	{ key: 'D-6', text: '종교(D-6)', value: 'D6' },
	{ key: 'D-7', text: '상사주재(D-7)', value: 'D7' },
	{ key: 'D-7-1', text: '외국기업(D-7-1)', value: 'D71' },
	{ key: 'D-7-2', text: '내국기업(D-7-2)', value: 'D72' },
	{ key: 'D-7-91', text: 'FTA전근(D-7-91)', value: 'D791' },
	{ key: 'D-7-92', text: 'FTA계약(D-7-92)', value: 'D792' },
	{ key: 'D-8', text: '기업투자(D-8)', value: 'D8' },
	{ key: 'D-8-1', text: '법인에투자(D-8-1)', value: 'D81' },
	{ key: 'D-8-2', text: '벤처투자(D-8-2)', value: 'D82' },
	{ key: 'D-8-3', text: '개인기업투자(D-8-3)', value: 'D83' },
	{ key: 'D-8-4', text: '기술창업(D-8-4)', value: 'D84' },
	{ key: 'D-8-91', text: 'FTA전근(D-8-91)', value: 'D891' },
	{ key: 'D-9', text: '무역경영(D-9)', value: 'D9' },
	{ key: 'D-9-1', text: '무역고유거래(D-9-1)', value: 'D91' },
	{ key: 'D-9-2', text: '수출설비(D-9-2)', value: 'D92' },
	{ key: 'D-9-3', text: '선박설비(D-9-3)', value: 'D93' },
	{ key: 'D-9-4', text: '경영영리사업(D-9-4)', value: 'D94' },
	{ key: 'E-1', text: '교수(E-1)', value: 'E1' },
	{ key: 'E-10-1', text: '내항선원(E-10-1)', value: 'E101' },
	{ key: 'E-10-2', text: '어선원(E-10-2)', value: 'E102' },
	{ key: 'E-10-3', text: '순항선원(E-10-3)', value: 'E103' },
	{ key: 'E-2-1', text: '일반회화강사(E-2-1)', value: 'E21' },
	{ key: 'E-2-2', text: '학교보조교사(E-2-2)', value: 'E22' },
	{ key: 'E-2-91', text: 'FTA영어(E-2-91)', value: 'E291' },
	{ key: 'E-3', text: '연구(E-3)', value: 'E3' },
	{ key: 'E-4', text: '기술지도(E-4)', value: 'E4' },
	{ key: 'E-5', text: '전문직업(E-5)', value: 'E5' },
	{ key: 'E-6', text: '예술흥행(E-6)', value: 'E6' },
	{ key: 'E-6-1', text: '예술연예(E-6-1)', value: 'E61' },
	{ key: 'E-6-2', text: '호텔유흥(E-6-2)', value: 'E62' },
	{ key: 'E-6-3', text: '운동(E-6-3)', value: 'E63' },
	{ key: 'E-7-1', text: '특정활동(E-7-1)', value: 'E71' },
	{ key: 'E-7-2', text: '의료코디(E-7-2)', value: 'E72' },
	{ key: 'E-7-3', text: '해삼양식(E-7-3)', value: 'E73' },
	{ key: 'E-7-91', text: 'FTA독립(E-7-91)', value: 'E791' },
	{ key: 'E-9-1', text: '제조업(E-9-1)', value: 'E91' },
	{ key: 'E-9-2', text: '건설업(E-9-2)', value: 'E92' },
	{ key: 'E-9-3', text: '농업(E-9-3)', value: 'E93' },
	{ key: 'E-9-4', text: '어업(E-9-4)', value: 'E94' },
	{ key: 'E-9-5', text: '서비스업(E-9-5)', value: 'E95' },
	{ key: 'E-9-6', text: '재료수집(E-9-6)', value: 'E96' },
	{ key: 'E-9-7', text: '관광호텔(E-9-7)', value: 'E97' },
	{ key: 'E-9-8', text: '축산업(E-9-8)', value: 'E98' },
	{ key: 'E-9-A', text: '음식업(E-9-A)', value: 'E9A' },
	{ key: 'E-9-B', text: '청소업(E-9-B)', value: 'E9B' },
	{ key: 'E-9-C', text: '간병가사(E-9-C)', value: 'E9C' },
	{ key: 'E-9-D', text: '건설업(E-9-D)', value: 'E9D' },
	{ key: 'E-9-E', text: '자차수리(E-9-E)', value: 'E9E' },
	{ key: 'E-9-F', text: '제조업(E-9-F)', value: 'E9F' },
	{ key: 'E-9-G', text: '농축산업(E-9-G)', value: 'E9G' },
	{ key: 'E-9-H', text: '연근해업(E-9-H)', value: 'E9H' },
	{ key: 'E-9-I', text: '욕탕업(E-9-I)', value: 'E9I' },
	{ key: 'E-9-J', text: '재료수집(E-9-J)', value: 'E9J' },
	{ key: 'E-9-K', text: '냉장냉동(E-9-K)', value: 'E9K' },
	{ key: 'E-9-L', text: '비전문취업(E-9-L)', value: 'E9L' },
	{ key: 'E-9-95', text: '과거추천연수(E-9-95)', value: 'E995' },
	{ key: 'E-9-96', text: '과거연수취업(E-9-96)', value: 'E996' },
	{ key: 'E-9-97', text: '과거특례고용(E-9-97)', value: 'E997' },
	{ key: 'E-9-98', text: '과거합법조치(E-9-98)', value: 'E998' },
	{ key: 'F-1', text: '방문동거(F-1)', value: 'F1' },
	{ key: 'F-1-1', text: '방문동거(F-1-1)', value: 'F11' },
	{ key: 'F-1-2', text: '가사보조(F-1-2)', value: 'F12' },
	{ key: 'F-1-3', text: '외교동거(F-1-3)', value: 'F13' },
	{ key: 'F-1-5', text: '결혼이민가족(F-1-5)', value: 'F15' },
	{ key: 'F-1-6', text: '결혼가사정리(F-1-6)', value: 'F16' },
	{ key: 'F-1-7', text: '국적신청(F-1-7)', value: 'F17' },
	{ key: 'F-1-8', text: '합법출생자녀(F-1-8)', value: 'F18' },
	{ key: 'F-1-9', text: '동포배우자등(F-1-9)', value: 'F19' },
	{ key: 'F-1-10', text: '동포고령(F-1-10)', value: 'F110' },
	{ key: 'F-1-11', text: '방문취업자녀(F-1-11)', value: 'F111' },
	{ key: 'F-1-12', text: '거주배우자(F-1-12)', value: 'F112' },
	{ key: 'F-1-13', text: '유학생부모(F-1-13)', value: 'F113' },
	{ key: 'F-1-14', text: '입양외국인(F-1-14)', value: 'F114' },
	{ key: 'F-1-21', text: '외교가사보조(F-1-21)', value: 'F121' },
	{ key: 'F-1-22', text: '고액가사보조(F-1-22)', value: 'F122' },
	{ key: 'F-1-23', text: '첨단가사보조(F-1-23)', value: 'F123' },
	{ key: 'F-1-24', text: '전문가사보조(F-1-24)', value: 'F124' },
	{ key: 'F-1-71', text: '국적신청가족(F-1-71)', value: 'F171' },
	{ key: 'F-1-72', text: '영주신청가족(F-1-72)', value: 'F172' },
	{ key: 'F-1-99', text: '기타동거(F-1-99)', value: 'F199' },
	{ key: 'F-2', text: '거주(F-2)', value: 'F2' },
	{ key: 'F-2-1', text: '국민배우자(F-2-1)', value: 'F21' },
	{ key: 'F-2-2', text: '국민자녀(F-2-2)', value: 'F22' },
	{ key: 'F-2-3', text: '영주자가족(F-2-3)', value: 'F23' },
	{ key: 'F-2-4', text: '난민(F-2-4)', value: 'F24' },
	{ key: 'F-2-5', text: '고액투자(F-2-5)', value: 'F25' },
	{ key: 'F-2-6', text: '숙련기능(F-2-6)', value: 'F26' },
	{ key: 'F-2-7', text: '점수우수인력(F-2-7)', value: 'F27' },
	{ key: 'F-2-8', text: '부동산투자(F-2-8)', value: 'F28' },
	{ key: 'F-2-9', text: '영주상실(F-2-9)', value: 'F29' },
	{ key: 'F-2-10', text: '자녀양육(F-2-10)', value: 'F210' },
	{ key: 'F-2-11', text: '공무임용(F-2-11)', value: 'F211' },
	{ key: 'F-2-12', text: '공익사업투자(F-2-12)', value: 'F212' },
	{ key: 'F-2-13', text: '공익은퇴가족(F-2-13)', value: 'F213' },
	{ key: 'F-2-14', text: '은퇴이민투자(F-2-14)', value: 'F214' },
	{ key: 'F-2-71', text: '점수가족(F-2-71)', value: 'F271' },
	{ key: 'F-2-81', text: '부동가족(F-2-81)', value: 'F281' },
	{ key: 'F-2-99', text: '기타장기(F-2-99)', value: 'F299' },
	{ key: 'F-3-1', text: '동반(F-3-1)', value: 'F31' },
	{ key: 'F-3-91', text: 'FTA동반(F-3-91)', value: 'F391' },
	{ key: 'F-4-1', text: '기여동포(F-4-1)', value: 'F41' },
	{ key: 'F-4-2', text: '일반동포(F-4-2)', value: 'F42' },
	{ key: 'F-4-11', text: '재외동포본인(F-4-11)', value: 'F411' },
	{ key: 'F-4-12', text: '재외동포직계가족(F-4-12)', value: 'F412' },
	{ key: 'F-4-13', text: 'DE계열6개월이상체류자(F-4-13)', value: 'F413' },
	{ key: 'F-4-14', text: '대학졸업자(F-4-14)', value: 'F414' },
	{ key: 'F-4-15', text: 'OECD영주자(F-4-15)', value: 'F415' },
	{ key: 'F-4-16', text: '법인대표등(F-4-16)', value: 'F416' },
	{ key: 'F-4-17', text: '10만불기업가(F-4-17)', value: 'F417' },
	{ key: 'F-4-18', text: '다국적기업(F-4-18)', value: 'F418' },
	{ key: 'F-4-19', text: '동포단체대표(F-4-19)', value: 'F419' },
	{ key: 'F-4-20', text: '공무원등(F-4-20)', value: 'F420' },
	{ key: 'F-4-21', text: '교원(F-4-21)', value: 'F421' },
	{ key: 'F-4-22', text: '개인사업가(F-4-22)', value: 'F422' },
	{ key: 'F-4-23', text: '빈번출입자(F-4-23)', value: 'F423' },
	{ key: 'F-4-24', text: '제조등근속자(F-4-24)', value: 'F424' },
	{ key: 'F-4-25', text: '60세이상자(F-4-25)', value: 'F425' },
	{ key: 'F-4-26', text: '수교전입국자(F-4-26)', value: 'F426' },
	{ key: 'F-4-27', text: '자격증소지자(F-4-27)', value: 'F427' },
	{ key: 'F-4-99', text: '재외동포기타(F-4-99)', value: 'F499' },
	{ key: 'F-5', text: '영주(F-5)', value: 'F5' },
	{ key: 'F-5-1', text: '장기체류(F-5-1)', value: 'F51' },
	{ key: 'F-5-2', text: '국민배우자(F-5-2)', value: 'F52' },
	{ key: 'F-5-3', text: '국민자녀(F-5-3)', value: 'F53' },
	{ key: 'F-5-4', text: '영주가족(F-5-4)', value: 'F54' },
	{ key: 'F-5-5', text: '고액투자(F-5-5)', value: 'F55' },
	{ key: 'F-5-6', text: '재외동포2년(F-5-6)', value: 'F56' },
	{ key: 'F-5-7', text: '동포국적요건(F-5-7)', value: 'F57' },
	{ key: 'F-5-8', text: '재한화교(F-5-8)', value: 'F58' },
	{ key: 'F-5-9', text: '첨단박사(F-5-9)', value: 'F59' },
	{ key: 'F-5-10', text: '첨단학사(F-5-10)', value: 'F510' },
	{ key: 'F-5-11', text: '특정능력(F-5-11)', value: 'F511' },
	{ key: 'F-5-12', text: '특별공로(F-5-12)', value: 'F512' },
	{ key: 'F-5-13', text: '연금수혜(F-5-13)', value: 'F513' },
	{ key: 'F-5-14', text: '방문취업4년(F-5-14)', value: 'F514' },
	{ key: 'F-5-15', text: '국내박사(F-5-15)', value: 'F515' },
	{ key: 'F-5-16', text: '점수제(F-5-16)', value: 'F516' },
	{ key: 'F-5-17', text: '부동산투자(F-5-17)', value: 'F517' },
	{ key: 'F-5-18', text: '점수가족(F-5-18)', value: 'F518' },
	{ key: 'F-5-19', text: '부동가족(F-5-19)', value: 'F519' },
	{ key: 'F-5-20', text: '영주출생(F-5-20)', value: 'F520' },
	{ key: 'F-5-21', text: '공익사업투자(F-5-21)', value: 'F521' },
	{ key: 'F-5-22', text: '공익은퇴가족(F-5-22)', value: 'F522' },
	{ key: 'F-5-23', text: '은퇴이민투자(F-5-23)', value: 'F523' },
	{ key: 'F-5-24', text: '법인창업영주(F-5-24)', value: 'F524' },
	{ key: 'F-5-25', text: '고액투자조건부영주(F-5-25)', value: 'F525' },
	{ key: 'F-6-1', text: '국민배우자(F-6-1)', value: 'F61' },
	{ key: 'F-6-2', text: '자녀양육(F-6-2)', value: 'F62' },
	{ key: 'F-6-3', text: '혼인단절(F-6-3)', value: 'F63' },
	{ key: 'G-1', text: '기타(G-1)', value: 'G1' },
	{ key: 'G-1-1', text: '산재보상(G-1-1)', value: 'G11' },
	{ key: 'G-1-2', text: '질병사고(G-1-2)', value: 'G12' },
	{ key: 'G-1-3', text: '소송진행(G-1-3)', value: 'G13' },
	{ key: 'G-1-4', text: '체임중재(G-1-4)', value: 'G14' },
	{ key: 'G-1-5', text: '난민신청(G-1-5)', value: 'G15' },
	{ key: 'G-1-6', text: '난민인허(G-1-6)', value: 'G16' },
	{ key: 'G-1-7', text: '가족사망(G-1-7)', value: 'G17' },
	{ key: 'G-1-9', text: '임신출산(G-1-9)', value: 'G19' },
	{ key: 'G-1-M', text: '의료관광(G-1-M)', value: 'G1M' },
	{ key: 'G-1-10', text: '치료요양(G-1-10)', value: 'G110' },
	{ key: 'G-1-11', text: '성매매피해자(G-1-11)', value: 'G111' },
	{ key: 'G-1-99', text: '기타(G-1-99)', value: 'G199' },
	{ key: 'H-1', text: '관광취업(H-1)', value: 'H1' },
	{ key: 'H-2-1', text: '연고방취(H-2-1)', value: 'H21' },
	{ key: 'H-2-2', text: '유학방취(H-2-2)', value: 'H22' },
	{ key: 'H-2-3', text: '자진방취(H-2-3)', value: 'H23' },
	{ key: 'H-2-4', text: '연수방취(H-2-4)', value: 'H24' },
	{ key: 'H-2-5', text: '추첨방취(H-2-5)', value: 'H25' },
	{ key: 'H-2-6', text: '변경방취(H-2-6)', value: 'H26' },
	{ key: 'H-2-7', text: '만기방취(H-2-7)', value: 'H27' },
	{ key: 'H-2-91', text: '기타방취(H-2-91)', value: 'H291' },
	{ key: 'H-2-99', text: '기타방취(H-2-99)', value: 'H299' },
	{ key: 'T-1-1', text: '관광상륙(T-1-1)', value: 'T11' },
];

export const altWorkerOption = [
	{ key: '', text: ' ', value: '' },
	{
		key: '1. 출산전후휴가 대체인력(지원금 대상)',
		text: '1. 출산전후휴가 대체인력(지원금 대상)',
		value: '1. 출산전후휴가 대체인력(지원금 대상)',
	},
	{
		key: '2. 육아기 근로시간단축 대체인력(지원금 대상자)',
		text: '2. 육아기 근로시간단축 대체인력(지원금 대상자)',
		value: '2. 육아기 근로시간단축 대체인력(지원금 대상자)',
	},
	{
		key: '3. 육아휴직 대체인력(지원금 대상자 아님)',
		text: '3. 육아휴직 대체인력(지원금 대상자 아님)',
		value: '3. 육아휴직 대체인력(지원금 대상자 아님)',
	},
	{
		key: '4. 위 3가지 경우에 해당하지 않음',
		text: '4. 위 3가지 경우에 해당하지 않음',
		value: '4. 위 3가지 경우에 해당하지 않음',
	},
];

export const trainee = [
	{ key: '', text: ' ', value: '' },
	{ key: 'traineeNo', text: '수습적용안함', value: '수습적용안함' },
	{ key: 'traineeYes', text: '수습적용', value: '수습적용' },
];

export const workerSupportFundYesOrNot = [
	{ key: '', text: ' ', value: '' },
	{ key: '신청', text: '신청', value: '신청' },
	{ key: '신청안함', text: '신청안함', value: '신청안함' },
];

export const monthType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'thisMonth', text: '당월', value: '당월' },
	{ key: 'nextMonth', text: '익월', value: '익월' },
];

export const dayType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'today', text: '당일', value: '당일' },
	{ key: 'nextDay', text: '익일', value: '익일' },
];

export const shiftWork = [
	{ key: '', text: ' ', value: '' },
	{ key: '격일제 해당', text: '격일제 해당', value: '격일제 해당' },
	{ key: '격일제 비해당', text: '격일제 비해당', value: '격일제 비해당' },
];

export const construct = [
	{ key: '', text: ' ', value: '' },
	{ key: 'contruct', text: '건설업', value: '건설업' },
	{ key: 'notContruct', text: '일반업종', value: '일반업종' },
];

export const dayWorkerTaxOrNot = [
	{ key: '', text: ' ', value: '' },
	{ key: 'dayWorker', text: '일용근로자세금', value: '일용근로자세금' },
	{ key: 'notDayWorker', text: '일반근로자세금', value: '일반근로자세금' },
];

export const payDayType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'today', text: '지급일 전 근무일', value: '지급일 전 근무일' },
	{ key: 'nextDay', text: '지급일 다음 근무일', value: '지급일 다음 근무일' },
];

export const over14InWeek = [
	{ key: '', text: ' ', value: '' },
	{ key: 'includeBLWage', text: '주휴수당 포함', value: '주휴수당 포함' },
	{ key: 'noBLWage', text: '주휴수당 미포함', value: '주휴수당 미포함' },
];

export const workTimeSystem = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'nomalWorkTimeSystem',
		text: '일반출퇴근제(지원금없음)',
		value: '정상출퇴근제',
	},
	{
		key: 'shiftWorkTimeSystem',
		text: '시차출근제(지원금대상)',
		value: '시차출근제',
	},
	{
		key: 'selectWorkTimeSystem',
		text: '선택근무제(지원금대상)',
		value: '재택근무제',
	},
	{
		key: 'homeWorkTimeSystem',
		text: '재택근무제(지원금대상)',
		value: '재택근무제',
	},
	{
		key: 'outsideWorkTimeSystem',
		text: '원격근무제(지원금대상)',
		value: '원격근무제',
	},
];

//퇴사자 4대보험 등록

export const severancePay = [
	{ key: '', text: ' ', value: '' },
	{ key: 'lessOneYear', text: '대상아님(근속 1년미만)', value: '근속 1년미만' },
	{
		key: 'within14days',
		text: '퇴직일부터 14일이내 지급(or 예정)',
		value: '14일지급',
	},
	{
		key: 'severancePention',
		text: '퇴직연금 지급(or 예정)',
		value: '퇴직연금',
	},
	{
		key: 'over14daysPaperComfirm',
		text: '합의서 기반 14일이후 지급(or 예정)',
		value: '합의서 14일후 지급',
	},
	{
		key: 'over14daysContractComfirm',
		text: '근로계약서 기반 14일이후 지급(or 예정)',
		value: '근로계약서 14일후 지급',
	},
	{
		key: 'over14daysVerbalComfirm',
		text: '언약 기반 14일이후 지급(or 예정)',
		value: '언약 14일후 지급',
	},
	{
		key: 'noPayNoMoney',
		text: '경영악화로 당분간 미지급(or 예정)',
		value: '경영악화 미지급',
	},
	{
		key: 'noPayWithDebate',
		text: '분쟁으로 당분간 미지급(or 예정)',
		value: '분쟁 미지급',
	},
];

export const annualLeavesPay = [
	{ key: '', text: ' ', value: '' },
	{ key: 'noTarget', text: '대상아님(모두사용)', value: '대상아님(모두사용)' },
	{
		key: 'within14days',
		text: '퇴직일부터 14일이내 지급(or 예정)',
		value: '14일지급',
	},
	{ key: 'noPayNoCount', text: '사실확인불가', value: '사실확인불가' },
	{
		key: 'over14daysPaperComfirm',
		text: '합의서 기반 14일이후 지급(or 예정)',
		value: '합의서 14일후 지급',
	},
	{
		key: 'over14daysContractComfirm',
		text: '근로계약서 기반 14일이후 지급(or 예정)',
		value: '근로계약서 14일후 지급',
	},
	{
		key: 'over14daysVerbalComfirm',
		text: '언약 기반 14일이후 지급(or 예정)',
		value: '언약 14일후 지급',
	},
	{
		key: 'noPayNoMoney',
		text: '경영악화로 당분간 미지급(or 예정)',
		value: '경영악화 미지급',
	},
	{
		key: 'noPayWithDebate',
		text: '분쟁으로 당분간 미지급(or 예정)',
		value: '분쟁 미지급',
	},
];

export const compensationVacationPay = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'noTargetNoRule',
		text: '대상아님(보상휴가 미도입)',
		value: '대상아님(보상휴가 미도입',
	},
	{ key: 'noTarget', text: '대상아님(모두사용)', value: '대상아님(모두사용' },
	{
		key: 'within14days',
		text: '퇴직일부터 14일이내 지급(or 예정)',
		value: '14일지급',
	},
	{ key: 'noPayNoCount', text: '사실확인불가', value: '사실확인불가' },
	{
		key: 'over14daysPaperComfirm',
		text: '합의서 기반 14일이후 지급(or 예정)',
		value: '합의서 14일후 지급',
	},
	{
		key: 'over14daysContractComfirm',
		text: '근로계약서 기반 14일이후 지급(or 예정)',
		value: '근로계약서 14일후 지급',
	},
	{
		key: 'over14daysVerbalComfirm',
		text: '언약 기반 14일이후 지급(or 예정)',
		value: '언약 14일후 지급',
	},
	{
		key: 'noPayNoMoney',
		text: '경영악화로 당분간 미지급(or 예정)',
		value: '경영악화 미지급',
	},
	{
		key: 'noPayWithDebate',
		text: '분쟁으로 당분간 미지급(or 예정)',
		value: '분쟁 미지급',
	},
];

export const yearEndRefundPay = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'noTargetNoRule',
		text: '대상아님(환급금 없음)',
		value: '대상아님(환급금 없음)',
	},
	{
		key: 'within14days',
		text: '퇴직일부터 14일이내 지급(or 예정)',
		value: '14일지급',
	},
	{ key: 'noPayNoCount', text: '사실확인불가', value: '사실확인불가' },
	{
		key: 'over14daysPaperComfirm',
		text: '합의서 기반 14일이후 지급(or 예정)',
		value: '합의서 14일후 지급',
	},
	{
		key: 'over14daysContractComfirm',
		text: '근로계약서 기반 14일이후 지급(or 예정)',
		value: '근로계약서 14일후 지급',
	},
	{
		key: 'over14daysVerbalComfirm',
		text: '언약 기반 14일이후 지급(or 예정)',
		value: '언약 14일후 지급',
	},
	{
		key: 'noPayNoMoney',
		text: '경영악화로 당분간 미지급(or 예정)',
		value: '경영악화 미지급',
	},
	{
		key: 'noPayWithDebate',
		text: '분쟁으로 당분간 미지급(or 예정)',
		value: '분쟁 미지급',
	},
];

export const eiOut4Type = [
	{ key: '', text: ' ', value: '' },
	{ key: '11', text: '11. 개인사정으로 인한 자진퇴사', value: '11' },
	{
		key: '12',
		text: '12. 사업장 이전, 근로자건 변동, 임금체불 등으로 자진퇴사',
		value: '12',
	},
	{ key: '22', text: '22. 폐업/도산(예정포함), 공사 중단', value: '22' },
	{
		key: '23',
		text: '23. 경영상 필요 또는 회사불황으로 인원감축 등에 의한 퇴사(해고, 권고사직, 계약파기 포함)',
		value: '23',
	},
	{
		key: '26',
		text: '26. 피보험자의 귀책사유에 의한 징계해고, 권고사직 또는 계약 파기',
		value: '26',
	},
	{ key: '31', text: '31. 정년', value: '31' },
	{ key: '32', text: '32. 계약기간 만료, 공사 종료', value: '32' },
	{ key: '41', text: '41. 고용보험 비적용', value: '41' },
	{ key: '42', text: '42. 이중 고용', value: '42' },
];

export const eiOutDetailReason = [
	{ key: '', text: ' ', value: '' },
	{
		key: '11.01',
		text: '11.01 다른 직장으로 옮기기 위해 이직한 경우',
		value: '01다른 직장으로 옮기기 위해 이직한 경우',
	},
	{
		key: '11.02',
		text: '11.02 본인 사업이나 가족사업 등을 하기 위하여 이직한 경우',
		value: '02본인 사업이나 가족사업 등을 하기 위하여 이직한 경우',
	},
	{
		key: '11.03',
		text: '11.03 결혼∙출산∙육아를 이유로 이직한 경우',
		value: '03결혼∙출산∙육아를 이유로 이직한 경우',
	},
	{
		key: '11.04',
		text: '11.04 가족(배우자 또는 부양해야 할 친족)과의 동거를 위하여 거소를 이전하여 이직한 경우',
		value:
			'04가족(배우자 또는 부양해야 할 친족)과의 동거를 위하여 거소를 이전하여 이직한 경우',
	},
	{
		key: '11.05',
		text: '11.05 자녀 교육을 위하여 이직한 경우',
		value: '05자녀 교육을 위하여 이직한 경우',
	},
	{
		key: '11.06',
		text: '11.06 사업장 이전∙전근은 없지만 개인사정(거주지 이전 등)으로 인해 사업장의 출퇴근이 곤란하여 이직한 경우',
		value:
			'06사업장 이전∙전근은 없지만 개인사정(거주지 이전 등)으로 인해 사업장의 출퇴근이 곤란하여 이직한 경우',
	},
	{
		key: '11.07',
		text: '11.07 본인이나 동거인∙친족의 질병∙부상으로 업무수행이 곤란하여 이직한 경우',
		value:
			'07본인이나 동거인∙친족의 질병∙부상으로 업무수행이 곤란하여 이직한 경우',
	},
	{
		key: '11.08',
		text: '11.08 고연령 등으로 업무수행이 곤란하다고 판단되어 스스로 이직한 경우',
		value: '08고연령 등으로 업무수행이 곤란하다고 판단되어 스스로 이직한 경우',
	},
	{
		key: '11.09',
		text: '11.09 본인의 업무상 과실 또는 능력 부족이나 체력 쇠퇴 등으로 스스로 이직한 경우',
		value:
			'09본인의 업무상 과실 또는 능력 부족이나 체력 쇠퇴 등으로 스스로 이직한 경우',
	},
	{
		key: '11.10',
		text: '11.10 본인의 학업 또는 시험 대비를 위하여 이직한 경우',
		value: '10본인의 학업 또는 시험 대비를 위하여 이직한 경우',
	},
	{
		key: '11.11',
		text: '11.11 병역의무를 이행하기 위해서 이직한 경우(*수급자격 있음)',
		value: '11병역의무를 이행하기 위해서 이직한 경우 (*수급자격 있음)',
	},
	{
		key: '11.12',
		text: '11.12 본인이 쉬고 싶어서 이직한 경우',
		value: '12본인이 쉬고 싶어서 이직한 경우',
	},
	{
		key: '11.13',
		text: '11.13 본인 의지에 따라 희망퇴직∙명예퇴직한 경우(*수급자격 없음)',
		value: '13본인 의지에 따라 희망퇴직∙명예퇴직한 경우(*수급자격 없음)',
	},
	{
		key: '11.14',
		text: '11.14 사업∙부서가 페지되고 신설법인으로 고용승계되었으나 이를 거부하여 이직한 경우',
		value:
			'14사업·부서가 폐지되고 신설법인으로 고용승계되었으나 이를 거부하여 이직한 경우',
	},
	{
		key: '11.15',
		text: '11.15 사업장에서 종교∙성별∙신체장애∙노조활동 등을 이유로 불합리한 차별대우를 받아 이직한 경우',
		value:
			'15사업장에서 종교∙성별∙신체장애∙노조활동 등을 이유로 불합리한 차별대우를 받아 이직한 경우',
	},
	{
		key: '11.16',
		text: '11.16 사업장에서 본인의 의사에 반하여 성희롱, 성폭력, 그 밖의 성적인 괴롭힘을 당하여 이직한 경우',
		value:
			'16사업장에서 본인의 의사에 반하여 성희롱, 성폭력, 그 밖의 성적인 괴롭힘을 당하여 이직한 경우',
	},
	{
		key: '11.17',
		text: '11.17 「근로기준법」제76조의2에 따른 직장 내 괴롭힘을 당하여 이직한 경우',
		value:
			'17「근로기준법」제76조의2에 따른 직장 내 괴롭힘을 당하여 이직한 경우',
	},
	{
		key: '11.18',
		text: '11.18 「산업안전보건법」제2조제2호에 따른 중대재해가 발생한 사업장으로서 그 지해와 관련된 안전보건상의 시정명령을 받고도 시정기간까지 시정하지 아니하여 같은 재해 위험에 노출되어 이직한 경우',
		value:
			'18「산업안전보건법」제2조제2호에 따른 중대재해가 발생한 사업장으로서 그 지해와 관련된 안전보건상의 시정명령을 받고도 시정기간까지 시정하지 아니하여 같은 재해 위험에 노출되어 이직한 경우',
	},
	{
		key: '11.19',
		text: '11.19 사업주의 사업 내용이 법령의 제정∙개정으로 위법하게 되거나 취업 당시와는 달리 법령에서 금지하는 재화 또는 용역을 제조하거나 판매하게 된 경우',
		value:
			'19사업주의 사업 내용이 법령의 제정∙개정으로 위법하게 되거나 취업 당시와는 달리 법령에서 금지하는 재화 또는 용역을 제조하거나 판매하게 된 경우',
	},
	{
		key: '11.20',
		text: '11.20 기타',
		value: '20기타',
	},
	{
		key: '12.01',
		text: '12.01 회사사정으로 인한 휴업∙휴직이 계속되어 이직한 경우',
		value: '01회사사정으로 인한 휴업∙휴직이 계속되어 이직한 경우',
	},
	{
		key: '12.02',
		text: '12.02 임금 등의 체불 또는 지연지급이 계속되어 이직한 경우',
		value: '02임금 등의 체불 또는 지연지급이 계속되어 이직한 경우',
	},
	{
		key: '12.03',
		text: '12.03 사업장(노무제공장소) 이전으로 출∙퇴근이 곤란하여 이직한 경우',
		value: '03사업장(노무제공장소) 이전으로 출∙퇴근이 곤란하여 이직한 경우',
	},
	{
		key: '12.04',
		text: '12.04 사업장(노무제공장소) 이전은 없으나 사업주로부터 통근이 불가능한 지역으로 전근 명령을 받아 이직한 경우',
		value:
			'04사업장(노무제공장소) 이전은 없으나 사업주로부터 통근이 불가능한 지역으로 전근 명령을 받아 이직한 경우',
	},
	{
		key: '12.05',
		text: '12.05 사업주로부터 사회통념상 타당성이 없는 보직변경을 받아 업무에 적응하지 못하여 이직한 경우',
		value:
			'05사업주로부터 사회통념상 타당성이 없는 보직변경을 받아 업무에 적응하지 못하여 이직한 경우',
	},
	{
		key: '12.06',
		text: '12.06 채용 시(계약체결 시) 사업주가 제시한 임금∙근로조건(계약조건) 등이 현저히 낮아지게 되어 이직한 경우',
		value:
			'06채용 시(계약체결 시) 사업주가 제시한 임금∙근로조건(계약조건) 등이 현저히 낮아지게 되어 이직한 경우',
	},
	{
		key: '12.07',
		text: '12.07 이직 전 12개월간 2개월 이상 임금이 최저임금보다 낮거나 근로기준법 제53조에 따른 연장근로 제한 위반이 계속되어 이직한 경우',
		value:
			'07이직 전 12개월간 2개월 이상 임금이 최저임금보다 낮거나 근로기준법 제53조에 따른 연장근로 제한 위반이 계속되어 이직한 경우',
	},
	{
		key: '22.01',
		text: '22.01 사업장의 도산∙폐업이 확정∙실현되어 이직한 경우',
		value: '01사업장의 도산∙폐업이 확정∙실현되어 이직한 경우',
	},
	{
		key: '22.02',
		text: '22.02 천재지변 등으로 사업 불가능하여 이직한 경우',
		value: '02천재지변 등으로 사업 불가능하여 이직한 경우',
	},
	{
		key: '22.03',
		text: '22.03 사업이 중단되고 재개될 전망이 없어서 이직한 경우',
		value: '03사업이 중단되고 재개될 전망이 없어서 이직한 경우',
	},
	{
		key: '23.01',
		text: '23.01 경영상 필요에 의한 인원 감축으로 인한 해고에 의해 이직한 경우',
		value: '01경영상 필요에 의한 인원 감축으로 인한 해고에 의해 이직한 경우',
	},
	{
		key: '23.02',
		text: '23.02 사업의 양도∙양수∙합병과정에서 본인 의사와 관계없이 고용승계가 배제되어 이직한 경우',
		value:
			'02사업의 양도∙양수∙합병과정에서 본인 의사와 관계없이 고용승계가 배제되어 이직한 경우',
	},
	{
		key: '23.03',
		text: '23.03 경영상 필요에 의한 인원 감축이 불가피하여 실시된 고용조정계획 또는 대량의 감원 예정에 따른 사업주의 퇴직 권고에 의하여 이직한 경우',
		value:
			'03경영상 필요에 의한 인원 감축이 불가피하여 실시된 고용조정계획 또는 대량의 감원 예정에 따른 사업주의 퇴직 권고에 의하여 이직한 경우',
	},
	{
		key: '23.04',
		text: '23.04 사업∙부서가 폐지되어 신설된 법인으로 이직한 경우',
		value: '04사업∙부서가 폐지되어 신설된 법인으로 이직한 경우',
	},
	{
		key: '23.05',
		text: '23.05 직제개편에 따른 조직의 폐지∙축소, 회사의 업종전환, 일부 사업 또는 작업형태의 변경으로 인해 사업주의 퇴직 권고에 의하여 이직한 경우',
		value:
			'05직제개편에 따른 조직의 폐지∙축소, 회사의 업종전환, 일부 사업 또는 작업형태의 변경으로 인해 사업주의 퇴직 권고에 의하여 이직한 경우',
	},
	{
		key: '23.06',
		text: '23.06 회사의 주문량∙작업량 감소 등과 같은 경영의 악화로 인해 이직한 경우',
		value:
			'06회사의 주문량∙작업량 감소 등과 같은 경영의 악화로 인해 이직한 경우',
	},
	{
		key: '23.07',
		text: '23.07 결혼, 임신, 출산, 군입대 등의 경우에 퇴직이 관행이 된 사업장에서 이직한 경우',
		value:
			'07결혼, 임신, 출산, 군입대 등의 경우에 퇴직이 관행이 된 사업장에서 이직한 경우',
	},
	{
		key: '23.08',
		text: '23.08 근로자의 귀책사유 없는 해고(예술인 또는 노무제공자의 귀책사유는 없지만 사업주의 요구에 따라 계약이 파기∙해지된 경우)',
		value:
			'08근로자의 귀책사유 없는 해고(예술인 또는 노무제공자의 귀책사유는 없지만 사업주의 요구에 따라 계약이 파기∙해지된 경우)',
	},
	{
		key: '26.01',
		text: '26.01 근로자가 업무상 중대한 과실로 인해 취업규칙∙단체협약 등으로 정한 징계해고 사유에 해당되어 징계해고 절차를 거쳐 해고된 경우(예술인∙노무제공제공자의 귀책사유로 인하여 계약이 파기∙해지된 경우)',
		value:
			'01근로자가 업무상 중대한 과실로 인해 취업규칙∙단체협약 등으로 정한 징계해고 사유에 해당되어 징계해고 절차를 거쳐 해고된 경우(예술인∙노무제공제공자의 귀책사유로 인하여 계약이 파기∙해지된 경우)',
	},
	{
		key: '26.02',
		text: '26.02 근로자가 업무상 중대한 과실로 징계해고에 해당하나 사업주가 권유해서 스스로 사직한 경우',
		value:
			'02근로자가 업무상 중대한 과실로 징계해고에 해당하나 사업주가 권유해서 스스로 사직한 경우',
	},
	{
		key: '26.03',
		text: '26.03 근로자의 업무상 과실(업무능력 미달 포함) 등 귀책 사유가 징계해고 정도는 아니지만 사업주가 퇴직을 권유하여 이직한 경우',
		value:
			'03근로자의 업무상 과실(업무능력 미달 포함) 등 귀책사유가 징계해고 정도는 아니지만 사업주가 퇴직을 권유하여 이직한 경우',
	},
	{
		key: '31.01',
		text: '31.01 취업규칙∙단체협약 등에 의한 정년에 해당되어 퇴직한 경우',
		value: '01취업규칙∙단체협약 등에 의한 정년에 해당되어 퇴직한 경우',
	},
	{
		key: '32.01',
		text: '32.01 근로계약 또는 문화예술용역 관련 계약 및 노무제공계약의 기간만료',
		value: '01근로계약 또는 문화예술용역 관련 계약 및 노무제공계약의 기간만료',
	},
	{
		key: '32.02',
		text: '32.02 조건부계약의 조건성취에 따라 계약이 만료된 경우',
		value: '02조건부계약의 조건성취에 따라 계약이 만료된 경우',
	},
	{
		key: '32.03',
		text: '32.03 공사계약의 기간만료',
		value: '03공사계약의 기간만료',
	},
	{
		key: '41.01',
		text: '41.01 고용보험 적용제외 또는 임의적용 대상자가 되어 피보험자격을 상실한 경우',
		value:
			'01고용보험 적용제외 또는 임의적용 대상자가 되어 피보험자격을 상실한 경우',
	},
	{
		key: '41.02',
		text: '41.02 사업장의 보험관계가 해지되어 피보험자격을 상실한 경우',
		value: '02사업장의 보험관계가 해지되어 피보험자격을 상실한 경우',
	},
	{
		key: '41.03',
		text: '41.03 임의가입자의 가입탈퇴 신청이 승인되어 피보험자격을 상실한 경우',
		value: '03임의가입자의 가입탈퇴 신청이 승인되어 피보험자격을 상실한 경우',
	},
	{
		key: '41.04',
		text: '41.04 본인의 사망으로 피보험자격을 상실한 경우',
		value: '04본인의 사망으로 피보험자격을 상실한 경우',
	},
	{
		key: '42.01',
		text: '42.01 근로자가 다른 사업장에서 근로자로서 피보험자격을 취득하여 상실한 경우',
		value:
			'01근로자가 다른 사업장에서 근로자로서 피보험자격을 취득하여 상실한 경우',
	},
];

export const pensionOut4Type = [
	{ key: '', text: ' ', value: '' },
	{ key: '1', text: '사망', value: '01' },
	{ key: '3', text: '사용관계 종료', value: '03' },
	{ key: '4', text: '국적 상실(국외 이주)', value: '04' },
	{
		key: '15',
		text: '(조기)노령연금 수급권 취득(조기노령연금의 지급이 정지 중인 경우는 제외)',
		value: '15',
	},
	{ key: '16', text: '협정국 연금가입', value: '16' },
	{ key: '19', text: '체류기간 만료(외국인)', value: '19' },
	{ key: '20', text: '적용제외 체류자격(외국인)', value: '20' },
	{ key: '21', text: '무보수 대표이사', value: '21' },
	{ key: '22', text: '근로자 제외', value: '22' },
	{
		key: '26',
		text: '취득취소(착오나 사정변경으로 자격취득 신고를 취소하는 경우)',
		value: '26',
	},
];

export const pensionIn4Type = [
	{ key: '', text: ' ', value: '' },
	{
		key: '18세 이상 당연취득(1개월이상 계속하여 사용하는 일용직·기한부근로자, 1개월 동안의 소정근로시간이 60시간 이상인 단시간근로자 등을 포함)',
		text: '18세 이상 당연취득(1개월이상 계속하여 사용하는 일용직·기한부근로자, 1개월 동안의 소정근로시간이 60시간 이상인 단시간근로자 등을 포함)',
		value:
			'1	18세 이상 당연취득(1개월이상 계속하여 사용하는 일용직·기한부근로자, 1개월 동안의 소정근로시간이 60시간 이상인 단시간근로자 등을 포함)',
	},
	{ key: '대학 강사', text: '대학 강사', value: '대학 강사' },
	{
		key: '월60시간 미만 신청 취득(사용자의 동의를 받아 근로자로 적용되기를 희망하는 경우',
		text: '월60시간 미만 신청 취득(사용자의 동의를 받아 근로자로 적용되기를 희망하는 경우',
		value:
			'월60시간 미만 신청 취득(사용자의 동의를 받아 근로자로 적용되기를 희망하는 경우',
	},
	{
		key: '일용근로자, 단시간근로자 등',
		text: '일용근로자, 단시간근로자 등',
		value: '일용근로자, 단시간근로자 등',
	},
	{ key: '18세 미만 취득', text: '18세 미만 취득', value: '18세 미만 취득' },
	{
		key: '전입(사업장 통·폐합)',
		text: '전입(사업장 통·폐합)',
		value: '전입(사업장 통·폐합)',
	},
	{
		key: '사학연금가입(국민연금 대상아님)',
		text: '사학연금가입(국민연금 대상아님)',
		value: '사학연금가입(국민연금 대상아님)',
	},
];

export const pensionIn4PublicJobType = [
	{ key: '', text: ' ', value: '' },
	{ key: '광원', text: '광원', value: '광원' },
	{ key: '부원', text: '부원', value: '부원' },
];

export const pensionIn4SpecialJobType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '직역연금(「공무원연금법」,「군인연금법」,「사립학교교직원 연금법」,「별정우체국법」에 따른 연금) 가입자',
		text: '직역연금(「공무원연금법」,「군인연금법」,「사립학교교직원 연금법」,「별정우체국법」에 따른 연금) 가입자',
		value:
			'직역연금(「공무원연금법」,「군인연금법」,「사립학교교직원 연금법」,「별정우체국법」에 따른 연금) 가입자',
	},
	{
		key: '직역연금(「공무원연금법」,「군인연금법」,「사립학교교직원 연금법」,「별정우체국법」에 따른 연금) 수급권자',
		text: '직역연금(「공무원연금법」,「군인연금법」,「사립학교교직원 연금법」,「별정우체국법」에 따른 연금) 수급권자',
		value:
			'직역연금(「공무원연금법」,「군인연금법」,「사립학교교직원 연금법」,「별정우체국법」에 따른 연금) 수급권자',
	},
];

export const healthOut4Type = [
	{ key: '', text: ' ', value: '' },
	{ key: '01', text: '퇴직', value: '01' },
	{ key: '02', text: '사망', value: '02' },
	{ key: '04', text: '의료급여수급권자', value: '04' },
	{ key: '05', text: '직장가입자로변경', value: '05' },
	{ key: '10', text: '유공자 등 건강보험 배제신청', value: '10' },
	{ key: '13', text: '그 밖의 사유(외국인 체류기간 만료 등)', value: '13' },
	{
		key: '16',
		text: '	취득취소(착오나 사정변경으로 자격취득 신고를 취소하는 경우)',
		value: '16',
	},
	{ key: '17', text: '국적상실', value: '국적상실(퇴직)' },
	{ key: '19', text: '이민출국', value: '19' },
	{ key: '24', text: '가입제외(외국의 법령)', value: '24' },
];

export const healthIn4Type = [
	{ key: '', text: ' ', value: '' },
	{ key: '01 최초취득', text: '01 최초취득', value: '01 최초취득' },
	{ key: '04	의료급여 해제', text: '04	의료급여 해제', value: '04	의료급여 해제' },
	{
		key: '05	직장가입자 변경',
		text: '05	직장가입자 변경',
		value: '05	직장가입자 변경',
	},
	{
		key: '06	직장피부양자 상실',
		text: '06	직장피부양자 상실',
		value: '06	직장피부양자 상실',
	},
	{
		key: '07	지역가입자에서 변경',
		text: '07	지역가입자에서 변경',
		value: '07	지역가입자에서 변경',
	},
	{
		key: '10	국가유공자 건강보험 적용신청',
		text: '10	국가유공자 건강보험 적용신청',
		value: '10	국가유공자 건강보험 적용신청',
	},
	{ key: '13	기타', text: '13	기타', value: '13	기타' },
	{
		key: '14	주민등록 재등록',
		text: '14	주민등록 재등록',
		value: '14	주민등록 재등록',
	},
	{ key: '17	국적취득', text: '17	국적취득', value: '17	국적취득' },
	{
		key: '42	가입자단독취득',
		text: '42	가입자단독취득',
		value: '42	가입자단독취득',
	},
	{
		key: '43	가입자동시취득피부',
		text: '43	가입자동시취득피부',
		value: '43	가입자동시취득피부',
	},
	{
		key: '59	무보수대표자 취소(직장)',
		text: '59	무보수대표자 취소(직장)',
		value: '59	무보수대표자 취소(직장)',
	},
	{
		key: '64	재외국민 주민등록',
		text: '64	재외국민 주민등록',
		value: '64	재외국민 주민등록',
	},
];

export const healthIn4DiscountType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '11	해외근무(전액)',
		text: '11	해외근무(전액)',
		value: '11	해외근무(전액)',
	},
	{
		key: '12	해외근무(반액)',
		text: '12	해외근무(반액)',
		value: '12	해외근무(반액)',
	},
	{
		key: '13	단기해외근무(전액)',
		text: '13	단기해외근무(전액)',
		value: '13	단기해외근무(전액)',
	},
	{
		key: '14	단기해외근무(반액)',
		text: '14	단기해외근무(반액)',
		value: '14	단기해외근무(반액)',
	},
	{ key: '21	군입대', text: '21	군입대', value: '21	군입대' },
	{
		key: '22	상근예비역(훈련)',
		text: '22	상근예비역(훈련)',
		value: '22	상근예비역(훈련)',
	},
	{
		key: '23	공중보건의(예비역훈련)',
		text: '23	공중보건의(예비역훈련)',
		value: '23	공중보건의(예비역훈련)',
	},
	{ key: '31	특수시설수용', text: '31	특수시설수용', value: '31	특수시설수용' },
	{
		key: '41	도서벽지(사업장)',
		text: '41	도서벽지(사업장)',
		value: '41	도서벽지(사업장)',
	},
	{
		key: '42	도서벽지(거주지)',
		text: '42	도서벽지(거주지)',
		value: '42	도서벽지(거주지)',
	},
	{
		key: '43	도서벽지(파견지)',
		text: '43	도서벽지(파견지)',
		value: '43	도서벽지(파견지)',
	},
	{
		key: '98	감면해제(도서벽지)',
		text: '98	감면해제(도서벽지)',
		value: '98	감면해제(도서벽지)',
	},
	{
		key: '99	감면해제(도서벽지제외)',
		text: '99	감면해제(도서벽지제외)',
		value: '99	감면해제(도서벽지제외)',
	},
];

export const noJobPayInMainJobType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '0',
		text: '경영ㆍ사무ㆍ금융ㆍ보험직',
		value: '0.경영ㆍ사무ㆍ금융ㆍ보험직',
	},
	{
		key: '1',
		text: '연구직 및 공학 기술직',
		value: '1. 연구직 및 공학 기술직',
	},
	{
		key: '2',
		text: '교육ㆍ법률ㆍ사회복지ㆍ경찰ㆍ소방직 및 군인',
		value: '2. 교육ㆍ법률ㆍ사회복지ㆍ경찰ㆍ소방직 및 군인',
	},
	{ key: '3', text: '보건ㆍ의료직', value: '3. 보건ㆍ의료직' },
	{
		key: '4',
		text: '예술ㆍ디자인ㆍ방송ㆍ스포츠직',
		value: '4. 예술ㆍ디자인ㆍ방송ㆍ스포츠직',
	},
	{
		key: '5',
		text: '미용ㆍ여행ㆍ숙박ㆍ음식ㆍ경비ㆍ청소직',
		value: '5. 미용ㆍ여행ㆍ숙박ㆍ음식ㆍ경비ㆍ청소직',
	},
	{
		key: '6',
		text: '영업ㆍ판매ㆍ운전ㆍ운송직',
		value: '6. 영업ㆍ판매ㆍ운전ㆍ운송직',
	},
	{ key: '7', text: '건설ㆍ채굴직', value: '7. 건설ㆍ채굴직' },
	{ key: '8', text: '설치ㆍ정비ㆍ생산직', value: '8. 설치ㆍ정비ㆍ생산직' },
	{ key: '9', text: '농림어업직', value: '9. 농림어업직' },
];

export const noJobPayInSubJobType = [
	{ key: '', text: ' ', value: '' },
	{ key: '01', text: '관리직(임원ㆍ부서장)', value: '01.관리직(임원ㆍ부서장)' },
	{ key: '02', text: '경영ㆍ행정ㆍ사무직', value: '02. 경영ㆍ행정ㆍ사무직' },
	{ key: '03', text: '금융ㆍ보험직', value: '03. 금융ㆍ보험직' },
	{
		key: '11',
		text: '인문ㆍ사회과학 연구직',
		value: '11. 인문ㆍ사회과학 연구직',
	},
	{
		key: '12',
		text: '자연ㆍ생명과학 연구직',
		value: '12. 자연ㆍ생명과학 연구직',
	},
	{
		key: '13',
		text: '정보통신 연구개발직 및 공학기술직',
		value: '13. 정보통신 연구개발직 및 공학기술직',
	},
	{
		key: '14',
		text: '건설ㆍ채굴 연구개발직 및 공학 기술직',
		value: '14. 건설ㆍ채굴 연구개발직 및 공학 기술직',
	},
	{
		key: '15',
		text: '제조 연구개발직 및 공학기술직',
		value: '15. 제조 연구개발직 및 공학기술직',
	},
	{ key: '21', text: '교육직', value: '21. 교육직' },
	{ key: '22', text: '법률직', value: '22. 법률직' },
	{ key: '23', text: '사회복지ㆍ종교직', value: '23. 사회복지ㆍ종교직' },
	{ key: '24', text: '경찰ㆍ소방ㆍ교도직', value: '24. 경찰ㆍ소방ㆍ교도직' },
	{ key: '25', text: '군인', value: '25. 군인' },
	{ key: '30', text: '보건의료직', value: '30. 보건의료직' },
	{
		key: '41',
		text: '예술ㆍ디자인ㆍ방송직',
		value: '41. 예술ㆍ디자인ㆍ방송직',
	},
	{
		key: '42',
		text: '스포츠ㆍ레크리에이션직',
		value: '42. 스포츠ㆍ레크리에이션직',
	},
	{ key: '51', text: '미용ㆍ예식 서비스직', value: '51. 미용ㆍ예식 서비스직' },
	{
		key: '52',
		text: '여행ㆍ숙박ㆍ오락 서비스직',
		value: '52. 여행ㆍ숙박ㆍ오락 서비스직',
	},
	{ key: '53', text: '음식 서비스직', value: '53. 음식 서비스직' },
	{ key: '54', text: '경호ㆍ경비직', value: '54. 경호ㆍ경비직' },
	{
		key: '55',
		text: '돌봄서비스직(간병ㆍ육아)',
		value: '55. 돌봄서비스직(간병ㆍ육아)',
	},
	{
		key: '56',
		text: '청소 및 기타 개인서비스직',
		value: '56. 청소 및 기타 개인서비스직',
	},
	{ key: '61', text: '영업ㆍ판매직', value: '61. 영업ㆍ판매직' },
	{ key: '62', text: '운전ㆍ운송직', value: '62. 운전ㆍ운송직' },
	{ key: '70', text: '건설ㆍ채굴직', value: '70. 건설ㆍ채굴직' },
	{
		key: '81',
		text: '기계 설치ㆍ정비ㆍ생산직',
		value: '81. 기계 설치ㆍ정비ㆍ생산직',
	},
	{
		key: '82',
		text: '금속ㆍ재료ㆍ설치ㆍ정비ㆍ생산직(판금ㆍ단조ㆍ주조ㆍ용접ㆍ도장 등)',
		value:
			'82. 금속ㆍ재료ㆍ설치ㆍ정비ㆍ생산직(판금ㆍ단조ㆍ주조ㆍ용접ㆍ도장 등)',
	},
	{
		key: '83',
		text: '전기ㆍ전자 설치ㆍ정비ㆍ생산직',
		value: '83. 전기ㆍ전자 설치ㆍ정비ㆍ생산직',
	},
	{
		key: '84',
		text: '정보통신 설치ㆍ정비직',
		value: '84. 정보통신 설치ㆍ정비직',
	},
	{
		key: '85',
		text: '화학ㆍ환경 설치ㆍ정비ㆍ생산직',
		value: '85. 화학ㆍ환경 설치ㆍ정비ㆍ생산직',
	},
	{ key: '86', text: '섬유ㆍ의복생산직', value: '86. 섬유ㆍ의복생산직' },
	{ key: '87', text: '식품가공ㆍ생산직', value: '87. 식품가공ㆍ생산직' },
	{
		key: '88',
		text: '인쇄ㆍ목재ㆍ공예 및 기타설치ㆍ정비ㆍ생산직',
		value: '88. 인쇄ㆍ목재ㆍ공예 및 기타설치ㆍ정비ㆍ생산직',
	},
	{ key: '89', text: '제조 단순직', value: '89. 제조 단순직' },
	{ key: '90', text: '농림어업직', value: '90. 농림어업직' },
];

export const noJobPayInDetailJobType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '011',
		text: '의회의원ㆍ고위공무원 및 기업 고위임원',
		value: '011 의회의원ㆍ고위공무원 및 기업 고위임원',
	},
	{
		key: '012',
		text: '행정ㆍ경영ㆍ금융ㆍ보험 관리자',
		value: '012 행정ㆍ경영ㆍ금융ㆍ보험 관리자　',
	},
	{ key: '013', text: '전문서비스 관리자', value: '013 전문서비스 관리자' },
	{
		key: '014',
		text: '미용ㆍ여행ㆍ숙박ㆍ음식ㆍ경비ㆍ청소 관리자',
		value: '014 미용ㆍ여행ㆍ숙박ㆍ음식ㆍ경비ㆍ청소 관리자',
	},
	{
		key: '015',
		text: '영업ㆍ판매ㆍ운송 관리자',
		value: '015 영업ㆍ판매ㆍ운송 관리자',
	},
	{
		key: '016',
		text: '건설ㆍ채굴ㆍ제조ㆍ생산 관리자',
		value: '016 건설ㆍ채굴ㆍ제조ㆍ생산 관리자',
	},
	{
		key: '021',
		text: '정부ㆍ공공행정 전문가',
		value: '021 정부ㆍ공공행정 전문가',
	},
	{ key: '022', text: '경영ㆍ인사 전문가', value: '022 경영ㆍ인사 전문가' },
	{
		key: '023',
		text: '회계ㆍ세무ㆍ감정 전문가',
		value: '023 회계ㆍ세무ㆍ감정 전문가',
	},
	{
		key: '024',
		text: '광고ㆍ조사ㆍ상품기획ㆍ행사기획 전문가',
		value: '024 광고ㆍ조사ㆍ상품기획ㆍ행사기획 전문가',
	},
	{
		key: '025',
		text: '정부ㆍ공공 행정 사무원',
		value: '025 정부ㆍ공공 행정 사무원',
	},
	{ key: '026', text: '경영지원 사무원', value: '026 경영지원 사무원' },
	{ key: '027', text: '회계ㆍ경리 사무원', value: '027 회계ㆍ경리 사무원' },
	{
		key: '028',
		text: '무역ㆍ운송ㆍ생산ㆍ품질 사무원',
		value: '028 무역ㆍ운송ㆍ생산ㆍ품질 사무원',
	},
	{
		key: '029',
		text: '안내ㆍ고객상담ㆍ통계ㆍ비서ㆍ사무보조 및 기타 사무원',
		value: '029 안내ㆍ고객상담ㆍ통계ㆍ비서ㆍ사무보조 및 기타 사무원',
	},
	{ key: '031', text: '금융ㆍ보험 전문가', value: '031 금융ㆍ보험 전문가' },
	{ key: '032', text: '금융ㆍ보험 사무원', value: '032 금융ㆍ보험 사무원' },
	{ key: '033', text: '금융ㆍ보험 영업원', value: '033 금융ㆍ보험 영업원' },
	{
		key: '110',
		text: '인문ㆍ사회과학 연구원',
		value: '110 인문ㆍ사회과학 연구원',
	},
	{
		key: '121',
		text: '자연과학 연구원 및 시험원',
		value: '121 자연과학 연구원 및 시험원',
	},
	{
		key: '122',
		text: '생명과학 연구원 및 시험원',
		value: '122 생명과학 연구원 및 시험원',
	},
	{
		key: '131',
		text: '컴퓨터하드웨어ㆍ통신공학 기술자',
		value: '131 컴퓨터하드웨어ㆍ통신공학 기술자',
	},
	{ key: '132', text: '컴퓨터시스템 전문가', value: '132 컴퓨터시스템 전문가' },
	{ key: '133', text: '소프트웨어 개발자', value: '133 소프트웨어 개발자' },
	{
		key: '134',
		text: '데이터ㆍ네트워크 및 시스템 운영 전문가',
		value: '134 데이터ㆍ네트워크 및 시스템 운영 전문가',
	},
	{ key: '135', text: '정보보안 전문가', value: '135 정보보안 전문가' },
	{
		key: '136',
		text: '통신ㆍ방송송출 장비 기사',
		value: '136 통신ㆍ방송송출 장비 기사',
	},
	{
		key: '140',
		text: '건축ㆍ토목공학 기술자 및 시험원',
		value: '140 건축ㆍ토목공학 기술자 및 시험원',
	},
	{
		key: '151',
		text: '기계ㆍ로봇공학 기술자 및 시험원',
		value: '151 기계ㆍ로봇공학 기술자 및 시험원',
	},
	{
		key: '152',
		text: '금속ㆍ재료공학 기술자 및 시험원',
		value: '152 금속ㆍ재료공학 기술자 및 시험원',
	},
	{
		key: '153',
		text: '전기ㆍ전자공학 기술자 및 시험원',
		value: '153 전기ㆍ전자공학 기술자 및 시험원',
	},
	{
		key: '154',
		text: '화학공학 기술자 및 시험원',
		value: '154 화학공학 기술자 및 시험원',
	},
	{
		key: '155',
		text: '에너지ㆍ환경공학 기술자 및 시험원',
		value: '155 에너지ㆍ환경공학 기술자 및 시험원',
	},
	{
		key: '156',
		text: '섬유공학 기술자 및 시험원',
		value: '156 섬유공학 기술자 및 시험원',
	},
	{
		key: '157',
		text: '식품공학 기술자 및 시험원',
		value: '157 식품공학 기술자 및 시험원',
	},
	{
		key: '158',
		text: '소방ㆍ방재ㆍ산업안전ㆍ비파괴 기술자',
		value: '158 소방ㆍ방재ㆍ산업안전ㆍ비파괴 기술자',
	},
	{
		key: '159',
		text: '제도사 및 기타 인쇄ㆍ목재 등 공학 기술자 및 시험원',
		value: '159 제도사 및 기타 인쇄ㆍ목재 등 공학 기술자 및 시험원',
	},
	{ key: '211', text: '대학 교수 및 강사', value: '211 대학 교수 및 강사' },
	{ key: '212', text: '학교 교사', value: '212 학교 교사' },
	{ key: '213', text: '유치원 교사', value: '213 유치원 교사' },
	{
		key: '214',
		text: '문리ㆍ기술ㆍ예능 강사',
		value: '214 문리ㆍ기술ㆍ예능 강사',
	},
	{
		key: '215',
		text: '장학관 및 기타 교육 종사자',
		value: '215 장학관 및 기타 교육 종사자',
	},
	{ key: '221', text: '법률전문가', value: '221 법률전문가' },
	{ key: '222', text: '법률사무원', value: '222 법률사무원' },
	{
		key: '231',
		text: '사회복지사 및 상담사',
		value: '231 사회복지사 및 상담사',
	},
	{
		key: '232',
		text: '보육교사 및 기타사회복지 종사자',
		value: '232 보육교사 및 기타사회복지 종사자',
	},
	{
		key: '233',
		text: '성직자 및 기타 종교 종사자',
		value: '233 성직자 및 기타 종교 종사자',
	},
	{
		key: '240',
		text: '경찰관, 소방관 및 교도관',
		value: '240 경찰관, 소방관 및 교도관',
	},
	{ key: '250', text: '군인', value: '250 군인' },
	{
		key: '301',
		text: '의사, 한의사 및 치과의사',
		value: '301 의사, 한의사 및 치과의사',
	},
	{ key: '302', text: '수의사', value: '302 수의사' },
	{ key: '303', text: '약사 및 한약사', value: '303 약사 및 한약사' },
	{ key: '304', text: '간호사', value: '304 간호사' },
	{ key: '305', text: '영양사', value: '305 영양사' },
	{
		key: '306',
		text: '의료기사ㆍ치료사ㆍ재활사',
		value: '306 의료기사ㆍ치료사ㆍ재활사',
	},
	{ key: '307', text: '보건ㆍ의료 종사자', value: '307 보건ㆍ의료 종사자' },
	{ key: '411', text: '작가ㆍ통번역가', value: '411 작가ㆍ통번역가' },
	{ key: '412', text: '기자 및 언론 전문가', value: '412 기자 및 언론 전문가' },
	{
		key: '413',
		text: '학예사ㆍ사서ㆍ기록물관리사',
		value: '413 학예사ㆍ사서ㆍ기록물관리사',
	},
	{
		key: '414',
		text: '창작ㆍ공연 전문가(작가, 연극 제외)',
		value: '414 창작ㆍ공연 전문가(작가, 연극 제외)',
	},
	{ key: '415', text: '디자이너', value: '415 디자이너' },
	{
		key: '416',
		text: '연극ㆍ영화ㆍ방송 전문가',
		value: '416 연극ㆍ영화ㆍ방송 전문가',
	},
	{
		key: '417',
		text: '문화ㆍ예술 기획자 및 매니저',
		value: '417 문화ㆍ예술 기획자 및 매니저',
	},
	{
		key: '420',
		text: '스포츠ㆍ레크리에이션 종사자',
		value: '420 스포츠ㆍ레크리에이션 종사자',
	},
	{ key: '511', text: '미용 서비스원', value: '511 미용 서비스원' },
	{
		key: '512',
		text: '결혼ㆍ장례 등 예식 서비스원',
		value: '512 결혼ㆍ장례 등 예식 서비스원',
	},
	{ key: '521', text: '여행 서비스원', value: '521 여행 서비스원' },
	{
		key: '522',
		text: '항공기ㆍ선박ㆍ열차 객실승무원',
		value: '522 항공기ㆍ선박ㆍ열차 객실승무원',
	},
	{ key: '523', text: '숙박시설 서비스원', value: '523 숙박시설 서비스원' },
	{ key: '524', text: '오락시설 서비스원', value: '524 오락시설 서비스원' },
	{ key: '531', text: '주방장 및 조리사', value: '531 주방장 및 조리사' },
	{ key: '532', text: '식당 서비스원', value: '532 식당 서비스원' },
	{ key: '541', text: '경호ㆍ보안 종사자', value: '541 경호ㆍ보안 종사자' },
	{ key: '542', text: '경비원', value: '542 경비원' },
	{ key: '550', text: '돌봄 서비스 종사자', value: '550 돌봄 서비스 종사자' },
	{
		key: '561',
		text: '청소ㆍ방역 및 가사 서비스원',
		value: '561 청소ㆍ방역 및 가사 서비스원',
	},
	{
		key: '562',
		text: '검침ㆍ주차관리 및 기타 서비스 단순 종사자',
		value: '562 검침ㆍ주차관리 및 기타 서비스 단순 종사자',
	},
	{
		key: '611',
		text: '부동산 컨설턴트 및 중개인',
		value: '611 부동산 컨설턴트 및 중개인',
	},
	{
		key: '612',
		text: '영업원 및 상품중개인',
		value: '612 영업원 및 상품중개인',
	},
	{ key: '613', text: '텔레마케터', value: '613 텔레마케터' },
	{
		key: '614',
		text: '소규모 상점 경영 및 일선 관리 종사자',
		value: '614 소규모 상점 경영 및 일선 관리 종사자',
	},
	{ key: '615', text: '판매 종사자', value: '615 판매 종사자' },
	{
		key: '616',
		text: '매장 계산원 및 매표원',
		value: '616 매장 계산원 및 매표원',
	},
	{
		key: '617',
		text: '판촉 및 기타 판매 단순 종사자',
		value: '617 판촉 및 기타 판매 단순 종사자',
	},
	{
		key: '621',
		text: '항공기ㆍ선박ㆍ철도 조종사 및 관제사',
		value: '621 항공기ㆍ선박ㆍ철도 조종사 및 관제사',
	},
	{ key: '622', text: '자동차 운전원', value: '622 자동차 운전원' },
	{
		key: '623',
		text: '물품이동장비 조작원(크레인ㆍ호이스트ㆍ지게차)',
		value: '623 물품이동장비 조작원(크레인ㆍ호이스트ㆍ지게차)',
	},
	{
		key: '624',
		text: '택배원 및 기타 운송 종사자',
		value: '624 택배원 및 기타 운송 종사자',
	},
	{ key: '701', text: '건설구조 기능원', value: '701 건설구조 기능원' },
	{ key: '702', text: '건축마감 기능원', value: '702 건축마감 기능원' },
	{ key: '703', text: '배관공', value: '703 배관공' },
	{
		key: '704',
		text: '건설ㆍ채굴 기계 운전원',
		value: '704 건설ㆍ채굴 기계 운전원',
	},
	{
		key: '705',
		text: '기타 건설 기능원(채굴포함)',
		value: '705 기타 건설 기능원(채굴포함)',
	},
	{
		key: '706',
		text: '건설ㆍ채굴 단순 종사자',
		value: '706 건설ㆍ채굴 단순 종사자',
	},
	{
		key: '811',
		text: '기계정비 설치ㆍ정비원(운송장비 제외)',
		value: '811 기계정비 설치ㆍ정비원(운송장비 제외)',
	},
	{ key: '812', text: '운송장비 정비원', value: '812 운송장비 정비원' },
	{
		key: '813',
		text: '금형원 및 공작기계 조작원',
		value: '813 금형원 및 공작기계 조작원',
	},
	{
		key: '814',
		text: '냉ㆍ난방 설비 조작원',
		value: '814 냉ㆍ난방 설비 조작원',
	},
	{
		key: '815',
		text: '자동조립라인ㆍ산업용로봇 조작원',
		value: '815 자동조립라인ㆍ산업용로봇 조작원',
	},
	{
		key: '816',
		text: '기계 조립원(운송장비 제외)',
		value: '816 기계 조립원(운송장비 제외)',
	},
	{ key: '817', text: '운송장비 조립원', value: '817 운송장비 조립원' },
	{
		key: '821',
		text: '금속관련 기계ㆍ설비 조작원',
		value: '821 금속관련 기계ㆍ설비 조작원',
	},
	{ key: '822', text: '판금원 및 제관원', value: '822 판금원 및 제관원' },
	{ key: '823', text: '단조원 및 주조원', value: '823 단조원 및 주조원' },
	{ key: '824', text: '용접원', value: '824 용접원' },
	{ key: '825', text: '도장원 및 도금원', value: '825 도장원 및 도금원' },
	{
		key: '826',
		text: '비금속제품 생산기계 조작원',
		value: '826 비금속제품 생산기계 조작원',
	},
	{ key: '831', text: '전기공', value: '831 전기공' },
	{
		key: '832',
		text: '전기ㆍ전자 기기 설치ㆍ수리원',
		value: '832 전기ㆍ전자 기기 설치ㆍ수리원',
	},
	{
		key: '833',
		text: '발전ㆍ배전 장치 조작원',
		value: '833 발전ㆍ배전 장치 조작원',
	},
	{
		key: '834',
		text: '전기ㆍ전자 설비 조작원',
		value: '834 전기ㆍ전자 설비 조작원',
	},
	{
		key: '835',
		text: '전기ㆍ전자 부품ㆍ제품 생산기계 조작원',
		value: '835 전기ㆍ전자 부품ㆍ제품 생산기계 조작원',
	},
	{
		key: '836',
		text: '전기ㆍ전자 부품ㆍ제품 조립원',
		value: '836 전기ㆍ전자 부품ㆍ제품 조립원',
	},
	{
		key: '841',
		text: '정보통신기기 설치ㆍ수리원',
		value: '841 정보통신기기 설치ㆍ수리원',
	},
	{
		key: '842',
		text: '방송ㆍ통신장비 설치ㆍ수리원',
		value: '842 방송ㆍ통신장비 설치ㆍ수리원',
	},
	{
		key: '851',
		text: '석유ㆍ화학물 가공장치 조작원',
		value: '851 석유ㆍ화학물 가공장치 조작원',
	},
	{
		key: '852',
		text: '고무ㆍ플라스틱 및 화학제품 생산기계 조작원 및 조립원',
		value: '852 고무ㆍ플라스틱 및 화학제품 생산기계 조작원 및 조립원',
	},
	{
		key: '853',
		text: '환경관련 장치 조작원',
		value: '853 환경관련 장치 조작원',
	},
	{
		key: '861',
		text: '섬유 제조ㆍ가공 기계 조작원',
		value: '861 섬유 제조ㆍ가공 기계 조작원',
	},
	{
		key: '862',
		text: '패턴사, 재단사 및 재봉사',
		value: '862 패턴사, 재단사 및 재봉사',
	},
	{
		key: '863',
		text: '의복 제조원 및 수선원',
		value: '863 의복 제조원 및 수선원',
	},
	{
		key: '864',
		text: '제화원, 기타 섬유ㆍ의복 기계 조작원 및 조립원',
		value: '864 제화원, 기타 섬유ㆍ의복 기계 조작원 및 조립원',
	},
	{
		key: '871',
		text: '제과ㆍ제빵원 및 떡제조원',
		value: '871 제과ㆍ제빵원 및 떡제조원',
	},
	{ key: '872', text: '식품 가공 기능원', value: '872 식품 가공 기능원' },
	{
		key: '873',
		text: '식품 가공 기계 조작원',
		value: '873 식품 가공 기계 조작원',
	},
	{
		key: '881',
		text: '인쇄기계ㆍ사진현상기 조작원',
		value: '881 인쇄기계ㆍ사진현상기 조작원',
	},
	{
		key: '882',
		text: '목재ㆍ펄프ㆍ종이 생산기계 조작원',
		value: '882 목재ㆍ펄프ㆍ종이 생산기계 조작원',
	},
	{
		key: '883',
		text: '가구ㆍ목제품 제조ㆍ수리원',
		value: '883 가구ㆍ목제품 제조ㆍ수리원',
	},
	{
		key: '884',
		text: '공예원 및 귀금속세공원',
		value: '884 공예원 및 귀금속세공원',
	},
	{
		key: '885',
		text: '악기ㆍ간판 및 기타 제조 종사자',
		value: '885 악기ㆍ간판 및 기타 제조 종사자',
	},
	{ key: '890', text: '제조 단순 종사자', value: '890 제조 단순 종사자' },
	{ key: '901', text: '작물재배 종사자', value: '901 작물재배 종사자' },
	{ key: '902', text: '낙농ㆍ사육 종사자', value: '902 낙농ㆍ사육 종사자' },
	{ key: '903', text: '임업 종사자', value: '903 임업 종사자' },
	{ key: '904', text: '어업 종사자', value: '904 어업 종사자' },
	{
		key: '905',
		text: '농림어업 단순 종사자',
		value: '905 농림어업 단순 종사자',
	},
];

export const noJobSanjePayCode = [
	{ key: '', text: ' ', value: '' },
	{ key: '51', text: '산재, 임채 부과', value: '51' },
	{ key: '52', text: '산재 부과', value: '52' },
	{ key: '54', text: '산재, 실업급여, 고안직능 부과', value: '54' },
	{ key: '55', text: '실업급여, 고안직능 부과', value: '55' },
	{ key: '56', text: '실업급여 부과', value: '56' },
	{ key: '57', text: '산재, 실업급여 부과', value: '57' },
	{ key: '58', text: '산재, 고안직능 부과', value: '58' },
	{ key: '60', text: '산재, 임채, 고안직능 부과', value: '60' },
];

export const retiredPaperType = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'selfResignitionWithPaper',
		text: '사직서 징구',
		value: '사직서 징구',
	},
	{
		key: 'selfResignitionWithoutPaper',
		text: '사직서 미징구',
		value: '사직서 미징구',
	},
];

export const firedPaperType = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'fireWithPaper',
		text: '해고장(사유,시기명기) 교부',
		value: '해고장(사유,시기명기) 교부',
	},
	{ key: 'fierWithoutPaper', text: '해고장 미교부', value: '해고장 미교부' },
];

export const noticeFireType = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'noticeFireNo',
		text: '해고예고도 수당도 지급 안함.',
		value: '해고예고 안함',
	},
	{
		key: 'noticeFireYes',
		text: '해고 예고한 기간이 1개월을 초과함',
		value: '1개월전 해고예고',
	},
	{
		key: 'noticeFireYesLess1Month',
		text: '해고 예고한 기간이 1개월에 미달함',
		value: '1개월전 해고예고',
	},
	{
		key: 'noticeFireWage',
		text: '해고 예고 수당 1개월분의 통상임금 지급',
		value: '해고예고 수당지급',
	},
	{
		key: 'noticeFireWageLess1Month',
		text: '해고 예고 수당 1개월분에 미달하는 통상임금 지급',
		value: '해고예고 수당지급',
	},
];

export const noticeFireExceptionType = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'under3Months',
		text: '0. 근로자가 계속 근로한 기간이 3개월 미만인 경우',
		value: '근속3개월미만자',
	},
	{
		key: 'stopBiz',
		text: '1. 천재사변, 그 밖의 부득이한 사유로 사업을 계속하는 것이 불가능한 경우',
		value: '사업불능',
	},
	{
		key: 'backPay',
		text: '2. 납품업체로부터 금품이나 향응을 받고 불량품을 납품받아 생산에 차질을 가져온 경우',
		value: '금품수수 생산차질',
	},
	{
		key: 'carAccident',
		text: '3. 영업용 차량을 임의로 타인에게 대리운전하게 하여 교통사고를 일으킨 경우',
		value: '대리운전 사고',
	},
	{
		key: 'secretOpen',
		text: '4. 사업의 기밀이나 그 밖의 정보를 경쟁관계에 있는 다른 사업자 등에게 제공하여 사업에 지장을 가져온 경우',
		value: '기밀누설',
	},
	{
		key: 'illigalGroupAction',
		text: '5. 허위 사실을 날조하여 유포하거나 불법 집단행동을 주도하여 사업에 막대한 지장을 가져온 경우',
		value: '불법 집단행동주도 사업방해',
	},
	{
		key: 'stealMoney',
		text: '6. 영업용 차량 운송 수입금을 부당하게 착복하는 등 직책을 이용하여 공금을 착복, 장기유용, 횡령 또는 배임한 경우 ',
		value: '횡령',
	},
	{
		key: 'stealStuff',
		text: '7. 제품 또는 원료 등을 몰래 훔치거나 불법 반출한 경우',
		value: '절도',
	},
	{
		key: 'fakeDocument',
		text: '8. 인사ㆍ경리ㆍ회계담당 직원이 근로자의 근무상황 실적을 조작하거나 허위 서류 등을 작성하여 사업에 손해를 끼친 경우',
		value: '문서위조 손해',
	},
	{
		key: 'destroy',
		text: '9. 사업장의 기물을 고의로 파손하여 생산에 막대한 지장을 가져온 경우',
		value: '고의파손 손해',
	},
	{
		key: 'etc',
		text: '10. 그 밖에 사회통념상 고의로 사업에 막대한 지장을 가져오거나 재산상 손해를 끼쳤다고 인정되는 경우',
		value: '기타 사회통념',
	},
	{
		key: 'noException',
		text: '11. 위 0~10번 사유에 해당하지 않음',
		value: '해당사항 없음',
	},
];

export const noJobPayYesOrNo = [
	{ key: '', text: ' ', value: '' },
	{ key: 'noJobPayYes', text: '1. 실업급여 신청', value: '1. 실업급여 신청' },
	{
		key: 'noJobPayNo',
		text: '2. 실업급여 미신청',
		value: '2. 실업급여 미신청',
	},
];

export const noJobPayYesTypeOption = [
	{ key: '', text: ' ', value: '' },
	{
		key: '1.1. (퇴사 전 1년간 2달이상) 실제 근로조건이 채용 시 제시된 근로조건이나 채용 후 일반적으로 적용받던 근로조건보다 낮아지게 된 경우',
		text: '1.1. (퇴사 전 1년간 2달이상) 실제 근로조건이 채용 시 제시된 근로조건이나 채용 후 일반적으로 적용받던 근로조건보다 낮아지게 된 경우',
		value:
			'1.1. (퇴사 전 1년간 2달이상) 실제 근로조건이 채용 시 제시된 근로조건이나 채용 후 일반적으로 적용받던 근로조건보다 낮아지게 된 경우',
	},
	{
		key: '1.2. (퇴사 전 1년간 2달이상) 임금체불이 있는 경우',
		text: '1.2. (퇴사 전 1년간 2달이상) 임금체불이 있는 경우',
		value: '1.2. (퇴사 전 1년간 2달이상) 임금체불이 있는 경우',
	},
	{
		key: '1.3. (퇴사 전 1년간 2달이상) 소정근로에 대하여 지급받은 임금이 「최저임금법」에 따른 최저임금에 미달하게 된 경우',
		text: '1.3. (퇴사 전 1년간 2달이상) 소정근로에 대하여 지급받은 임금이 「최저임금법」에 따른 최저임금에 미달하게 된 경우',
		value:
			'1.3. (퇴사 전 1년간 2달이상) 소정근로에 대하여 지급받은 임금이 「최저임금법」에 따른 최저임금에 미달하게 된 경우',
	},
	{
		key: '1.4. (퇴사 전 1년간 2달이상)「근로기준법」 제53조에 따른 연장 근로의 제한을 위반한 경우',
		text: '1.4. (퇴사 전 1년간 2달이상)「근로기준법」 제53조에 따른 연장 근로의 제한을 위반한 경우',
		value:
			'1.4. (퇴사 전 1년간 2달이상)「근로기준법」 제53조에 따른 연장 근로의 제한을 위반한 경우',
	},
	{
		key: '1.5. (퇴사 전 1년간 2달이상) 사업장의 휴업으로 휴업 전 평균임금의 70퍼센트 미만을 지급받은 경우',
		text: '1.5. (퇴사 전 1년간 2달이상) 사업장의 휴업으로 휴업 전 평균임금의 70퍼센트 미만을 지급받은 경우',
		value:
			'1.5. (퇴사 전 1년간 2달이상) 사업장의 휴업으로 휴업 전 평균임금의 70퍼센트 미만을 지급받은 경우',
	},
	{
		key: '1.6. 사업장에서 종교, 성별, 신체장애, 노조활동 등을 이유로 불합리한 차별대우를 받은 경우',
		text: '1.6. 사업장에서 종교, 성별, 신체장애, 노조활동 등을 이유로 불합리한 차별대우를 받은 경우',
		value:
			'1.6. 사업장에서 종교, 성별, 신체장애, 노조활동 등을 이유로 불합리한 차별대우를 받은 경우',
	},
	{
		key: '1.7. 사업장에서 본인의 의사에 반하여 성희롱, 성폭력, 그 밖의 성적인 괴롭힘을 당한 경우',
		text: '1.7. 사업장에서 본인의 의사에 반하여 성희롱, 성폭력, 그 밖의 성적인 괴롭힘을 당한 경우',
		value:
			'1.7. 사업장에서 본인의 의사에 반하여 성희롱, 성폭력, 그 밖의 성적인 괴롭힘을 당한 경우',
	},
	{
		key: '1.8. 「근로기준법」 제76조의2에 따른 직장 내 괴롭힘을 당한 경우',
		text: '1.8. 「근로기준법」 제76조의2에 따른 직장 내 괴롭힘을 당한 경우',
		value: '1.8. 「근로기준법」 제76조의2에 따른 직장 내 괴롭힘을 당한 경우',
	},
	{
		key: '1.9.  (퇴직권유 및 희망퇴직) 사업의 양도·인수·합병',
		text: '1.9. (퇴직권유 및 희망퇴직) 사업의 양도·인수·합병',
		value: '1.9.  (퇴직권유 및 희망퇴직) 사업의 양도·인수·합병',
	},
	{
		key: '1.10. (퇴직권유 및 희망퇴직) 일부 사업의 폐지나 업종전환',
		text: '1.10. (퇴직권유 및 희망퇴직) 일부 사업의 폐지나 업종전환',
		value: '1.10. (퇴직권유 및 희망퇴직) 일부 사업의 폐지나 업종전환',
	},
	{
		key: '1.11. (퇴직권유 및 희망퇴직) 직제개편에 따른 조직의 폐지·축소',
		text: '1.11. (퇴직권유 및 희망퇴직) 직제개편에 따른 조직의 폐지·축소',
		value: '1.11. (퇴직권유 및 희망퇴직) 직제개편에 따른 조직의 폐지·축소',
	},
	{
		key: '1.12. (퇴직권유 및 희망퇴직) 신기술의 도입, 기술혁신 등에 따른 작업형태의 변경',
		text: '1.12. (퇴직권유 및 희망퇴직) 신기술의 도입, 기술혁신 등에 따른 작업형태의 변경',
		value:
			'1.12. (퇴직권유 및 희망퇴직) 신기술의 도입, 기술혁신 등에 따른 작업형태의 변경',
	},
	{
		key: '1.13. (퇴직권유 및 희망퇴직) 경영의 악화, 인사 적체, 그 밖에 이에 준하는 사유가 발생한 경우',
		text: '1.13. (퇴직권유 및 희망퇴직) 경영의 악화, 인사 적체, 그 밖에 이에 준하는 사유가 발생한 경우',
		value:
			'1.13. (퇴직권유 및 희망퇴직) 경영의 악화, 인사 적체, 그 밖에 이에 준하는 사유가 발생한 경우',
	},
	{
		key: '1.14. (통상적인 출퇴근 3시간 이상 소요) 사업장의 이전',
		text: '1.14. (통상적인 출퇴근 3시간 이상 소요) 사업장의 이전',
		value: '1.14. (통상적인 출퇴근 3시간 이상 소요) 사업장의 이전',
	},
	{
		key: '1.15. (통상적인 출퇴근 3시간 이상 소요) 지역을 달리하는 사업장으로의 전근',
		text: '1.15. (통상적인 출퇴근 3시간 이상 소요) 지역을 달리하는 사업장으로의 전근',
		value:
			'1.15. (통상적인 출퇴근 3시간 이상 소요) 지역을 달리하는 사업장으로의 전근',
	},
	{
		key: '1.16. (통상적인 출퇴근 3시간 이상 소요) 배우자나 부양하여야 할 친족과의 동거를 위한 거소 이전',
		text: '1.16. (통상적인 출퇴근 3시간 이상 소요) 배우자나 부양하여야 할 친족과의 동거를 위한 거소 이전',
		value:
			'1.16. (통상적인 출퇴근 3시간 이상 소요) 배우자나 부양하여야 할 친족과의 동거를 위한 거소 이전',
	},
	{
		key: '1.17. (통상적인 출퇴근 3시간 이상 소요) 그 밖에 피할 수 없는 사유로 통근이 곤란한 경우',
		text: '1.17. (통상적인 출퇴근 3시간 이상 소요) 그 밖에 피할 수 없는 사유로 통근이 곤란한 경우',
		value:
			'1.17. (통상적인 출퇴근 3시간 이상 소요) 그 밖에 피할 수 없는 사유로 통근이 곤란한 경우',
	},
	{
		key: '1.18. 부모나 동거 친족의 질병·부상 등으로 30일 이상 본인이 간호해야 하는 기간에 기업의 사정상 휴가나 휴직이 허용되지 않아 이직한 경우',
		text: '1.18. 부모나 동거 친족의 질병·부상 등으로 30일 이상 본인이 간호해야 하는 기간에 기업의 사정상 휴가나 휴직이 허용되지 않아 이직한 경우',
		value:
			'1.18. 부모나 동거 친족의 질병·부상 등으로 30일 이상 본인이 간호해야 하는 기간에 기업의 사정상 휴가나 휴직이 허용되지 않아 이직한 경우',
	},
	{
		key: '1.19.「산업안전보건법」 제2조제2호에 따른 "중대재해"가 발생한 사업장으로서 그 재해와 관련된 고용노동부장관의 안전보건상의 시정명령을 받고도 시정기간까지 시정하지 아니하여 같은 재해 위험에 노출된 경우',
		text: '1.19.「산업안전보건법」 제2조제2호에 따른 "중대재해"가 발생한 사업장으로서 그 재해와 관련된 고용노동부장관의 안전보건상의 시정명령을 받고도 시정기간까지 시정하지 아니하여 같은 재해 위험에 노출된 경우',
		value:
			'1.19.「산업안전보건법」 제2조제2호에 따른 "중대재해"가 발생한 사업장으로서 그 재해와 관련된 고용노동부장관의 안전보건상의 시정명령을 받고도 시정기간까지 시정하지 아니하여 같은 재해 위험에 노출된 경우',
	},
	{
		key: '1.20. 체력의 부족, 심신장애, 질병, 부상, 시력·청력·촉각의 감퇴 등으로 피보험자가 주어진 업무를 수행하는 것이 곤란하고, 기업의 사정상 업무종류의 전환이나 휴직이 허용되지 않아 이직한 것이 의사의 소견서, 사업주 의견 등에 근거하여 객관적으로 인정되는 경우',
		text: '1.20. 체력의 부족, 심신장애, 질병, 부상, 시력·청력·촉각의 감퇴 등으로 피보험자가 주어진 업무를 수행하는 것이 곤란하고, 기업의 사정상 업무종류의 전환이나 휴직이 허용되지 않아 이직한 것이 의사의 소견서, 사업주 의견 등에 근거하여 객관적으로 인정되는 경우',
		value:
			'1.20. 체력의 부족, 심신장애, 질병, 부상, 시력·청력·촉각의 감퇴 등으로 피보험자가 주어진 업무를 수행하는 것이 곤란하고, 기업의 사정상 업무종류의 전환이나 휴직이 허용되지 않아 이직한 것이 의사의 소견서, 사업주 의견 등에 근거하여 객관적으로 인정되는 경우',
	},
	{
		key: '1.21. 임신, 출산, 만 8세 이하 또는 초등학교 2학년 이하의 자녀(입양한 자녀를 포함한다)의 육아, 「병역법」에 따른 의무복무 등으로 업무를 계속적으로 수행하기 어려운 경우로서 사업주가 휴가나 휴직을 허용하지 않아 이직한 경우',
		text: '1.21. 임신, 출산, 만 8세 이하 또는 초등학교 2학년 이하의 자녀(입양한 자녀를 포함한다)의 육아, 「병역법」에 따른 의무복무 등으로 업무를 계속적으로 수행하기 어려운 경우로서 사업주가 휴가나 휴직을 허용하지 않아 이직한 경우',
		value:
			'1.21. 임신, 출산, 만 8세 이하 또는 초등학교 2학년 이하의 자녀(입양한 자녀를 포함한다)의 육아, 「병역법」에 따른 의무복무 등으로 업무를 계속적으로 수행하기 어려운 경우로서 사업주가 휴가나 휴직을 허용하지 않아 이직한 경우',
	},
	{
		key: '1.22. 사업주의 사업 내용이 법령의 제정·개정으로 위법하게 되거나 취업 당시와는 달리 법령에서 금지하는 재화 또는 용역을 제조하거나 판매하게 된 경우',
		text: '1.22. 사업주의 사업 내용이 법령의 제정·개정으로 위법하게 되거나 취업 당시와는 달리 법령에서 금지하는 재화 또는 용역을 제조하거나 판매하게 된 경우',
		value:
			'1.22. 사업주의 사업 내용이 법령의 제정·개정으로 위법하게 되거나 취업 당시와는 달리 법령에서 금지하는 재화 또는 용역을 제조하거나 판매하게 된 경우',
	},
	{
		key: '1.23. 정년의 도래나 계약기간의 만료로 회사를 계속 다닐 수 없게 된 경우',
		text: '1.23. 정년의 도래나 계약기간의 만료로 회사를 계속 다닐 수 없게 된 경우',
		value:
			'1.23. 정년의 도래나 계약기간의 만료로 회사를 계속 다닐 수 없게 된 경우',
	},
	{
		key: '1.24. 그 밖에 피보험자와 사업장 등의 사정에 비추어 그러한 여건에서는 통상의 다른 근로자도 이직했을 것이라는 사실이 객관적으로 인정되는 경우',
		text: '1.24. 그 밖에 피보험자와 사업장 등의 사정에 비추어 그러한 여건에서는 통상의 다른 근로자도 이직했을 것이라는 사실이 객관적으로 인정되는 경우',
		value:
			'1.24. 그 밖에 피보험자와 사업장 등의 사정에 비추어 그러한 여건에서는 통상의 다른 근로자도 이직했을 것이라는 사실이 객관적으로 인정되는 경우',
	},
	{
		key: '1.25. 중대한 귀책이 아닌 사유로 해고되는 경우',
		text: '1.25. 중대한 귀책이 아닌 사유로 해고되는 경우',
		value: '1.25. 중대한 귀책이 아닌 사유로 해고되는 경우',
	},
	{
		key: '1.26. 취업규칙상 징계 해고 사유에 해당되지만 회사의 권유로 사직하는 경우',
		text: '1.26. 취업규칙상 징계 해고 사유에 해당되지만 회사의 권유로 사직하는 경우',
		value:
			'1.26. 취업규칙상 징계 해고 사유에 해당되지만 회사의 권유로 사직하는 경우',
	},
	{
		key: '1.27. 업무능력 미달에 해당되어 회사의 권유로 사직하는 경우',
		text: '1.27. 업무능력 미달에 해당되어 회사의 권유로 사직하는 경우',
		value: '1.27. 업무능력 미달에 해당되어 회사의 권유로 사직하는 경우',
	},
];

export const noJobPayNoTypeOption = [
	{ key: '', text: ' ', value: '' },
	{
		key: '2.0. (사직) 전직 또는 자영업 등을 하기 위하여 자발적으로 이직한 경우',
		text: '2.0. (사직) 전직 또는 자영업 등을 하기 위하여 자발적으로 이직한 경우',
		value:
			'2.0. (사직) 전직 또는 자영업 등을 하기 위하여 자발적으로 이직한 경우',
	},
	{
		key: '2.1. (해고)「형법」 또는 직무와 관련된 법률을 위반하여 금고 이상의 형을 선고받은 경우',
		text: '2.1. (해고)「형법」 또는 직무와 관련된 법률을 위반하여 금고 이상의 형을 선고받은 경우',
		value:
			'2.1. (해고)「형법」 또는 직무와 관련된 법률을 위반하여 금고 이상의 형을 선고받은 경우',
	},
	{
		key: '2.2. (해고) 납품업체로부터 금품이나 향응을 받고 불량품을 납품받아 생산에 차질을 가져온 경우',
		text: '2.2. (해고) 납품업체로부터 금품이나 향응을 받고 불량품을 납품받아 생산에 차질을 가져온 경우',
		value:
			'2.2. (해고) 납품업체로부터 금품이나 향응을 받고 불량품을 납품받아 생산에 차질을 가져온 경우',
	},
	{
		key: '2.3. (해고) 사업의 기밀이나 그 밖의 정보를 경쟁관계에 있는 다른 사업자 등에게 제공한 경우',
		text: '2.3. (해고) 사업의 기밀이나 그 밖의 정보를 경쟁관계에 있는 다른 사업자 등에게 제공한 경우',
		value:
			'2.3. (해고) 사업의 기밀이나 그 밖의 정보를 경쟁관계에 있는 다른 사업자 등에게 제공한 경우',
	},
	{
		key: '2.4. (해고) 거짓 사실을 날조ㆍ유포하거나 불법 집단행동을 주도하여 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우',
		text: '2.4. (해고) 거짓 사실을 날조ㆍ유포하거나 불법 집단행동을 주도하여 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우',
		value:
			'2.4. (해고) 거짓 사실을 날조ㆍ유포하거나 불법 집단행동을 주도하여 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우',
	},
	{
		key: '2.5. (해고) 직책을 이용하여 공금을 착복ㆍ장기유용ㆍ횡령하거나 배임한 경우',
		text: '2.5. (해고) 직책을 이용하여 공금을 착복ㆍ장기유용ㆍ횡령하거나 배임한 경우',
		value:
			'2.5. (해고) 직책을 이용하여 공금을 착복ㆍ장기유용ㆍ횡령하거나 배임한 경우',
	},
	{
		key: '2.6. (해고) 제품이나 원료 등을 절취하거나 불법 반출한 경우',
		text: '2.6. (해고) 제품이나 원료 등을 절취하거나 불법 반출한 경우',
		value: '2.6. (해고) 제품이나 원료 등을 절취하거나 불법 반출한 경우',
	},
	{
		key: '2.7. (해고) 인사ㆍ경리ㆍ회계담당 직원이 근로자의 근무상황 실적을 조작하거나 거짓서류 등을 작성하여 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우',
		text: '2.7. (해고) 인사ㆍ경리ㆍ회계담당 직원이 근로자의 근무상황 실적을 조작하거나 거짓서류 등을 작성하여 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우',
		value:
			'2.7. (해고) 인사ㆍ경리ㆍ회계담당 직원이 근로자의 근무상황 실적을 조작하거나 거짓서류 등을 작성하여 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우',
	},
	{
		key: '2.8. (해고) 사업장의 기물을 고의로 파손하여 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우',
		text: '2.8. (해고) 사업장의 기물을 고의로 파손하여 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우',
		value:
			'2.8. (해고) 사업장의 기물을 고의로 파손하여 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우',
	},
	{
		key: '2.9. (해고) 영업용 차량을 사업주의 위임이나 동의 없이 다른 사람에게 대리운전하게 하여 교통사고를 일으킨 경우',
		text: '2.9. (해고) 영업용 차량을 사업주의 위임이나 동의 없이 다른 사람에게 대리운전하게 하여 교통사고를 일으킨 경우',
		value:
			'2.9. (해고) 영업용 차량을 사업주의 위임이나 동의 없이 다른 사람에게 대리운전하게 하여 교통사고를 일으킨 경우',
	},
	{
		key: '2.10. (해고) 정당한 사유 없이 근로계약 또는 취업규칙 등을 위반하여 장기간 무단 결근한 경우',
		text: '2.10. (해고) 정당한 사유 없이 근로계약 또는 취업규칙 등을 위반하여 장기간 무단 결근한 경우',
		value:
			'2.10. (해고) 정당한 사유 없이 근로계약 또는 취업규칙 등을 위반하여 장기간 무단 결근한 경우',
	},
	{
		key: '2.11. (권고사직) 위 해고 사유로 인한 사업주 권고사직',
		text: '2.11. (권고사직) 위 해고 사유로 인한 사업주 권고사직',
		value: '2.11. (권고사직) 위 해고 사유로 인한 사업주 권고사직',
	},
];

//11월21일
export const salesPath = [
	{ key: '', text: ' ', value: '' },
	{ key: '우승제 매니저', text: '우승제 매니저', value: '우승제 매니저' },
	{ key: '김채린 매니저', text: '김채린 매니저', value: '김채린 매니저' },
	{ key: '김성아 매니저', text: '김성아 매니저', value: '김성아 매니저' },
	{ key: '김경모 노무사', text: '김경모 노무사', value: '김경모 노무사' },
	{ key: '이일선 노무사', text: '이일선 노무사', value: '이일선 노무사' },
];

export const recommendPath = [
	{ key: '', text: ' ', value: '' },
	{ key: '매니저', text: '매니저', value: '매니저' },
	{ key: '인터넷 검색', text: '인터넷 검색', value: '인터넷 검색' },
	{
		key: '네이버 스마트 스토어',
		text: '네이버 스마트 스토어',
		value: '네이버 스마트 스토어',
	},
	{ key: '크몽', text: '크몽', value: '크몽' },
	{ key: '002agentNomusa', text: '노무사소개', value: '노무사소개' },
	{ key: '003agentSemusa', text: '세무사소개', value: '세무사소개' },
	{ key: '004person', text: '지인소개', value: '지인소개' },
	{ key: '04etc', text: '기타', value: '기타' },
];

//11월21일
export const agentType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'ps노무사', text: 'ps노무사', value: 'ps노무사' },
	{ key: 'bs노무사', text: 'bs노무사', value: 'bs노무사' },
	{ key: 'ps세무사', text: 'ps세무사', value: 'ps세무사' },
	{ key: 'bs세무사', text: 'bs세무사', value: 'bs세무사' },
	{ key: 'ps변호사', text: 'ps변호사', value: 'ps변호사' },
	{ key: 'bs변호사', text: 'bs변호사', value: 'bs변호사' },
	{ key: 'bs설계사', text: '설계사', value: '설계사' },
	{ key: 'bs제약영업', text: '제약영업', value: '제약영업' },
	{ key: 'bs기타영업', text: '기타영업', value: '기타영업' },
	// {key: 'nomosaFirm', text: '노무법인', value: '노무법인'},
	// {key: 'semusaFirm', text: '세무법인', value: '세무법인'},
	// {key: 'wheugesaFirm', text: '회계법인', value: '회계법인'},
	// {key: 'wheugesa', text: '회계사(개인)', value: '회계사(개인)'},
	// {key: 'lawyerFirm', text: '법무법인', value: '법무법인'},
	{ key: 'etc', text: '기타', value: '기타' },
];
// ①②③④⑤⑥⑦⑧⑨⑩
export const nomuServiceType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '① 4대보험 입퇴사 신고 대행',
		text: '① 4대보험 입퇴사 신고 대행',
		value: '① 4대보험 입퇴사 신고 대행',
	},
	{
		key: '② 매월 4대보험료 정산 공제 임금대장 작성 대행',
		text: '② 매월 4대보험료 정산 공제 임금대장 작성 대행',
		value: '② 매월 4대보험료 정산 공제 임금대장 작성 대행',
	},
	{
		key: '③ 매월 지급 임금대장 작성 대행',
		text: '③ 매월 지급 임금대장 작성 대행',
		value: '③ 매월 지급 임금대장 작성 대행',
	},
	{
		key: '④ 입퇴사자 전산 노무 관리 대행',
		text: '④ 입퇴사자 전산 노무 관리 대행',
		value: '④ 입퇴사자 전산 노무 관리 대행',
	},
	{
		key: '⑤ 방문 노무관리 대행',
		text: '⑤ 방문 노무관리 대행',
		value: '⑤ 방문 노무관리 대행',
	},
];

export const nomuPackageServiceType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '⑥ Basic 패키지( ① + ② ) - 4대보험 신고 및 공제 임금대장 작성',
		text: '⑥ Basic 패키지( ① + ② ) - 4대보험 신고 및 공제 임금대장 작성',
		value: '⑥ Basic 패키지( ① + ② ) - 4대보험 신고 및 공제 임금대장 작성',
	},
	{
		key: '⑦ Standard 패키지( ① + ② + ③ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성',
		text: '⑦ Standard 패키지( ① + ② + ③ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성',
		value:
			'⑦ Standard 패키지( ① + ② + ③ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성',
	},
	{
		key: '⑧ Premium 패키지( ① + ② + ③ + ④ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성 및 입퇴사자 전산 노무 관리',
		text: '⑧ Premium 패키지( ① + ② + ③ + ④ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성 및 입퇴사자 전산 노무 관리',
		value:
			'⑧ Premium 패키지( ① + ② + ③ + ④ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성 및 입퇴사자 전산 노무 관리',
	},
	{
		key: '⑩ VIP 패키지( ① + ② + ③ + ④ + ⑤ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성 및 입퇴사자 전산 노무 관리 및 방문 노무관리',
		text: '⑩ VIP 패키지( ① + ② + ③ + ④ + ⑤ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성 및 입퇴사자 전산 노무 관리 및 방문 노무관리',
		value:
			'⑩ VIP 패키지( ① + ② + ③ + ④ + ⑤ ) - 4대보험 신고 및 공제 임금대장 작성 및 지급 임금대장 작성 및 입퇴사자 전산 노무 관리 및 방문 노무관리',
	},
];

export const companyProductEventType = [
	{ key: '', text: ' ', value: '' },
	{ key: '참여', text: '참여', value: '참여' },
	{ key: '미참여', text: '미참여', value: '미참여' },
];

//11월21일
export const payDateType = [
	{ key: '1일', text: '1일', value: '1일' },
	{ key: '5일', text: '5일', value: '5일' },
	{ key: '10일', text: '10일', value: '10일' },
	{ key: '15일', text: '15일', value: '15일' },
	{ key: '20일', text: '20일', value: '20일' },
	{ key: '26일', text: '26일', value: '26일' },
];

//11월21일
export const isCoWorkSemusaOrNot = [
	{ key: '', text: ' ', value: '' },
	{ key: 'yes', text: '제휴 세무사', value: '제휴 세무사' },
	{ key: 'no', text: '제휴 세무사 아님', value: '제휴 세무사 아님' },
];

export const isAgentOrNot = [
	{ key: '', text: ' ', value: '' },
	{ key: '스폰서', text: '스폰서', value: '스폰서' },
	{ key: '공식대행사', text: '공식대행사', value: '공식대행사' },
	{ key: '스폰서 아님', text: '스폰서 아님', value: '스폰서 아님' },
];

export const insureManageType = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'inup4InManage',
		text: '4대보험 노무사 위탁관리',
		value: '4대보험 노무사 위탁관리',
	},
	{ key: 'self4InManage', text: '4대보험 자체관리', value: '4대보험 자체관리' },
];

export const workerInOutManageType = [
	{ key: '', text: ' ', value: '' },
	{
		key: 'inup4InManage',
		text: '입퇴사 등록 노무사 위탁관리',
		value: '입퇴사 등록 노무사 위탁관리',
	},
	{ key: 'selfWorkerInOutManage', text: '자체관리', value: '자체관리' },
];

export const regionCode = [
	{ key: '', text: ' ', value: '' },
	{ key: '11', text: '서울특별시', value: '서울특별시' },
	{ key: '21', text: '부산광역시', value: '부산광역시' },
	{ key: '22', text: '대구광역시', value: '대구광역시' },
	{ key: '23', text: '인천광역시', value: '인천광역시' },
	{ key: '24', text: '광주광역시', value: '광주광역시' },
	{ key: '25', text: '대전광역시', value: '대전광역시' },
	{ key: '26', text: '울산광역시', value: '울산광역시' },
	{ key: '29', text: '세종특별자치시', value: '세종특별자치시' },
	{ key: '31', text: '경기도', value: '경기도' },
	{ key: '32', text: '강원도', value: '강원도' },
	{ key: '33', text: '충청북도', value: '충청북도' },
	{ key: '34', text: '충청남도', value: '충청남도' },
	{ key: '35', text: '전라북도', value: '전라북도' },
	{ key: '36', text: '전라남도', value: '전라남도' },
	{ key: '37', text: '경상북도', value: '경상북도' },
	{ key: '38', text: '경상남도', value: '경상남도' },
	{ key: '39', text: '제주특별자치도', value: '제주특별자치도' },
	{ key: '00', text: '전국', value: '전국' },
];

export const selectWorkDates = [
	'',
	'1일',
	'2일',
	'3일',
	'4일',
	'5일',
	'6일',
	'7일',
	'8일',
	'9일',
	'10일',
	'11일',
	'12일',
	'13일',
	'14일',
	'15일',
	'16일',
	'17일',
	'18일',
	'19일',
	'20일',
	'21일',
	'22일',
	'23일',
	'24일',
	'25일',
	'26일',
	'27일',
	'28일',
	'29일',
	'30일',
	'31일',
];

export const includeAnnualWage = [
	{ key: '', text: ' ', value: '' },
	{
		key: '년차 15일분 임금에 포함',
		text: '년차 15일분 임금에 포함',
		value: '년차 15일분 임금에 포함',
	},
	{
		key: '년차 15일분 임금에 미포함',
		text: '년차 15일분 임금에 미포함',
		value: '년차 15일분 임금에 미포함',
	},
];

export const contractOldWorkerOrNot = [
	{ key: '', text: ' ', value: '' },
	// {key: '일반계약직', text: '일반계약직', value: '일반계약직'},
	{
		key: '정년이후 촉탁계약직',
		text: '정년이후 촉탁계약직',
		value: '정년이후 촉탁계약직',
	},
];

export const netAge15To18OrNot = [
	{ key: '', text: ' ', value: '' },
	{ key: 'yes', text: '18세 미만 연소근로자', value: '18세 미만 연소근로자' },
	{ key: 'no', text: '일반근로자', value: '일반근로자' },
];

export const skipWeekType = [
	{ key: '', text: ' ', value: '' },
	{ key: 'all', text: '매주근무', value: '매주근무' },
	{ key: 'skip', text: '격주근무', value: '격주근무' },
];

export const companySizeType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '우선지원대상기업',
		text: '우선지원대상기업',
		value: '우선지원대상기업',
	},
	{ key: '대규모기업', text: '대규모기업', value: '대규모기업' },
];

export const moreSupportBizType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '지식서비스산업 관련 업종',
		text: '지식서비스산업 관련 업종',
		value: '지식서비스산업 관련 업종',
	},
	{
		key: '문화콘텐츠산업 관련 업종',
		text: '문화콘텐츠산업 관련 업종',
		value: '문화콘텐츠산업 관련 업종',
	},
	{
		key: '신․재생에너지산업 관련 업종 ',
		text: '신․재생에너지산업 관련 업종 ',
		value: '신․재생에너지산업 관련 업종 ',
	},
	{
		key: '청년 창업기업(39세미만 대표, 업력7년미만)',
		text: '청년 창업기업(39세미만 대표, 업력7년미만)',
		value: '청년 창업기업(39세미만 대표, 업력7년미만)',
	},
	{ key: '미래유망기업', text: '미래유망기업', value: '미래유망기업' },
	{ key: '지역주력산업', text: '지역주력산업', value: '지역주력산업' },
	{
		key: '고용위기지역 소재 기업',
		text: '고용위기지역 소재 기업',
		value: '고용위기지역 소재 기업',
	},
	{
		key: '특별고용지원업종 해당 기업',
		text: '특별고용지원업종 해당 기업',
		value: '특별고용지원업종 해당 기업',
	},
	{
		key: '위 어디에도 해당되지 않는 업종',
		text: '위 어디에도 해당되지 않는 업종',
		value: '위 어디에도 해당되지 않는 업종',
	},
];

export const nationality = [
	{ key: '100', text: '한국', value: '100' },
	{ key: '101', text: '아프가니스탄', value: '101' },
	{ key: '104', text: '바레인', value: '104' },
	{ key: '105', text: '방글라데쉬', value: '105' },
	{ key: '106', text: '부탄', value: '106' },
	{ key: '107', text: '브루나이', value: '107' },
	{ key: '108', text: '미얀마', value: '108' },
	{ key: '109', text: '영령인도양섬', value: '109' },
	{ key: '110', text: '캄보디아', value: '110' },
	{ key: '111', text: '스리랑카', value: '111' },
	{ key: '112', text: '중국', value: '112' },
	{ key: '113', text: '대만', value: '113' },
	{ key: '114', text: '키프로스', value: '114' },
	{ key: '118', text: '북한', value: '118' },
	{ key: '120', text: '홍콩', value: '120' },
	{ key: '121', text: '홍콩거주난민', value: '121' },
	{ key: '122', text: '한국계중국인', value: '122' },
	{ key: '124', text: '인도', value: '124' },
	{ key: '125', text: '인도네시아', value: '125' },
	{ key: '126', text: '이란', value: '126' },
	{ key: '127', text: '이라크', value: '127' },
	{ key: '128', text: '이스라엘', value: '128' },
	{ key: '130', text: '일본', value: '130' },
	{ key: '131', text: '요르단', value: '131' },
	{ key: '133', text: '카자흐스탄', value: '133' },
	{ key: '134', text: '키르기즈스탄공화국', value: '134' },
	{ key: '135', text: '쿠웨이트', value: '135' },
	{ key: '138', text: '라오스', value: '138' },
	{ key: '139', text: '레바논', value: '139' },
	{ key: '142', text: '마카오', value: '142' },
	{ key: '143', text: '말레이지아', value: '143' },
	{ key: '144', text: '몰디브', value: '144' },
	{ key: '145', text: '몽골', value: '145' },
	{ key: '146', text: '마요트', value: '146' },
	{ key: '148', text: '네팔', value: '148' },
	{ key: '150', text: '오만', value: '150' },
	{ key: '153', text: '파키스탄', value: '153' },
	{ key: '154', text: '팔레스타인', value: '154' },
	{ key: '155', text: '필리핀', value: '155' },
	{ key: '156', text: '동티모르', value: '156' },
	{ key: '159', text: '카타르', value: '159' },
	{ key: '162', text: '사우디아라비아', value: '162' },
	{ key: '163', text: '시킴왕국', value: '163' },
	{ key: '164', text: '싱가폴', value: '164' },
	{ key: '165', text: '시리아', value: '165' },
	{ key: '169', text: '타지키스탄', value: '169' },
	{ key: '170', text: '태국', value: '170' },
	{ key: '171', text: '터키', value: '171' },
	{ key: '172', text: '투르크메니스탄', value: '172' },
	{ key: '180', text: '아랍에미레이트', value: '180' },
	{ key: '181', text: '우즈베키스탄', value: '181' },
	{ key: '185', text: '베트남', value: '185' },
	{ key: '191', text: '예멘', value: '191' },
	{ key: '191', text: '마데이라', value: '191' },
	{ key: '192', text: '예멘인민민주공화국', value: '192' },
	{ key: '200', text: '안퀼라', value: '200' },
	{ key: '201', text: '안티구아바부다', value: '201' },
	{ key: '202', text: '아르헨티나', value: '202' },
	{ key: '203', text: '아루바', value: '203' },
	{ key: '205', text: '바하마', value: '205' },
	{ key: '206', text: '바베이도즈', value: '206' },
	{ key: '207', text: '벨리제', value: '207' },
	{ key: '208', text: '볼리비아', value: '208' },
	{ key: '209', text: '브라질', value: '209' },
	{ key: '210', text: '버뮤다 제도', value: '210' },
	{ key: '211', text: '부 베 트', value: '211' },
	{ key: '212', text: '케이맨', value: '212' },
	{ key: '213', text: '캐나다', value: '213' },
	{ key: '214', text: '칠레', value: '214' },
	{ key: '215', text: '콜롬비아', value: '215' },
	{ key: '216', text: '코스타리카', value: '216' },
	{ key: '217', text: '쿠바', value: '217' },
	{ key: '220', text: '도미니카연방', value: '220' },
	{ key: '221', text: '도미니카공화국', value: '221' },
	{ key: '223', text: '이스터 제도', value: '223' },
	{ key: '224', text: '에콰도르', value: '224' },
	{ key: '225', text: '엘살바도르', value: '225' },
	{ key: '226', text: '포클랜드섬', value: '226' },
	{ key: '227', text: '불령 가이아나', value: '227' },
	{ key: '229', text: '그레나다', value: '229' },
	{ key: '230', text: '과델로프', value: '230' },
	{ key: '231', text: '과테말라', value: '231' },
	{ key: '232', text: '가이아나', value: '232' },
	{ key: '235', text: '아이티', value: '235' },
	{ key: '236', text: '온두라스', value: '236' },
	{ key: '239', text: '싸이톱앤드프리시페', value: '239' },
	{ key: '240', text: '자마이카', value: '240' },
	{ key: '246', text: '디에고가르시아', value: '246' },
	{ key: '247', text: '아센손', value: '247' },
	{ key: '247', text: '마리티니크', value: '247' },
	{ key: '248', text: '멕시코', value: '248' },
	{ key: '249', text: '몬테세라트', value: '249' },
	{ key: '251', text: '네덜란드영안틸레스', value: '251' },
	{ key: '251', text: '이디오피아', value: '251' },
	{ key: '252', text: '니카라과', value: '252' },
	{ key: '255', text: '파나마', value: '255' },
	{ key: '256', text: '파라과이아', value: '256' },
	{ key: '257', text: '페루', value: '257' },
	{ key: '258', text: '푸에르토리코', value: '258' },
	{ key: '260', text: '남조지아남샌드위치군도', value: '260' },
	{ key: '261', text: '마다가스칼', value: '261' },
	{ key: '262', text: '세인트크리스토퍼 네비스', value: '262' },
	{ key: '263', text: '세인트루시아', value: '263' },
	{ key: '264', text: '세인트빈센트', value: '264' },
	{ key: '265', text: '수리남', value: '265' },
	{ key: '268', text: '트리니다드 토바고', value: '268' },
	{ key: '269', text: '턱스케이코스 제도', value: '269' },
	{ key: '274', text: '우르과이', value: '274' },
	{ key: '275', text: '미국', value: '275' },
	{ key: '276', text: '미국인근섬', value: '276' },
	{ key: '280', text: '베네쥬엘라', value: '280' },
	{ key: '281', text: '미국령버진도', value: '281' },
	{ key: '282', text: '영령 버진아일랜드', value: '282' },
	{ key: '301', text: '알바니아', value: '301' },
	{ key: '302', text: '안도라', value: '302' },
	{ key: '303', text: '오스트리아', value: '303' },
	{ key: '304', text: '아르메니아', value: '304' },
	{ key: '305', text: '아제르바이젠', value: '305' },
	{ key: '306', text: '벨지움', value: '306' },
	{ key: '307', text: '불가리아', value: '307' },
	{ key: '308', text: '벨라루스', value: '308' },
	{ key: '309', text: '보스니아헤르체코비나', value: '309' },
	{ key: '310', text: '체코', value: '310' },
	{ key: '311', text: '피로에', value: '311' },
	{ key: '312', text: '에스토니아', value: '312' },
	{ key: '313', text: '덴마크', value: '313' },
	{ key: '314', text: '영국보호민', value: '314' },
	{ key: '315', text: '영국속국민', value: '315' },
	{ key: '316', text: '영국', value: '316' },
	{ key: '317', text: '영국속령지시민', value: '317' },
	{ key: '318', text: '영국외지민', value: '318' },
	{ key: '319', text: '영국외지시민', value: '319' },
	{ key: '320', text: '핀랜드', value: '320' },
	{ key: '321', text: '프랑스', value: '321' },
	{ key: '322', text: '러시아', value: '322' },
	{ key: '323', text: '그루지아', value: '323' },
	{ key: '324', text: '독일', value: '324' },
	{ key: '325', text: '동독', value: '325' },
	{ key: '326', text: '그리스', value: '326' },
	{ key: '327', text: '지브롤터', value: '327' },
	{ key: '328', text: '그린랜드', value: '328' },
	{ key: '329', text: '헝가리', value: '329' },
	{ key: '333', text: '아이슬랜드', value: '333' },
	{ key: '334', text: '아일랜드', value: '334' },
	{ key: '335', text: '이태리', value: '335' },
	{ key: '337', text: '코소보', value: '337' },
	{ key: '339', text: '라트비아', value: '339' },
	{ key: '340', text: '리히텐슈타인', value: '340' },
	{ key: '341', text: '룩셈부르크', value: '341' },
	{ key: '342', text: '리튜아니아', value: '342' },
	{ key: '343', text: '마케도니아', value: '343' },
	{ key: '344', text: '몰타', value: '344' },
	{ key: '345', text: '모나코', value: '345' },
	{ key: '346', text: '몰도바', value: '346' },
	{ key: '347', text: '몬테네그로', value: '347' },
	{ key: '350', text: '네덜란드', value: '350' },
	{ key: '350', text: '지브랄타', value: '350' },
	{ key: '352', text: '노르웨이', value: '352' },
	{ key: '357', text: '싸이프러스', value: '357' },
	{ key: '360', text: '폴랜드', value: '360' },
	{ key: '361', text: '포르투갈', value: '361' },
	{ key: '365', text: '루마니아', value: '365' },
	{ key: '366', text: '러시아', value: '366' },
	{ key: '367', text: '세르비아', value: '367' },
	{ key: '368', text: '슬로바키아', value: '368' },
	{ key: '370', text: '슬로베니아', value: '370' },
	{ key: '371', text: '산마리노', value: '371' },
	{ key: '372', text: '스페인', value: '372' },
	{ key: '373', text: '스웨덴', value: '373' },
	{ key: '374', text: '스위스', value: '374' },
	{ key: '375', text: '스발바르', value: '375' },
	{ key: '378', text: '우크라이나', value: '378' },
	{ key: '380', text: '독립국가연합', value: '380' },
	{ key: '390', text: '바티칸시티', value: '390' },
	{ key: '391', text: '크로아티아', value: '391' },
	{ key: '395', text: '유고슬라비아', value: '395' },
	{ key: '396', text: '세르비아몬테네그로', value: '396' },
	{ key: '404', text: '호주', value: '404' },
	{ key: '411', text: '캐롤라인군도', value: '411' },
	{ key: '412', text: '쿡크제도', value: '412' },
	{ key: '413', text: '크리스마스섬', value: '413' },
	{ key: '414', text: '코코스제도', value: '414' },
	{ key: '418', text: '피지', value: '418' },
	{ key: '419', text: '불령폴리네시아', value: '419' },
	{ key: '420', text: '불령남태평양섬', value: '420' },
	{ key: '423', text: '괌', value: '423' },
	{ key: '425', text: '허드 맥도날드', value: '425' },
	{ key: '429', text: '키리바티', value: '429' },
	{ key: '434', text: '북마리아나군', value: '434' },
	{ key: '435', text: '마이크로네시아', value: '435' },
	{ key: '436', text: '마라아나 군도', value: '436' },
	{ key: '437', text: '마샬제도', value: '437' },
	{ key: '438', text: '미드웨이', value: '438' },
	{ key: '441', text: '나우르', value: '441' },
	{ key: '443', text: '뉴칼레도니아', value: '443' },
	{ key: '446', text: '뉴질랜드', value: '446' },
	{ key: '447', text: '니우에섬', value: '447' },
	{ key: '448', text: '노르퍽', value: '448' },
	{ key: '451', text: '팔라우', value: '451' },
	{ key: '452', text: '파푸뉴기나아', value: '452' },
	{ key: '454', text: '핏캐른', value: '454' },
	{ key: '461', text: '서사모아', value: '461' },
	{ key: '462', text: '미국령사모아', value: '462' },
	{ key: '463', text: '솔로몬제도', value: '463' },
	{ key: '464', text: '호주령 솔로몬군도', value: '464' },
	{ key: '473', text: '통가', value: '473' },
	{ key: '474', text: '토 켈 라 우', value: '474' },
	{ key: '475', text: '투발루', value: '475' },
	{ key: '485', text: '바누아투', value: '485' },
	{ key: '490', text: '웨이크아일랜드', value: '490' },
	{ key: '491', text: '웰리스푸투나', value: '491' },
	{ key: '502', text: '알제리아', value: '502' },
	{ key: '503', text: '앙골라', value: '503' },
	{ key: '506', text: '보츠와나', value: '506' },
	{ key: '507', text: '브룬디', value: '507' },
	{ key: '510', text: '카메룬', value: '510' },
	{ key: '511', text: '카나리제도', value: '511' },
	{ key: '512', text: '카푸버데', value: '512' },
	{ key: '513', text: '중앙아프리카', value: '513' },
	{ key: '514', text: '차드', value: '514' },
	{ key: '515', text: '코모로', value: '515' },
	{ key: '516', text: '콩고', value: '516' },
	{ key: '517', text: '콩고민주공화국', value: '517' },
	{ key: '520', text: '베넹', value: '520' },
	{ key: '521', text: '지브티', value: '521' },
	{ key: '525', text: '이집트', value: '525' },
	{ key: '526', text: '적도기니아', value: '526' },
	{ key: '527', text: '에티오피아', value: '527' },
	{ key: '528', text: '에리트리아', value: '528' },
	{ key: '530', text: '가봉', value: '530' },
	{ key: '531', text: '갬비아', value: '531' },
	{ key: '532', text: '가나', value: '532' },
	{ key: '533', text: '기니아', value: '533' },
	{ key: '534', text: '기나아비쏘우', value: '534' },
	{ key: '537', text: '코트디부와르', value: '537' },
	{ key: '540', text: '케냐', value: '540' },
	{ key: '542', text: '레스토', value: '542' },
	{ key: '543', text: '라이베리아', value: '543' },
	{ key: '544', text: '리비아', value: '544' },
	{ key: '550', text: '마다가스카르', value: '550' },
	{ key: '551', text: '말라워', value: '551' },
	{ key: '552', text: '말리', value: '552' },
	{ key: '553', text: '모리타이나', value: '553' },
	{ key: '554', text: '모리셔스', value: '554' },
	{ key: '555', text: '모로코', value: '555' },
	{ key: '556', text: '모잠비크', value: '556' },
	{ key: '560', text: '나미비아', value: '560' },
	{ key: '561', text: '니제르', value: '561' },
	{ key: '562', text: '나이제리아', value: '562' },
	{ key: '564', text: '레위니옹', value: '564' },
	{ key: '565', text: '짐바브웨', value: '565' },
	{ key: '566', text: '르완다', value: '566' },
	{ key: '571', text: '상투메프린시페', value: '571' },
	{ key: '572', text: '세네갈', value: '572' },
	{ key: '573', text: '세이셀', value: '573' },
	{ key: '574', text: '씨에라네온', value: '574' },
	{ key: '575', text: '소말리아', value: '575' },
	{ key: '576', text: '남아공화국', value: '576' },
	{ key: '577', text: '세인트헬레나', value: '577' },
	{ key: '578', text: '수단', value: '578' },
	{ key: '579', text: '스와질랜드', value: '579' },
	{ key: '583', text: '탄자니아', value: '583' },
	{ key: '584', text: '토고', value: '584' },
	{ key: '585', text: '튀니지', value: '585' },
	{ key: '588', text: '우간다', value: '588' },
	{ key: '589', text: '브르키나파소', value: '589' },
	{ key: '591', text: '서사하라', value: '591' },
	{ key: '594', text: '자이르', value: '594' },
	{ key: '595', text: '잠비아', value: '595' },
	{ key: '600', text: '무국적', value: '600' },
	{ key: '620', text: '남극', value: '620' },
	{ key: '666', text: '공해', value: '666' },
	{ key: '703', text: '한국(재외국민)', value: '703' },
	{ key: '704', text: '난민', value: '704' },
	{ key: '900', text: '국제연합', value: '900' },
	{ key: '999', text: '기타', value: '999' },
];

export const discountRateVale = [
	{ key: '', text: ' ', value: '' },
	{ key: '5%', text: '5%', value: '5%' },
	{ key: '10%', text: '10%', value: '10%' },
];

// 고용보험 납부
export const endless65EmploymentInsuranceType = [
	{ key: '', text: ' ', value: '' },
	{
		key: '65세 이전이후 근로단절 없이 입사',
		text: '65세 이전이후 근로단절 없이 입사',
		value: '65세 이전이후 근로단절 없이 입사',
	},
];

export const divideAnnualLeaveDayType = [
	{ key: '', text: ' ', value: '' },
	{ key: '없음', text: '없음', value: '없음' },
	{ key: '있음', text: '있음', value: '있음' },
];

//사학연금
export const teacherPensionPayTypeOption = [
	{ key: '', text: ' ', value: '' },
	{ key: 'none', text: '사학연금료 공제안함', value: '사학연금료 공제안함' },
	{
		key: 'thisMonthWage',
		text: '당월과세금액(매월변동반영)',
		value: '당월과세금액(매월변동반영)',
	},
	{
		key: 'fixed',
		text: '기준소득월액(사학연금공단에 신고된 금액)',
		value: '기준소득월액(사학연금공단에 신고된 금액)',
	},
];

export const isPrivateSchool = [
	{ key: '', text: ' ', value: '' },
	{
		key: '사립학교법 적용',
		text: '사립학교법 적용',
		value: '사립학교법 적용',
	},
	{
		key: '사립학교법 적용 안함',
		text: '사립학교법 적용 안함',
		value: '사립학교법 적용 안함',
	},
];
