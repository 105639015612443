import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import MyTextInput from '../../../app/common/form/MyTextInput';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import {
	decrypto,
	updateUserProfile,
} from '../../../app/firestore/firestoreService';
import {
	agentType,
	annualLeaves,
	companySizeType,
	divideAnnualLeaveDayType,
	insureManageType,
	moreSupportBizType,
	ownType,
	personalEvaluationUseType,
	regionCode,
	retirementPayType,
	roeConsultingType,
	ruleLaborCouncilConsultingType,
	rOEBizSecretConsultingType,
	workerInOutManageType,
	payDateType,
	isPrivateSchool,
} from '../../../app/api/dropdownOption';

import { useHistory } from 'react-router';
import MySelectInput from '../../../app/common/form/MySelectInput';
import { useSelector } from 'react-redux';

import CustomerServiceForm from './CustomerServiceForm';
import CustomerPromotionForm from './CustomerPromotionForm';
import CustomerBasicForm from './CustomerBasicForm';
import MyDateInput from '../../../app/common/form/MyDateInput';
import { format } from 'date-fns/esm';
import { isAgentF } from '../../../app/common/util/util';
import CustomerContractForm from './CustomerContractForm';
import MyNumberInput from '../../../app/common/form/MyNumberInput';

export default function ProfileForm({ profile }) {
	const history = useHistory();
	const thisYear = format(new Date(), 'yyyy');
	const { currentUserProfile } = useSelector((state) => state.profile);

	const [numberOfWorkers, setNumOfWorkers] = useState(
		currentUserProfile?.numOfWorkers || 1
	);
	const [openBizOwnType, setOpenBizOwnType] = useState(
		currentUserProfile?.ceoBirthNumber ? true : false
	);
	// 11월23일-공통
	const [openAnnualLeavesType, setOpenAnnualLeavesType] = useState(
		currentUserProfile?.finBaseDate ? true : false
	);

	const [openRoeConsulting, setOpenRoeConsulting] = useState(
		currentUserProfile?.roeConsulting === '취업규칙 컨설팅 요청' ? true : false
	);

	const [openRuleLaborCouncilConsulting, setOpenRuleLaborCouncilConsulting] =
		useState(
			currentUserProfile?.ruleLaborCouncilConsulting ===
				'노사협의회 컨설팅 요청'
				? true
				: false
		);

	const [openROEBizSecretConsulting, setOpenROEBizSecretConsulting] = useState(
		currentUserProfile?.rOEBizSecretConsulting === '영업비밀 컨설팅 요청'
			? true
			: false
	);

	const isWorker = currentUserProfile?.isWorker;
	const isAgent = isAgentF(
		currentUserProfile?.agentType,
		currentUserProfile?.authLevel
	);

	const isCoWorkSemusa =
		isAgent === '스폰서' && currentUserProfile?.agentType === 'bs세무사'
			? true
			: false;

	const decryptoData = {};
	for (const prop in profile) {
		decryptoData[prop] =
			prop === 'ceoSocialNumberFront' || prop === 'ceoSocialNumberBack'
				? decrypto(profile[prop], String(process.env.CRYPTO_KEY))
				: profile[prop];
	}

	const initialValues = decryptoData ?? {
		authLevel: profile.authLevel || 10,
		id: profile.id || '',
		companyName: profile.companyName || '',
		companyRegion: profile.companyRegion || '',
		salesPerson: profile.salesPerson || '',
		salesPath: profile.salesPath || '',
		recommendPath: profile.recommendPath || '',
		isCoWorkSemusa: profile.isCoWorkSemusa || '',
		isAgent: profile.isAgent || '',
		agentType: profile.agentType || '',
		agentRegion: profile.agentRegion || '',
		agentName: profile.agentName || '',
		agentUid: profile.agentUid || '',
		agentWorkerName: profile.agentWorkerName || '',
		agentWorkerPhone: profile.agentWorkerPhone || '',
		referralAgentCode: profile.referralAgentCode || '',
		price: profile.price || '',

		insureManageType: profile.insureManageType || '',

		bizOwnTypeValue: profile.bizOwnTypeValue || '',
		companyRegisterNumber: profile.companyRegisterNumber || '',
		ceoBirthNumber: profile.ceoBirthNumber || '',

		companyStatus: profile.companyStatus || '',
		regularProduct: profile.regularProduct || '',
		monthFee: profile.monthFee || '',
		payDay: profile.payDay || '',
		payType: profile.payType || '',
		serviceStartDate: profile.serviceStartDate || '',
		serviceEndDate: profile.serviceEndDate || new Date(),
		contractPath: profile.contractPath || '',
		contractEndDate: profile.companyContractEndDate || '',
		contractEndReason: profile.contractEndReason || '',
		irregularProduct1: profile.irregularProduct1 || '',
		irregularProductPrice1: profile.irregularProductPrice1 || '',
		enterFee: profile.enterFee || '',

		recommenderCode: profile.recommenderCode || '',
		recommenderPersonName: profile.recommenderPersonName || '',
		discountPermmitPerson: profile.discountPermmitPerson || '',
		discountPermmitReason: profile.discountPermmitReason || '',
		freeUsePermmitPerson: profile.freeUsePermmitPerson || '',
		freeUsePermmitReason: profile.freeUsePermmitReason || '',

		discountCode: profile.discountCode || '',
		discountRate: profile.discountRate || '',
		discountStartDate: profile.discountStartDate || '',
		discountEndDate: profile.discountEndDate || '',
		discountFee: profile.discountFee || '',
		freeUseMonth: profile.freeUseMonth || '',

		//고객기초정보
		ceoName: profile.ceoName || '',
		companyBizNumber: profile.companyBizNumber || '',
		bizType: profile.bizType || '',
		bizItem: profile.bizItem || '',
		staffName: profile.staffName || '',
		companyPhone: profile.companyPhone || '',
		companyAddress: profile.companyAddress || '',
		staffEmail: profile.staffEmail || '',
		cellPhone: profile.cellPhone || '',
		privateSchool: profile.privateSchool || '',
		//제도설계정보
		annualLeaves: profile.annualLeaves || '',
		finBaseDate: profile.finBaseDate || '',
		numOfWorkers: profile.numOfWorkers || '',
		normalWorkerWeekWorkDays: profile.normalWorkerWeekWorkDays || '',
		overFiveStartDay: profile.overFiveStartDay || '',
		perDayMealTaxFree: profile.perDayMealTaxFree || '',
		ruleLaborCouncilConsulting: profile.ruleLaborCouncilConsulting || '',
		rlcExcuteDueDate: profile.rlcExcuteDueDate || '',
		roeConsulting: profile.roeConsulting || '',
		roeExcuteDueDate: profile.roeExcuteDueDate || '',
		rOEBizSecretConsulting: profile.rOEBizSecretConsulting || '',
		bizSecretExcuteDueDate: profile.bizSecretExcuteDueDate || '',
		retirementPay: profile.retirementPay || '',
		nomuService: profile.nomuService || '',
		nomuPackageService: profile.nomuPackageService || '',
		companyProductEvent: profile.companyProductEvent || '',
		specialContractMonthPrice: profile.specialContractMonthPrice || '',
		specialContractSettingPrice: profile.specialContractSettingPrice || '',

		//자동이체 통장정보
		companyBankName: profile.companyBankName || '',
		companyBankAccount: profile.companyBankAccount || '',
		companyBankAccountOwner: profile.companyBankAccountOwner || '',
		companyWageWorkComment: profile.companyWageWorkComment || '',
		workerInOutManage: profile.workerInOutManage || '',
	};

	return (
		<>
			{isWorker ? null : (
				<>
					<h1>Information of Company</h1>
					<Formik
						initialValues={initialValues}
						validationSchema={Yup.object({
							ceoName: Yup.string().required('대표자명'),
							companyBizNumber: Yup.string().required('사업자등록번호.'),
							companyAddress: Yup.string().required('회사 주소.'),
							companyRegion: Yup.string().required('회사 지역.'),
							bizType: Yup.string().required('업태'),
							bizItem: Yup.string().required('종목'),
							staffName: Yup.string().required('담당자 이름.'),
							companyPhone: Yup.string().required('회사 담당자 연락처.'),
							staffEmail: Yup.string().required('담당자 이메일.'),
							annualLeaves: Yup.string().required('연차설정'),
							numOfWorkers: Yup.number().required('직원수는 필수'),
						})}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								await updateUserProfile(values);
								history.push('/clientReqs4In');
							} catch (error) {
								console.log(error.message);
							} finally {
								setSubmitting(false);
							}
						}}>
						{({ isSubmitting, isValid, dirty, values }) => (
							<Form className='ui form'>
								{currentUserProfile.authLevel >= 100 && (
									<>
										<hr />
										{/* 11월21일 */}
										<CustomerBasicForm
											authLevel={currentUserProfile.authLevel}
											isCoWorkSemusa={isCoWorkSemusa}
										/>
										<hr />
										<CustomerServiceForm />
										<hr />
										<CustomerPromotionForm
											authLevel={currentUserProfile.authLevel}
										/>
										<hr />
									</>
								)}
								<h3>고객기본정보</h3>
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										justifyContent: 'flex-start',
										alignItems: 'center',
										alignContent: 'flex-end',
									}}>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='대표자명'
											name='ceoName'
											placeholder='대표자명'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='사업자번호(10자리)'
											name='companyBizNumber'
											placeholder='①②③-④⑤-⑥⑦⑧⑨⑩'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MySelectInput
											label='회사 지역'
											name='companyRegion'
											placeholder='회사지역'
											options={regionCode}
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='회사 상세 주소'
											name='companyAddress'
											placeholder='회사 상세 주소'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='업태'
											name='bizType'
											placeholder='사업자등록증 참조'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='종목'
											name='bizItem'
											placeholder='사업자등록증 참조'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='회사 담당자명'
											name='staffName'
											placeholder='홍길동 대리'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='회사 연락처'
											name='companyPhone'
											placeholder='회사 연락처'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='회사담당자 이메일'
											name='staffEmail'
											placeholder='회사 담당자 이메일'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MySelectInput
											label='사립학교법 적용 여부'
											name='privateSchool'
											placeholder='사립학교법 적용 여부'
											autoComplete='off'
											options={isPrivateSchool}
										/>
									</div>
								</div>
								<hr />
								<h3>제도설계정보</h3>
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										justifyContent: 'flex-start',
										alignItems: 'center',
										alignContent: 'flex-end',
									}}>
									<div
										className='margin'
										style={{ width: '25%', marginRight: '3px' }}>
										<MySelectInput
											onChangeCallback={setOpenAnnualLeavesType}
											label='연차 설정(법정 VS 특정일(회계년도 등))'
											name='annualLeaves'
											options={annualLeaves}
											autoComplete='off'
										/>
									</div>
									{!openAnnualLeavesType ||
									openAnnualLeavesType === '5인미만 연차미부여' ||
									openAnnualLeavesType === '입사일(법정)기준' ? null : (
										<div
											className='margin'
											style={{ width: '24%', marginRight: '3px' }}>
											<MyDateInput
												name='finBaseDate'
												label={`${thisYear}년 연차부여 특정일(회계년도 등)기준`}
												placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
												timeCaption='time'
												dateFormat='yyyy-MM-dd'
												autoComplete='off'
											/>
										</div>
									)}
									<div
										className='margin'
										style={{ width: '25%', marginRight: '3px' }}>
										<MyNumberInput
											label='회사 임의 부여 추가 연차일수(일)'
											name='plusAnnualLeavesDays'
											// placeholder='2'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '25%', marginRight: '3px' }}>
										<MySelectInput
											label='연차 1일 분할 사용(반차, 반반차 등)'
											name='divideAnnualLeavesDay'
											placeholder='있음 / 없음'
											options={divideAnnualLeaveDayType}
											autoComplete='off'
										/>
									</div>

									{numberOfWorkers > 100 ? (
										<div
											className='margin'
											style={{ width: '33%', marginRight: '3px' }}>
											<MySelectInput
												label='우선지원 대상기업 여부'
												name='companySize'
												placeholder='대규모 기업 vs 우선지원 대상기업'
												options={companySizeType}
												autoComplete='off'
											/>
										</div>
									) : null}
									{numberOfWorkers < 5 ? (
										<div
											className='margin'
											style={{ width: '33%', marginRight: '3px' }}>
											<MySelectInput
												label='청년도약장려금 5인미만도 지원금을 주는 업종 여부'
												name='moreSupportBiz'
												placeholder='지식서비스업, 미래유망업종 등 '
												options={moreSupportBizType}
												autoComplete='off'
											/>
										</div>
									) : null}
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MySelectInput
											label='퇴직금 설정'
											name='retirementPay'
											placeholder='DC형 퇴직연금'
											options={retirementPayType}
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MySelectInput
											label='4대보험관리'
											name='insureManageType'
											placeholder='자체 관리'
											options={insureManageType}
											autoComplete='off'
										/>
									</div>
								</div>
								<hr />
								{/*  <h3>계약 정보</h3> */}
								<CustomerContractForm
									values={values}
									setNumOfWorkers={setNumOfWorkers}
									currentUserProfile={currentUserProfile}
								/>
								<br />
								<hr />
								<h3>자동이체 계좌 정보</h3>
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										justifyContent: 'flex-start',
										alignItems: 'center',
										alignContent: 'flex-end',
									}}>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MySelectInput
											label='자동이체 시작일'
											name='payDay'
											placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
											options={payDateType}
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='회사 통장 은행명'
											name='companyBankName'
											placeholder='회사통장'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='계좌번호'
											name='companyBankAccount'
											placeholder='회사통장 계좌번호'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='예금주명'
											name='companyBankAccountOwner'
											placeholder='주의 : 법인은 법인명'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MyTextInput
											label='대표자 또는 회계담당자 휴대전화번호'
											name='cellPhone'
											placeholder='휴대전화번호'
											autoComplete='off'
										/>
									</div>
									<div
										className='margin'
										style={{ width: '33%', marginRight: '3px' }}>
										<MySelectInput
											label='사업자 구분(법인/개인)'
											name='bizOwnTypeValue'
											onChangeCallback={setOpenBizOwnType}
											placeholder='법인'
											options={ownType}
											autoComplete='off'
										/>
									</div>
									{!openBizOwnType || openBizOwnType === '법인' ? null : (
										<div
											className='margin'
											style={{ width: '33%', marginRight: '3px' }}>
											<MyTextInput
												label='대표자 생년월일(주민번호 앞자리)'
												name='ceoBirthNumber'
												placeholder=''
												autoComplete='off'
											/>
										</div>
									)}
								</div>
								<hr />
								{currentUserProfile?.authLevel >= 100 ? (
									<>
										<h3>유료 서비스 설정</h3>
										<div
											style={{
												display: 'flex',
												flexWrap: 'wrap',
												justifyContent: 'flex-start',
												alignItems: 'center',
												alignContent: 'flex-end',
											}}>
											<div
												className='margin'
												style={{ width: '33%', marginRight: '3px' }}>
												<MySelectInput
													label='입퇴사등록관리'
													name='workerInOutManage'
													placeholder='자체 관리'
													options={workerInOutManageType}
													autoComplete='off'
												/>
											</div>
											<div
												className='margin'
												style={{ width: '33%', marginRight: '3px' }}>
												<MySelectInput
													onChangeCallback={setOpenRoeConsulting}
													label='취업규칙 컨설팅 요청 여부'
													name='roeConsulting'
													placeholder='취업규칙 컨설팅 요청여부'
													options={roeConsultingType}
													autoComplete='off'
												/>
											</div>
											{openRoeConsulting === '취업규칙 컨설팅 요청' ? (
												<div
													className='margin'
													style={{ width: '33%', marginRight: '3px' }}>
													<MyDateInput
														name='roeExcuteDueDate'
														label='취업규칙 시행일'
														placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
														timeCaption='time'
														dateFormat='yyyy-MM-dd'
														autoComplete='off'
													/>
												</div>
											) : null}
											<div
												className='margin'
												style={{ width: '33%', marginRight: '3px' }}>
												<MySelectInput
													onChangeCallback={setOpenRuleLaborCouncilConsulting}
													label='노사협의회 컨설팅 요청 여부'
													name='ruleLaborCouncilConsulting'
													placeholder='노사협의회 컨설팅 요청여부'
													options={ruleLaborCouncilConsultingType}
													autoComplete='off'
												/>
											</div>
											{openRuleLaborCouncilConsulting ===
											'노사협의회 컨설팅 요청' ? (
												<div
													className='margin'
													style={{ width: '33%', marginRight: '3px' }}>
													<MyDateInput
														name='rlcExcuteDueDate'
														label='노사협의회 규정 시행일'
														placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
														timeCaption='time'
														dateFormat='yyyy-MM-dd'
														autoComplete='off'
													/>
												</div>
											) : null}

											<div
												className='margin'
												style={{ width: '33%', marginRight: '3px' }}>
												<MySelectInput
													onChangeCallback={setOpenROEBizSecretConsulting}
													label='영업비밀보호 컨설팅 요청 여부'
													name='rOEBizSecretConsulting'
													placeholder='영업비밀보호 컨설팅 요청여부'
													options={rOEBizSecretConsultingType}
													autoComplete='off'
												/>
											</div>
											{openROEBizSecretConsulting === '영업비밀 컨설팅 요청' ? (
												<div
													className='margin'
													style={{ width: '33%', marginRight: '3px' }}>
													<MyDateInput
														name='bizSecretExcuteDueDate'
														label='영업비밀보호 규정 시행일'
														placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
														timeCaption='time'
														dateFormat='yyyy-MM-dd'
														autoComplete='off'
													/>
												</div>
											) : null}
											<div
												className='margin'
												style={{ width: '33%', marginRight: '3px' }}>
												<MySelectInput
													label='BSC기반 인사고과(Premium 이상시 사용가능)'
													name='personalEvaluation'
													placeholder='BSC기반 인사고과 사용여부'
													options={personalEvaluationUseType}
													autoComplete='off'
												/>
											</div>
										</div>
									</>
								) : (
									<>
										<h1>
											부과 회사제도 설계 및 전산 등록 유료 서비스는
											02-555-9356으로 문의주세요.
										</h1>

										<h3>1. 취업규칙 컨설팅 및 취업규칙 전산 등록 서비스</h3>
										<h3>
											2. 노사협의회 규정 컨설팅 및 노사협의회 규정 전산 등록
											서비스
										</h3>
										<h3>
											3. 영업비밀보호 규정 컨설팅 및 영업비밀보호 규정 전산 등록
											서비스
										</h3>
										<h3>4. BSC기반 인사고과 기능 서비스</h3>
									</>
								)}

								{currentUserProfile?.authLevel >= 100 && (
									<>
										<hr />
										<h3>관리회사 정보</h3>
										<div
											style={{
												display: 'flex',
												flexWrap: 'wrap',
												justifyContent: 'flex-start',
												alignItems: 'center',
												alignContent: 'flex-end',
											}}>
											<div
												className='margin'
												style={{ width: '33%', marginRight: '3px' }}>
												<MyTextInput
													label='관리회사 (회사)명'
													name='agentName'
													placeholder='예) 노무법인 인업'
												/>
											</div>
											<div
												className='margin'
												style={{ width: '33%', marginRight: '3px' }}>
												<MyTextInput
													label='관리회사 코드'
													name='agentUid'
													placeholder='관리회사에게 문의하세요.'
												/>
											</div>
											<div
												className='margin'
												style={{ width: '33%', marginRight: '3px' }}>
												<MySelectInput
													label='관리회사 종류'
													name='agentType'
													options={agentType}
												/>
											</div>
											<div
												className='margin'
												style={{ width: '33%', marginRight: '3px' }}>
												<MyTextInput
													label='관리회사 담당자명'
													name='agentWorkerName'
													placeholder='관리회사 담당자'
												/>
											</div>
											<div
												className='margin'
												style={{ width: '33%', marginRight: '3px' }}>
												<MyTextInput
													label='관리회사 연락처'
													name='agentWorkerPhone'
													placeholder='관리회사 담당자 연락처'
												/>
											</div>
										</div>
									</>
								)}
								<Button
									loading={isSubmitting}
									disabled={isSubmitting || !isValid || !dirty}
									floated='right'
									type='submit'
									size='large'
									positive
									content='회사정보 수정/저장'
								/>
							</Form>
						)}
					</Formik>
					<br />
					<br />
				</>
			)}
		</>
	);
}
