import React from 'react';
import { Button } from 'semantic-ui-react';
import { clickConfirmedTimeOfLaborContract } from '../../../../../app/firestore/firestoreService4In';
import { delay } from '../../../../../app/common/util/util';
import ClientReqDashboard4InCalendar from './ClientReqDashboard4InCalendar';

export default function ClientReqDashboard4InWorker({
	companyNameUn,
	isDayWorker,
	isBizWorker,
	laborContractId,
	history,
	companyId,
	workerName,
}) {
	function goToPaydocus() {
		history.push('/paydocus');
	}

	function viewLaborContractHandlerWorker(laborContractId) {
		delay(1000);
		history.push(`/clientReqs4In/${laborContractId}`);
		clickConfirmedTimeOfLaborContract(laborContractId);
	}

	function viewLaborContractHandlerDayWorker(laborContractId) {
		delay(1000);
		history.push(`/clientReqDaylyPayContract/${laborContractId}`);
		clickConfirmedTimeOfLaborContract(laborContractId);
	}

	return (
		<>
			<hr />
			{isBizWorker ? (
				<Button
					style={{ color: 'blue', background: 'yellow' }}
					onClick={() => goToPaydocus()}>
					사업소득 명세서 보러가기
				</Button>
			) : (
				<>
					<h3>
						*{' '}
						<span style={{ color: 'blue', background: 'yellow' }}>
							{companyNameUn}
						</span>{' '}
						귀하에게 AI 노무사를 통해 제공하는 근로계약서와 임금계약서는
						근로기준법 제17조에 따른 소정근로시간, 휴가, 휴일, 임금의 구성항목,
						계산방법, 지급방법이 명시된 계약서이며, <br />
						<br /> 임금명세서는 근로기준법 제48조 제2항 및 동법 시행령 제27조의
						2에 따른 임금의 구성항목, 계산방법, 근로자를 특정할 수 있는 정보,
						임금지급일, 임금총액, 임금의 구성항목별 금액, 임금의 구성항목별
						금액이 근무일수/시간 등에 따라 달라지는 경우 그 계산방법을 명시한
						임금명세서임을 안내해 드립니다.
					</h3>
					{isDayWorker ? (
						<Button
							style={{ color: 'blue', background: 'yellow' }}
							onClick={() =>
								viewLaborContractHandlerDayWorker(laborContractId)
							}>
							근로계약서 보러가기
						</Button>
					) : (
						<Button
							style={{ color: 'blue', background: 'yellow' }}
							onClick={() => viewLaborContractHandlerWorker(laborContractId)}>
							근로계약서 보러가기
						</Button>
					)}
					<Button
						style={{ color: 'blue', background: 'yellow' }}
						onClick={() => goToPaydocus()}>
						임금명세서 보러가기
					</Button>
				</>
			)}
			<hr />
			<ClientReqDashboard4InCalendar
				workerId={laborContractId}
				companyId={companyId}
				workerName={workerName}
			/>
		</>
	);
}
