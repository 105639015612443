import React from 'react';
import { Table } from 'semantic-ui-react';

export default function ClientReqDLCWageTableDetailAWage7({
	gumakStyle,
	centerStyle,
	inputWageStyle,
	inputCommentStyle,
	cAWage7,
	cAWEName7,
	cAWage7Comment,
	handelInputChange,
}) {
	return (
		<Table.Row textAlign='center'>
			<Table.Cell className='gubun' style={centerStyle}>
				평균임금
			</Table.Cell>
			<Table.Cell className='gumak' style={centerStyle}>
				<input
					style={inputWageStyle}
					name='cAWEName7'
					value={cAWEName7}
					onChange={(e) => handelInputChange(e)}
				/>
			</Table.Cell>
			<Table.Cell className='gumak' style={gumakStyle}>
				<input
					style={inputWageStyle}
					name='cAWage7'
					value={cAWage7}
					onChange={(e) => handelInputChange(e)}
				/>
			</Table.Cell>
			<Table.Cell textAlign='left' colSpan='7' className='sulmung'>
				<textarea
					style={inputCommentStyle}
					name='cAWage7Comment'
					value={cAWage7Comment}
					onChange={(e) => handelInputChange(e)}
				/>
			</Table.Cell>
		</Table.Row>
	);
}
